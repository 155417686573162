'use strict';


const angular = require('angular');
const ngResource = require('angular-resource');

export default angular.module('freshideas.resources.company', [ngResource])
    .service('Company', ['$resource', 'EnvConfig', function ($resource, EnvConfig) {
        return $resource(EnvConfig.host + '/freshideas/web/company', {}, {
            'liteCompanies': {method: 'GET', cache: false, url: EnvConfig.host + '/freshideas/web/company/lite'},
            'list': {method: 'GET', cache: false, url: EnvConfig.host + '/freshideas/web/company/list', isArray: true},
            'pagedList': {method: 'GET', cache: false, url: EnvConfig.host + '/freshideas/web/company/list/v2'},
            'deleteCompany': {method: 'DELETE', url: EnvConfig.host + '/freshideas/web/company'},
            'getDefaultCompany': {method: 'GET', url: EnvConfig.host + '/freshideas/web/company/default'},
            'add': {method: 'POST', url: EnvConfig.host + '/freshideas/web/company'},
            'update': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/company'},
            'upgrade': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/company/upgrade'},
            'updateTimezone': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/company/timezone'},
            'updateAttributes': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/company/attributes'},
            'testSolinkConnection': {method: 'POST', url: EnvConfig.host + '/freshideas/web/solink/testEvent'},
            'completeSetup': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/company/completeSetup'},
            'createLoyaltyMealPlan': {method: 'POST', url: EnvConfig.host + '/freshideas/web/company/loyaltyMealPlan'},
            'deleteSecondaryDisplaySlide': {method: 'DELETE', url: EnvConfig.host + '/freshideas/web/company/secondary-display'},
            'updateSecondaryDisplay': {method: 'POST', url: EnvConfig.host + '/freshideas/web/company/secondary-display'},
            'sevenshiftsImport': {method: 'GET', url: EnvConfig.host + '/freshideas/web/company/sevenshiftsImport'},
            'sevenshiftsExport': {method: 'GET', url: EnvConfig.host + '/freshideas/web/company/sevenshiftsExport'},
            'checkSevenshiftsConnectivity': {method: 'GET', url: EnvConfig.host + '/freshideas/web/company/checkSevenshiftsConnectivity'},
            'checkADPConnectivity': {method: 'GET', url: EnvConfig.host + '/freshideas/web/company/checkADPConnectivity'},
            'adpImport': {method: 'GET', url: EnvConfig.host + '/freshideas/web/company/adpImport'},
            'updateCurrencyAndLanguage': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/company/currencyAndLanguage'},
            'deleteImage': {method: 'DELETE', url: EnvConfig.host + '/freshideas/web/company/image'},
            'updateMobileFeatureToggles': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/company/mobileFeatureToggles'},
            'clearLoginSessions': {method: 'DELETE', url: EnvConfig.host + '/freshideas/web/company/clearLoginSessions'},
            'delete': {method: 'DELETE', url: EnvConfig.host + '/freshideas/web/company/delete'},
            'updateServiceConfig': {method: 'POST', url: EnvConfig.host + '/freshideas/web/company/service-config'}
        });
    }]);
