'use strict';


const angular = require('angular');
const ngResources = require('angular-resource');

export default angular.module('freshideas.resources.users', [ngResources])
    .service('Users', ['$resource', 'EnvConfig', function ($resource, EnvConfig) {
        return $resource(EnvConfig.host + '/freshideas/web/user', {}, {
            'getDefaultUser': {method: 'GET', url: EnvConfig.host + '/freshideas/web/users/default'},
            'listUsers': {method: 'GET', cache: false, url: EnvConfig.host + '/freshideas/web/users/list'},
            'update': {method: 'POST', url: EnvConfig.host + '/freshideas/web/users'},
            'add': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/users'},
            'deleteUser': {method: 'DELETE', url: EnvConfig.host + '/freshideas/web/users'},
            'logUserActivity': {method: 'POST', url: EnvConfig.host + '/freshideas/web/userActivity/log'},
            'validatePin': {method: 'POST', url: EnvConfig.host + '/freshideas/web/users/validatePin'},
            'clockIn': {method: 'POST', url: EnvConfig.host + '/freshideas/web/users/clockIn'},
            'clockOut': {method: 'POST', url: EnvConfig.host + '/freshideas/web/users/clockOut'},
            'getClockedInUsers': {method: 'GET', url: EnvConfig.host + '/freshideas/web/users/clockedIn', isArray: true},
            'takeABreak': {method: 'POST', url: EnvConfig.host + '/freshideas/web/users/takeABreak'},
            'endBreak': {method: 'POST', url: EnvConfig.host + '/freshideas/web/users/endBreak'},
            'getEmployeeTimecards': {method: 'GET', cache: false, url: EnvConfig.host + '/freshideas/web/users/timecards'},
            'addOfflineTimecard': {method: 'POST', url: EnvConfig.host + '/freshideas/web/users/offlineTimecards'},
            'updateTimecard': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/users/timecards'},
            'getTimecardEditHistory': {method: 'GET', cache: false, url: EnvConfig.host + '/freshideas/web/users/timecardDetails', isArray: true},
            'addLabour': {method: 'POST', url: EnvConfig.host + '/freshideas/web/users/labour'},
            'getUsersForOffline': {method: 'GET', url: EnvConfig.host + '/freshideas/web/users/getUsersForOffline'}
        });
    }])
    .service('Patrons', ['$resource', 'EnvConfig', function ($resource, EnvConfig) {
        return $resource(EnvConfig.host + '/freshideas/web/patron', {}, {
            'getDefaultPatron': {method: 'GET', url: EnvConfig.host + '/freshideas/web/patron/default'},
            'getPatron': {method: 'GET', url: EnvConfig.host + '/freshideas/web/patron'},
            'byPatronKey': {method: 'GET', url: EnvConfig.host + '/freshideas/web/patron/byPatronKey'},
            'byPatronEmailAddress': {method: 'GET', url: EnvConfig.host + '/freshideas/web/patron/byPatronEmailAddress'},
            'listPatrons': {method: 'GET', cache: false, url: EnvConfig.host + '/freshideas/web/patron/list'},
            'listPatronsByStatus': {method: 'GET', cache: false, url: EnvConfig.host + '/freshideas/web/patron/listByStatus'},
            'getPatronMealPlanList': {method: 'GET', isArray: true, url: EnvConfig.host + '/freshideas/web/patron/getPatronMealPlanList'},
            'getArchivedPatronMealPlanList': {method: 'GET', isArray: true, url: EnvConfig.host + '/freshideas/web/patron/getArchivedPatronMealPlanList'},
            'updatePatron': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/patron/updatePatron'},
            'archivePatron': {method: 'DELETE', url: EnvConfig.host + '/freshideas/web/patron/archivePatron'},
            'archivePatronMealPlan': {method: 'POST', url: EnvConfig.host + '/freshideas/web/patron/archivePatronMealPlan'},
            'unarchivePatronMealPlan': {method: 'POST', url: EnvConfig.host + '/freshideas/web/patron/unarchivePatronMealPlan'},
            'getUninitializedCompanyMeal': {method: 'GET', isArray: true, url: EnvConfig.host + '/freshideas/web/patron/getUninitializedCompanyMeal'},
            'getCompanyMeal': {method: 'GET', url: EnvConfig.host + '/freshideas/web/patron/getCompanyMeal', isArray: true},
            'update': {method: 'POST', url: EnvConfig.host + '/freshideas/web/patron'},
            'batchUpdate': {method: 'POST', url: EnvConfig.host + '/freshideas/web/patron/batch'},
            'adjustMealBalance': {method: 'POST', url: EnvConfig.host + '/freshideas/web/patron/adjustMealBalance'},
            'add': {method: 'PUT', url: EnvConfig.host + '/freshideas/web/patron'},
            'deletePatron': {method: 'DELETE', url: EnvConfig.host + '/freshideas/web/patron'},
            'getPatronHistory': {method: 'GET', url: EnvConfig.host + '/freshideas/web/patron/patronTransactionHistory', isArray: true},
            'getPatronHistoryV2': {method: 'GET', url: EnvConfig.host + '/freshideas/web/patron/customerHistory', isArray: true},
            'getPatronPinnedNotes': {method: 'GET', url: EnvConfig.host + '/freshideas/web/patron/patronPinnedNotes', isArray: true},
            'getpatronTransactionItem': {method: 'GET', url: EnvConfig.host + '/freshideas/web/patron/patronTransactionItemDetails', isArray: true},
            'patronsCount': {method: 'GET', url: EnvConfig.host + '/freshideas/web/patron/patronCount'},
            'schoolCardMask': {method: 'GET', url: EnvConfig.host + '/freshideas/web/patron/schoolCardMask'},
            'patronsTransactionCount': {method: 'GET', url: EnvConfig.host + '/freshideas/web/patron/patronTransactionCount'},
            'patronAddNewAdjustment': {method: 'POST', url: EnvConfig.host + '/freshideas/web/patron/patronAddNewAdjustment'},
            'patronAddNote': {method: 'POST', url: EnvConfig.host + '/freshideas/web/patron/patronAddNote'},
            'patronAddNewDeposit': {method: 'POST', url: EnvConfig.host + '/freshideas/web/patron/patronAddNewDeposit'},
            'patronAddNewRefund': {method: 'POST', url: EnvConfig.host + '/freshideas/web/patron/patronAddNewRefund'},
            'patronsChangeByStatus': {method: 'POST', url: EnvConfig.host + '/freshideas/web/patron/updatePatronsStatus'},
            'addAdjustmentBatch': {method: 'POST', url: EnvConfig.host + '/freshideas/web/patron/addAdjustmentBatch'},
            'addDepositBatch': {method: 'POST', url: EnvConfig.host + '/freshideas/web/patron/addDepositBatch'},
            'addRefundBatch': {method: 'POST', url: EnvConfig.host + '/freshideas/web/patron/addRefundBatch'},
            'updatePatronsMealPlan': {method: 'POST', url: EnvConfig.host + '/freshideas/web/patron/updatePatronsMealPlan'},
            'removePatronsMealPlan': {method: 'POST', url: EnvConfig.host + '/freshideas/web/patron/removePatronsMealPlan'},
            'getTopItems': {method: 'GET', url: EnvConfig.host + '/freshideas/web/patron/topItems', isArray: true},
            'getLoyaltyDetails': {method: 'GET', cache: false, url: EnvConfig.host + '/freshideas/web/patron/loyaltyDetails'},
            'getAllAvailableGCs': {method: 'GET', url: EnvConfig.host + '/freshideas/web/patron/availableGiftCards', isArray: true},
            'patronsByEmail': {method: 'GET', url: EnvConfig.host + '/freshideas/web/patron/patronsByEmail', isArray: true}
        });
    }]);
