'use strict';


const angular = require('angular');
const Decimal = require('decimal.js').default;
const Controller = new (require('../../external/pos.controller.js'))();
const SharedFunctionService = require('../../external/pos.meal-plan-calculations.js');
const SharedDataService = require('../../external/pos.data-service.js');

module.exports = function (posModule) {

    posModule.controller('PosModify', [
        '$scope',
        '$window',
        '$modal',
        '$modalInstance',
        '$translate',
        'CashierShift',
        'CurrentSession',
        'PosAlertService',
        'parent',
        'item',
        'isEdit',
        '$timeout',
        'applyReceiptItemChangeFunction',
        'adjustments',
        'Security',
        'createPOSDataFunction',
        'CompanyAttributesService',
        'KioskService',
        'SmbPosService',
        'patron',
        function (
            $scope,
            $window,
            $modal,
            $modalInstance,
            $translate,
            CashierShift,
            CurrentSession,
            PosAlertService,
            parent,
            item,
            isEdit,
            $timeout,
            applyReceiptItemChangeFunction,
            adjustments,
            Security,
            createPOSDataFunction,
            CompanyAttributesService,
            KioskService,
            SmbPosService,
            patron) {

            $scope.patron = patron;
            $scope.isLoyalty = (!!patron && !!patron.patronId);
            $scope.selectedModifiersList = [];
            $scope.maxSelectionOverride = Number.POSITIVE_INFINITY;
            $scope.itemPromoDisabled = CompanyAttributesService.itemPromoDisabled();
            $scope.isItemDiscountDisabled = CompanyAttributesService.isItemDiscountDisabled();

            const calculateMaxSelectionOverride = () => {
                let limit = Number.POSITIVE_INFINITY;

                $scope.selectedModifiersList.forEach((mod) => {
                    if (mod.maxSelectionOverride !== undefined &&
                        mod.maxSelectionOverride > -1 &&
                        mod.maxSelectionOverride < limit) {
                        // finding the min among all selected mods
                        limit = mod.maxSelectionOverride;
                    }
                });

                return limit;
            };

            const clearCategorySelections = (category) => {
                category.children.forEach((item) => {
                    if (item.selected) {
                        toggleItem(category, item);
                    }
                });
            };

            if (KioskService.isKiosk()) {
                $scope.$on('kiosk::go-home', function () {
                    $modalInstance.dismiss();
                });

                $scope.toggleCategoryAccordion = function (category) {
                    category.selected = !category.selected;
                };

                $scope.openCategoryAccordion = function (category) {
                    category.selected = true;
                };

                $scope.closeCategoryAccordion = function (category) {
                    category.selected = false;
                };

                $scope.favouritePopularItemsKioskEnabled = CompanyAttributesService.enableFavouritePopularItemsKiosk();
                $scope.itemPopularModifiers = [];
                var populatePopularItemModifiers = function () {
                    $timeout(function () {
                        SmbPosService.getPopularItemModifiers($scope.modItem.locationServicePeriodMenuId,
                            $scope.locationDetail.locationId, 3)
                            .then((response) => {
                                let popularModifiers = response.posMenuItemEntries[0].entries;
                                for (let popularModifier of popularModifiers) {
                                    for (let category of $scope.categories) {
                                        for (let modifier of category.children) {
                                            if (modifier.locationServicePeriodMenuId == popularModifier.locationServicePeriodMenuId) {
                                                $scope.itemPopularModifiers.push(modifier);
                                                break;
                                            }
                                        }
                                    }
                                }

                            })
                            .catch((error) => {
                                console.log('Error getting this item\'s top modifiers');
                            });
                    });
                };

                $scope.popularModifierClick = function (modifierId) {
                    $timeout(function () {
                        angular.element(document.querySelector('[id=\'' + modifierId + '\']')).click();
                    });
                };

                $scope.modifierPage = 'required';
                $scope.switchModifierPage = function (page) {
                    if (page == 'optional' && !isValid().success) {
                        return;
                    }
                    $scope.modifierPage = page;
                };

                $scope.locationIsInOntario = false;
                var isLocationIsInOntario = function () {
                    $scope.locationIsInOntario = ($scope.locationDetail.region === 'ON');
                };
            }

            $scope.modParent = parent;
            $scope.modItem = Object.assign({}, item);
            $scope.modItem.visualPrice = new Decimal(
                $scope.modItem.visualPrice || ($scope.modItem.price * $scope.modItem.quantity)
            ).toNearest(SharedDataService.baseDollar);

            if ($scope.modItem.visualTotal) {
                $scope.modItem.visualTotal = new Decimal($scope.modItem.visualTotal).toNearest(SharedDataService.baseDollar);
            } else {
                var decimalSubtotal = new Decimal($scope.modItem.price).toNearest(SharedDataService.baseDollar);
                var decimalTaxRate = new Decimal(1 + $scope.modItem.taxRate);
                var decimalTax = decimalSubtotal.times(decimalTaxRate).toNearest(SharedDataService.baseDollar);

                $scope.modItem.visualTotal = decimalSubtotal.plus(decimalTax);
            }

            $scope.modItem.discount = $scope.modItem.discount || {};
            $scope.modItem.discount.percentage = $scope.modItem.discount.percentage || 0.00;
            $scope.modItem.discount.price = $scope.modItem.discount.price || 0.00;
            $scope.modItem.discount.visualVal = $scope.modItem.discount.visualVal || 0.00;
            $scope.modItem.discount.type = $scope.modItem.discount.type || 'percentage';
            $scope.modItem.totalIndividualPrice = $scope.modItem.totalIndividualPrice || 0.00;
            $scope.isEdit = isEdit;
            $scope.fullItem = [];
            $scope.categories = $scope.modItem.children;
            $scope.transItemText = {};
            $scope.transItemText.text = angular.copy($scope.modItem.notes) || '';
            $scope.transItemText.sideBarIndex = 1;
            $scope.transItemVisualDiscount = angular.copy($scope.modItem.visualDiscount) || {};
            $scope.transItemVisualDiscount.sideBarIndex = 2;
            $scope.toShowNote = ($scope.transItemText.text != '' || $scope.modItem.discount.visualVal != 0.00) ? false : true;
            $scope.transactionAdjustments = Object.assign({}, adjustments);
            $scope.modItem.isPriceChanged = $scope.modItem.isPriceChanged || false;
            $scope.modItem.originalPrice = new Decimal($scope.modItem.originalPrice || $scope.modItem.price);

            $scope.requiredCategories = $scope.categories.filter(function (category) {
                return category && category.minSelection != -1;
            });

            $scope.optionalCategories = $scope.categories.filter(function (category) {
                return category && category.minSelection == -1;
            });

            if (KioskService.isKiosk()) {
                if ($scope.optionalCategories.length) {
                    $scope.optionalCategories.forEach($scope.openCategoryAccordion);
                }
            }

            $scope.config = {
                taxIncludedInPrice: SharedDataService.taxIncludedInPrice
            };

            var viewIndex = 0;
            _.each($scope.categories, function (category) {
                if (!category) {
                    return;
                }

                category.isValid = true;
                category.toAnimate = false;
                category.viewIndex = viewIndex;
                category.selectedCount = category.selectedCount || 0;
                var modViewIndex = 0;
                var isActive = false;
                _.each(category.children, function (modifier) {
                    if (modifier.selected) {
                        $scope.toShowNote = false;
                    }
                    modifier.visualName = (modifier.name.length > 35) ? modifier.name.substring(0, 34) + '...'
                        : modifier.name;
                    modifier.viewIndex = modViewIndex;
                    modViewIndex++;
                    modifier.originalPrice = new Decimal(modifier.originalPrice || modifier.price);
                    modifier.multiplier = (modifier.multiplier == undefined) ? 1 : modifier.multiplier;
                    if (item.multiplier == 0 || item.multiplier == 0.5) {
                        item.multiplierInWords = $translate.instant('pos.menu.modifier.multiplier.' + item.multiplier);
                    } else {
                        item.multiplierInWords = item.multiplier + 'x';
                    }

                    if (modifier.active) {
                        isActive = true;
                    }
                });

                category.active = isActive && category.posEnabled;
                viewIndex++;
            });

            $scope.isDiscountEnabled = function () {
                return CompanyAttributesService.enableItemLevelOpenDiscount();
            };

            $scope.canApplyDiscounts = (item.subtype && item.subtype.includes('giftcard')) ? CompanyAttributesService.isPGCDiscountable() : true;

            // copy of item before modification; used for comparison
            const originalItem = angular.copy(item);

            /*
            Commented by Nick Simone 2021/10/13
            Here we are passing the quantity of the base item for the case that we only want to increase the
            quantity of the item and not change the modifiers. In this case we do not want to reprint the
            entire quantity of the item as updated and just print the new one(s). In order to do this, we
            check if the ratio of the modifier quantities to the base item quantity are the same as they were
            originally.
            */
            const kitchenPrintComparisonData = (rootData, baseQuantity) => {
                let obj = {
                    children: []
                };

                if (rootData.subtype !== 'discount' && rootData.subtype !== 'loyalty') {
                    // attributes (for comparison) relevant for kitchen printing
                    obj.locationServicePeriodMenuId = rootData.locationServicePeriodMenuId;
                    obj.quantity = rootData.quantity / baseQuantity;
                    obj.selected = rootData.selected;
                } else {
                    return null;
                }

                if (rootData.children) {
                    rootData.children.forEach((itemData) => {
                        const child = kitchenPrintComparisonData(itemData, baseQuantity);
                        if (child !== null) {
                            obj.children.push(child);
                        }
                    });
                }

                return obj;
            };

            /** Function to save the changes of the modal
            **  Calls the validation function and closes the
            **  modal if validation passes , or animates the
            **  invalid section
            **/
            $scope.save = function () {
                for (var i = 0; i < $scope.modItem.children.length; i++) {
                    var child = $scope.modItem.children[i];
                    child.isValid = true;
                }

                var valid = isValid();
                if (valid.success) {
                    $scope.modItem._timestamp = Date.now();

                    // item notes have been changed
                    if ($scope.modItem.notes !== $scope.transItemText.text) {
                        $scope.modItem.updatedSinceLastKitchenPrint = true;
                    } else { // item modifiers have been changed
                        // We are not comparing all of modItem and originalItem because not all difference are relevant for the kitchen printer
                        let originalItemComparisonData = kitchenPrintComparisonData(originalItem, originalItem.quantity);
                        let modItemComparisonData = kitchenPrintComparisonData($scope.modItem, $scope.modItem.quantity);
                        // discounts (null itemComparisonData) are ignored by kitchen printer, so we will leave updatedSinceLastKitchenPrint as is for null case
                        if (originalItemComparisonData !== null && modItemComparisonData !== null) {
                            $scope.modItem.updatedSinceLastKitchenPrint = !_.isEqual(originalItemComparisonData, modItemComparisonData);
                        }
                    }

                    // We need to reprint all modifiers if the base item was changed
                    if ($scope.modItem.updatedSinceLastKitchenPrint) {
                        setChildrenAsModified($scope.modItem);
                    }

                    // Save the value and name of this discount so we can report them to Solink more easily
                    if ($scope.modItem.discount) {
                        $scope.modItem.discount.totalAmount = $scope.transItemVisualDiscount.price;
                        $scope.modItem.discount.name = $scope.transItemVisualDiscount.name;
                    }

                    $scope.fullItem = Controller.buildItem($scope.modItem, 0, 0);
                    $scope.modItem.totalIndividualPrice = $scope.buildItemDiscountAmount($scope.fullItem).toNumber();
                    $scope.modItem.visualDiscount = $scope.transItemVisualDiscount;
                    $scope.modItem.notes = $scope.transItemText.text;
                    $modalInstance.close({
                        parent: $scope.modParent,
                        item: $scope.modItem,
                        isEdit: $scope.isEdit
                    });
                }
            };

            const setChildrenAsModified = (item) => {
                if (!item.children) {
                    return;
                }
                item.children.forEach((child) => {
                    child.updatedSinceLastKitchenPrint = true;
                    setChildrenAsModified(child);
                });
            };

            /**
            ** Validation function to validate that all
            **  requirements of each modifier category is met.
            ** Breaks from the loop and returns false if the validation fails
            **/
            var isValid = function () {
                if (!$scope.modItem.children) {
                    return {'success': true};
                }
                for (var i = 0; i < $scope.modItem.children.length; i++) {
                    var child = $scope.modItem.children[i];
                    // Disabled for kiosk
                    if (!KioskService.isKiosk()) {
                        var childDiv = $window.document.getElementById('modCategory_' + child.viewIndex);
                    }
                    if (child.subtype === 'discount' || child.subtype === 'loyalty') {
                        continue;
                    }

                    if (!child.active) {
                        continue;
                    }

                    if (child.minSelection !== -1 && (!child.selectedCount || (child.selectedCount < child.minSelection && child.minSelection <= child.children.length))) {
                        child.isValid = false;
                        child.toAnimate = true;

                        // Disabled for kiosk
                        if (!KioskService.isKiosk()) {
                            childDiv.scrollIntoView();
                        }
                        return {'success': false, 'message': 'You need to select at least ' + child.minSelection + ' item' + (child.minSelection === 1 ? '' : 's') + ' under ' + child.name};
                    }

                    if (child.maxSelection !== -1 && child.selectedCount > child.maxSelection) {
                        child.isValid = false;
                        child.toAnimate = true;
                        return {'success': false, 'message': 'You can only select ' + child.maxSelection + ' item' + (child.maxSelection === 1 ? '' : 's') + ' under ' + child.name};
                    }
                }

                return {'success': true};
            };

            $scope.findParentOptionalCategory = (item) => {
                return _.find($scope.optionalCategories, (category) => {
                    return _.find(category.children, {locationServicePeriodMenuId: item.locationServicePeriodMenuId});
                });
            };

            /**
            ** Function called on click of any modifier button
            ** Takes in parameter of the parent category, modifier item selected
            ** and an optional paramater, mentioning if it is to be toggled Off or not
            **/
            $scope.btnClicked = function (parent, item, toToggleOff = true) {
                var hasChildren = false; // item.children && item.children.length > 0;

                if (hasChildren) {
                    modifyItem(parent, item);
                } else {
                    var toggled = toggleItem(parent, item, toToggleOff);
                    $timeout(function () {
                        parent.isValid = toggled.success;
                        parent.toAnimate = false;
                    }, 0);
                }
            };


            /**
            ** Function to build the amount to display
            ** Next to the main item. This function is currently only being called by calculate Discount,
            ** where we are also calculating the visual amount to display.
            ** It also populates a map of modifiers (locationMenuId) and their respective price to show on
            ** the sidebar.
            ** Takes in a param of flat list of items returned by the function Controller.buildItem function
            **/
            $scope.buildVisualAmount = function (items) {
                $scope.modifierPrices = {};
                var overallPrice = new Decimal(0.00);
                var overallTotal = new Decimal(0.00);
                for (var item of items) {
                    if (item.level !== 0) {
                        $scope.modifierPrices[item.locationServicePeriodMenuId] = item.price;
                    }

                    if (!(item.subtype === 'discount' || item.subtype === 'loyalty')) {
                        overallPrice = overallPrice.plus(item.price);
                        overallTotal = overallTotal.plus(item.total);
                    }
                }
                return {
                    price: overallPrice,
                    total: overallTotal
                };
            };


            /**
            ** Function to build the amount on which discount is to be applied
            ** This function is called whenever an item is toggled or whenvever a new percentage is set,
            ** basically, any change relating to price happening and when its being calculated again
            ** Takes in a param of flat list of items returned by the function Controller.buildItem function
            **/
            $scope.buildItemDiscountAmount = function (items) {
                var totalAmountToDiscountFrom = new Decimal(0.00);
                for (var item of items) {
                    if (!(item.subtype === 'discount' || item.subtype === 'loyalty') && item.price > 0.00) {
                        totalAmountToDiscountFrom = totalAmountToDiscountFrom.plus(item.price);
                    }
                }
                return totalAmountToDiscountFrom;
            };


            /**
            ** Function to build the visual Discount to be displayed on the modal
            ** This function is called whenever an item is toggled or whenvever a new percentage is set,
            ** basically, any change relating to price happening and when its being calculated again
            ** Takes in a param of flat list of items returned by the function Controller.buildItem function
            **/
            function buildVisualDiscount (receiptItem) {
                var {price: decimalOriginalPrice, total: decimalOriginalTotal} = $scope.buildVisualAmount(receiptItem);

                var calculatedBalances = SharedFunctionService.calculateBalances(receiptItem, null, $scope.transactionAdjustments, createPOSDataFunction(), $scope.patron);

                $scope.modItem.visualPrice = new Decimal(calculatedBalances.payments.subTotalAmount);
                $scope.modItem.visualTotal = new Decimal(calculatedBalances.payments.totalAmount);

                // leave 2 decimal places for percentage display (eg. 12.75%)
                var tempVisualDiscount = new Decimal($scope.modItem.discount.percentage || 0).times(100).toNearest(0.01);
                $scope.transItemVisualDiscount.price = -(decimalOriginalPrice.minus($scope.modItem.visualPrice).toNearest(SharedDataService.baseDollar));
                $scope.transItemVisualDiscount.total = -(decimalOriginalTotal.minus($scope.modItem.visualTotal).toNearest(SharedDataService.baseDollar));

                if ($scope.modItem.discount.subtype === 'promo') {
                    $scope.transItemVisualDiscount.name = $translate.instant('smb.pos.receipt.item.discount.promo.label', {
                        itemName: $scope.modItem.name
                    });
                } else if ($scope.modItem.discount.subtype === 'labelled') {
                    $scope.transItemVisualDiscount.name = $translate.instant('smb.pos.receipt.item.discount.labelled.label', {
                        itemName: $scope.modItem.name,
                        labelledDiscount: $scope.modItem.discount.labelledDiscount
                    });
                } else {
                    $scope.transItemVisualDiscount.name = $translate.instant('smb.pos.receipt.item.discount.percentage.label', {
                        itemName: $scope.modItem.name,
                        discountPercentage: tempVisualDiscount.toFixed(2)
                    });
                }

                // Code to hide the notes on the side bar
                var transItemVisualDiscountPrice = new Decimal($scope.transItemVisualDiscount.price).toNearest(SharedDataService.baseDollar);
                if (transItemVisualDiscountPrice !== 0) {
                    $scope.toShowNote = false;
                }
            }

            /**
            ** Function to toggle the type of dicount applied on the item.
            ** This function is called whenever any discount type button is clicked
            ** If the passed discount type is not selected already, it will reset the discount applied and
            ** select the passed discount type for the item. Discount type - "price" and "percent"
            **/
            $scope.toggleDiscountType = function (discountType) {
                if ($scope.modItem.discount.type != discountType) {
                    $scope.modItem.discount.type = discountType;
                    $scope.modItem.discount.percentage = 0.00;
                    $scope.modItem.discount.price = 0.00;
                    $scope.modItem.discount.visualVal = 0.00;
                    $scope.fullItem = Controller.buildItem($scope.modItem, 0, 0);
                    $scope.modItem.totalIndividualPrice = $scope.buildItemDiscountAmount($scope.fullItem).toNumber();
                    applyReceiptItemChangeFunction($scope.modItem, $scope.transactionAdjustments, true, true);
                    $scope.fullItem = Controller.buildItem($scope.modItem, 0, 0);
                    buildVisualDiscount($scope.fullItem);
                }
            };

            /**
            ** Function to change the value of the discount text area
            ** This function is called whenever any discount type button is clicked
            ** Based on the discount type selected, it will open the respective numpad and
            ** on saving the amount, will build the discount again
            **/
            $scope.editDiscount = function () {
                // Handle discounts coming from backend
                if ($scope.transactionAdjustments.percentDiscount || $scope.transactionAdjustments.dollarDiscount
                    || $scope.modItem.isPriceChanged) {
                    return;
                }

                var percentValidator = function (oldVal, newVal) {
                    if (parseInt(oldVal + '' + newVal) > 100) {
                        return false;
                    }
                    return true;
                };

                var priceValidator = function (oldVal, newVal) {
                    var decimalAmountToValidate = (SharedDataService.taxIncludedInPrice)
                        ? $scope.modItem.visualTotal
                        : $scope.modItem.visualPrice;

                    if (parseInt(oldVal + '' + newVal) > parseInt(decimalAmountToValidate.times(100).toNumber())) {
                        return false;
                    }
                    return true;
                };

                if ($scope.modItem.discount.type == 'percentage') {
                    return showPinPad('percentage', $scope.modItem.discount.percentage, checkIfAuthenticationRequired, percentValidator);
                } else if ($scope.modItem.discount.type == 'price') {
                    return showPinPad('currency', $scope.modItem.discount.price, checkIfAuthenticationRequired, priceValidator);
                }
            };

            /**
            ** Function to change the base price of the main Item or any modifier
            ** This function is called whenever the button for price change is clicked
            ** It will open a popup to change the price
            ** on saving the prices, will call the callback function onEditPrice
            **/
            $scope.editItemPrice = function () {
                $scope.modItem.price = $scope.modItem.price.toNumber(); // Keeping it consistent for editPriceModal
                var modifyModal = $modal.open({
                    templateUrl: 'pos/pos-modify/pos-modify-price.tpl.html',
                    controller: 'PosModifyPrice',
                    windowClass: 'mods-price-modal',
                    animation: false,
                    resolve: {
                        item: function () {
                            return $scope.modItem;
                        },
                        adjustments: function () {
                            return $scope.transactionAdjustments;
                        }

                    },
                    backdrop: true
                }, function (error) {
                });
                modifyModal.result.then(function (obj) {
                    $scope.modItem = obj;
                    $scope.modItem.price = new Decimal($scope.modItem.price);
                    if ($scope.modItem.isPriceChanged) {
                        resetAppliedItemDiscount();
                    }
                    onEditPrice();
                }, function () {
                    $scope.modItem.price = new Decimal($scope.modItem.price);
                });
            };


            /**
            ** Callback Function to be executed on resolve of the price
            ** change modifier
            **/
            var onEditPrice = function () {
                $scope.fullItem = Controller.buildItem($scope.modItem, 0, 0);
                applyReceiptItemChangeFunction($scope.modItem, $scope.transactionAdjustments, true, true);
                $scope.fullItem = Controller.buildItem($scope.modItem, 0, 0);
                buildVisualDiscount($scope.fullItem);
            };


            /**
            ** Function to reset any discount applied on the item
            **/
            var resetAppliedItemDiscount = function () {
                $scope.modItem.discount.percentage = 0.00;
                $scope.modItem.discount.price = 0.00;
                $scope.modItem.discount.visualVal = 0.00;
                $scope.modItem.discount.type = 'percentage';
                $scope.transItemVisualDiscount = {};
                $scope.transItemVisualDiscount.sideBarIndex = 2;

                $scope.fullItem = Controller.buildItem($scope.modItem, 0, 0);
                $scope.modItem.totalIndividualPrice = $scope.buildItemDiscountAmount($scope.fullItem).toNumber();
                applyReceiptItemChangeFunction($scope.modItem, $scope.transactionAdjustments, true, true);
                $scope.fullItem = Controller.buildItem($scope.modItem, 0, 0);
                buildVisualDiscount($scope.fullItem);
            };

            /**
            ** Callback function on edit Discount to check if any authentication is required or not
            **/
            var checkIfAuthenticationRequired = function (editedValue) {
                var params = {};
                params.requestedPermission = 'pos:cashier_discount_limit';
                var isManagerOverrideRequired = false;
                params.callback = function (pinResponse) {
                    onEditDiscount(editedValue, false);
                };
                params.errorCallback = function (error) {
                    var message = '';
                    if (error) {
                        var exception = error.exception || {};
                        if (exception.appCode === 412) {
                            message = 'general.error.cashier-discout-fail-invalid-manager-pin.msg';
                        } else {
                            message = exception.message || '';
                        }
                        PosAlertService.showAlertByName('cashier-discout-fail', {
                            message: message
                        });
                    }
                };
                params.forceCheckIfManagerLogin = true;
                params.message = 'general.popup.manager-pin.ttl';
                if ($scope.isDiscountLimitActive) {
                    if ($scope.modItem.discount.type == 'percentage') {
                        if (parseFloat(editedValue) > parseFloat($scope.discountLimit)) {
                            isManagerOverrideRequired = true;
                        }
                    } else if ($scope.modItem.discount.type == 'price') {
                        isManagerOverrideRequired = true;
                    }

                }

                if (isManagerOverrideRequired) {
                    $scope.$emit('PincodeAuthentication:Required', params);
                } else {
                    onEditDiscount(editedValue, false);
                }
            };

            var onEditDiscount = function (editedValue, isPromo, labelledDiscount) {
                if ($scope.modItem.discount.type == 'percentage') {
                    $scope.modItem.discount.percentage = parseFloat(editedValue);
                    $scope.modItem.discount.visualVal = new Decimal(editedValue * 100).toFixed(0);
                } else if ($scope.modItem.discount.type == 'price') {
                    if (editedValue) {
                        $scope.modItem.discount.price = parseFloat(editedValue);
                        $scope.fullItem = Controller.buildItem($scope.modItem, 0, 0);
                        var originalTotalAmt = $scope.buildItemDiscountAmount($scope.fullItem);
                        $scope.modItem.discount.percentage = new Decimal($scope.modItem.discount.price).dividedBy(originalTotalAmt);
                    }
                    $scope.modItem.discount.visualVal = new Decimal(editedValue).toNearest(SharedDataService.baseDollar);
                }

                if (labelledDiscount) {
                    $scope.modItem.discount.labelledDiscount = labelledDiscount;
                    $scope.modItem.discount.subtype = 'labelled';
                } else if (isPromo) {
                    delete $scope.modItem.discount.labelledDiscount;
                    $scope.modItem.discount.subtype = 'promo';
                } else {
                    delete $scope.modItem.discount.labelledDiscount;
                    delete $scope.modItem.discount.subtype;
                }

                applyReceiptItemChangeFunction($scope.modItem, $scope.transactionAdjustments, true, true);
                $scope.fullItem = Controller.buildItem($scope.modItem, 0, 0);
                buildVisualDiscount($scope.fullItem);
            };

            /**
            ** Function to set Focus on any html div or textarea.
            ** Currently focuses on only 3 types of areas to focus on as per the btnType - 'discount', 'notes' & 'sideabar'
            ** Notes and discount focus on the respective text areas
            ** Sidebar puts the focus at the end of the overflow list
            **/
            $scope.setFocus = function (btnType) {
                var discountTextArea = $window.document.getElementById('discount_text_area');
                var notesTextArea = $window.document.getElementById('notes_text_area');
                var sideBarDiv = $window.document.getElementById('mod_categories_sidebar_id');
                if (discountTextArea && btnType == 'discount') {
                    discountTextArea.scrollIntoView(true);
                    discountTextArea.focus();
                } else if (notesTextArea && btnType == 'notes') {
                    notesTextArea.scrollIntoView(true);
                    notesTextArea.focus();
                } else if (sideBarDiv && btnType == 'sideBar') {
                    sideBarDiv.scrollTop = sideBarDiv.scrollHeight;
                }
            };

            /**
            ** Function to set Focus on any html div or textarea.
            ** Currently focuses on only 3 types of areas to focus on as per the btnType - 'discount', 'notes' & 'sideabar'
            ** Notes and discount focus on the respective text areas
            ** Sidebar puts the focus at the end of the overflow list
            **/
            $scope.updateItemModifiersAsPerQuantityModifier = function (item, quantMod) {
                _.each(item.children, function (category) {
                    _.each(category.children, function (modifier) {
                        if (modifier.selected) {
                            var individualItemModifierQuantity = 0;
                            if (quantMod == 'plus') {
                                individualItemModifierQuantity = modifier.quantity / (item.quantity - 1);
                            } else {
                                individualItemModifierQuantity = modifier.quantity / (item.quantity + 1);
                            }
                            modifier.quantity = individualItemModifierQuantity * item.quantity;
                        }
                    });
                });
            };


            /**
            ** Function to modify quantity of the main item.
            ** Two modifiers - 'plus' and 'minus'
            ** Updates the modifier quantites as per the new main item quantity
            ** Calls build Item to update the total and build entire item
            **/
            $scope.modifyQuantity = function (item, modifier) {
                if (modifier == 'plus') {
                    item.quantity++;
                    $scope.updateItemModifiersAsPerQuantityModifier(item, modifier);
                    $scope.fullItem = Controller.buildItem($scope.modItem, 0, 0);
                    $scope.modItem.totalIndividualPrice = $scope.buildItemDiscountAmount($scope.fullItem).toNumber();
                    applyReceiptItemChangeFunction($scope.modItem, $scope.transactionAdjustments, true, true);
                    $scope.fullItem = Controller.buildItem($scope.modItem, 0, 0);
                    buildVisualDiscount($scope.fullItem);
                } else if (modifier == 'minus') {
                    if (item.quantity > 1) {
                        item.quantity--;
                        $scope.updateItemModifiersAsPerQuantityModifier(item, modifier);
                        $scope.fullItem = Controller.buildItem($scope.modItem, 0, 0);
                        $scope.modItem.totalIndividualPrice = $scope.buildItemDiscountAmount($scope.fullItem).toNumber();
                        applyReceiptItemChangeFunction($scope.modItem, $scope.transactionAdjustments, true, true);
                        $scope.fullItem = Controller.buildItem($scope.modItem, 0, 0);
                        buildVisualDiscount($scope.fullItem);
                    }
                }
            };

            /**
            ** Function to check if any toggle made is valid or not
            ** Parameters are parent category, the modifier toggled and to check
            ** if multiple are allowed or not
            **/
            var isToggleValid = function (parent, newModifier, checkIfMultipleNotAllowed = false) {
                if (parent.allowMultipleSelection === false && checkIfMultipleNotAllowed) {
                    for (var child in parent.children) {
                        if (parent.children[child].selected) {
                            if (newModifier.locationServicePeriodMenuId != parent.children[child].locationServicePeriodMenuId) {
                                return false;
                            }
                            if (parent.children[child].multiplier > 1) {
                                return false;
                            }
                        }
                    }
                } else if (parent.allowMultipleSelection === true && parent.maxSelection == -1) {
                    return true;
                } else if (parent.allowMultipleSelection === true) {
                    if (!newModifier.selected && newModifier.multiplier == 1 && parent.selectedCount >= parent.maxSelection) {
                        return false;
                    }
                    if (newModifier.selected && newModifier.multiplier == 1 && parent.selectedCount > parent.maxSelection) {
                        return false;
                    }
                    if (newModifier.multiplier != 1 && parent.selectedCount > parent.maxSelection) {
                        return false;
                    }
                }

                return true;
            };

            /**
            ** Function to toggle the multiplier using the + and - buttons on the modifer
            ** Parameters are parent category, the modifier toggled and the multiplierDirection -> 'minus' and 'plus'
            **/
            $scope.toggleMultiplier = function (parent, item, multiplierDirection) {
                if (multiplierDirection == 'minus') {
                    if (item.multiplier == 20) {
                        toggleItem(parent, item, false, false);
                    } else if (item.multiplier >= 1 && item.multiplier <= 19) {
                        toggleItem(parent, item, false, false);
                    } else if (item.multiplier == 0.5) {
                        toggleItem(parent, item, false, false);
                    }

                } else {
                    if (item.multiplier == 0) {
                        toggleItem(parent, item, false, true);
                    } else if (item.multiplier == 0.5) {
                        toggleItem(parent, item, false, true);
                    } else if (item.multiplier >= 1 && item.multiplier <= 19) {
                        toggleItem(parent, item, false, true);
                    }
                }

                if (KioskService.isKiosk() && item.multiplier === 0) {
                    // toggle the item off
                    toggleItem(parent, item, true);
                }

            };

            $scope.canIncreaseMultiplier = (category) => {
                return (category.selectedCount < category.selectionLimit) || category.selectionLimit == -1;
            };

            /**
            ** Function to toggle a modifier.
            ** being called at multiple places, either to toggle it off completely or to toggle multipliers
            ** Parameters are parent category, the modifier toggled, to Toggle Off or if it is a carousel
            ** type of flow for multipliers or not
            **/
            var toggleItem = function (parent, item, toToggleOff = true, isCarousel = true) {
                var toFocus = false;
                var modifierMul = 1;
                if (item.selected) {
                    if (toToggleOff) {
                        item.quantity = 0;
                        item.selected = false;
                        parent.selectedCount -= (item.multiplier > 1 || item.multiplier == 0) ? item.multiplier : 1;
                        item.multiplier = 1;
                        toFocus = true;
                        $scope.selectedModifiersList.forEach(function (selectedModifier, index) {
                            if (selectedModifier.locationServicePeriodMenuId == item.locationServicePeriodMenuId) {
                                $scope.selectedModifiersList.splice(index, 1);
                            }
                        });
                    } else {
                        if (item.multiplier == 0) {
                            item.multiplier = 0.5;
                            parent.selectedCount += 1;
                        } else if (item.multiplier == 0.5) {
                            // Will be called when minus button clicked
                            if (!isCarousel) {
                                item.multiplier = 0;
                                parent.selectedCount -= 1;
                            } else {
                                item.multiplier = 1;
                            }
                        } else if (item.multiplier == 20) {
                            // Only will be called when minus button clicked
                            if (!isCarousel) {
                                item.multiplier = 19;
                                parent.selectedCount += 1;
                            } else {
                                item.multiplier = 0;
                                parent.selectedCount -= 20;
                            }
                        } else if (item.multiplier >= 2 && item.multiplier <= 19) {
                            // Only will be called when minus button clicked
                            if (!isCarousel) {
                                item.multiplier = item.multiplier - 1;
                                parent.selectedCount -= 1;
                            } else {
                                item.multiplier = item.multiplier + 1;
                                parent.selectedCount += 1;
                            }
                        } else if (item.multiplier == 1) {
                            // Only will be called when minus button clicked
                            if (!isCarousel) {
                                item.multiplier = 0.5;
                            } else {
                                item.multiplier = 2;
                                parent.selectedCount += 1;
                            }
                        }
                        // parent.selectedCount += 1;
                        modifierMul = buildItemMultiplier(item);
                        item.quantity = parseInt($scope.modItem.quantity) * modifierMul;
                        parent.isValid = true;
                    }
                    if (item.multiplier == 0 || item.multiplier == 0.5) {
                        item.multiplierInWords = $translate.instant('pos.menu.modifier.multiplier.' + item.multiplier);
                    } else {
                        item.multiplierInWords = item.multiplier + 'x';
                    }
                    $scope.selectedModifiersList.forEach(function (selectedModifier) {
                        if (selectedModifier.locationServicePeriodMenuId == item.locationServicePeriodMenuId) {
                            selectedModifier.quantity = item.quantity;
                            selectedModifier.multiplierInWords = item.multiplierInWords;
                        }
                    });
                } else {
                    parent.selectedCount = parent.selectedCount || 0;

                    if (parent.allowMultipleSelection === false && !isToggleValid(parent, item, true)) {
                        _.each(parent.children, function (child) {
                            if (child.selected) {
                                child.multiplier = 1;
                            }
                            child.selected = false;

                            /** Commented By Akash Mehta on July 14 2020
                            *** There was a bug reported by real fruit where item discounts
                            *** applied were not being re-calculated correctly on switching modifier
                            *** sizes. The issue ended up being that the quantity of modifiers  were never updated in the object
                            *** passed to the discount calculation code (a nested item object : item --> modifierCategory --> modifierOptions).
                            *** This caused the discount code to assume that all the mentioned modifiers are being discounted and hence the
                            *** discount calculated seemed incorrect.
                            ***/
                            child.quantity = 0;

                            // ---- FIX ENDS HERE ----- //

                            $scope.selectedModifiersList.forEach(function (selectedModifier, index) {
                                if (selectedModifier.locationServicePeriodMenuId == child.locationServicePeriodMenuId) {
                                    $scope.selectedModifiersList.splice(index, 1);
                                }
                            });
                        });
                        parent.selectedCount = 0;
                    }

                    if (!isToggleValid(parent, item)) {
                        return {'success': false};
                    }


                    item.quantity = parseInt($scope.modItem.quantity) * item.multiplier;
                    item.selected = true;
                    parent.selectedCount += 1;
                    toFocus = true;
                    if (item.multiplier == 0 || item.multiplier == 0.5) {
                        item.multiplierInWords = $translate.instant('pos.menu.modifier.multiplier.' + item.multiplier);
                    } else {
                        item.multiplierInWords = item.multiplier + 'x';
                    }

                    modifierMul = buildItemMultiplier(item);
                    $scope.selectedModifiersList.push(item);
                }

                $scope.fullItem = Controller.buildItem($scope.modItem, 0, 0);
                $scope.modItem.totalIndividualPrice = $scope.buildItemDiscountAmount($scope.fullItem).toNumber();
                applyReceiptItemChangeFunction($scope.modItem, $scope.transactionAdjustments, true, true);
                $scope.fullItem = Controller.buildItem($scope.modItem, 0, 0);
                // Assuming that there will always be one entry of the modifier in the new receipt
                buildVisualDiscount($scope.fullItem);
                if (toFocus) {
                    $scope.setFocus('sideBar');
                }

                // if required
                if (parent.minSelection !== -1) {
                    const newLimit = calculateMaxSelectionOverride();
                    $scope.maxSelectionOverride = newLimit;

                    $scope.optionalCategories.forEach((category) => {
                        if (KioskService.isKiosk() && category.selectedCount > 0) {
                            $scope.closeCategoryAccordion(category);
                        }

                        if (!category.allowMultipleSelection) {
                            return;
                        }

                        const useMaxSelectionOverride = category.selectionOverrideEnabled && newLimit < Number.POSITIVE_INFINITY;
                        const exceedsNewOverride = useMaxSelectionOverride && category.selectedCount > newLimit;
                        const exceedsMaxSelection = !useMaxSelectionOverride && category.maxSelection !== -1 && category.selectedCount > category.maxSelection;

                        // we need the OR check because the override could have been removed (via an edit),
                        // in which case we need to check against the default maxSelection limit
                        if (exceedsNewOverride || exceedsMaxSelection) {
                            clearCategorySelections(category);

                            if (KioskService.isKiosk()) {
                                $scope.openCategoryAccordion(category);
                            }
                        }
                    });

                    populateCategoryLimitsInWords();
                }

                return {'success': true};
            };

            /**
            ** Not being used for NOWN
            **/
            var modifyItem = function (parent, item) {
                showModifyModal(parent, item);
            };

            /**
            ** Not being used for NOWN
            **/
            var showModifyModal = function (parent, item) {
                var modifyModal = $modal.open({
                    templateUrl: 'pos/pos-modify/pos-modify.tpl.html',
                    controller: 'PosModify',
                    windowClass: 'modal-tender',
                    animation: false,
                    resolve: {
                        parent: function () {
                            return parent;
                        },
                        item: function () {
                            return item;
                        },
                        isEdit: function () {
                            return false;
                        }
                    },
                    backdrop: true
                }, function (error) {
                });
                modifyModal.result.then(function (obj) {
                    toggleItem(obj.parent, obj.item, false);
                }, function () {
                });
            };

            /**
            ** Init function for the controller
            **/
            $scope.init = function () {
                checkIfReplacingParentPrice();
                $scope.fullItem = Controller.buildItem($scope.modItem, 0, 0);
                $scope.modItem.totalIndividualPrice = $scope.buildItemDiscountAmount($scope.fullItem).toNumber();
                applyReceiptItemChangeFunction($scope.modItem, $scope.transactionAdjustments, true, true);
                $scope.fullItem = Controller.buildItem($scope.modItem, 0, 0);
                buildVisualDiscount($scope.fullItem);
                $scope.isDiscountLimitActive = (Security.getUser().company.attributes.other__activate_cashier_discount_limit == 'true') ? true : false;
                $scope.discountLimit = Security.getUser().company.attributes.other__cashier_discount_limit;
                if ($scope.modItem.defaultDiscountMatrix) {
                    $scope.selectLabelledDiscount($scope.modItem.defaultDiscountMatrix.discountDTO, false);
                }
                $scope.loadDiscounts();
                $scope.locationDetail = SharedDataService.location;
                if (KioskService.isKiosk()) {
                    populatePopularItemModifiers();
                    isLocationIsInOntario();
                }

                $scope.categories.forEach(function (category) {
                    var categorySelectedModifiers = category.children.filter(function (modifier) {
                        return modifier.selected;
                    });

                    if (categorySelectedModifiers) {
                        $scope.selectedModifiersList.push(...categorySelectedModifiers);
                    }
                });

                $scope.maxSelectionOverride = calculateMaxSelectionOverride();
                populateCategoryLimitsInWords();
            };

            /**
            ** Function to populate the choice limits for each category
            **/
            var populateCategoryLimitsInWords = function () {
                _.each($scope.categories, function (category) {
                    if (!category) {
                        return;
                    }

                    if (category.allowMultipleSelection) {
                        // save the originalMaxSelection for use when maxSelectionOverride no longer applies
                        if (category.originalMaxSelection === undefined) {
                            category.originalMaxSelection = category.maxSelection;
                        }

                        if (category.selectionOverrideEnabled && $scope.maxSelectionOverride < Number.POSITIVE_INFINITY) {
                            category.maxSelection = $scope.maxSelectionOverride;
                        } else {
                            category.maxSelection = category.originalMaxSelection;
                        }

                        category.selectionLimit = category.maxSelection;
                        category.toBeAdded = ['up to', (category.maxSelection == -1) ? $translate.instant('pos.menu.modifier.options.multiple') : category.maxSelection];

                        if (category.minSelection != -1) {
                            category.subtitleSelectionLimit = [$translate.instant('pos.menu.modifier.options.required'), '- Choose ', category.minSelection];
                        } else if (category.minSelection == -1) {
                            category.subtitleSelectionLimit = [$translate.instant('pos.menu.modifier.options.optional'), '- Choose '];
                            if (category.maxSelection == -1) {
                                category.toBeAdded.splice(0, 1);
                            }
                        }
                        category.subtitleSelectionLimit.push(...category.toBeAdded);
                    } else {
                        if (category.maxSelection != -1) {
                            category.selectionLimit = category.maxSelection;
                            if (category.minSelection != -1) {
                                category.subtitleSelectionLimit = [$translate.instant('pos.menu.modifier.options.required'), '- Choose ', category.minSelection];
                            } else if (category.minSelection == -1) {
                                category.subtitleSelectionLimit = [$translate.instant('pos.menu.modifier.options.optional'), '- Choose ', category.maxSelection];
                            }
                        } else if (category.minSelection != -1) {
                            category.selectionLimit = category.minSelection;
                            category.subtitleSelectionLimit = [$translate.instant('pos.menu.modifier.options.required'), '- Choose ', category.minSelection];
                        } else {
                            category.selectionLimit = 1;
                            category.subtitleSelectionLimit = [$translate.instant('pos.menu.modifier.options.optional'), '- Choose ', category.selectionLimit];
                        }
                    }

                    category.selectionLimitInWords = ['/'];
                    if (category.selectionLimit == -1) {
                        category.selectionLimitInWords.push($translate.instant('pos.menu.modifier.options.-1'));
                    } else {
                        category.selectionLimitInWords.push(category.selectionLimit);
                    }

                    category.selectionLimitInWords = category.selectionLimitInWords.join('');

                    if (category.subtitleSelectionLimit) {
                        category.subtitleSelectionLimit = category.subtitleSelectionLimit.join(' ');
                    }
                });

            };

            /**
            ** Function to build the multiplier for item.price.
            ** This is to apply rule for 0.5 multiplier and if we ever want to add other rules in the future
            **/
            var buildItemMultiplier = function (item) {
                return (item.multiplier > 1 || item.multiplier == 0) ? item.multiplier : 1;
            };

            /**
            ** Function to open pinpad
            **/
            var showPinPad = function (type, model, callback, validator, note) {
                var pinPadInstance = $modal.open({
                    templateUrl: 'pos/pos.numpad.tpl.html',
                    controller: 'PosNumpadCtrl',
                    animation: false,
                    windowClass: 'modal-numpad modal-fullscreen-transparent modal-right',
                    resolve: {
                        initial: function () {
                            return model;
                        },
                        type: function () {
                            return type;
                        },
                        note: function () {
                            return note;
                        }
                    },
                    backdrop: true
                });
                pinPadInstance.lucovaValidator = validator;
                return pinPadInstance.result.then(function (value) {
                    if (callback) {
                        callback(value);
                    }
                });
            };

            /**
            ** Function to check, if ant modifier is replacing parent Price or not
            ** and then update the modifier min and max selections if replacing parent
            **/
            var checkIfReplacingParentPrice = function () {
                _.each($scope.modItem.children, function (modifier) {
                    var priceReplacedBy = $scope.modItem.priceReplacedBy;
                    if (priceReplacedBy
                        && priceReplacedBy.locationServicePeriodMenuId === modifier.locationServicePeriodMenuId) {

                        modifier.minSelection = Math.max(-1, modifier.minSelection);
                        modifier.maxSelection = 1;
                        modifier.includes = 0;
                    }
                });
            };

            $scope.isApplyingPromo = false;
            $scope.markAsPromo = function () {
                $scope.isApplyingPromo = !($scope.modItem.discount.subtype === 'promo');

                if ($scope.isApplyingPromo) {
                    $scope.modItem.discount.type = 'price';
                    $scope.modItem.discount.price = $scope.modItem.price;
                    onEditDiscount($scope.modItem.price, true);
                } else {
                    $scope.modItem.discount.price = 0;

                    onEditDiscount(0, false);
                }
            };

            $scope.isLoading = {};

            $scope.loadDiscounts = function () {
                $scope.isLoading.discounts = true;
                return CashierShift.getLabelledDiscounts({companyId: CurrentSession.getCompany().companyId}, function (response) {
                    var discounts = _.filter(response, {itemLevel: true});
                    discounts.sort(function (a, b) {
                        if (a.discountName < b.discountName) {
                            return -1;
                        }

                        if (b.discountName < a.discountName) {
                            return 1;
                        }

                        return 0;
                    });
                    var permission = 'Site Owner, ';
                    _.each(discounts, function (discount) {
                        discount.status = (discount.active) ? 'ACTIVE' : 'INACTIVE';
                        if (discount.permissionRoles) {
                            discount.permissionRoles = permission + discount.permissionRoles;
                        } else {
                            discount.permissionRoles = permission.substr(0, permission.length - 2);
                        }
                    });

                    $scope.labelledDiscounts = discounts;
                    if ($scope.modItem.labelledDiscount) {
                        var discountToModify = $scope.labelledDiscounts.find(function (discount) {
                            return discount.id === $scope.modItem.labelledDiscount.id;
                        });

                        if (discountToModify) {
                            discountToModify.isSelected = true;
                            if ($scope.modItem.labelledDiscount.userId) {
                                discountToModify.userId = $scope.modItem.labelledDiscount.userId;
                            }
                        }
                    }
                    $scope.isLoading.discounts = false;
                }, function (error) {
                    $scope.isLoading.discounts = false;
                });
            };

            $scope.selectLabelledDiscount = function (labelledDiscount, toReset = true) {
                if ($scope.modItem.discount.labelledDiscount != labelledDiscount) {
                    $scope.modItem.discount.type = 'percentage';

                    onEditDiscount(labelledDiscount.discountPercentage, false, labelledDiscount);
                } else {
                    if (toReset) {
                        onEditDiscount(0, false);
                        $scope.modItem.discount = {};
                    }
                }
            };

            $scope.init();

            $scope.dismiss = function () {
                $scope.$dismiss();
            };
        }
    ]);
};
