'use strict';


const angular = require('angular');

export default angular.module('freshideas.services.kds', [])
.factory('KdsService', [
    '$log',
    'Locations',
    'Lucova',
    'CurrentSession',
    'Pure',
    'CompanyAttributesService',
    '$translate',
    function (
        $log,
        Locations,
        Lucova,
        CurrentSession,
        Pure,
        CompanyAttributesService,
        $translate) {
        /** ****************
        * Local Variables *
        ******************/
        var ReceiptStatusEnum = Object.freeze({
            'INCOMING': 0,
            'PREPARE': 1,
            'COMPLETE': 2,
            'EXPOCOMPLETE': 3
        });
        const receipts = [];

        /** **********
        * Lifecycle *
        ************/
        var clearReceipts = function () {
            receipts.length = 0;
        };

        const cleanReceipts = function (currentTimestamp, isExpoScreenEnabled) {
            if (isExpoScreenEnabled) {
                completeOrderOnExpoScreen(currentTimestamp, isExpoScreenEnabled);
            }
            var receiptsToDelete = _.filter(receipts, function (receipt) {
                // Completed status and receipts older than 5 minutes ago
                if ((isExpoScreenEnabled === false && receipt.status === ReceiptStatusEnum.COMPLETE &&
                    (receipt.timestamp < (currentTimestamp - 5 * 60 * 1000)))
                    || (receipt.status === ReceiptStatusEnum.EXPOCOMPLETE)) {
                        return true;
                    } else {
                        return false;
                    }
            });
            // Remove expired entries from array
            for (var i = 0; i < receiptsToDelete.length; i++) {
                var index = _.findIndex(receipts, {
                    uuid: receiptsToDelete[i].uuid
                });
                receipts.splice(index, 1);
            }
        };

        /** *********************
        * Fiit Backend Related *
        ***********************/
        // Polled by KDS controller
        // Retrieves KDS receipts from Fiit backend
        const getReceiptsFromFiit = function (isExpoScreenView = false, showOrderOnKdsExpo = false, callback) {
            if (!CurrentSession.getCompany() || !CurrentSession.getCompany().locationId) {
                $log.error('Failed to get location ID from CurrentSession when getting KDS from backend.');
            }
            // Get receipts from backend
            Locations.getKdsOrders({locationId: CurrentSession.getCompany().locationId, limit: 200, isExpoScreenView: isExpoScreenView, showOrderOnKdsExpo: showOrderOnKdsExpo})
            .$promise.then(function (response) {
                const responseMap = {};
                const oldReceiptsToDelete = [];
                const responseReceiptsToDelete = [];

                response.entries.forEach((receipt, index) => {
                    responseMap[receipt.uuid] = {index, lastUpdated: receipt.lastUpdated};
                });

                // we want to keep unchanged receipts as is,
                // update existing receipts in place if needed,
                // append only completely new new receipts
                receipts.forEach((receipt, idx) => {
                const newEntry = responseMap[receipt.uuid];

                if (!newEntry) {
                    oldReceiptsToDelete.push(idx);
                    return;
                }

                if (newEntry.lastUpdated !== receipt.lastUpdated) {
                    receipts[idx] = response.entries[newEntry.index];
                }

                responseReceiptsToDelete.push(receipt.uuid);
                    delete responseMap[receipt.uuid];
                });

                oldReceiptsToDelete.forEach((idx) => receipts.splice(idx, 1));
                const newReceipts = response.entries.filter((receipt) => !responseReceiptsToDelete.includes(receipt.uuid));
                receipts.push(...newReceipts);
                if (callback) {
                    callback();
                }
            }, function (error) {
                $log.error(error);
            });
        };

        // Automatically complete orders on Expo Screen after 10 minutes
        const completeOrderOnExpoScreen = function (currentTimestamp, isExpoScreenEnabled) {
            receipts.forEach(function (receipt) {
                const isComplete = receipt.status === ReceiptStatusEnum.COMPLETE;
                const isTimoutElapsed = receipt.timestamp < (currentTimestamp - 10*60*1000);

                if (!isComplete || !isTimoutElapsed) {
                    return;
                }

                receipt.status = ReceiptStatusEnum.EXPOCOMPLETE;
                receipt.timestamp = Date.now();
                updateReceiptFiit(receipt, isExpoScreenEnabled);
            });
        };

        // Add a KDS receipt to Fiit backend
        const addReceiptFiit = function (receipt) {
            return Locations.addKdsOrder(receipt).$promise;
        };

        // Update a KDS receipt on Fiit backend
        const updateReceiptFiit = function (receipt, isExpoScreenEnabled) {
            receipt.lastUpdated = new Date().getTime();

            // Send to Fiit backend
            // Fiit backend uses receiptJson
            var receiptFiit = _.pick(receipt, [
                            'kdsId',
                            'uuid',
                            'guestTransaction',
                            'locationId',
                            'patronName',
                            'patronPhotoUrl',
                            'status',
                            'receipt',
                            'timestamp',
                            'lastUpdated',
                            'receiptCounter',
                            'suspendId',
                            'posStationName',
                            'serviceMode']);
            if (isExpoScreenEnabled) {
                if (!_.some(receiptFiit.receipt, {expoEnabled: true, itemLevel: 0}) && receiptFiit.status === ReceiptStatusEnum.COMPLETE) {
                    receiptFiit.status = ReceiptStatusEnum.EXPOCOMPLETE;
                }
            }
            Locations.updateKdsOrder(receiptFiit).$promise.then(function (response) {
                pushKdsMessage();
            }, function (error) {
                pushKdsMessage();
                $log.error(error);
            });
        };

        /** *************************
        * Initialization Functions *
        ***************************/
        // Process KDS event received from window/SocketIO
        const processKdsEvent = function (kdsReceipt) {
            getReceiptsFromFiit();

            // This code below used to take the info from the push message
            // and manually insert it into our receipts array. Let's keep it
            // simple and just refresh the entire receipt list each time we
            // receive a KDS event.
            /*
            let receiptsIndex = _.findIndex(receipts, function (receipt) {
                return receipt.uuid === kdsReceipt.uuid ||
                    (receipt.suspendId !== null && receipt.suspendId === kdsReceipt.suspendId);
            });

            if (receiptsIndex === -1) {
                // New entry
                receipts.push(kdsReceipt);
            } else {
                // Existing entry
                // Only modify if lastUpdated is newer than current
                if (kdsReceipt.lastUpdated > receipts[receiptsIndex].lastUpdated) {
                    receipts[receiptsIndex] = kdsReceipt;
                }
            }
            */
        };

        // Prepare an already completed receipt
        // Reset time and push back in as a new order
        const prepareReceiptFromCompleted = function (receipt) {
            var index = receipts.indexOf(receipt);
            if (index > -1) {
                receipt.status = ReceiptStatusEnum.PREPARE;
                receipt.timestamp = new Date().getTime();
                receipt.deltaY = 0;
                receipt.isClosing = false;
                receipt.isMoving = false;
                receipt.isCompleting = false;
                receipt.isCompletingExpo = false;
            }
        };

        // Send update through Socket.IO
        const pushKdsMessage = (payload) => {
            if (!payload || !Object.keys(payload).length) {
                return;
            }

            try {
                Lucova.manager().pushMessage({
                    terminalId: Lucova.getTerminalId(),
                    data: payload
                },
                    function (response) { },
                    function (error) { }
                );
            } catch (e) {
                console.log(e);
            }
        };

        // Used to send preorder to the KDS
        const sendPreorderToKds = (user, preorder) => {
            const isKdsEnabled = CurrentSession.getCompany().attributes.kds_enabled === 'true';
            const locationId = CurrentSession.getCompany().locationId;

            if (!isKdsEnabled) {
                return;
            }

            if (!locationId) {
                $log.error('Failed to get location ID from CurrentSession when accepting order.');
                return;
            }

            // ensure all items have been enabled for KDS
            // if there are no KDS enabled items, we do not need to send this order to KDS.
            const kdsEnabledItems = preorder.items.filter((item) => item.original.kdsEnabled);
            if (!kdsEnabledItems.length) {
                return;
            }

            const receiptItems = [];
            for (const item of kdsEnabledItems) {
                const modifierGroups = item.children;
                for (const modifierGroup of modifierGroups) {
                    const modifiers = modifierGroup.children;
                    for (const modifier of modifiers) {
                        const modifierItem = {
                            itemName: modifier.name,
                            itemQuantity: modifier.quantity,
                            itemIndex: modifier.original.index,
                            itemLevel: modifier.original.level
                        };

                        receiptItems.push(modifierItem);
                    }
                }

                const receiptItem = {
                    itemName: item.name,
                    itemQuantity: item.quantity,
                    itemNotes: item.note,
                    itemIndex: item.original.index,
                    itemLevel: item.original.level,
                    expoEnabled: item.original.expoEnabled
                };

                receiptItems.push(receiptItem);
            }

            const kdsDataObject = {
                uuid: Pure.generateUuid(),
                guestTransaction: false,
                locationId: locationId,
                patronName: user.full_name,
                patronPhotoUrl: user.photo_url,
                status: 0,
                receipt: receiptItems,
                timestamp: new Date().getTime(),
                lastUpdated: new Date().getTime(),
                posStationName: $translate.instant('kds.onlineOrder'),
                serviceMode: null
            };

            // adds a KDS receipt to fiit backend.
            addReceiptFiit(kdsDataObject).then((response) => {
                pushKdsMessage(kdsDataObject);
            }, (error) => {
                pushKdsMessage(kdsDataObject);
                $log.error(error);
            });
        };


        return {
            clearReceipts: clearReceipts,
            cleanReceipts: cleanReceipts,
            getReceiptsFromFiit: getReceiptsFromFiit,
            addReceiptFiit: addReceiptFiit,
            updateReceiptFiit: updateReceiptFiit,
            processKdsEvent: processKdsEvent,
            prepareReceiptFromCompleted: prepareReceiptFromCompleted,
            sendPreorderToKds: sendPreorderToKds,
            completeOrderOnExpoScreen: completeOrderOnExpoScreen,
            receipts: receipts,
            ReceiptStatusEnum: ReceiptStatusEnum
        };
    }
]);
