'use strict';

const freshideasSystem = angular.module('freshideas.system', []);

freshideasSystem
    .factory('SystemService', [
        '$rootScope',
        '$timeout',
        '$log',
        'Lookup',
        function ($rootScope, $timeout, $log, Lookup) {
            const STATUS_CHECK_INTERVAL = 60000; // 60 sec

            var isWebkit = window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.bridge;
            let systemTimeOffsetMilliseconds = 0;
            var statusCheck = function () {
                Lookup.statusCheck({}).$promise.then(function (response) {
                    broadcastStatusCheck(response);
                    systemTimeOffsetMilliseconds = Date.now() - response.currentUnix;
                    monitorStatusCheck();
                }).catch(function (error) {
                    // We get here if the network or server is down and do not have an
                    // HTTP status code.
                    $log.info(error);
                    monitorStatusCheck();
                });
            };
            var monitorStatusCheck = function () {
                $timeout(statusCheck, STATUS_CHECK_INTERVAL);
            };
            // First check after 2 seconds
            $timeout(statusCheck, 2000);

            var broadcastStatusCheck = function (status) {
                $rootScope.$emit('system.status', status);
            };

            var setCanShowMessage = function (value) {
                system.canShowMessage = value;

                $rootScope.$emit('system.canShowMessage');
            };

            // This function returns the time difference between server and users system im milliseconds
            const getSystemTimeOffsetMilliseconds = () => {
                return systemTimeOffsetMilliseconds;
            };

            var system = {
                isWebkit: isWebkit,
                setCanShowMessage: setCanShowMessage,
                canShowMessage: true,
                getSystemTimeOffsetMilliseconds: getSystemTimeOffsetMilliseconds
            };

            return system;
        }
    ])
    .controller('SystemCtrl', [
        '$scope',
        'Pure',
        'SystemService',
        function ($scope, Pure, SystemService) {

            $scope.system = SystemService;

            $scope.messages = [];
            $scope.addMessage = function (type, dismissble, showThis) {
                var messageId = Pure.generateUuid();
                var message = {
                    id: messageId,
                    type: type,
                    dismissble: dismissble
                };

                $scope.messages.unshift(message);

                if (showThis) {
                    $scope.currentMessage = message;
                }
            };

            $scope.minimizeMessage = function () {
                $scope.currentMessage = undefined;
            };
        }
    ]);


require('./system.server.js')(freshideasSystem);
require('./system.terminal.js')(freshideasSystem);
require('./system.connectivity.js')(freshideasSystem);


export default freshideasSystem;


