'use strict';

/* globals StarWebPrintBuilder, StarWebPrintTrader */
/* eslint-disable guard-for-in */
const angular = require('angular');
const $ = require('jquery');
const moment = require('moment');
const async = require('async');
const Decimal = require('decimal.js').default;
const TscBuffer = require('../../lib/TscBuffer');

const ReceiptFactoryWrapper = require('./receipt-factory/receipt.factory.wrapper');

let freshIdeasPrintServiceModule = angular.module('freshideas.services.print', ['pascalprecht.translate']);

freshIdeasPrintServiceModule.factory('PrintService', [
    '$log',
    '$q',
    '$filter',
    '$translate',
    'Lucova',
    'PrintStatusService',
    'Pure',
    'PrintType',
    'BluetoothPrinter',
    'TCPPrinter',
    'USBPrinter',
    'SharedDataService',
    'CurrentSession',
    'CompanyAttributesService',
    'EnvConfig',
    'Platform',
    'PRINTOUT_TYPE',
    'GIFTCARD_SOURCE',
    'GatewayFiit',
    'MevBoxService',
    'PosAlertService',
    'PreorderService',
    'IosTcpClient',
    'PosStatusService',
    '$injector',
    function ($log,
        $q,
        $filter,
        $translate,
        Lucova,
        PrintStatusService,
        Pure,
        PrintType,
        BluetoothPrinter,
        TCPPrinter,
        USBPrinter,
        SharedDataService,
        CurrentSession,
        CompanyAttributesService,
        EnvConfig,
        Platform,
        PRINTOUT_TYPE,
        GIFTCARD_SOURCE,
        GatewayFiit,
        MevBoxService,
        PosAlertService,
        PreorderService,
        IosTcpClient,
        PosStatusService,
        $injector) {
        // `session` allows the printing algorithm to determine whether a printing job is coming from the same
        //      "session" as a POS shift. Rather than using a shift id to identify the current POS shift session, a
        //      random string should be generated (`updateSession`) every time a session starts (eg. a new shift,
        //      service period switch) if one hasn't started already, and a session should be cleared (`clearSession`)
        //      every time a session ends (eg. end shift, service period switch before a new service period is assigned).
        // This is very useful for filtering out print status messages, because it is possible for the printer
        //      to return the status of a print job after a new shift has been created (due to self retry). By introducing
        //      the concept of `session`, the print job callback is able to determine whether it still makes sense to
        //      push a print status message or if the session associated with the print job has already ended.

        var session;
        var clearSession = function () {
            session = undefined;
            PrintStatusService.clearMessages();
        };
        var updateSession = function () {
            session = session || Pure.generateUuid();
        };

        var attemptTimeout = [1000, 2000, 4000, 8000, 15000, 30000];

        var creditCardIdNameMap = {
            1: 'Visa',
            2: 'Amex',
            3: 'Mastercard',
            4: 'Debit',
            5: 'JCB',
            6: 'Discover',
            7: 'Union Pay'
        };

        var appData = {
            'freshx': {
                'name': 'FreshX by Fresh Ideas',
                'url': 'https://www.freshxapp.com',
                'logo': '/images/apps/freshx-dark.jpg'
            },
            'eatable': {
                'name': 'Eatable',
                'url': 'https://www.eatablemobile.com',
                'logo': '/images/apps/eatable.jpg'
            }
        };

        const translate = (key) => {
            // inject within function to avoid circular dependency
            const defaultLang = $injector.get('SmbPosService').data.organizationLanguages.find((lang) => lang.defaultLanguage);
            return $translate.instant(key, null, null, defaultLang.locale);
        };

        // preload app logos for printing purpose
        // TODO: keep reloading if image cannot be loaded?
        _.each(appData, function (appDatum) {
            // var logoUrl = appDatum.logl;
            var appLogo = $('<img class="app-logo app-logo-freshx" src="' + appDatum.logo + '" style="position: absolute; top: 0; right: 0; width: 120px; height: 120px; display: none;"/>');
            $('body').append(appLogo);


            appLogo.on('load', function () {
                var imageCanvas = $('<canvas class="app-logo-canvas app-logo-canvas-freshx" style="position: absolute; top: -100%; left: -100%; display: none;"></canvas>');
                $('body').append(imageCanvas);

                var imageCanvasCtx = imageCanvas[0].getContext('2d');
                imageCanvasCtx.drawImage(appLogo[0], 0, 0, 120, 120);

                appLogo.remove();

                appDatum.logoCanvasCtx = imageCanvasCtx;
            });

        });

        var generateReceiptHierarchy = function (receipt) {
            if (!receipt || !receipt.length) {
                return [];
            }

            var receiptCopy = angular.copy(receipt);
            // Commented By Tony prior to July 6 2020
            // `level` attribute is being set in the frontend when a transaction
            // is being run. The attribute does not exist in the database and
            // therefore does not exist when a historical transaction is being
            // printed.
            //
            // Commented By Akash Mehta on July 6 2020
            // Since we needed to add the field `level` to out item DTO on the
            // backend (in order to support the calculation server), the reprinting
            // of receipts is causing an issue as every modifier has the level 0 (root level item)
            // (default value for the field in the DTO). And also the check for level seemed very
            // weak. Hence going forward, we will be checking if any item has its row-id (receiptItemId) or not.
            // If it does contain the receiptItemId, means the printout request is for a historical transaction
            //  else it would be considered to be a new transaction
            if (!isNaN(receipt[0].receiptItemId)) {
                // `index` field in each item in `receipt` actually refers to
                // the index of its parent (or itself when an item is a top
                // level item). This is the naming convention already used
                // so we are keeping the same name for consistency
                var receiptItemsRoot = _.filter(receiptCopy, function (item) {
                    return item.index === item.itemIndex;
                });

                // Build list of children for each item
                for (let i = 0; i < receiptItemsRoot.length; i++) {
                    var topLevelItem = receiptItemsRoot[i];
                    let receiptChildren = _.filter(receipt, function (item) {
                        return item.index === topLevelItem.itemIndex && item.itemIndex !== topLevelItem.itemIndex;
                    });

                    if (receiptChildren) {
                        receiptItemsRoot[i].children = receiptChildren;
                    }
                }

                return receiptItemsRoot;
            } else {
                // For when the cashier is still running transaction
                receiptItemsRoot = _.filter(receiptCopy, {
                    level: 0
                });

                // Build list of children for each item
                for (let i = 0; i < receiptItemsRoot.length; i++) {
                    let receiptChildren = _.filter(receiptCopy, function (receiptChild) {
                        return receiptChild.index === receiptItemsRoot[i].index && receiptChild.level > 0;
                    });

                    if (receiptChildren) {
                        receiptItemsRoot[i].children = receiptChildren;
                    }
                }

                return receiptItemsRoot;
            }
        };

        const DEFAULT_RECEIPT_SIZE = 43;

        var receiptSize = DEFAULT_RECEIPT_SIZE;
        var maxItemNameLength = 36;
        var maxSubItemNameLength = 32;
        var defaultItemNameEllipsis = '...';

        var setReceiptSize = function (receiptWidth) {
            if (receiptWidth == 2) {
                receiptSize = 32;
            } else {
                receiptSize = DEFAULT_RECEIPT_SIZE;
            }

            return receiptSize;
        };

        // Maintain a number of printing queues for different urls to prevent placeholder/non-existent printer url from
        // clogging up the printing queue.
        var printingQueues = {};
        var fetchPrintingQueue = function (url) {
            if (!printingQueues[url]) {
                var printingQueue = async.queue(function (job, jobCompleteCallback) {
                    var promise = job.promise;
                    var task = job.task;

                    promise
                        .then(function (response) {
                            jobCompleteCallback();
                        })
                        .catch(function (error) {
                            jobCompleteCallback();
                        });

                    if (task) {
                        task.call();
                    }
                });
                printingQueue.queueUuid = url;
                printingQueues[url] = printingQueue;
            }

            return printingQueues[url];
        };

        function round (number, decimals) {
            return number.toFixed(decimals || 2);
        }

        const BLUETOOTH = 'bt';
        const TCP = 'tcp';
        const HTTP = 'http';
        const USB = 'usb';

        function buildPrinterUrl (ipAddress, port, protocol) {
            switch (protocol) {
                case BLUETOOTH:
                    return ipAddress;
                default:
                    return (port == 443 ? 'https://' : 'http://') + ipAddress + ':' + port + '/StarWebPRNT/SendMessage';
            }
        }

        function buildPrinterObjArray (posPrinters, isKitchenReceipt) {
            posPrinters = posPrinters || [];

            var printerObjArray = [];
            for (var i = 0; i < posPrinters.length; i++) {
                if (posPrinters[i] && ((!isKitchenReceipt && posPrinters[i].receiptPrinter) ||
                    (isKitchenReceipt && posPrinters[i].kitchenPrinter))) {
                    var printerObj = {
                        printerId: posPrinters[i].posPrinterId,
                        printerName: posPrinters[i].printerName,
                        protocol: posPrinters[i].protocol,
                        receiptSize: posPrinters[i].receiptSize,
                        printerEmulation: posPrinters[i].printerEmulation,
                        printerModel: posPrinters[i].printerModel
                    };

                    if (printerObj.protocol === TCP || printerObj.protocol === HTTP) {
                        printerObj.ipAddress = posPrinters[i].printerIpAddress;
                        printerObj.printerPort = posPrinters[i].printerPort;
                        printerObj.printerUrl = buildPrinterUrl(
                            printerObj.ipAddress, printerObj.printerPort, printerObj.protocol);
                    } else if (printerObj.protocol === BLUETOOTH) {
                        printerObj.printerName = posPrinters[i].bluetoothName;
                    } else if (printerObj.protocol === USB) {
                        printerObj.printerVid = posPrinters[i].usbVid;
                        printerObj.printerPid = posPrinters[i].usbPid;
                    }

                    printerObjArray.push(printerObj);
                }
            }

            return printerObjArray;
        }

        function buildLabelPrinterArray (posPrinters, isLabelPrinter) {
            var printerObjArray = [];
            for (var i = 0; i < posPrinters.length; i++) {
                if (isLabelPrinter && posPrinters[i].labelPrinter) {
                    var printerObj = {
                        printerId: posPrinters[i].posPrinterId,
                        protocol: posPrinters[i].protocol,
                        receiptSize: posPrinters[i].receiptSize,
                        printerEmulation: posPrinters[i].printerEmulation
                    };

                    if (printerObj.protocol === TCP || printerObj.protocol === HTTP) {
                        printerObj.ipAddress = posPrinters[i].printerIpAddress;
                        printerObj.printerPort = posPrinters[i].printerPort;
                        printerObj.printerUrl = buildPrinterUrl(
                            printerObj.ipAddress, printerObj.printerPort, printerObj.protocol);
                    } else if (printerObj.protocol === BLUETOOTH) {
                        printerObj.printerName = posPrinters[i].bluetoothName;
                    } else if (printerObj.protocol === USB) {
                        printerObj.printerVid = posPrinters[i].usbVid;
                        printerObj.printerPid = posPrinters[i].usbPid;
                    }

                    printerObjArray.push(printerObj);
                }
            }

            return printerObjArray;
        }

        function sendMessageArray (config) {
            for (var i = 0; i < config.urlArray.length; i++) {
                var printer = config.urlArray[i];
                var protocol = printer.protocol;

                // This is a temporary solution to detect which printer should support
                // external cash drawer
                var cashDrawerType = printer.printerModel === 'mint' ? 'mint2' : null;
                if (cashDrawerType) {
                    config.cashDrawerType = cashDrawerType;
                }

                var printJob;
                if (protocol == BLUETOOTH) {
                    printJob = BluetoothPrinter.print(Object.assign({},
                        config, {
                        url: printer
                    }), '');
                } else if (protocol == TCP) {
                    printJob = TCPPrinter.print(Object.assign({},
                        config, {
                        url: printer
                    }), '');
                } else if (protocol == USB) {
                    printJob = USBPrinter.print(Object.assign({},
                        config, {
                        url: printer
                    }), '');
                }

                // Push job onto the async queue
                var printingQueue = fetchPrintingQueue(config.urlArray[i].printerUrl);
                if (config.immediate) {
                    printingQueue.unshift(printJob);
                } else {
                    printingQueue.push(printJob);
                }
                // Register a callback that is called when the last
                // item from the queue has returned from the worker
                printingQueue.drain = function () {
                    try {
                        // Free-up memory allocated to the printer queue
                        let uuid = printingQueue.queueUuid;
                        delete printingQueues[uuid];
                    } catch (e) {
                        console.error(e);
                    }
                };
            }
        }

        var srmReceiptFactory = require('./receipt-factory/srm-receipt');

        var sortDiscountsObject = function (a, b) {
            return a[1].id - b[1].id;
        };

        var _currency = function (amount, isNegative = false) {
            let val = !amount ? 0 : amount;
            var absoluteRoundedAmount = $filter('currency')(round(Math.abs(val), 2));

            if (amount < 0 || isNegative) {
                return '-' + absoluteRoundedAmount;
            } else {
                return absoluteRoundedAmount;
            }
        };
        async function generateEndOfShiftReport (report, printer) {
            var isFiitEnabled = GatewayFiit.isEnabled();
            printer
                .initialize()
                .newLine(6);

            var location = report.location || {};
            var locationName = location.locationName || '';

            var station = report.station || {};
            var stationName = station.posStationName || '';

            // Report Title
            printer
                .align('center')
                .text(locationName, true)
                .text(stationName, true)
                .title('END OF SHIFT REPORT', true)
                .newLine();

            // Offline Transactions Included
            // Disabled because trying to print this will causes POS to crash (tested on iPad)
            //            if (report.hasOfflineTransactions) {
            //                printer
            //                    .align('center')
            //                    .text('* This shift includes transactions that', true, {height: 1.3})
            //                    .text('were completed in Offline Mode. The', true, {height: 1.3})
            //                    .text('shift start time may not be accurate.', true, {height: 1.3})
            //                    .newLine()
            //                    .newLine();
            //            }

            // Report Time
            var startDate = moment(report.start).format('YYYY-MM-DD hh:mm A');
            var endDate = '';

            if (report.end) {
                endDate = moment(report.end).format('YYYY-MM-DD hh:mm A');
            } else {
                endDate = '(Shift Not Ended)';
            }

            printer
                .align()
                .textJustified(['FROM    : ', startDate.toLocaleString()])
                .textJustified(['TO      : ', endDate.toLocaleString()])
                .newLine();

            if (report.autoCreate) {
                printer
                    .align('center')
                    .text('(Mobile Order Only)', true);
            } else {
                printer
                    .align('left')
                    .textJustified(['STATION LOGIN: ', report.employeeName]);

                if (report.closedByEmployeeId) {
                    printer
                        .textJustified(['CLOSED BY:', report.closedByEmployeeName]);
                } else {
                    printer
                        .textJustified(['CLOSED BY:', 'STATION OPEN']);
                }
            }

            printer.divider();

            var company = CurrentSession.getCompany();
            var isCampus = CurrentSession.isCampus();
            var companyAttributes = company.attributes || {};
            var isGiftCardEnabled = CompanyAttributesService.hasGiftCardsEnabled();

            var decimalAllTransactionSubtotals = new Decimal(report.subtotalAmount).times(new Decimal(100));
            var decimalAllTransactionTotals = new Decimal(report.totalSales).times(new Decimal(100));
            var decimalAllCashRoundings = new Decimal(report.totalCashRounding).times(new Decimal(100));
            var decimalAllGiftCardSales = new Decimal(report.giftCardSalesCents);

            let totalCCTipAmount = 0;

            var decimalReportableSubtotal = decimalAllTransactionSubtotals.minus(decimalAllGiftCardSales);
            var reportableSubtotal = decimalReportableSubtotal.dividedBy(new Decimal(100)).toNearest(SharedDataService.baseDollar).toNumber();

            var giftCardNewAmount = new Decimal(report.giftCard.createAmountCents).dividedBy(100).toNearest(SharedDataService.baseDollar).toNumber();
            var giftCardReloadAmount = new Decimal(report.giftCard.reloadAmountCents).dividedBy(100).toNearest(SharedDataService.baseDollar).toNumber();

            var voidBreakdowns = report.voids.breakdowns || {};
            var totalVoids = report.voids || {count: 0, amountCents: 0, amountMeals: 0};
            var cashVoids = voidBreakdowns['CASH'] || {count: 0, amountCents: 0, amountMeals: 0};
            var creditVoids = voidBreakdowns['CREDIT'] || {count: 0, amountCents: 0, amountMeals: 0};
            var otherVoids = voidBreakdowns['OTHER'] || {count: 0, amountCents: 0, amountMeals: 0};
            var giftVoids = voidBreakdowns['GIFTCARD'] || {count: 0, amountCents: 0, amountMeals: 0};

            var refundBreakdowns = report.refunds.breakdowns || {};
            var totalRefunds = report.refunds || {count: 0, amountCents: 0, amountMeals: 0};
            var cashRefunds = refundBreakdowns['CASH'] || {count: 0, amountCents: 0, amountMeals: 0};
            var creditRefunds = refundBreakdowns['CREDIT'] || {count: 0, amountCents: 0, amountMeals: 0};
            var otherRefunds = refundBreakdowns['OTHER'] || {count: 0, amountCents: 0, amountMeals: 0};
            var giftRefunds = refundBreakdowns['GIFTCARD'] || {count: 0, amountCents: 0, amountMeals: 0};

            var refundSubtotalCents = report.refunds.subtotalCents;
            var refundTaxCents = report.refunds.taxCents;
            var decimalRefundTax = new Decimal(refundTaxCents).dividedBy(new Decimal(100));
            var refundCashRoundingCents = report.refunds.cashRoundingCents;

            var netTotalTaxes = new Decimal(report.totalTaxes).minus(decimalRefundTax).toNearest(SharedDataService.baseDollar).toNumber();

            var decimalReportableTotalSales = decimalAllTransactionTotals
                .minus(decimalAllCashRoundings)
                .minus(decimalAllGiftCardSales)
                .minus(new Decimal(refundSubtotalCents))
                .minus(new Decimal(refundTaxCents))
                .minus(new Decimal(refundCashRoundingCents));

            var reportableTotalSales = decimalReportableTotalSales.dividedBy(new Decimal(100)).toNearest(SharedDataService.baseDollar).toNumber();

            var decimalNetSubtotal = decimalReportableSubtotal.minus(new Decimal(refundSubtotalCents + refundCashRoundingCents));
            var netSubtotal = decimalNetSubtotal.dividedBy(new Decimal(100)).toNearest(SharedDataService.baseDollar).toNumber();

            var decimalCashRefundAmount = new Decimal(cashRefunds.amountCents).dividedBy(new Decimal(100));

            /** # 1 Sales **/
            printer.section();

            printer
                .align('center')
                .text('SALES', true)
                .divider(true)
                .align('left')
                .textJustified(['SALES        :', _currency(reportableSubtotal)])
                .textJustified(['REFUND SALES :', _currency((refundSubtotalCents + refundCashRoundingCents) / 100)])
                .textJustified(['', ''], false, {}, {}, '-') // dashed line
                .textJustified(['NET SALES    :', _currency(netSubtotal)], true)
                .textJustified(['CASH ROUNDING:', _currency(-report.totalCashRounding)])
                .textJustified(['TAXES        :', _currency(netTotalTaxes)])
                .textJustified(['', ''], false, {}, {}, '-') // dashed line
                .textJustified(['TOTAL        :', _currency(reportableTotalSales)]); // accounting "subtotal" = transaction total sales

            printer
                .textJustified(['NEW GIFT     :', _currency(giftCardNewAmount)])
                .textJustified(['RELOAD GIFT  :', _currency(giftCardReloadAmount)]);

            var totalPurchases = new Decimal(reportableTotalSales)
                .plus(new Decimal(giftCardNewAmount))
                .plus(new Decimal(giftCardReloadAmount))
                .toNearest(SharedDataService.baseDollar).toNumber();

            printer
                .textJustified(['', ''], false, {}, {}, '-') // dashed line
                .textJustified(['TOTAL TRANSACTION', _currency(totalPurchases)], true);

            var decimalTotalTender = new Decimal(report.totalCreditCardsPos)
                .plus(new Decimal(report.totalCreditCardsApp))
                .plus(new Decimal(report.totalCashIn))
                .plus(new Decimal(-report.giftCard.redemptionAmountCents / 100))
                .plus(new Decimal(report.giftCard.promoAmountCents / 100))
                .plus(new Decimal(report.totalOtherAmount));

            var totalTender = decimalTotalTender.toNearest(SharedDataService.baseDollar).toNumber();

            var exchangeAmount = 0;
            if (report.exchanges && report.exchanges.count) {
                exchangeAmount = new Decimal(report.exchanges.amountCents).dividedBy(new Decimal(100)).toNearest(SharedDataService.baseDollar).toNumber();
            }

            var decimalTotalCollected = decimalTotalTender
                .plus(new Decimal(-cashRefunds.amountCents / 100))
                .plus(new Decimal(-creditRefunds.amountCents / 100))
                .plus(new Decimal(-giftRefunds.amountCents / 100))
                .plus(new Decimal(-otherRefunds.amountCents / 100))
                .plus(new Decimal(exchangeAmount));
            var totalCollected = decimalTotalCollected.toNearest(SharedDataService.baseDollar).toNumber();

            var otherAmountCents = new Decimal(report.totalOtherAmount).times(new Decimal(100)).toNearest(SharedDataService.baseCent).toNumber();
            var trackableOtherTenderLabelBreakdowns = [];
            for (let otherTenderLabelBreakdown of report.otherTenderLabelBreakdowns) {
                if (otherTenderLabelBreakdown.label === 'fiitmps') {
                    otherAmountCents -= otherTenderLabelBreakdown.amountCents;
                } else {
                    trackableOtherTenderLabelBreakdowns.push(otherTenderLabelBreakdown);
                }
            }

            printer
                .divider(true)
                .align('left')
                .textJustified(['CARD - POS    :', _currency(report.totalCreditCardsPos)])
                .textJustified(['CARD - APP    :', _currency(report.totalCreditCardsApp)])
                .textJustified(['CASH COLLECTED:', _currency(report.totalCashIn)])
                .textJustified(['GIFT REDEEMED :', _currency(-report.giftCard.redemptionAmountCents / 100)])
                .textJustified(['GIFT CREDIT   :', _currency(report.giftCard.promoAmountCents / 100)]);

            if (companyAttributes['other__enable_other_tender_option'] === 'true' || otherAmountCents) {
                printer
                    .textJustified(['OTHER         :', _currency(otherAmountCents / 100)]);
            }

            if (isFiitEnabled) {
                printer
                    .textJustified(['DCB           : ', _currency(report.totalFiitDcbAmount)]);

                printer
                    .textJustified(['MEAL UNITS    : ', _currency(report.totalFiitMealUnitAmount)]);
            }

            printer
                .textJustified(['', ''], false, {}, {}, '-') // dashed line
                .textJustified(['TOTAL TENDER   :', _currency(totalTender)])
                .textJustified(['REFUND CASH    :', _currency(-cashRefunds.amountCents / 100)])
                .textJustified(['REFUND CARD    :', _currency(-creditRefunds.amountCents / 100)])
                .textJustified(['REFUND GIFT    :', _currency(-giftRefunds.amountCents / 100)]);

            if (report.exchanges && report.exchanges.count) {
                printer
                    .textJustified([
                        'EXCHANGE CREDIT:',
                        _currency(exchangeAmount)
                    ]);
            }

            if (companyAttributes['other__enable_other_tender_option'] === 'true' || otherRefunds.amountCents) {
                printer
                    .textJustified(['REFUND OTHER  :', _currency(-otherRefunds.amountCents / 100)]);
            }

            printer
                .textJustified(['', ''], false, {}, {}, '-') // dashed line
                .textJustified(['TOTAL COLLECTED', _currency(totalCollected)], true);

            var decimalCreditCardPos = new Decimal(report.totalCreditCardsPos);
            var decimalCreditCardApp = new Decimal(report.totalCreditCardsApp);
            var decimalCreditCardRefund = new Decimal(creditRefunds.amountCents / 100);
            var decimalCreditTenderTotal = decimalCreditCardPos.plus(decimalCreditCardApp).minus(decimalCreditCardRefund);
            var creditTenderTotal = decimalCreditTenderTotal.toNearest(SharedDataService.baseDollar).toNumber();

            printer
                .divider(true)
                .textJustified(['CREDIT TENDER TOTAL   :', _currency(creditTenderTotal)])
                .textJustified(['TOTAL TIPS COLLECTED  :', _currency(report.totalTipAmount)]);

            if (report.exchanges && report.exchanges.count) {
                printer
                    .textJustified([
                        'TOTAL EXCHANGES (count: ' + report.exchanges.count + ') ',
                        _currency(exchangeAmount)
                    ]);
            }

            printer
                .divider();

            if (isCampus && isFiitEnabled) {
                printer.section();
                printer
                    .align('center')
                    .text('FIIT MEAL PLANS', true)
                    .divider(true);

                var unitString = (report.totalFiitMealUnitCount == 1) ? 'Unit' : 'Units';
                printer
                    .textJustified([
                        'MEALS (count: ' + report.totalFiitMealUnitCount + ' ' + unitString + ')',
                        _currency(report.totalFiitMealUnitAmount)
                    ]);
                printer
                    .textJustified([
                        'DCB (count: ' + report.totalFiitDcbCount + ') ',
                        _currency(report.totalFiitDcbAmount)
                    ]);
                printer.divider();
            }

            /** # Taxes Collected By Tender **/
            if (report.taxBreakdown.length > 0) {
                let totalTaxesCount = 0;
                let totalTaxesAmount = 0;
                let isEmpty = true;
                const MAX_CHAR = 9;
                const SPACE = ' ';

                printer.section(); // create new section
                // title
                printer
                    .align('center')
                    .text('TAXES BREAKDOWN', true)
                    .divider(true);

                // contents
                for (let breakdown of report.taxBreakdown) {
                    let leftString = (!breakdown.label) ? breakdown.type : `${breakdown.type} - ${breakdown.label.toUpperCase()}`;
                    let taxAmountInDollars = _currency(new Decimal(breakdown.amountInCents / 100));
                    let rightString = `(count: ${breakdown.count}) ${SPACE.repeat(Math.max(MAX_CHAR - String(taxAmountInDollars).length, 0))}${taxAmountInDollars}`;

                    if (breakdown.amountInCents <= 0 || breakdown.type === 'MEAL') {
                        continue;
                    }

                    totalTaxesCount += breakdown.count;
                    totalTaxesAmount += breakdown.amountInCents;

                    printer
                        .align('left')
                        .textJustified([leftString, rightString]);
                    isEmpty = false;
                }

                totalTaxesAmount = _currency(new Decimal(totalTaxesAmount / 100));

                if (!isEmpty) {
                    // total taxes
                    printer
                    .align('left')
                    .textJustified(['', ''], false, {}, {}, '-') // dashed line
                    .textJustified([
                        'TOTAL COLLECTED ',
                        `(count: ${totalTaxesCount}) ${SPACE.repeat(Math.max(MAX_CHAR - String(totalTaxesAmount).length, 0))}${totalTaxesAmount}`
                    ], true);
                } else {
                    printer.align('center').text('None');
                }

                printer.divider(); // closing divider
            }

            if (CompanyAttributesService.includeCCTipsInTipsPayout() && report.tipBreakDown.length > 0) {
                let isEmpty = true;
                let totalTipCount = 0;
                let totalTipAmount = 0;
                const MAX_CHAR = 9;
                const SPACE = ' ';

                printer.section(); // create new section
                // title
                printer
                    .align('center')
                    .text('TIPS BREAKDOWN', true)
                    .divider(true);

                // contents
                for (let breakdown of report.tipBreakDown) {
                    let leftString = (breakdown.label) ? `${breakdown.type} - ${breakdown.label.toUpperCase()}` : breakdown.type;
                    let tipAmountInDollars = _currency(new Decimal(breakdown.amountInCents / 100));
                    let rightString = `(count: ${breakdown.count}) ${SPACE.repeat(Math.max(MAX_CHAR - String(tipAmountInDollars).length, 0))}${tipAmountInDollars}`;

                    if (!breakdown.amountInCents) {
                        continue;
                    }

                    totalTipCount += breakdown.count;
                    totalTipAmount += breakdown.amountInCents;

                    if (breakdown.label == 'CC') {
                        totalCCTipAmount += breakdown.amountInCents;
                    }

                    printer
                    .align('left')
                    .textJustified([leftString, rightString]);
                    isEmpty = false;
                }

                if (!isEmpty) {
                    totalTipAmount = _currency(new Decimal(totalTipAmount / 100));

                    // total taxes
                    printer
                    .align('left')
                    .textJustified(['', ''], false, {}, {}, '-') // dashed line
                    .textJustified([
                        'TOTAL TIPS COLLECTED ',
                        `(count: ${totalTipCount}) ${SPACE.repeat(Math.max(MAX_CHAR - String(totalTipAmount).length, 0))}${totalTipAmount}`
                    ], true);
                } else {
                    printer.align('center').text('None');
                }

                printer.divider(); // closing divider
            }

            /** # 2 Orders with Remaining Balance **/
            printer.section();

            printer
                .align('center')
                .text('ORDERS WITH REMAINING BALANCE', true)
                .divider(true);

            if (report.incompleteTransactions && report.incompleteTransactions.length > 0) {
                if (report.incompleteTransactions.length <= 2) {
                    printer
                        .align('left')
                        .textJustified(['Transaction ID', 'Remaining'])
                        .divider(true);

                    for (let incompleteTransaction of report.incompleteTransactions) {
                        let leftString = incompleteTransaction.transactionId;

                        let remainingAmountString = _currency(incompleteTransaction.remainingAmountCents / 100);
                        let totalAmountString = _currency(incompleteTransaction.transactionAmountCents / 100);
                        let rightString = remainingAmountString + ' (Total: ' + totalAmountString + ')';

                        printer
                            .textJustified([leftString, rightString]);
                    }
                } else {
                    // if there are too many (eg. 20) incomplete transactions in a shift, show a summary of the
                    // incomplete transactions rather than listing everything out
                    printer
                        .align('left')
                        .text('Transaction IDs:');

                    let incompleteTransactionIds = [];
                    let totalAmountCents = 0;
                    let remainingAmountCents = 0;
                    for (let incompleteTransaction of report.incompleteTransactions) {
                        let transactionId = incompleteTransaction.transactionId;
                        incompleteTransactionIds.push(transactionId);

                        totalAmountCents += incompleteTransaction.transactionAmountCents;
                        remainingAmountCents += incompleteTransaction.remainingAmountCents;
                    }

                    let incompleteTransactionIdString = incompleteTransactionIds.join(', ');
                    printer
                        .text(incompleteTransactionIdString);

                    printer.newLine();

                    printer
                        .align('left')
                        .text('Remaining:')
                        .text(_currency(remainingAmountCents / 100) + ' (Total: ' + _currency(totalAmountCents / 100) + ')');
                }
            } else {
                printer
                    .align('center')
                    .text('None');
            }

            printer
                .divider();

            /** # 3 Transaction Count **/
            printer.section();

            var averageReportableSales = 0;
            if (report.transactions > 0) {
                averageReportableSales = decimalReportableTotalSales
                    .dividedBy(new Decimal(100))
                    .dividedBy(new Decimal(report.transactions))
                    .toNearest(SharedDataService.baseDollar).toNumber();
            }

            printer
                .align('center')
                .text('TRANSACTION COUNT', true)
                .divider(true)
                .align('left')
                .textJustified(['# PATRON TX:', report.patonTransactions])
                .textJustified(['# GUEST TX :', report.guestTransactions])
                .textJustified(['# TOTAL TX :', report.transactions], true)
                .divider(true)
                .align('left')
                .textJustified(['AVG SALES:', _currency(averageReportableSales)])
                .divider();

            /** # 4 Sales **/
            printer.section();

            printer
                .align('center')
                .text('CARD PAYMENTS - POS', true)
                .divider(true);

            if (report.creditCardBreakdowns && report.creditCardBreakdowns.length) {
                var cardBreakdownTotalCents = 0;

                _.each(report.creditCardBreakdowns, function (breakdown) {
                    var cardName = 'Other';
                    if (breakdown.creditCardId && creditCardIdNameMap[breakdown.creditCardId]) {
                        cardName = creditCardIdNameMap[breakdown.creditCardId];
                    }

                    printer
                        .align('left')
                        .text(cardName)
                        .textJustified(['    # Transactions:', breakdown.count])
                        .textJustified(['    Amount:', _currency(breakdown.amountCents / 100)]);

                    cardBreakdownTotalCents += breakdown.amountCents;
                });

                printer
                    .align('left')
                    .textJustified(['', ''], false, {}, {}, '-') // dashed line
                    .textJustified(['Total:', _currency(cardBreakdownTotalCents / 100)]);

                printer.divider();
            } else {
                printer
                    .align('center')
                    .text('None')
                    .divider();
            }

            /** 5 Gift Cards **/
            printer.section();

            if (isGiftCardEnabled) {
                printer
                    .align('center')
                    .text('GIFT CARDS', true)
                    .divider(true);

                if (report.giftCard.createCount + report.giftCard.reloadCount + report.giftCard.redemptionCount > 0) {
                    printer
                        .align('left')
                        .textJustified(['# NEW SALES :', report.giftCard.createCount])
                        .textJustified(['$ NEW SALES :', _currency(report.giftCard.createAmountCents / 100)])
                        .textJustified(['# RELOAD    :', report.giftCard.reloadCount])
                        .textJustified(['$ RELOAD    :', _currency(report.giftCard.reloadAmountCents / 100)])
                        .textJustified(['# REDEMPTION:', report.giftCard.redemptionCount])
                        .textJustified(['$ REDEMPTION:', _currency(-report.giftCard.redemptionAmountCents / 100)])
                        .divider();
                } else {
                    printer
                        .align('center')
                        .text('No Gift Card Activity')
                        .divider();
                }
            }

            /** #6 Cash Drawer **/
            printer.section();

            printer
                .align('center')
                .text('CASH DRAWER SUMMARY', true)
                .divider(true)
                .align('left')
                .textJustified(['CASH COLLECTED       :', _currency(report.totalCashCollected)])
                .textJustified(['CASH REFUNDED        :', _currency(decimalCashRefundAmount.toNearest(SharedDataService.baseDollar).toNumber(), true)])
                .textJustified(['CASH PAID OUT        :', _currency(report.totalPayout, true)])
                .textJustified(['CASH ADDED           :', _currency(report.totalCashAdded)]);

            var netCash = new Decimal(report.totalCashCollected)
                .plus(new Decimal(report.totalCashAdded))
                .minus(decimalCashRefundAmount).toNearest(SharedDataService.baseDollar)
                .minus(new Decimal(report.totalPayout))
                .toNearest(SharedDataService.baseDollar).toNumber();

            printer
                .textJustified(['', ''], false, {}, {}, '-') // dashed line
                .textJustified(['CASH EXPECTED        :', _currency(netCash)], true)
                .newLine();

            printer
                .textJustified(['STARTING CASH BALANCE:', _currency(report.startingBalance)]);


            if (report.end) {
                printer
                    .textJustified(['ENDING CASH BALANCE  :', _currency(report.endingCashBalance)]);

                var cashActual = new Decimal(report.endingCashBalance)
                    .minus(report.startingBalance)
                    .toNearest(SharedDataService.baseDollar).toNumber();

                printer
                    .textJustified(['', ''], false, {}, {}, '-') // dashed line
                    .textJustified(['CASH ACTUAL          :', _currency(cashActual)], true);

                var cashDrawerBalance = new Decimal(report.endingCashBalance)
                    .minus(report.startingBalance)
                    .minus(new Decimal(netCash))
                    .toNearest(SharedDataService.baseDollar).toNumber();

                if (cashDrawerBalance > 0.1) {
                    printer
                        .textJustified(['CASH OVER            :', _currency(cashDrawerBalance)]);
                } else if (cashDrawerBalance < -0.1) {
                    printer
                        .textJustified(['CASH SHORTAGE        :', _currency(cashDrawerBalance, true)]);
                } else {
                    printer
                        .textJustified(['CASH BALANCED        :', _currency(cashDrawerBalance)]);
                }

                var cashOwedToTheHouse = new Decimal(netCash)
                    .minus(report.totalTipAmount - Number(parseFloat(totalCCTipAmount / 100).toPrecision(2)))
                    .toNearest(SharedDataService.baseDollar).toNumber();

                printer
                    .newLine()
                    .textJustified(['TOTAL TIPS COLLECTED   :', _currency(report.totalTipAmount - Number(parseFloat(totalCCTipAmount / 100).toPrecision(2)))], true)
                    .textJustified(['CASH OWED TO THE HOUSE :', _currency(cashOwedToTheHouse)], true);

            } else {
                printer
                    .textJustified(['ENDING CASH BALANCE  :', '(Shift Not Ended)']);
            }

            printer
                .newLine()
                .textJustified(['CASH DRAWER OPEN COUNTS :', report.cashDrawerOpenCounts]);
            printer.divider();

            /** #7 Other Tender Breakdown **/
            printer.section();

            var currentOtherTenderLabelString = companyAttributes['other__other_tender_labels'] || '';
            var currentOtherTenderLabels = currentOtherTenderLabelString.split(',');

            for (var currentOtherTenderLabel of currentOtherTenderLabels) {
                if (!currentOtherTenderLabel || currentOtherTenderLabel == '') {
                    continue;
                }

                currentOtherTenderLabel = currentOtherTenderLabel.toLowerCase();
                var foundBreakdown = _.findWhere(trackableOtherTenderLabelBreakdowns, {label: currentOtherTenderLabel});
                if (!foundBreakdown) {
                    trackableOtherTenderLabelBreakdowns.push({
                        label: currentOtherTenderLabel,
                        count: 0,
                        amountCents: 0
                    });
                }
            }

            trackableOtherTenderLabelBreakdowns = _.sortBy(trackableOtherTenderLabelBreakdowns, 'label');

            if (companyAttributes['other__enable_other_tender_option'] === 'true' || otherRefunds.amountCents) {
                trackableOtherTenderLabelBreakdowns.push({
                    label: 'Refund Other',
                    amountCents: -otherRefunds.amountCents,
                    count: otherRefunds.count
                });
            }

            if (companyAttributes['other__other_tender_labels'] || trackableOtherTenderLabelBreakdowns.length) {
                printer
                    .align('center')
                    .text('OTHER TENDER BREAKDOWNS', true)
                    .divider(true);

                for (var otherTenderLabelBreakdown of trackableOtherTenderLabelBreakdowns) {
                    var labelName = otherTenderLabelBreakdown.label;

                    if (labelName === 'fiitmps') {
                        labelName = 'FIIT Meal Card';
                    }

                    var otherTenderLabel = labelName + ' (count: ' + otherTenderLabelBreakdown.count + ') ';
                    var otherTenderValue = _currency(otherTenderLabelBreakdown.amountCents / 100);
                    printer
                        .align('left')
                        .textJustified([otherTenderLabel, otherTenderValue]);
                }

                printer.divider();
            }


            /** #8 Discounts **/
            printer.section();

            printer
                .align('center')
                .text('DISCOUNTS', true)
                .divider(true);

            var hasDiscountActivity = (report.totalPercentDiscountAmount > 0)
                || (report.totalDollarDiscountAmount)
                || (report.discountsApplied)
                || (report.itemDiscountBreakdowns && report.itemDiscountBreakdowns.length);

            if (hasDiscountActivity) {
                printer
                    .align('left')
                    .textJustified(['PERCENT DISCOUNTS (count: ' + report.totalPercentDiscountCount + '): ',
                    _currency(report.totalPercentDiscountAmount)])
                    .textJustified(['DOLLAR DISCOUNTS (count: ' + report.totalDollarDiscountCount + '): ',
                    _currency(report.totalDollarDiscountAmount)]);

                if (report.discountsApplied || (report.itemDiscountBreakdowns && report.itemDiscountBreakdowns.length)) {
                    printer
                        .textJustified(['', ''], false, {}, {}, '-'); // dashed line
                }

                if (report.discountsApplied) {
                    for (let [key, discount] of Object.entries(report.discountsApplied).sort(sortDiscountsObject)) { // eslint-disable-line no-unused-vars
                        printer
                            .align('left')
                            .textJustified([
                                '[Basket]' + discount.name + ' (count: ' + discount.count + '): ',
                                _currency(discount.amount)
                            ]);
                    }
                }

                if (report.itemDiscountBreakdowns) {
                    for (let itemDiscount of report.itemDiscountBreakdowns) {
                        printer
                            .align('left')
                            .textJustified([
                                '[Item]' + itemDiscount.discountName + ' (count: ' + itemDiscount.discountCount + '): ',
                                _currency(itemDiscount.totalDiscountAmount)
                            ]);
                    }
                }
            } else {
                printer
                    .align('center')
                    .text('No Discount Activity');
            }

            printer
                .divider(true);


            /** #9 Voids **/
            printer.section();

            printer
                .align('center')
                .text('VOIDS', true)
                .divider(true)
                .align('left');

            printer
                .textJustified([
                    'CASH VOIDS (count: ' + cashVoids.count + ') ',
                    _currency(cashVoids.amountCents / 100)
                ]);

            printer
                .textJustified([
                    'CREDIT VOIDS (count: ' + creditVoids.count + ') ',
                    _currency(creditVoids.amountCents / 100)
                ]);

            printer
                .textJustified([
                    'GIFT VOIDS (count: ' + giftVoids.count + ') ',
                    _currency(giftVoids.amountCents / 100)
                ]);

            if (otherVoids.count) {
                printer
                    .textJustified([
                        'TOTAL VOIDS (count: ' + otherVoids.count + ') ',
                        _currency(otherVoids.amountCents / 100)
                    ]);
            }

            printer
                .textJustified([
                    'TOTAL VOIDS (count: ' + totalVoids.count + ') ',
                    _currency(totalVoids.amountCents / 100)
                ]);

            printer
                .divider();

            /** # 10 Refunds **/
            printer.section();

            printer
                .align('center')
                .text('REFUNDS', true)
                .divider(true)
                .align('left');

            printer
                .textJustified([
                    'CASH REFUNDS (count: ' + cashRefunds.count + ') ',
                    _currency(cashRefunds.amountCents / 100)
                ]);

            printer
                .textJustified([
                    'CREDIT REFUNDS (count: ' + creditRefunds.count + ') ',
                    _currency(creditRefunds.amountCents / 100)
                ]);

            printer
                .textJustified([
                    'GIFT REFUNDS (count: ' + giftRefunds.count + ') ',
                    _currency(giftRefunds.amountCents / 100)
                ]);

            if (otherRefunds.count) {
                printer
                    .textJustified([
                        'OTHER REFUNDS (count: ' + otherRefunds.count + ') ',
                        _currency(otherRefunds.amountCents / 100)
                    ]);
            }

            printer
                .textJustified([
                    'TOTAL REFUNDS (count: ' + totalRefunds.count + ') ',
                    _currency(totalRefunds.amountCents / 100)
                ]);

            printer
                .textJustified([
                    'TOTAL EXCHANGES (count: ' + report.exchanges.count + ') ',
                    _currency(exchangeAmount)
                ]);

            printer
                .divider();

            printer
                .align('center')
                .text('CANCELLED TRANSACTIONS', true)
                .divider(true)
                .align('left')
                .textJustified(['# OF CANCELLED TRANSACTIONS: ', report.cancelledTransactions])
                .divider();

            printer
                .align('center')
                .text('EMPLOYEE HOURS', true)
                .divider(true)
                .align('left');

            printTimeCardSections(printer, report.timeCards);

            printer.divider();

            var currentDateTime = moment().format('YYYY-MM-DD hh:mm A');
            var printTime = 'Printed On ' + currentDateTime;
            printer
                .align('center')
                .text('END OF SHIFT REPORT')
                .text(printTime);

            printer
                .newLine(6)
                .cut();

            await printer.output(CompanyAttributesService.isScreenPrintEnabled());
            printer.attachCutElement(CompanyAttributesService.partialCutEnabled());
            return Promise.resolve();
        }

        var printTimeCards = async function (report, posStation) {
            const posPrinters = (posStation.posPrinters && posStation.posPrinters.length) ? posStation.posPrinters : report.posPrinters;
            const receiptFactory = new ReceiptFactoryWrapper();
            posStation = posStation || {};

            if (!posPrinters || !posPrinters.length) {
                return Promise.reject('No Printers');
            }

            var printUrlArrayOriginal = buildPrinterObjArray(posPrinters, false);
            var promiseArr = [];

            var printUrlArrayGroupedByWidth = _.groupBy(printUrlArrayOriginal, 'receiptSize');

            for (const key of Object.keys(printUrlArrayGroupedByWidth)) {
                var printUrlArray = printUrlArrayGroupedByWidth[key];

                var company = CurrentSession.getCompany();
                var companyAttributes = company.attributes || {};
                var useTransactionReceiptV2 = companyAttributes.use_transaction_receipt_v2 === 'true';
                var useTransactionReceiptV1Image = companyAttributes.use_transaction_receipt_v1_image === 'true';

                if (useTransactionReceiptV2 || useTransactionReceiptV1Image) {
                    var printerProtocol = printUrlArray[0].protocol;
                    var outputFormat = (printerProtocol && printerProtocol == 'usb') ? 'image/png' : 'image/jpeg';
                    receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.IMAGE_PRINTER, {
                        envConfig: EnvConfig,
                        printerDetails: printUrlArray[0],
                        type: 'report',
                        outputFormat: outputFormat
                    });
                } else {
                    receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.STAR_PRINTER, {
                        printerDetails: printUrlArray[0]
                    });

                    if (CompanyAttributesService.isScreenPrintEnabled()) {
                        receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.SCREEN_PRINTER, {
                            printerDetails: printUrlArray[0]
                        });
                    }

                }

                await generateTimeCards(report, receiptFactory);

                for (const factory of receiptFactory.getFactoryList()) {
                    promiseArr.push(new Promise(function (resolve, reject) {
                        sendMessageArray({
                            urlArray: printUrlArray,
                            request: factory.receiptData,
                            openDrawer: false,
                            successCallback: function () {
                                resolve();
                            },
                            errorCallback: function (error, attempt) {
                                reject(error);
                            }
                        });
                    }));
                }

            }

            return Promise.all(promiseArr);
        };

        var generateTimeCards = async function (report, printer) {
            printer
                .initialize()
                .newLine(2);

            // Report Title
            printer
                .align('left')
                .text('TIME SHEET', true)
                .divider()
                .newLine(1);

            // Report Time
            var printDate = moment().format('MMM DD, YYYY, hh:mm A');
            printer
                .align('left')
                .text('Print On: ' + printDate)
                .newLine(2);

            printTimeCardSections(printer, report.timeCards);

            printer
                .newLine(2)
                .cut();

            await printer.output();
        };

        var printOpenDrawerReceipt = async function (openDrawerEntry, posStation, location) {
            let receiptFactory = new ReceiptFactoryWrapper();
            posStation = posStation || {};

            var printUrlArrayOriginal;
            var promiseArr = [];

            if (posStation && posStation.posPrinters) {
                printUrlArrayOriginal = buildPrinterObjArray(posStation.posPrinters, false);
            }

            var printUrlArrayGroupedByWidth = _.groupBy(printUrlArrayOriginal, 'receiptSize');

            for (const key of Object.keys(printUrlArrayGroupedByWidth)) {
                var printUrlArray = printUrlArrayGroupedByWidth[key];
                var company = CurrentSession.getCompany();
                var companyAttributes = company.attributes || {};
                var useTransactionReceiptV2 = companyAttributes.use_transaction_receipt_v2 === 'true';
                var useTransactionReceiptV1Image = companyAttributes.use_transaction_receipt_v1_image === 'true';

                if (useTransactionReceiptV2 || useTransactionReceiptV1Image) {
                    var printerProtocol = printUrlArray[0].protocol;
                    var outputFormat = (printerProtocol && printerProtocol == 'usb') ? 'image/png' : 'image/jpeg';
                    receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.IMAGE_PRINTER, {
                        envConfig: EnvConfig,
                        printerDetails: printUrlArray[0],
                        type: 'report',
                        outputFormat: outputFormat
                    });
                } else {
                    receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.STAR_PRINTER, {
                        printerDetails: printUrlArray[0]
                    });

                    if (CompanyAttributesService.isScreenPrintEnabled()) {
                        receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.SCREEN_PRINTER, {
                            printerDetails: printUrlArray[0]
                        });
                    }
                }

                await generateOpenDrawerReceipt(openDrawerEntry, receiptFactory, location, posStation);

                for (const factory of receiptFactory.getFactoryList()) {
                    promiseArr.push(new Promise(function (resolve, reject) {
                        sendMessageArray({
                            urlArray: printUrlArray,
                            request: factory.receiptData,
                            openDrawer: false,
                            successCallback: function () {
                                resolve();
                            },
                            errorCallback: function (error, attempt) {
                                reject(error);
                            }
                        });
                    }));
                }
            }

            return Promise.all(promiseArr);
        };

        var generateOpenDrawerReceipt = async function (openDrawerEntry, printer, location, posStation) {
            printer
                .initialize()
                .newLine(2);

            var locationName = location.locationName || '';

            var station = posStation || {};
            var stationName = station.name || '';

            // Report Title
            printer
                .align('center')
                .text(locationName, true)
                .text(stationName, true)
                .newLine();

            var activityType = '';
            if (openDrawerEntry.cashDrawerActivityType == 'payout') {
                activityType = 'PAID OUT';
            } else if (openDrawerEntry.cashDrawerActivityType == 'addcash') {
                activityType = 'ADD CASH';
            }

            // Report Title
            printer
                .align('center')
                .title(activityType, true)
                .newLine(1);

            // Report Title
            printer
                .align('center')
                .text('Cash Drawer')
                .newLine(1);

            var name = openDrawerEntry.user.firstname;
            name += ' ';
            name += openDrawerEntry.user.lastname.substr(0, 1);
            printer
                .textJustified(['EMPLOYEE :', name])
                .textJustified(['', ''], false, {}, {}, '-') // dashed line
                .newLine();


            // Report Time
            var printDate = moment().format('MM/DD/YYYY, hh:mm A');
            printer
                .textJustified(['DATE: ', printDate])
                .newLine();

            printer
                .textJustified(['DESCRIPTION: ', openDrawerEntry.description])
                .newLine();

            if (openDrawerEntry.cashDrawerActivityType == 'payout') {

                printer
                    .textJustified(['SUPPLIER: ', (openDrawerEntry.supplier || 'NOT PROVIDED')])
                    .newLine();

                printer
                    .textJustified(['INVOICE P.0: ', (openDrawerEntry.invoiceNumber || 'NOT PROVIDED')])
                    .newLine();
            }

            var amount = (openDrawerEntry.cashDrawerActivityType == 'payout') ? _currency(openDrawerEntry.amount, true)
                : '+' + _currency(openDrawerEntry.amount);

            printer
                .textJustified(['', ''], false, {}, {}, '-') // dashed line
                .newLine()
                .textJustified(['CASH AMOUNT:', amount], true)
                .newLine(6);

            printer
                .textJustified(['', ''], false, {}, {}, '-'); // dashed line

            var currentDateTime = moment().format('MM/DD/YYYY, hh:mm A');
            var printTime = 'Printed On ' + currentDateTime;
            printer
                .align('center')
                .text(printTime);

            printer
                .newLine()
                .cut();

            await printer.output();
        };

        var _convertDurationMillisToString = function (durationMillis) {
            var duration = moment.duration(durationMillis);

            var durationHours = Math.floor(duration.as('hours')); // .as('hours') shows total number of hours
            var durationMinutes = duration.minutes(); // `.minutes()` shows remaining minutes (after hours)

            var durationHourString = durationHours + ' hr';
            var durationMinuteString = durationMinutes + ' min';
            var durationString = durationHourString + ' ' + durationMinuteString;

            return durationString;
        };

        var printTimeCardSections = function (printer, timeCards) {
            if (!timeCards || timeCards.length === 0) {
                printer.text('(No employee clocked in)');
            }
            _.each(timeCards, function (timeCard, index) {
                var employeeName = timeCard.user.firstname;
                printer.text(employeeName);

                var startTime = moment(timeCard.startTime).format('MMM DD, YYYY hh:mm A');
                printer.text(['  Start: ', startTime]);

                var duration;
                var durationString;
                // Ended shift
                if (timeCard.endTime) {
                    var endTime = moment(timeCard.endTime).format('MMM DD, YYYY hh:mm A');
                    printer.text(['  End  : ', endTime]);

                    duration = timeCard.duration;
                    durationString = _convertDurationMillisToString(duration);
                    printer.text(['  Total: ', durationString]);

                    // Active shift
                } else {
                    duration = moment.duration(new Date().getTime() - timeCard.startTime);
                    durationString = _convertDurationMillisToString(duration);
                    printer.text(['  Running : ', durationString]);
                }

                var activeBreak = _.find(timeCard.timeCardBreaks, {
                    isActive: true
                });

                if (timeCard.totalBreakMillis || activeBreak) {
                    var breakDuration = timeCard.totalBreakMillis || 0;

                    // Active break
                    if (activeBreak) {
                        breakDuration += (new Date().getTime() - activeBreak.startTime);
                    }

                    var breakDurationString = _convertDurationMillisToString(breakDuration);
                    printer.text(['  Break: ', breakDurationString]);

                    var adjustedDurationString = _convertDurationMillisToString(
                        duration - breakDuration);
                    printer.text(['  Adjusted: ', adjustedDurationString]);
                }

                if (index < timeCards.length - 1) {
                    printer.divider(true);
                }
            });
        };

        async function printEndOfShiftReportV2 (report, posStation = {}) {
            const posPrinters = (posStation.posPrinters && posStation.posPrinters.length) ? posStation.posPrinters : report.posPrinters;
            let receiptFactory = new ReceiptFactoryWrapper();
            let promiseArr = [];

            if (!posPrinters || !posPrinters.length) {
                return Promise.reject('No printers');
            }

            let printUrlArrayOriginal = buildPrinterObjArray(posPrinters, false);
            var printUrlArrayGroupedByWidth = _.groupBy(printUrlArrayOriginal, 'receiptSize');

            for (const key of Object.keys(printUrlArrayGroupedByWidth)) {
                var printUrlArray = printUrlArrayGroupedByWidth[key];

                var company = CurrentSession.getCompany();
                var companyAttributes = company.attributes || {};
                var useTransactionReceiptV2 = companyAttributes.use_transaction_receipt_v2 === 'true';
                var useTransactionReceiptV1Image = companyAttributes.use_transaction_receipt_v1_image === 'true';

                if (useTransactionReceiptV2 || useTransactionReceiptV1Image) {
                    var printerProtocol = printUrlArray[0].protocol;
                    var outputFormat = (printerProtocol && printerProtocol == 'usb') ? 'image/png' : 'image/jpeg';
                    receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.IMAGE_PRINTER, {
                        envConfig: EnvConfig,
                        printerDetails: printUrlArray[0],
                        type: 'report',
                        outputFormat: outputFormat
                    });
                } else {
                    receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.STAR_PRINTER, {
                        printerDetails: printUrlArray[0]
                    });

                    if (CompanyAttributesService.isScreenPrintEnabled()) {
                        receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.SCREEN_PRINTER, {
                            printerDetails: printUrlArray[0]
                        });
                    }
                }

                await generateEndOfShiftReport(report, receiptFactory);

                for (const factory of receiptFactory.getFactoryList()) {
                    promiseArr.push(new Promise(function (resolve, reject) {
                        sendMessageArray({
                            urlArray: printUrlArray,
                            request: factory.receiptData,
                            openDrawer: false,
                            successCallback: function () {
                                resolve();
                            },
                            errorCallback: function (error, attempt) {
                                reject(error);
                            }
                        });
                    }));
                }
            }

            return Promise.all(promiseArr);
        }
        function previewEndOfShiftReport (report, posStation = {}) {
            const posPrinters = (posStation.posPrinters && posStation.posPrinters.length) ? posStation.posPrinters : report.posPrinters;
            let printerObjArr = buildPrinterObjArray(posPrinters, false);
            var printer = new ReceiptFactoryWrapper();
            printer.registerFactory(ReceiptFactoryWrapper.factoryAlias.HTML_PRINTER, {
                printerDetails: printerObjArr[0]
            });

            return new Promise(async function (resolve, reject) {
                await generateEndOfShiftReport(report, printer);
                const printerList = printer.getFactoryList();
                resolve(printerList[0].receiptData);
            });
        }
        function openCashDrawer (printUrlArray, immediate, retry, successCallback, errorCallback) {
            var company = CurrentSession.getCompany();
            var companyAttributes = company.attributes || {};
            var useQuebecSrm = companyAttributes.has_mev_box === 'true';

            if (useQuebecSrm) {
                MevBoxService.openCashDrawer().then(successCallback).catch(errorCallback);
            } else {
                sendMessageArray({
                    urlArray: printUrlArray,
                    request: '',
                    openDrawer: true,
                    immediate: true,
                    attempt: 0,
                    successCallback: successCallback,
                    errorCallback: errorCallback
                });
            }
        }

        function testPrinter (printUrlArrayOriginal) {
            var printUrlArrayGroupedByWidth = _.groupBy(printUrlArrayOriginal, 'receiptSize');

            var promise;
            Object.keys(printUrlArrayGroupedByWidth).forEach(function (key) {
                var printUrlArray = printUrlArrayGroupedByWidth[key];

                var request = '';
                var builder = new StarWebPrintBuilder();

                request += builder.createInitializationElement();

                promise = new Promise(function (resolve, reject) {
                    sendMessageArray({
                        'urlArray': printUrlArray,
                        'request': request,
                        'openDrawer': false,
                        'immediate': true,
                        'attempt': attemptTimeout.length, // no retry
                        'successCallback': function () {
                            resolve();
                        },
                        'errorCallback': function (error, attempt) {
                            reject(error);
                        }
                    });
                });
            });
            return promise;
        }

        function mapCardReceipt (cardReceipt) {
            var builder = new StarWebPrintBuilder();
            var request = '';

            var cardReceiptData = [
                {name: 'Terminal ID', value: cardReceipt.terminalId},
                {name: 'Trans ID', value: cardReceipt.transactionId},
                {name: 'Trans Type', value: cardReceipt.transactionType},
                {name: 'Date/Time', value: cardReceipt.transactionDate},
                {name: 'Card Type', value: cardReceipt.cardBrand},
                {name: 'Card Number', value: cardReceipt.maskedCardNumber},
                {name: 'Entry Method', value: cardReceipt.entryMethod},
                {name: 'Approval Code', value: cardReceipt.approvalCode},
                {name: 'Total Amount', value: cardReceipt.formattedAmount},
                {name: 'Transaction Seq. #', value: cardReceipt.transactionSequenceNum},
                {name: 'Account Type', value: cardReceipt.debitAccountType},
                {name: 'EMV AID', value: cardReceipt.emvAid},
                {name: 'EMV TVR', value: cardReceipt.emvTvr},
                {name: 'EMV TSI', value: cardReceipt.emvTsi},
                {name: 'EMV App Label', value: cardReceipt.emvAppLabel},
                {name: 'CVM Result', value: cardReceipt.cvmResult},
                {name: 'Host Response Code', value: cardReceipt.hostResponseCode},
                {name: 'Host Response ISO', value: cardReceipt.hostResponseIsoCode},
                {name: 'Reference #', value: cardReceipt.referenceNumber},
                {name: 'Authorization #', value: cardReceipt.referenceNumber || ''},
            ];

            request += builder.createAlignmentElement({position: 'center'});

            _.each(cardReceiptData, function (datum) {
                try {
                    if (datum.value || datum.value === '') {
                        datum.nameSpacing = Array(20 - datum.name.length).join(' ');
                        datum.valueSpacing = Array(24 - datum.value.length).join(' ');

                        request += builder.createTextElement({data: datum.name + datum.nameSpacing + datum.value + datum.valueSpacing + '\n'});
                    }
                } catch (e) {
                    console.log(e);
                }
            });

            return request;
        }

        function printErrorReceipt (printUrlArrayOriginal, details = {}, cardReceipt, printType = PrintType.MERCHANT) {
            var printUrlArrayGroupedByWidth = _.groupBy(printUrlArrayOriginal, 'receiptSize');

            Object.keys(printUrlArrayGroupedByWidth).forEach(function (key) {
                var printUrlArray = printUrlArrayGroupedByWidth[key];

                var builder = new StarWebPrintBuilder();
                var request = '';
                request += builder.createInitializationElement();
                request += builder.createTextElement({characterspace: 0});
                request += builder.createAlignmentElement({position: 'right'});
                request += builder.createLogoElement({number: 1});
                request += builder.createAlignmentElement({position: 'center'});

                // location name
                var locationName = details.locationName + '\n';
                request += builder.createTextElement({data: locationName});
                request += builder.createTextElement({data: '\n\n'});

                request += builder.createTextElement({data: cardReceipt.addressLine1});
                request += builder.createTextElement({data: cardReceipt.addressLine2});
                request += builder.createTextElement({data: '\n\n'});

                cardReceipt.transactionDate = moment().format('YYYY-MM-DD HH:mm:ss');
                // 'USD$' + cardReceipt.approvedAmount;
                cardReceipt.formattedAmount = cardReceipt.approvedAmount ? '$' + cardReceipt.approvedAmount : null;

                request += mapCardReceipt(cardReceipt);

                request += builder.createTextElement({data: '\n\n'});
                request += builder.createTextElement({data: 'Transaction Not Completed\n'});
                request += builder.createTextElement({data: '\n\n'});

                var receiptRequests = [];

                var customerCopyRequest = request;

                if (printType == PrintType.MERCHANT
                    || printType == PrintType.ALL) {
                    // Add merchant copy to printer request

                    request += builder.createAlignmentElement({position: 'center'});
                    request += builder.createTextElement({data: '\nMERCHANT COPY\n\n'});
                    receiptRequests.push(request);
                }

                if (printType == PrintType.CUSTOMER
                    || printType == PrintType.ALL) {
                    // Add customer copy to printer request

                    customerCopyRequest += builder.createAlignmentElement({position: 'center'});
                    customerCopyRequest += builder.createTextElement({data: '\n' + translate('receipt.customerCopy') + '\n\n'});
                    receiptRequests.push(customerCopyRequest);
                }

                _.each(receiptRequests, function (receiptRequest) {
                    receiptRequest += builder.createTextElement({characterspace: 0});
                    receiptRequest += getCutElement(builder);

                    sendMessageArray({
                        urlArray: printUrlArray,
                        request: receiptRequest,
                    });
                });
            });
        }

        function addLine (builder, request, leftText, rightText) {
            var str = '';

            if (leftText) {
                str += leftText;
            }
            if (rightText) {
                str += ' ' + rightText;
            }
            str += '\n';
            return builder.createTextElement({data: str});
        }

        function addLine2 (receiptFactory, leftText, rightText) {
            let str = '';
            let align = (rightText || rightText == '') ? 'left' : 'center';

            str += (leftText) ? leftText : '';
            str += (rightText) ? rightText : '';

            receiptFactory
                .align(align)
                .text(str);
        }

        function _groupDiscountModifiers (modifiers) {
            if (!modifiers || modifiers.length === 0) {
                return [];
            }

            var groupedResult = [];
            var nonDiscountModifiers = _.filter(modifiers, function (modifier) {
                return !(modifier.subtype === 'discount' || modifier.subtype === 'loyalty');
            });
            groupedResult = groupedResult.concat(nonDiscountModifiers);

            var discountModifiers = _.filter(modifiers, function (modifier) {
                return (modifier.subtype === 'discount' || modifier.subtype === 'loyalty');
            });
            discountModifiers = _.sortBy(discountModifiers, 'receiptItemId');

            if (discountModifiers && discountModifiers.length > 0) {
                var mainDiscount = discountModifiers[0];

                // the string between '()' inclusive
                const discountLabel = mainDiscount.name.substring(0, mainDiscount.name.indexOf(')') + 1);

                var totalDiscountPrice = new Decimal(0);
                _.each(discountModifiers, function (discountModifier) {
                    var discountPrice = new Decimal($filter('itemDisplayPrice')(discountModifier));
                    totalDiscountPrice = totalDiscountPrice.plus(discountPrice);
                });

                var aggregatedDiscountModifier = {
                    name: discountLabel || mainDiscount.name,
                    notes: '',
                    origin: mainDiscount.origin,
                    price: totalDiscountPrice.toNearest(SharedDataService.baseDollar).toNumber(),
                    quantity: 1,
                    taxAmount: 0,
                    taxRate: 0,
                    total: 0,
                    type: mainDiscount.type,
                    subtype: mainDiscount.subtype
                };

                groupedResult.push(aggregatedDiscountModifier);
            }

            return groupedResult;
        }

        function printReceiptModifier (receiptFactory, modifier, options, rootItem) {
            options = options || {};
            var ignoreDiscount = !!options.ignoreDiscount;
            var emphasis = !!options.emphasis;
            var styles = options.styles;
            var useLoyalty = !!options.useLoyalty;

            // var modifierQuantity = modifier.quantity || 1;

            if (((modifier.subtype === 'discount' || modifier.subtype === 'loyalty') && ignoreDiscount)
                || modifier.subtype === 'attachment') {
                return;
            }


            let multiplierToAdd = '';
            if (modifier.multiplier !== 1 && modifier.multiplier !== undefined) {
                multiplierToAdd = modifier.multiplierInWords;
            } else if (rootItem) {
                const modifierMultiplier = new Decimal(modifier.quantity).div(rootItem.quantity).toNearest(1).toNumber();
                if (modifierMultiplier > 1) {
                    multiplierToAdd = modifierMultiplier + 'x';
                } else if (modifierMultiplier == 0) {
                    multiplierToAdd = 'NONE';
                } else {
                    multiplierToAdd = '';
                }
            }
            // not showing modifier quantity for now since there is no way to
            // select more than 1 of the same modifier anyway

            var indentation = (styles && styles.width && styles.width > 1)
                ? '  + '
                : '    + ';

            var leftString = indentation + multiplierToAdd + ' ';

            var rightString = '';
            if (modifier.price && !modifier._ignorePrice) {
                rightString = _currency($filter('itemDisplayPrice')(modifier));

                if (useLoyalty && modifier.subtype === 'loyalty') {
                    leftString += '*';
                }
            }

            leftString += modifier.name;

            if (rightString === '') {
                receiptFactory.text(leftString, emphasis, styles);
            } else {
                receiptFactory.textJustified([leftString, rightString], emphasis, styles);
            }
        }

        function printReceipt (
            response,
            receipt,
            cardReceiptArr,
            skipCashDrawer = false,
            printType = PrintType.MERCHANT,
            isDuplicate = false
        ) {
            var company = CurrentSession.getCompany();
            var companyAttributes = company.attributes || {};
            var useTransactionReceiptV2 = companyAttributes.use_transaction_receipt_v2 === 'true';
            var useTransactionReceiptV1Image = companyAttributes.use_transaction_receipt_v1_image === 'true';
            var useQuebecSrm = companyAttributes.has_mev_box === 'true';

            // TODO add condition to switch between V1 and V2
            if (useQuebecSrm && !SharedDataService.bypassSrm) {
                printReceiptSrm(
                    response,
                    receipt,
                    cardReceiptArr,
                    skipCashDrawer,
                    printType,
                    isDuplicate
                );
            } else if (useTransactionReceiptV2) {
                printReceiptV2(
                    response,
                    receipt,
                    cardReceiptArr,
                    skipCashDrawer,
                    printType,
                    isDuplicate
                );
            } else if (useTransactionReceiptV1Image) {
                printReceiptV1Image(
                    response,
                    receipt,
                    cardReceiptArr,
                    skipCashDrawer,
                    printType,
                    isDuplicate
                );
            } else {
                printReceiptV1(
                    response,
                    receipt,
                    cardReceiptArr,
                    skipCashDrawer,
                    printType,
                    isDuplicate
                );
            }
        }

        function printReceiptSrm (
            response,
            receipt,
            cardReceiptArr,
            skipCashDrawer = false,
            printType = PrintType.MERCHANT,
            isDuplicate = false
        ) {

            var printTypesArr;
            if (printType == PrintType.ALL) {
                printTypesArr = [PrintType.MERCHANT, PrintType.CUSTOMER];
            } else {
                printTypesArr = [printType];
            }

            var company = CurrentSession.getCompany();

            var localizations = {
                'en': {
                    transactionRecord: 'TRANSACTION RECORD',
                    type: 'TYPE',
                    acct: 'ACCT',
                    amount: 'AMOUNT',
                    partiallyApproved: 'PARTIALLY APPROVED',
                    amountDue: 'AMOUNT DUE',
                    tip: 'TIP',
                    surcharge: 'SURCHARGE',
                    total: 'TOTAL',
                    accountBalance: 'ACCOUNT BALANCE',
                    cardNumber: 'CARD #',
                    dateTime: 'DATE/TIME',
                    referenceNum: 'Reference #',
                    authNum: 'AUTH #',
                    verifiedByPin: 'VERIFIED BY PIN',
                    chipCardSwiped: 'CHIP CARD SWIPED',
                    chipCardKeyed: 'CHIP CARD KEYED',
                    chipCardMalfunction: 'CHIP CARD MALFUNCTION',
                    transactionPartiallyApproved: 'TRANSACTION PARTIALLY APPROVED',
                    invoiceNumber: 'INVOICE #',
                    transactionCancelled: 'Transaction Cancelled',
                    n99approved: '99 Approved - Thank You XXX',
                    partialAuthorizationCancelled: 'Partial Authorization Cancelled',
                    transactionNotCompleted: 'Transaction Not Completed',
                    n99transactionNotApproved: '99 Transaction Not Approved XXX',
                    n99transactionNotCompleted: '99 Transaction Not Completed XXX',
                    cardHolderSignature: 'Cardholder Signature',
                    buyerAgrees: 'CARDHOLDER WILL PAY CARD ISSUER ABOVE AMOUNT PURSUANT TO CARDHOLDER AGREEMENT',
                    retainCopy: '*Important - retain this copy for your records*',
                    merchantCopy: 'MERCHANT COPY',
                    customerCopy: 'CUSTOMER COPY',

                    default: 'FLASH DEFAULT',
                    savings: 'SAVINGS',
                    chequing: 'CHEQUING',

                    purchase: 'PURCHASE',
                    refund: 'REFUND',
                    purchaseCorrection: 'PURCHASE CORRECTION',
                    refundCorrection: 'REFUND CORRECTION',
                    batchClose: 'BATCH CLOSE',
                    balanceInquiry: 'BALANCE INQUIRY',
                    recallMessage: 'RECALL MESSAGE',

                    declinedByCard: 'DECLINED BY CARD - 990',
                    cardRemoved: 'CARD REMOVED - 991',
                    noSignatureRequired: 'NO SIGNATURE REQUIRED',

                    void: 'VOID',
                    cardBalanceInquiry: 'CARD BALANCE INQUIRY',
                    settlement: 'SETTLEMENT',
                    cardEntryMethod: 'ENTRY METHOD',
                },
                'es': {},
                'fr': {
                    transactionRecord: 'RELEVE DE TRANSACTION',
                    type: 'TYPE',
                    acct: 'COMPTE',
                    amount: 'MONTANT',
                    partiallyApproved: 'AUTORISATION PARTIEL',
                    amountDue: 'MONTANT DU',
                    tip: 'POURBOIRE',
                    surcharge: 'FRAIS',
                    total: 'TOTAL',
                    accountBalance: 'SOLDE DU COMPTE',
                    cardNumber: 'N. DE CARTE',
                    dateTime: 'DATE/HEURE',
                    referenceNum: '# Reference',
                    authNum: '# AUTH',
                    verifiedByPin: 'VERIFIEE PAR NIP',
                    chipCardSwiped: 'CARTE A PUCE GLISSEE',
                    chipCardKeyed: 'CARTE A PUCE TAPEE',
                    chipCardMalfunction: 'DEFAILLANCE CARTE A PUCE',
                    transactionPartiallyApproved: 'AUTORISATION PARTIEL DE LA TRANSACTION',
                    invoiceNumber: 'NO. DE FACTURE',
                    transactionCancelled: 'Autorisation Partiel Annule',
                    n99approved: '99 Approuvee - MERCI XXX',
                    partialAuthorizationCancelled: 'Autorisation Partiel Annule',
                    transactionNotCompleted: 'Operation non Completee',
                    n99transactionNotApproved: '99 Operation Refusee XXX',
                    n99transactionNotCompleted: '99 Operation non Completee XXX',
                    cardHolderSignature: 'SIGNATURE',
                    buyerAgrees: 'LE TITULAIRE VERSERA CE MONTANT A L\'EMETTEUR CONFORMEMENT AU CONTRAT ADHERENT',
                    retainCopy: '*Important - conserver cette copie pour vos dossiers*',
                    merchantCopy: 'COPIE DU DETAILLANT',
                    customerCopy: 'COPIE DU CLIENT',

                    default: 'DEFAUT FLASH',
                    savings: 'EPARGNE',
                    chequing: 'CHEQUE',

                    purchase: 'ACHAT',
                    refund: 'REMISE D\'ACHAT',
                    purchaseCorrection: 'CORRECTION D\'ACHAT',
                    refundCorrection: 'CORRECTION DE REMISE',
                    batchClose: 'BATCH CLOSE',
                    balanceInquiry: 'INTERROGATION DE SOLDE',
                    recallMessage: 'RECALL MESSAGE',

                    declinedByCard: 'REFUSEE PAR LA CARTE - 990',
                    cardRemoved: 'CARTE RETIREE - 991',
                    noSignatureRequired: 'SIGNATURE NON REQUISE',

                    void: 'VENTE',
                    cardBalanceInquiry: 'CARD BALANCE INQUIRY',
                    settlement: 'SETTLEMENT',
                    cardEntryMethod: 'ENTRY METHOD',
                },
            };

            var printUrlArrayOriginal = buildPrinterObjArray(response.posPrinters, false);
            var printUrlArrayGroupedByWidth = _.groupBy(printUrlArrayOriginal, 'receiptSize');

            var giftCardPurchaseMap = {};
            _.each(response.giftCardPurchases, function (giftCardPurchase) {
                giftCardPurchaseMap[giftCardPurchase.code] = giftCardPurchase;
            });

            var refundedTransactionItemMap = {};
            if (response.refundTransactions) {
                for (let refundTransaction of response.refundTransactions) {
                    for (var refundedTransactionItem of refundTransaction.items) {
                        var refundedQuantity = refundedTransactionItemMap[refundedTransactionItem.transactionItemId] || 0;
                        refundedQuantity += refundedTransactionItem.quantity;

                        refundedTransactionItemMap[refundedTransactionItem.transactionItemId] = refundedQuantity;
                    }
                }
            }

            var hasTransactionPercentDiscount = !!response.percentDiscount;

            Object.keys(printUrlArrayGroupedByWidth).forEach(async function (key) {
                var translation = localizations['fr'];

                var receiptRequests = [];
                for (let i of Object.keys(printTypesArr)) {
                    var currentPrintType = printTypesArr[i];

                    var receiptFactory = srmReceiptFactory();
                    receiptFactory.initialize();

                    // Print held order qr code
                    if (response.heldOrder && response.heldOrder.suspendId) {
                        await receiptFactory
                            .align('center')
                            .createQrCodeElement(`ho:1:${response.heldOrder.suspendId}`);
                    }

                    receiptFactory
                        .align('center')
                        .text(response.locationName);

                    if (response.location) {
                        if (response.location.street) {
                            receiptFactory.text(response.location.street);
                        }

                        var city = response.location.city;
                        var region = response.location.region;

                        var cityRegionArray = [city, region].filter(function (e) {
                            return e != null;
                        });
                        var cityRegion = cityRegionArray.join(', ');

                        if (cityRegion) {
                            receiptFactory.text(cityRegion);
                        }
                    }

                    receiptFactory.newLine(1);

                    if (response.cashierName) {
                        receiptFactory
                            .text(translate('receipt.cashierName') + ': ' + response.cashierName);
                    }

                    if (response.stationName) {
                        receiptFactory
                            .text(translate('receipt.posStation') + ': ' + response.stationName)
                            .newLine(2);
                    }

                    var receiptCounterString = generateReceiptCounterString(response.receiptCounter, response.heldOrder);
                    if (receiptCounterString) {
                        receiptFactory
                            .align('center')
                            .title(receiptCounterString, true, {width: 4, height: 4, invert: true})
                            .newLine(1);
                    }

                    if (response.patronName) {
                        receiptFactory
                            .align('center')
                            .title(response.patronName)
                            .newLine(1);
                    }

                    var transactionDateTime = moment(response.transactionDateTime);
                    var transactionDateTimeString = transactionDateTime.format('YYYY-MM-DD hh:mm:ss A');

                    if (response.receiptId) {
                        receiptFactory
                            .align('left')
                            .text(translate('receipt.transactionId') + ': #' + response.receiptId);
                    } else {
                        if (response.heldOrder) {
                            receiptFactory
                                .align('left')
                                .text(translate('receipt.bill'));
                        }
                    }

                    var transactionType = response.transactionType || 'SALE';
                    var isVoidOrRefund = (transactionType === 'VOID' || transactionType === 'REFUND' || transactionType === 'REFUND_PARTIAL');
                    var transactionTypeString = transactionType;

                    if (isVoidOrRefund) {
                        switch (transactionType) {
                            case 'VOID':
                                transactionTypeString = 'VOID';
                                break;
                            case 'REFUND':
                                transactionTypeString = 'REFUND';
                                break;
                            case 'REFUND_PARTIAL':
                                transactionTypeString = 'PART. REFUND';
                                break;
                        }

                        receiptFactory
                            .align('center')
                            .title(transactionTypeString, true, {width: 3, height: 3})
                            .newLine(1);

                        receiptFactory
                            .align('left')
                            .text(translate('receipt.authorizedby') + ': ' + response.userFullName)
                            .newLine(1);
                    } else {
                        if (response.originalTransactionId) {
                            transactionTypeString = 'EXCHANGE';
                        }

                        receiptFactory
                            .align('left')
                            .text(translate('receipt.transactionType') + ': ' + transactionTypeString)
                            .newLine(1);
                    }

                    if (response.percentDiscount) {
                        if (response.mealPlansRedeemed && response.mealEqAmount) {
                            receiptFactory
                                .align('left')
                                .text(translate('receipt.discount.rewardsRedemption'))
                                .text(translate('receipt.discount.authorized.mobile'))
                                .newLine(1);

                        } else {
                            if (response.labelledDiscounts && response.labelledDiscounts.length > 0) {
                                receiptFactory
                                    .align('left')
                                    .text(translate('receipt.discount.type') + ': ' + response.labelledDiscounts[0].discountName);

                                if (response.labelledDiscounts[0].authorizedByUser) {
                                    receiptFactory
                                        .align('left')
                                        .text(translate('receipt.discount.authorized.by') + ': ' + response.labelledDiscounts[0].authorizedByUser.firstname
                                            + ' ' + response.labelledDiscounts[0].authorizedByUser.lastname)
                                        .newLine(1);
                                } else {
                                    receiptFactory
                                        .align('left')
                                        .text(translate('receipt.discount.authorized.nopin'))
                                        .newLine(1);
                                }

                            } else {
                                receiptFactory
                                    .align('left')
                                    .text(translate('receipt.discount.type.general'))
                                    .text(translate('receipt.discount.authorized.general'))
                                    .newLine(1);
                            }
                        }
                    } else if (response.dollarDiscountAmount) {
                        receiptFactory
                            .align('left')
                            .text(translate('receipt.discount.type.general'))
                            .text(translate('receipt.discount.authorized.general'))
                            .newLine(1);
                    }


                    // / Move code to the bottom ends here
                    receiptFactory
                        .align('left')
                        .text('Date: ' + transactionDateTimeString)
                        .newLine(2);

                    receiptFactory
                        .textJustified([translate('receipt.item'), translate('receipt.price')])
                        .divider(true);

                    var decimalDisplayedPercentDiscountAmount = new Decimal(0);

                    var receiptHierarchy = generateReceiptHierarchy(receipt);

                    _.each(receiptHierarchy, function (item) {
                        if (item.locationServicePeriodMenuId === -99999) {
                            return; // buzzer
                        }

                        if (item.price < 0 && response.percentDiscount > 0) {
                            return; // skip trannsaction-wide discount
                        }

                        var leftString = item.quantity || 1;
                        leftString += '   ';
                        leftString += item.name;

                        var rightString = _currency($filter('itemDisplayPrice')(item));
                        var hasLoyaltyDiscount = _.some(item.children, {subtype: 'loyalty'});
                        if (hasLoyaltyDiscount) {
                            leftString = '*' + leftString;
                        }

                        receiptFactory
                            .align()
                            .textJustifiedAndWrapped([leftString, rightString]);

                        if (item.subtype === 'giftcard.create' || item.subtype === 'giftcard.reload') {
                            var giftCardPurchase = giftCardPurchaseMap[item.upc];

                            if (giftCardPurchase) {
                                var balanceDollar = giftCardPurchase.currentBalanceCents / 100.0;
                                receiptFactory
                                    .textJustified([translate('receipt.giftcard.remaining'), _currency(balanceDollar)]);
                            }
                        }

                        var receiptModifierPrintOptions = {
                            ignoreDiscount: hasTransactionPercentDiscount,
                            styles: {
                                width: 1,
                                height: 1
                            },
                            useLoyalty: hasLoyaltyDiscount
                        };

                        var modifiers = _groupDiscountModifiers(item.children);

                        if (modifiers && modifiers.length > 0) {
                            _.each(modifiers, function (subItem) {
                                if (subItem.type === 'modifier_group') {
                                    _.each(subItem.children, function (subSubItem) {
                                        printReceiptModifier(
                                            receiptFactory,
                                            subSubItem,
                                            receiptModifierPrintOptions,
                                            item
                                        );
                                    });
                                    // Individual Item
                                } else {
                                    printReceiptModifier(
                                        receiptFactory,
                                        subItem,
                                        receiptModifierPrintOptions,
                                        item
                                    );
                                }

                                if (subItem.subtype === 'discount' || subItem.subtype === 'loyalty') {
                                    decimalDisplayedPercentDiscountAmount = decimalDisplayedPercentDiscountAmount
                                        .plus(new Decimal(subItem.price));
                                }
                            });
                        }

                        if (item.notes) {
                            var noteString = translate('receipt.note') + ': ' + item.notes;
                            receiptFactory.text(noteString);
                        }

                        var itemRefundQuantity = refundedTransactionItemMap[item.receiptItemId];
                        if (itemRefundQuantity > 0) {
                            var refundVerb = '';
                            switch (transactionType) {
                                case 'VOID':
                                    refundVerb = 'voided';
                                    break;
                                case 'REFUND':
                                case 'REFUND_PARTIAL':
                                    refundVerb = (response.exchanged) ? 'exchanged' : 'refunded';
                                    break;
                            }

                            if (!refundVerb) {
                                // shouldn't happen, but adding this to be safe
                                return;
                            }

                            var refundActionString = (itemRefundQuantity == 1) ? ' has been ' : ' have been ';
                            refundActionString += refundVerb;
                            var refundString = '    *' + itemRefundQuantity + refundActionString;
                            receiptFactory.text(refundString);
                        }
                    });

                    if (response.percentDiscount) {
                        if (response.mealPlansRedeemed && response.mealEqAmount) {
                            var loyaltyDiscountDescription = (response.percentDiscount * 100).toFixed(0) + '%';
                            receiptFactory
                                .newLine(1)
                                .align()
                                .titleJustified([translate('receipt.rewards.applied') + ': ', loyaltyDiscountDescription])
                                .text(translate('receipt.rewards.applied.disclaimer'));
                        } else {
                            var percentageString = response.percentDiscount * 100 + '%';
                            var discountString = '';
                            if (response.labelledDiscounts && response.labelledDiscounts.length > 0) {
                                discountString = response.labelledDiscounts[0].discountName + ' (' + percentageString + '): ';
                            } else {
                                discountString = translate('receipt.discount') + ' (' + percentageString + '): ';
                            }

                            // Basket-level discount is applied pre-tax, so the amount discounted (and recorded in the db) is also
                            // the pre-tax amount. In order to display amount discounted in VAT-included countries, we have to dig
                            // the tax information from the receipt list. This ensures correct tax amount even with items with
                            // mixed tax rates
                            var displayedPercentDiscountAmount = (SharedDataService.taxIncludedInPrice)
                                ? decimalDisplayedPercentDiscountAmount.toNumber() // this is already recorded as negative cash flow
                                : -response.percentDiscountAmount; // this needs to be negated because it is recorded as an absolute amount

                            response.transactionSubTotal = response.transactionSubTotal + 0.01;
                            displayedPercentDiscountAmount = displayedPercentDiscountAmount + 0.01;
                            response.transactionTotal = response.transactionTotal + 0.01;
                            response.creditCardAmount = response.creditCardAmount + 0.01;
                            receiptFactory
                                .align()
                                .textJustified([discountString, _currency(displayedPercentDiscountAmount)]);
                        }
                    }

                    receiptFactory.divider(true);

                    if (response.dollarDiscountAmount) {
                        receiptFactory
                            .align()
                            .textJustified([translate('receipt.discount') + ': ', _currency(-response.dollarDiscountAmount)]);
                    }

                    let gstAmount = 0;
                    let qstAmount = 0;

                    if (response.transactionTax > 0) {
                        let totalTax = new Decimal(response.transactionTax);
                        let gstPercent = new Decimal(5);
                        let quebecTax = new Decimal(14.975);

                        gstAmount = totalTax.times(gstPercent.dividedBy(quebecTax)).toDecimalPlaces(2).toNumber();
                        qstAmount = totalTax.minus(gstAmount).toDecimalPlaces(2).toNumber();
                    }

                    let tpsNum = 'NA';
                    let tvqNum = 'NA';

                    const taxRegisDetails = CompanyAttributesService.getReceiptTaxRegistrationDetails();

                    if (taxRegisDetails) {
                        taxRegisDetails.split(/\s/).forEach(function (taxNum) {
                            let details = taxNum.split(/#/);

                            switch (details[0]) {
                                case 'TPS':
                                    tpsNum = details[1];
                                    break;
                                case 'TVQ':
                                    tvqNum = details[1];
                                    break;
                                default:
                            }
                        });
                    }

                    receiptFactory
                        .align()
                        .textJustified([translate('receipt.subtotal') + ': ', _currency(response.transactionSubTotal)])
                        .textJustified([translate('receipt.gst') + ` (${tpsNum}): `, _currency(gstAmount)])
                        .textJustified([translate('receipt.pst') + ` (${tvqNum}): `, _currency(qstAmount)])
                        .textJustified([translate('receipt.total.tax') + ': ', _currency(response.transactionTax)]);

                    if (response.tipAmount > 0) {
                        receiptFactory
                            .textJustified([translate('receipt.tiP') + ': ', _currency(response.tipAmount)]);
                    }

                    receiptFactory
                        .newLine(1)
                        .titleJustified([translate('receipt.total') + ': ', _currency(response.transactionTotal + response.tipAmount)], true);

                    receiptFactory
                        .divider(true);

                    if (!isDuplicate && response.remainingBalance < 0) {
                        receiptFactory
                            .textJustified([translate('receipt.cashReceived') + ': ', _currency(response.cashAmount + response.remainingBalance)])
                            .textJustified([translate('receipt.credit') + ': ', _currency(response.creditCardAmount + response.tipAmount)]);
                    } else {
                        receiptFactory
                            .textJustified([translate('receipt.cashReceived') + ': ', _currency(response.cashAmount)])
                            .textJustified([translate('receipt.credit') + ': ', _currency(response.creditCardAmount + response.tipAmount)]);
                    }

                    if (response.otherAmount || response.fiitMealPlanCount || response.fiitDcbAmount) {
                        for (let tender of response.tenders) {
                            if (tender.transactionType != 'OTHER') {
                                continue;
                            }

                            if (tender.fiitTenders && tender.fiitTenders.length) {
                                for (var fiitTender of tender.fiitTenders) {
                                    var fiitLeftString = fiitTender.fiitMealPlanName || '';

                                    fiitLeftString = (fiitLeftString.length > 15) ? fiitLeftString.substr(0, 15).concat('...') : fiitLeftString;

                                    if (fiitTender.fiitMealPlanType === 'DCB') {
                                        fiitLeftString += ' (DCB) ';
                                    }

                                    var fiitRightString = fiitTender.unitsUsed;
                                    if (fiitTender.fiitMealPlanType === 'MEAL') {
                                        fiitRightString += (fiitTender.unitsUsed > 1) ? ' Meal Units' : ' Meal Unit';
                                        fiitRightString = '(' + fiitRightString + ') ';
                                        fiitRightString += _currency(fiitTender.equivalentDollarValue);
                                    } else {
                                        fiitRightString = _currency(fiitRightString);
                                    }

                                    receiptFactory
                                        .align()
                                        .textJustifiedAndWrapped([fiitLeftString, fiitRightString]);
                                }
                            } else {
                                let tenderDetail = tender.transactionTenderDetail || {};
                                let otherTenderType = tenderDetail.otherType;
                                let otherTenderTypeDisplayName = otherTenderType || translate('receipt.other.amount');
                                let mealUnitsDisplayName = translate('receipt.meal.units') + ': ';

                                switch (otherTenderType) {
                                    case 'alphapay':
                                        otherTenderTypeDisplayName = 'WeChat Pay/Alipay';
                                        break;
                                    case 'fiitmps':
                                        otherTenderTypeDisplayName = 'DCB';
                                        break;
                                }

                                otherTenderTypeDisplayName = otherTenderTypeDisplayName + ': ';


                                if (tender.amountCents > 0) {
                                    receiptFactory
                                        .textJustified([otherTenderTypeDisplayName, _currency(tender.amountCents / 100)]);
                                }


                                if (tender.amountMeals > 0) {
                                    receiptFactory
                                        .textJustified([mealUnitsDisplayName, tender.amountMeals]);
                                }
                            }
                        }
                    }

                    _.each(response.tenders, function (tender) {
                        if (tender.transactionType === 'GIFTCARD') {
                            var giftCardId = (tender.giftCard) ? tender.giftCard.id : undefined;
                            var giftCard = _.findWhere(response.giftCardUsage, {id: giftCardId});
                            if (giftCard) {
                                if (giftCard.source === GIFTCARD_SOURCE.SOURCE_TRANSACTION || giftCard.source === GIFTCARD_SOURCE.SOURCE_API) {
                                    let giftCardDescription = translate('receipt.gift.card') + '(' + $filter('giftCardMask')(giftCard.code) + '): ';
                                    let tenderAmount = (tender.amountCents + tender.tipAmountCents) / 100.0;
                                    let giftCardBalance = giftCard.currentBalanceCents / 100.0;

                                    receiptFactory
                                        .textJustified([giftCardDescription, _currency(tenderAmount)]);

                                    if (!isVoidOrRefund) {
                                        // for void and refund, the remaining balance is displayed in the
                                        // VOID/REFUND history below
                                        receiptFactory
                                            .text(translate('receipt.giftcard.remaining') + ': ' + _currency(giftCardBalance));
                                    }
                                } else {
                                    let storeCreditDescription = translate('receipt.store.credit') + ': ';
                                    let tenderAmount = tender.amountCents / 100.0;

                                    receiptFactory
                                        .textJustified([storeCreditDescription, _currency(tenderAmount)]);
                                }
                            }
                        }
                    });

                    if (response.cashRounding) {
                        receiptFactory
                            .textJustified([translate('receipt.cash.rounding') + ': ', _currency(response.cashRounding)]);
                    }

                    if (transactionType === 'SALE') {
                        if (response.remainingBalance > 0) {
                            receiptFactory
                                .titleJustified([translate('receipt.remaining.balance') + ': ', _currency(response.remainingBalance)], true);
                        } else {
                            if (PosStatusService.isOffline()) {
                                receiptFactory
                                    .titleJustified([translate('receipt.change') + ': ', _currency(response.change)], true);
                            } else {
                                receiptFactory
                                    .titleJustified([translate('receipt.change') + ': ', _currency(-response.change)], true);
                            }
                        }
                    }

                    if (response.refundTransactions && response.refundTransactions.length > 0) {
                        receiptFactory
                            .align('center')
                            .newLine(1)
                            .title('--- ' + transactionTypeString + ' History ---')
                            .newLine(1);

                        for (let refundTransaction of response.refundTransactions) {
                            receiptFactory
                                .align('left')
                                .text(moment(refundTransaction.eventTime).format('YYYY-MM-DD HH:mm:ss'));

                            for (var refundTender of refundTransaction.tenders) {
                                var refundTenderType = refundTender.transactionType;
                                if (refundTenderType === 'MEAL') {
                                    receiptFactory
                                        .textJustified(['LOYALTY', refundTender.amountMeals + ' PTS']);
                                } else {
                                    var isGiftCardTender = refundTender.transactionType === 'GIFTCARD' && refundTender.giftCard;
                                    if (isGiftCardTender) {
                                        var refundTenderDescription;
                                        if (refundTender.giftCard.source == 0) {
                                            refundTenderDescription = 'Gift Card (' + $filter('giftCardMask')(refundTender.giftCard.code) + '): ';
                                            receiptFactory
                                                .textJustified([refundTenderDescription, _currency(refundTender.amountCents / 100.0)]);
                                            receiptFactory
                                                .text('   - Remaining: ' + _currency(refundTender.giftCard.currentBalanceCents / 100.0));
                                        } else {
                                            refundTenderDescription = 'Store Credit';
                                            receiptFactory
                                                .textJustified([refundTenderDescription, _currency(refundTender.amountCents / 100.0)]);
                                        }
                                    } else {
                                        receiptFactory
                                            .textJustified([refundTender.transactionType, _currency(refundTender.amountCents / 100.0)]);
                                    }
                                }
                            }

                            receiptFactory.newLine(1);
                        }
                    }

                    receiptFactory
                        .newLine(1);

                    receiptFactory
                        .align()
                        .textJustified(['', ''], false, {}, {}, '-'); // a line of asterisks

                    if (CurrentSession.getCompany().hasLoyalty) {
                        var rewardSummaryTitle = translate('receipt.rewards.summary');
                        if (response.patronName) {
                            rewardSummaryTitle = response.patronName + ' ' + rewardSummaryTitle;
                        }

                        var loyaltyEarned = (response.loyaltyAmount || 0);
                        if (response.hasLoyalty) {
                            var rewardCollectedDescription = loyaltyEarned + ' PTS';
                            var rewardRedemptionDescription = response.mealPlansRedeemed + ' PTS';
                            var rewardDiscountDescription = ((response.mealPlansRedeemed) ?
                                (response.percentDiscount * 100).toFixed(0) : 0) + '%';
                            var rewardBalanceDescription = (response.currentLoyaltyPoints || 0).toFixed(0) + ' PTS';

                            receiptFactory
                                .align('center')
                                .text(rewardSummaryTitle)
                                .align('left')
                                .textJustified(['', ''], false, {}, {}, '-') // a line of asterisks
                                .textJustified([translate('receipt.loyalty.collected') + ': ', rewardCollectedDescription])
                                .textJustified([translate('receipt.loyalty.redeemed') + ': ', rewardRedemptionDescription + '(' + rewardDiscountDescription + ')'])
                                .textJustified([translate('receipt.loyalty.balance') + ': ', rewardBalanceDescription]);
                        } else {
                            var appName = CurrentSession.getOrganizationAppName();

                            var guestRewardCollectedDescription = '0 PTS';
                            var guestRewardRedemptionDescription = '0 PTS';
                            var guestRewardBalanceDescription = '0 PTS';
                            var guestLoyaltyReminder = translate('receipt.download.app', {appName: appName});

                            receiptFactory
                                .align('center')
                                .text(rewardSummaryTitle)
                                .align('left')
                                .textJustified(['', ''], false, {}, {}, '-') // a line of asterisks
                                .textJustified([translate('receipt.loyalty.collected') + ': ', guestRewardCollectedDescription])
                                .textJustified([translate('receipt.loyalty.redeemed') + ': ', guestRewardRedemptionDescription])
                                .textJustified([translate('receipt.loyalty.balance') + ': ', guestRewardBalanceDescription]);

                            // temporary pitaland contest info.
                            // should be removed after contest is over.
                            if (company && CompanyAttributesService.getCustomReceiptText()) {
                                // inserting new lines dynamically based on \n
                                var textSplit = CompanyAttributesService.getCustomReceiptText().split('\n');
                                _.each(textSplit, (line) => {
                                    receiptFactory
                                        .align('left')
                                        .text(line);
                                });
                            } else {
                                receiptFactory
                                    .align('left')
                                    .text(guestLoyaltyReminder);
                            }
                        }
                    }

                    if (CompanyAttributesService.hasQRCodePrint() && response.transactionUuid) {
                        const appUrl = CurrentSession.getOrganization().mobileAppUrl;
                        const qrCode = appUrl + '#' + response.transactionUuid;
                        receiptFactory.align('center');
                        await receiptFactory.createQrCodeElement(qrCode);
                    }

                    receiptFactory
                        .align('left')
                        .textJustified(['', ''], false, {}, {}, '-'); // a line of asterisks*/

                    receiptFactory
                        .newLine(1);

                    // Offer summary
                    let offers = response.offersUsed || [];
                    for (let offer of offers) {
                        let offerSummaryTitle = translate('receipt.offers.summary');
                        let offerReward;

                        if (offer.offerType === 'EXTRA_POINTS' || offer.offerType === 'POINTS_MULTIPLIER') {
                            offerReward = offer.pointsIssued + ' PTS';
                        } else {
                            offerReward = _currency(offer.discountAmountCents / 100.0);
                        }
                        receiptFactory
                            .align('center')
                            .text(offerSummaryTitle)
                            .align('left')
                            .textJustified(['', ''], false, {}, {}, '-') // a line of asterisks
                            .textJustified([translate('receipt.offers.name') + ': ', offer.name])
                            .textJustified([translate('receipt.offers.reward') + ': ', offerReward]);
                    }
                    receiptFactory
                        .align('left')
                        .textJustified(['', ''], false, {}, {}, '-'); // a line of asterisks*/

                    receiptFactory
                        .newLine(1);

                    receiptFactory
                        .align('center')
                        .text(translate('receipt.thanksForVisit'))
                        .text(response.companyName)
                        .newLine(2);

                    for (let i of Object.keys(cardReceiptArr)) {
                        var cardReceipt = cardReceiptArr[i];

                        // some terminals like the Moneris Core family of terminals
                        // return a full string that we need to print. In this case
                        // we call "continue" and avoid formatting the receipt ourselves.
                        if (currentPrintType == PrintType.CUSTOMER && cardReceipt.rawStringCustomer) {
                            receiptFactory.addSections({data: cardReceipt.rawStringCustomer});
                            continue;
                        } else if (currentPrintType == PrintType.MERCHANT && cardReceipt.rawStringMerchant) {
                            receiptFactory.addSections({data: cardReceipt.rawStringMerchant});
                            continue;
                        }

                        if (currentPrintType == PrintType.CUSTOMER) {
                            translation = localizations[cardReceipt.customerLanguage || 'en'];
                        } else {
                            translation = localizations['en'];
                        }

                        receiptFactory.addSections({data: '\n'});
                        receiptFactory.addSections({data: '\n'});

                        receiptFactory.addSections({data: translation.transactionRecord + '\n'});

                        var isCreditCard = cardReceipt.isCredit;

                        // var transDate = new Date(response.transactionDateTime);
                        // transactionDateTime = transDate.toLocaleString() + '\n';

                        if (cardReceipt.paymentProcessor === 'moneris') {
                            // currently only Moneris returns this information from
                            // the terminal.  For globalpayments, we need an
                            // alternate source to populate merchantname + address
                            receiptFactory.addSections({data: cardReceipt.merchantName});
                            receiptFactory.addSections({data: cardReceipt.addressLine1});
                            receiptFactory.addSections({data: cardReceipt.addressLine2});
                        }

                        if (cardReceipt.paymentProcessor === 'globalpayments' && cardReceipt.demoMode) {
                            receiptFactory.addSections({data: 'DEMO MODE'});
                        }

                        receiptFactory.textJustified(['TYPE:', translation[cardReceipt.transactionType] || cardReceipt.transactionType]);

                        // VISA, MASTERCARD, AMERICAN EXPRESS, INTERAC

                        if (cardReceipt.paymentProcessor === 'globalpayments') {
                            if (cardReceipt.transactionSequenceNum) {
                                receiptFactory.textJustified(['SEQ: ', cardReceipt.transactionSequenceNum]);
                            }

                            if (currentPrintType == PrintType.MERCHANT && cardReceipt.merchantId) {
                                receiptFactory.textJustified(['MID: ', cardReceipt.merchantId]);
                            }
                        }

                        if (cardReceipt.isDebit) {
                            if (cardReceipt.debitAccountType === 'default') {
                                receiptFactory.textJustified([translation.acct + ':', translation[cardReceipt.debitAccountType]]);
                            } else {
                                receiptFactory.textJustified([translation.acct + ':', cardReceipt.cardName + ' ' + (translation[cardReceipt.debitAccountType] || '')]);
                            }
                        } else {
                            receiptFactory.textJustified([translation.acct + ':', cardReceipt.cardName]);
                        }
                        receiptFactory.textJustified([translation.amount + ':', cardReceipt.transactionAmount]);

                        // only need to show these is a partial transaction occurred.
                        if (cardReceipt.partiallyApproved) {
                            receiptFactory.textJustified([translation.partiallyApproved + ':', cardReceipt.partiallyApproved]);
                            receiptFactory.textJustified([translation.amountDue + ':', cardReceipt.balanceDue]);
                        }

                        if (cardReceipt.tipAmount && cardReceipt.tipAmount > 0) {
                            receiptFactory.textJustified([translation.tip + ': ', cardReceipt.tipAmount]);
                        }

                        if (cardReceipt.isDebit && cardReceipt.transactionTypeCode === '00' && cardReceipt.surchargeAmount) {
                            receiptFactory.textJustified([translation.surcharge + ': ', cardReceipt.surchargeAmount]);
                        }
                        receiptFactory.textJustified([translation.total + ': ', cardReceipt.totalAmount]);

                        if (currentPrintType == PrintType.CUSTOMER &&
                            !cardReceipt.isDebit &&
                            ['00', '01', '60'].includes(cardReceipt.transactionTypeCode) &&
                            cardReceipt.cardBalance) {
                            receiptFactory.textJustified([translation.accountBalance + ': ', cardReceipt.cardBalance]);
                        }

                        if (cardReceipt.cardholderName) {
                            receiptFactory.textJustified(['Cardholder Name' + ': ', cardReceipt.cardholderName]);
                        }

                        receiptFactory.textJustified([translation.cardNumber + ': ', cardReceipt.cardNumber]);
                        receiptFactory.textJustified([translation.dateTime + ': ', cardReceipt.transactionDateTime]);

                        if (cardReceipt.paymentProcessor === 'globalpayments' || cardReceipt.paymentProcessor === 'heartland') {
                            receiptFactory.textJustified([translation.cardEntryMethod + ': ', cardReceipt.cardEntryMethod]);
                        } else if (cardReceipt.referenceNumber) {
                            var referenceNum = '';
                            referenceNum += cardReceipt.referenceNumber + ' ';

                            if (cardReceipt.cardEntryMethod) {
                                referenceNum += cardReceipt.cardEntryMethod;
                            }
                            receiptFactory.textJustified([translation.referenceNum + ': ', referenceNum]);
                        }

                        if (cardReceipt.success && cardReceipt.authorizationNumber) {
                            receiptFactory.textJustified([translation.authNum + ': ', cardReceipt.authorizationNumber]);
                        }

                        if (cardReceipt.emvAppPreferredName) {
                            receiptFactory.textJustified([cardReceipt.emvAppPreferredName]);
                        } else if (cardReceipt.emvAppLabel) {
                            receiptFactory.textJustified([cardReceipt.emvAppLabel]);
                        } else if (cardReceipt.emvAid) {
                            // need to show something if EMV transaction
                            receiptFactory.textJustified([cardReceipt.cardName]);
                        }

                        if (cardReceipt.emvAid) {
                            receiptFactory.textJustified([cardReceipt.emvAid]);
                        }

                        var emvTvr = '';
                        if (cardReceipt.emvTvr) {
                            emvTvr = cardReceipt.emvTvr;
                        }
                        var emvTsi = '';
                        if (cardReceipt.emvTsi) {
                            emvTsi = cardReceipt.emvTsi;
                        }
                        receiptFactory.textJustified([emvTvr + ' ' + emvTsi]);

                        if (cardReceipt.emvCryptogramType && cardReceipt.emvCryptogram) {
                            receiptFactory.textJustified([cardReceipt.emvCryptogramType + ' ' + cardReceipt.emvCryptogram]);
                        }

                        // TODO transactionStatus 11 and 19 are applicable only to Moneris, NOT GP
                        if (cardReceipt.emvAid && cardReceipt.transactionStatus === '11') {
                            receiptFactory.textJustified([translation.declinedByCard]);
                        }
                        if (cardReceipt.emvAid && cardReceipt.transactionStatus === '19') {
                            receiptFactory.textJustified([translation.cardRemoved]);
                        }

                        if (cardReceipt.verifiedByPin && currentPrintType == PrintType.MERCHANT) {
                            receiptFactory.textJustified([translation.verifiedByPin]);
                        }

                        if (cardReceipt.cardEntryMethod === 'F') {
                            // F means EMV with fallback swipe
                            receiptFactory.textJustified([translation.chipCardSwiped]);
                        } else if (cardReceipt.emvAid && cardReceipt.cardEntryMethod === 'G') {
                            // G means swipe, so have have to verify it is EMV as well
                            receiptFactory.textJustified([translation.chipCardKeyed]);
                        }

                        if (cardReceipt.approvedWithMalfunc
                            && cardReceipt.cardName == 'MASTERCARD'
                            && currentPrintType == PrintType.MERCHANT) {
                            receiptFactory.textJustified([translation.chipCardMalfunction]);
                        }

                        if (cardReceipt.partiallyApproved) {
                            receiptFactory.textJustified([translation.transactionPartiallyApproved]);
                        }

                        if (cardReceipt.invoiceNumber) {
                            receiptFactory.textJustified([translation.invoiceNumber + ': ', cardReceipt.invoiceNumber]);
                        }

                        var isoCode = '';
                        if (cardReceipt.hostResponseIsoCode && !cardReceipt.isReversal) {
                            isoCode = cardReceipt.hostResponseIsoCode;
                        }

                        var hostResponseCode = '';
                        if (cardReceipt.hostResponseCode && !cardReceipt.isReversal) {
                            hostResponseCode = cardReceipt.hostResponseCode;
                        }

                        var statusLine = '';

                        /* 12 - Communication Error
                           13 - Cancelled by User
                           14 - Timed out on User Input
                           15 - Transaction Not Completed
                           16 - Transaction Not Completed, Card Removed
                           17 - Chip failure occurs during communication with the card
                           23 - Partial approval is cancelled by the merchant or the customer
                           30 - Transaction not Supported
                           31 - Transaction not Allowed
                           32 - Invalid ECR/PC Parameter
                           95 - Terminal is not in the Idle state or it is in Stand-Alone mode. Terminal Can't accept a new request. */

                        if (cardReceipt.success) {
                            statusLine = translation.n99approved;
                        } else {
                            if (cardReceipt.cancelledByUser) {
                                statusLine = translation.transactionCancelled;
                            } else if (cardReceipt.partialApprovalCancelled) {
                                statusLine = translation.partialAuthorizationCancelled;
                            } else if (cardReceipt.declinedByCardOnline) {
                                statusLine = translation.transactionNotCompleted;
                            } else if (cardReceipt.cardRemoved) {
                                statusLine = translation.transactionNotCompleted;
                            } else if (cardReceipt.terminalTimeout) {
                                statusLine = translation.transactionNotCompleted;
                            } else if (cardReceipt.reversal) {
                                statusLine = translation.transactionNotCompleted;
                            } else if (cardReceipt.isDebit) {
                                if (['05', '51', '54', '55', '57', '58', '61', '62', '65', '75', '85', '92'].includes(cardReceipt.hostResponseIsoCode)) {
                                    statusLine = translation.n99transactionNotApproved;
                                } else {
                                    statusLine = translation.n99transactionNotCompleted;
                                }
                            } else if (
                                ['060', '068', '069', '074', '075', '078', '087', '088', '097', '100',
                                    '101', '102', '104', '106', '108', '113', '115', '206', '212', '800',
                                    '801', '802', '810', '811', '821', '898'].includes(cardReceipt.hostResponseCode)
                            ) {
                                statusLine = translation.n99transactionNotCompleted;
                            } else {
                                statusLine = translation.n99transactionNotApproved;
                            }
                        }
                        statusLine = statusLine.replace(/XXX/, hostResponseCode);
                        statusLine = statusLine.replace(/99/, isoCode);
                        receiptFactory.addSections({data: statusLine});

                        if (cardReceipt.formFactor) {
                            receiptFactory.addSections({data: cardReceipt.formFactor});
                        }

                        if (
                            cardReceipt.success
                            && isCreditCard
                            && (cardReceipt.showCustomerSignatureLine || cardReceipt.showMerchantSignatureLine)
                            && currentPrintType == PrintType.MERCHANT
                            // && (cardReceipt.transactionTypeCode == '00' ||
                            // cardReceipt.transactionTypeCode == '03')
                        ) {
                            receiptFactory.addSections({data: 'X____________________________________'});
                            receiptFactory.addSections({data: translation.cardHolderSignature});

                            if (cardReceipt.transactionTypeCode == '00') {
                                receiptFactory.addSections({data: translation.buyerAgrees});
                            }
                        }

                        if (isCreditCard) {
                            receiptFactory.addSections({data: translation.retainCopy});
                        }

                        if (isCreditCard && ['H', 'T'].includes(cardReceipt.cardEntryMethod) && !cardReceipt.showCustomerSignatureLine) {
                            receiptFactory.addSections({data: translation.noSignatureRequired});
                        }
                    }

                    // ask for signature for non credit card refunds and voids
                    if (cardReceiptArr &&
                        cardReceiptArr.length == 0 &&
                        currentPrintType == PrintType.MERCHANT &&
                        (transactionType == 'VOID' || transactionType == 'REFUND' || transactionType == 'REFUND_PARTIAL')) {

                        receiptFactory.addSections({data: 'X____________________________________'});
                        receiptFactory.addSections({data: translate('receipt.customer.signature')});
                    }

                    if (company && CompanyAttributesService.getReceiptFooter()) {
                        receiptFactory.addSections({data: CompanyAttributesService.getReceiptFooter()});
                    }

                    if (company && CompanyAttributesService.getReceiptTaxRegistrationDetails()) {
                        receiptFactory.addSections({data: CompanyAttributesService.getReceiptTaxRegistrationDetails()});
                    }

                    if (currentPrintType == PrintType.CUSTOMER) {
                        for (let key of Object.keys(giftCardPurchaseMap)) {
                            let item = giftCardPurchaseMap[key];
                            buildGiftCardRequestSrm(receiptFactory, item);
                        }
                    }

                    var request = await receiptFactory.output();
                    var tenders = response.tenders || [];
                    var tenderTypes = tenders.map((tender) => {
                        // credit and debit both have type 'CREDIT'. This ensures
                        // that we make a distinction by creditCardName
                        let type = tender.transactionType;
                        type = type === 'CREDIT' && tender.creditCardName ? cardTypeMap[tender.creditCardName.toLowerCase()] : type;

                        return type;
                    });

                    receiptRequests.push({
                        body: request,
                        transactionSubTotal: response.transactionSubTotal,
                        transactionTotal: response.transactionTotal,
                        transactionTaxQst: qstAmount,
                        transactionTaxGst: gstAmount,
                        receiptId: response.receiptId ? response.receiptId : 0,
                        transactionType: transactionType,
                        transactionDateTime: transactionDateTime.format('YYYYMMDDhhmmss'),
                        tenderTypes: tenderTypes,
                        remainingBalance: response.remainingBalance,
                        isDuplicate: isDuplicate,
                        isCustomerCopy: currentPrintType == PrintType.CUSTOMER,
                        isSplitTransaction: response.isSplit,
                        isTransactionAlreadyInitialized: response.isTransactionAlreadyInitialized
                    });
                }

                var isCashTransaction = response.cashAmount && response.cashAmount > 0;
                var isCreditCardTransaction = response.creditCardAmount && response.creditCardAmount > 0;
                var restrictOpenDrawerToCashTransaction = company.attributes.receipt__restrict_open_cash_drawer === 'true';

                var openDrawer = (!skipCashDrawer);

                var openDrawerForTransaction = (restrictOpenDrawerToCashTransaction)
                    ? isCashTransaction
                    : isCashTransaction || isCreditCardTransaction;

                openDrawer = openDrawer && openDrawerForTransaction;

                var errorModalCount = 0;
                var errorCallback = function (error, attempt) {
                    if (errorModalCount > 0) {
                        return;
                    }
                    errorModalCount++;
                    PosAlertService.showAlertByName('srm-connect', {
                        modalCallback: function () {
                            MevBoxService.setMevBoxBypass(false);
                        },
                        dismissModalCallback: function () {
                            MevBoxService.setMevBoxBypass(true);
                        }
                    });
                };

                for (let receiptRequest of receiptRequests) {
                    receiptRequest.openDrawer = openDrawer;
                    receiptRequest.iconUrl = company.companyLogoUrl;

                    await MevBoxService.printReceipt(receiptRequest).catch(errorCallback);
                }
            });
        }

        const cardTypeMap = {
            'visa': 'CREDIT',
            'american express': 'CREDIT',
            'amex': 'CREDIT',
            'mastercard': 'CREDIT',
            'debit': 'DEBIT',
            'interac': 'DEBIT',
            'jcb': 'CREDIT',
            'discover': 'CREDIT',
            'unionpay': 'CREDIT',
        };

        function printReceiptV1 (
            response,
            receipt,
            cardReceiptArr,
            skipCashDrawer = false,
            printType = PrintType.MERCHANT,
            isDuplicate = false
        ) {

            const receiptFactory = new ReceiptFactoryWrapper();
            var printTypesArr;
            if (printType == PrintType.ALL) {
                printTypesArr = [PrintType.MERCHANT, PrintType.CUSTOMER];
            } else {
                printTypesArr = [printType];
            }

            var company = CurrentSession.getCompany();

            var localizations = {
                'en': {
                    transactionRecord: 'TRANSACTION RECORD',
                    type: 'TYPE',
                    acct: 'ACCT',
                    amount: 'AMOUNT',
                    partiallyApproved: 'PARTIALLY APPROVED',
                    amountDue: 'AMOUNT DUE',
                    tip: 'TIP',
                    surcharge: 'SURCHARGE',
                    total: 'TOTAL',
                    accountBalance: 'ACCOUNT BALANCE',
                    cardNumber: 'CARD #',
                    dateTime: 'DATE/TIME',
                    referenceNum: 'Reference #',
                    authNum: 'AUTH #',
                    verifiedByPin: 'VERIFIED BY PIN',
                    chipCardSwiped: 'CHIP CARD SWIPED',
                    chipCardKeyed: 'CHIP CARD KEYED',
                    chipCardMalfunction: 'CHIP CARD MALFUNCTION',
                    transactionPartiallyApproved: 'TRANSACTION PARTIALLY APPROVED',
                    invoiceNumber: 'INVOICE #',
                    transactionCancelled: 'Transaction Cancelled',
                    n99approved: '99 Approved - Thank You XXX',
                    partialAuthorizationCancelled: 'Partial Authorization Cancelled',
                    transactionNotCompleted: 'Transaction Not Completed',
                    n99transactionNotApproved: '99 Transaction Not Approved XXX',
                    n99transactionNotCompleted: '99 Transaction Not Completed XXX',
                    cardHolderSignature: 'Cardholder Signature',
                    buyerAgrees: 'CARDHOLDER WILL PAY CARD ISSUER ABOVE AMOUNT PURSUANT TO CARDHOLDER AGREEMENT',
                    retainCopy: '*Important - retain this copy for your records*',
                    merchantCopy: 'MERCHANT COPY',
                    customerCopy: 'CUSTOMER COPY',

                    default: 'FLASH DEFAULT',
                    savings: 'SAVINGS',
                    chequing: 'CHEQUING',

                    purchase: 'PURCHASE',
                    refund: 'REFUND',
                    purchaseCorrection: 'PURCHASE CORRECTION',
                    refundCorrection: 'REFUND CORRECTION',
                    batchClose: 'BATCH CLOSE',
                    balanceInquiry: 'BALANCE INQUIRY',
                    recallMessage: 'RECALL MESSAGE',

                    declinedByCard: 'DECLINED BY CARD - 990',
                    cardRemoved: 'CARD REMOVED - 991',
                    noSignatureRequired: 'NO SIGNATURE REQUIRED',

                    void: 'VOID',
                    cardBalanceInquiry: 'CARD BALANCE INQUIRY',
                    settlement: 'SETTLEMENT',
                    cardEntryMethod: 'ENTRY METHOD',
                },
                'es': {},
                'fr': {
                    transactionRecord: 'RELEVE DE TRANSACTION',
                    type: 'TYPE',
                    acct: 'COMPTE',
                    amount: 'MONTANT',
                    partiallyApproved: 'AUTORISATION PARTIEL',
                    amountDue: 'MONTANT DU',
                    tip: 'POURBOIRE',
                    surcharge: 'FRAIS',
                    total: 'TOTAL',
                    accountBalance: 'SOLDE DU COMPTE',
                    cardNumber: 'N. DE CARTE',
                    dateTime: 'DATE/HEURE',
                    referenceNum: '# Reference',
                    authNum: '# AUTH',
                    verifiedByPin: 'VERIFIEE PAR NIP',
                    chipCardSwiped: 'CARTE A PUCE GLISSEE',
                    chipCardKeyed: 'CARTE A PUCE TAPEE',
                    chipCardMalfunction: 'DEFAILLANCE CARTE A PUCE',
                    transactionPartiallyApproved: 'AUTORISATION PARTIEL DE LA TRANSACTION',
                    invoiceNumber: 'NO. DE FACTURE',
                    transactionCancelled: 'Autorisation Partiel Annule',
                    n99approved: '99 Approuvee - MERCI XXX',
                    partialAuthorizationCancelled: 'Autorisation Partiel Annule',
                    transactionNotCompleted: 'Operation non Completee',
                    n99transactionNotApproved: '99 Operation Refusee XXX',
                    n99transactionNotCompleted: '99 Operation non Completee XXX',
                    cardHolderSignature: 'SIGNATURE',
                    buyerAgrees: 'LE TITULAIRE VERSERA CE MONTANT A L\'EMETTEUR CONFORMEMENT AU CONTRAT ADHERENT',
                    retainCopy: '*Important - conserver cette copie pour vos dossiers*',
                    merchantCopy: 'COPIE DU DETAILLANT',
                    customerCopy: 'COPIE DU CLIENT',

                    default: 'DEFAUT FLASH',
                    savings: 'EPARGNE',
                    chequing: 'CHEQUE',

                    purchase: 'ACHAT',
                    refund: 'REMISE D\'ACHAT',
                    purchaseCorrection: 'CORRECTION D\'ACHAT',
                    refundCorrection: 'CORRECTION DE REMISE',
                    batchClose: 'BATCH CLOSE',
                    balanceInquiry: 'INTERROGATION DE SOLDE',
                    recallMessage: 'RECALL MESSAGE',

                    declinedByCard: 'REFUSEE PAR LA CARTE - 990',
                    cardRemoved: 'CARTE RETIREE - 991',
                    noSignatureRequired: 'SIGNATURE NON REQUISE',

                    void: 'VENTE',
                    cardBalanceInquiry: 'CARD BALANCE INQUIRY',
                    settlement: 'SETTLEMENT',
                    cardEntryMethod: 'MÉTHODE D\'ENTRÉE',
                },
            };

            var printUrlArrayOriginal = buildPrinterObjArray(response.posPrinters, false);
            var printUrlArrayGroupedByWidth = _.groupBy(printUrlArrayOriginal, 'receiptSize');

            var giftCardPurchaseMap = {};
            _.each(response.giftCardPurchases, function (giftCardPurchase) {
                giftCardPurchaseMap[giftCardPurchase.code] = giftCardPurchase;
            });

            var refundedTransactionItemMap = {};
            if (response.refundTransactions) {
                for (let refundTransaction of response.refundTransactions) {
                    for (var refundedTransactionItem of refundTransaction.items) {
                        var refundedQuantity = refundedTransactionItemMap[refundedTransactionItem.transactionItemId] || 0;
                        refundedQuantity += refundedTransactionItem.quantity;

                        refundedTransactionItemMap[refundedTransactionItem.transactionItemId] = refundedQuantity;
                    }
                }
            }

            var hasTransactionPercentDiscount = !!response.percentDiscount;

            Object.keys(printUrlArrayGroupedByWidth).forEach(async function (key) {
                var printUrlArray = printUrlArrayGroupedByWidth[key];
                var translation = localizations['en'];

                var receiptRequests = [];
                for (let i of Object.keys(printTypesArr)) {
                    var currentPrintType = printTypesArr[i];

                    var builder = new StarWebPrintBuilder();

                    receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.STAR_PRINTER, {
                        printerDetails: printUrlArray[0]
                    });

                    if (CompanyAttributesService.isScreenPrintEnabled()) {
                        receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.SCREEN_PRINTER, {
                            printerDetails: printUrlArray[0]
                        });
                    }

                    receiptFactory.initialize();

                    // Print held order qr code
                    if (response.heldOrder && response.heldOrder.suspendId) {
                        await receiptFactory
                            .align('center')
                            .createQrCodeElement(`ho:1:${response.heldOrder.suspendId}`);
                    }

                    receiptFactory
                        .align('center')
                        .text(response.locationName);

                    if (response.location) {
                        if (response.location.street) {
                            receiptFactory.text(response.location.street);
                        }

                        var city = response.location.city;
                        var region = response.location.region;

                        var cityRegionArray = [city, region].filter(function (e) {
                            return e != null;
                        });
                        var cityRegion = cityRegionArray.join(', ');

                        if (cityRegion) {
                            receiptFactory.text(cityRegion);
                        }
                    }

                    receiptFactory.newLine(1);

                    if (response.cashierName) {
                        receiptFactory
                            .text('Cashier Name: ' + response.cashierName);
                    }

                    if (response.stationName) {
                        receiptFactory
                            .text('POS Station: ' + response.stationName)
                            .newLine(2);
                    }

                    if (isDuplicate) {
                        receiptFactory
                            .align('center')
                            .title('DUPLICATE', true, {width: 1, height: 1})
                            .newLine(1);
                    }

                    // Move this code to the bottom starts here

                    var receiptCounterString = generateReceiptCounterString(response.receiptCounter, response.heldOrder);
                    if (receiptCounterString) {
                        receiptFactory
                            .align('center')
                            .title(receiptCounterString, true, {width: 4, height: 4, invert: true})
                            .newLine(1);
                    }

                    if (response.serviceMode) {
                        receiptFactory
                            .align('center')
                            .title(response.serviceMode)
                            .newLine(1);
                    }

                    if (response.patronName) {
                        if (response.heldOrder && response.heldOrder.isPayAtCounter) {
                            receiptFactory
                                .align('center')
                                .text(response.patronName, true, {width: 5, height: 5});
                        } else {
                            receiptFactory
                                .align('center')
                                .title(response.patronName)
                                .newLine(1);
                        }
                    }

                    var transactionDateTime = moment(response.transactionDateTime);
                    var transactionDateTimeString = transactionDateTime.format('YYYY-MM-DD hh:mm:ss A');

                    // DELIVERY
                    if (response.isDelivery) {
                        receiptFactory.title('Delivery: ');
                        receiptFactory.title(response.deliveryAddress)
                            .newLine(1);
                    }

                    // MOBILE PICKUP
                    if (response.pickupTime) {
                        receiptFactory.text('Mobile Order: ');
                        var pickupDate = moment(response.pickupTime);
                        var pickupDateString = pickupDate.format('MM/DD/YYYY');
                        var pickupTimeString = pickupDate.format('hh:mm:ss A');
                        receiptFactory.text('Estimated Pickup: ');
                        receiptFactory.text(pickupTimeString);
                        receiptFactory.text(pickupDateString);
                    }

                    receiptFactory.newLine(1);

                    var transactionType = response.transactionType || 'SALE';
                    var isVoidOrRefund = (transactionType === 'VOID' || transactionType === 'REFUND' || transactionType === 'REFUND_PARTIAL');
                    var transactionTypeString = transactionType;

                    if (response.receiptId) {
                        let receiptIdText = `TX Id: ${response.receiptId}`;

                        if (!isVoidOrRefund) {
                            receiptIdText = `${receiptIdText} (${response.originalTransactionId ? 'EXCHANGE' : transactionTypeString})`;
                        }

                        receiptFactory
                            .align('left')
                            .text(receiptIdText);
                    } else {
                        if (response.heldOrder) {
                            receiptFactory
                                .align('left')
                                .text('Bill');
                        }
                    }

                    if (isVoidOrRefund) {
                        switch (transactionType) {
                            case 'VOID':
                                transactionTypeString = 'VOID';
                                break;
                            case 'REFUND':
                                transactionTypeString = 'REFUND';
                                break;
                            case 'REFUND_PARTIAL':
                                transactionTypeString = 'PART. REFUND';
                                break;
                        }

                        receiptFactory
                            .align('center')
                            .title(transactionTypeString, true, {width: 3, height: 3})
                            .newLine(1);

                        receiptFactory
                            .align('left')
                            .text('Authorized By: ' + response.userFullName)
                            .newLine(1);
                    }

                    if (response.mealPlansRedeemed && response.mealEqAmount) {
                        receiptFactory
                            .align('left')
                            .text('Discount Type: REWARDS REDEMPTION')
                            .text('Discount Authorized By: Mobile App')
                            .newLine(1);
                    } else if (response.percentDiscount) {
                        if (response.labelledDiscounts && response.labelledDiscounts.length > 0) {
                            receiptFactory
                                .align('left')
                                .text('Discount Type: ' + response.labelledDiscounts[0].discountName);

                            if (response.labelledDiscounts[0].authorizedByUser) {
                                receiptFactory
                                    .align('left')
                                    .text('Discount Authorized By: ' + response.labelledDiscounts[0].authorizedByUser.firstname
                                        + ' ' + response.labelledDiscounts[0].authorizedByUser.lastname)
                                    .newLine(1);
                            } else {
                                receiptFactory
                                    .align('left')
                                    .text('Discount Authorized By: No PIN ')
                                    .newLine(1);
                            }

                        } else {
                            receiptFactory
                                .align('left')
                                .text('Discount Type: GENERAL')
                                .text('Discount Authorized By: GENERAL')
                                .newLine(1);
                        }
                    } else if (response.dollarDiscountAmount) {
                        receiptFactory
                            .align('left')
                            .text('Discount Type: GENERAL')
                            .text('Discount Authorized By: GENERAL')
                            .newLine(1);
                    }


                    // / Move code to the bottom ends here
                    receiptFactory
                        .align('left')
                        .text('Date: ' + transactionDateTimeString)
                        .newLine(2);

                    receiptFactory
                        .align('center')
                        .textJustified(['Item   ', 'Price']);

                    receiptFactory
                        .align()
                        .textJustified(['', ''], false, {}, {}, '-');

                    var decimalDisplayedPercentDiscountAmount = new Decimal(0);

                    var receiptHierarchy = generateReceiptHierarchy(receipt);

                    _.each(receiptHierarchy, function (item) {
                        if (item.locationServicePeriodMenuId === -99999) {
                            return; // buzzer
                        }

                        if (item.price < 0 && response.percentDiscount > 0) {
                            return; // skip trannsaction-wide discount
                        }

                        var leftString = item.quantity || 1;
                        leftString += '   ';
                        leftString += item.name;

                        var rightString = _currency($filter('itemDisplayPrice')(item));
                        var hasLoyaltyDiscount = _.some(item.children, {subtype: 'loyalty'});
                        if (hasLoyaltyDiscount) {
                            leftString = '*' + leftString;
                        }

                        receiptFactory
                            .align()
                            .textJustifiedAndWrapped([leftString, rightString]);

                        if (item.subtype === 'giftcard.create' || item.subtype === 'giftcard.reload') {
                            var giftCardPurchase = giftCardPurchaseMap[item.upc];

                            if (giftCardPurchase) {
                                var balanceDollar = giftCardPurchase.currentBalanceCents / 100.0;
                                receiptFactory
                                    .text('    - Remaining:' + _currency(balanceDollar));
                            }
                        }

                        var receiptModifierPrintOptions = {
                            ignoreDiscount: hasTransactionPercentDiscount,
                            styles: {
                                width: 1,
                                height: 1
                            },
                            useLoyalty: hasLoyaltyDiscount
                        };

                        var modifiers = _groupDiscountModifiers(item.children);

                        if (modifiers && modifiers.length > 0) {
                            _.each(modifiers, function (subItem) {
                                if (subItem.type === 'modifier_group') {
                                    _.each(subItem.children, function (subSubItem) {
                                        printReceiptModifier(
                                            receiptFactory,
                                            subSubItem,
                                            receiptModifierPrintOptions,
                                            item
                                        );
                                    });
                                    // Individual Item
                                } else {
                                    printReceiptModifier(
                                        receiptFactory,
                                        subItem,
                                        receiptModifierPrintOptions,
                                        item
                                    );
                                }

                                if (subItem.subtype === 'discount' || subItem.subtype === 'loyalty') {
                                    decimalDisplayedPercentDiscountAmount = decimalDisplayedPercentDiscountAmount
                                        .plus(new Decimal(subItem.price));
                                }
                            });
                        }

                        if (item.notes) {
                            var noteString = 'Note: ' + item.notes;
                            receiptFactory.text(noteString, false, {width: 1, height: 1});
                        }

                        var itemRefundQuantity = refundedTransactionItemMap[item.receiptItemId];
                        if (itemRefundQuantity > 0) {
                            var refundVerb = '';
                            switch (transactionType) {
                                case 'VOID':
                                    refundVerb = 'voided';
                                    break;
                                case 'REFUND':
                                case 'REFUND_PARTIAL':
                                    refundVerb = (response.exchanged) ? 'exchanged' : 'refunded';
                                    break;
                            }

                            if (!refundVerb) {
                                // shouldn't happen, but adding this to be safe
                                return;
                            }

                            var refundActionString = (itemRefundQuantity == 1) ? ' has been ' : ' have been ';
                            refundActionString += refundVerb;
                            var refundString = '    *' + itemRefundQuantity + refundActionString;
                            receiptFactory.text(refundString);
                        }
                    });

                    if (response.percentDiscount) {
                        if (response.mealPlansRedeemed && response.mealEqAmount) {
                            var loyaltyDiscountDescription = (response.percentDiscount * 100).toFixed(0) + '%';
                            receiptFactory
                                .newLine(1)
                                .align()
                                .titleJustified(['Rewards Applied: ', loyaltyDiscountDescription])
                                .text('*on eligible items');
                        } else {
                            var percentageString = response.percentDiscount * 100 + '%';
                            var discountString = '';
                            if (response.labelledDiscounts && response.labelledDiscounts.length > 0) {
                                discountString = response.labelledDiscounts[0].discountName + ' (' + percentageString + '): ';
                            } else {
                                discountString = 'Discount (' + percentageString + '): ';
                            }

                            // Basket-level discount is applied pre-tax, so the amount discounted (and recorded in the db) is also
                            // the pre-tax amount. In order to display amount discounted in VAT-included countries, we have to dig
                            // the tax information from the receipt list. This ensures correct tax amount even with items with
                            // mixed tax rates
                            var displayedPercentDiscountAmount = (SharedDataService.taxIncludedInPrice)
                                ? decimalDisplayedPercentDiscountAmount.toNumber() // this is already recorded as negative cash flow
                                : -response.percentDiscountAmount; // this needs to be negated because it is recorded as an absolute amount

                            receiptFactory
                                .align()
                                .textJustified([discountString, _currency(displayedPercentDiscountAmount)]);
                        }
                    }

                    receiptFactory
                        .align()
                        .textJustified(['', ''], false, {}, {}, '-');

                    if (response.dollarDiscountAmount) {
                        receiptFactory
                            .align()
                            .textJustified(['Discount: ', _currency(-response.dollarDiscountAmount)]);
                    }

                    receiptFactory
                        .align()
                        .textJustified(['Subtotal: ', _currency(response.transactionSubTotal)]);

                    // ALL DELIVERY RELATED ITEMS HERE NEEDS TO BE DOUBLE CHECKED
                    if (response.isDelivery) {
                        receiptFactory
                            .align()
                            .textJustified(['Delivery Fee: ', _currency(response.deliveryFee)]);
                    }

                    // Temperary - add gst and qst tax amount when quebec
                    if (CurrentSession.getCompany() && CurrentSession.getCompany().operatingAddress.region == 'QC') {
                        let gstAmount = 0;
                        let qstAmount = 0;
                        let tpsNum = 'NA';
                        let tvqNum = 'NA';

                        const taxRegisDetails = CompanyAttributesService.getReceiptTaxRegistrationDetails();

                        if (taxRegisDetails) {
                            taxRegisDetails.split(/\s/).forEach(function (taxNum) {
                                let details = taxNum.split(/#/);

                                switch (details[0]) {
                                    case 'TPS':
                                        tpsNum = details[1];
                                        break;
                                    case 'TVQ':
                                        tvqNum = details[1];
                                        break;
                                    default:
                                }
                            });
                        }

                        if (response.transactionTax > 0) {
                            let totalTax = new Decimal(response.transactionTax);
                            let gstPercent = new Decimal(5);
                            let quebecTax = new Decimal(14.975);

                            gstAmount = totalTax.times(gstPercent.dividedBy(quebecTax)).toDecimalPlaces(2).toNumber();
                            qstAmount = totalTax.minus(gstAmount).toDecimalPlaces(2).toNumber();
                        }

                        receiptFactory
                            .align()
                            .textJustified([translate('receipt.gst') + ` (${tpsNum}): `, _currency(gstAmount)])
                            .textJustified([translate('receipt.pst') + ` (${tvqNum}): `, _currency(qstAmount)]);
                    }

                    receiptFactory
                        .align()
                        .textJustified(['Tax: ', _currency(response.transactionTax)]);

                    if (response.tipAmount > 0) {
                        receiptFactory
                            .textJustified(['Tip: ', _currency(response.tipAmount)]);
                    }

                    const numOfItems = _.filter(receipt, {level: 0}).length;
                    if (numOfItems <= 1) {
                        receiptFactory
                        .newLine(1)
                        .titleJustified(['Total: (' + numOfItems + ' ' + translate('receipt.item') + ')', _currency(response.transactionTotal + response.tipAmount)], true);
                    } else {
                        receiptFactory
                        .textJustified(['Total: (' + numOfItems + ' ' + translate('receipt.items') + ')', _currency(response.transactionTotal + response.tipAmount)], true);
                    }

                    receiptFactory
                        .align()
                        .textJustified(['', ''], false, {}, {}, '-');

                    if (!isDuplicate && response.remainingBalance < 0) {
                        receiptFactory
                            .textJustified(['Cash Received: ', _currency(response.cashAmount + response.remainingBalance)])
                            .textJustified(['Card Amount Received: ', _currency(
                                (response.creditCardAmount > 0) ? response.creditCardAmount + response.tipAmount : 0
                            )]);
                    } else {
                        receiptFactory
                            .textJustified(['Cash Received: ', _currency(response.cashAmount)])
                            .textJustified(['Card Amount Received: ', _currency(
                                (response.creditCardAmount > 0) ? response.creditCardAmount + response.tipAmount : 0
                            )]);
                    }

                    if (response.otherAmount || response.fiitMealPlanCount || response.fiitDcbAmount) {
                        for (let tender of response.tenders) {
                            if (tender.transactionType != 'OTHER') {
                                continue;
                            }

                            if (tender.fiitTenders && tender.fiitTenders.length) {
                                for (var fiitTender of tender.fiitTenders) {
                                    var fiitLeftString = fiitTender.fiitMealPlanName || '';

                                    fiitLeftString = (fiitLeftString.length > 15) ? fiitLeftString.substr(0, 15).concat('...') : fiitLeftString;

                                    if (fiitTender.fiitMealPlanType === 'DCB') {
                                        fiitLeftString += ' (DCB) ';
                                    }

                                    var fiitRightString = fiitTender.unitsUsed;
                                    if (fiitTender.fiitMealPlanType === 'MEAL') {
                                        fiitRightString += (fiitTender.unitsUsed > 1) ? ' Meal Units' : ' Meal Unit';
                                        fiitRightString = '(' + fiitRightString + ') ';
                                        fiitRightString += _currency(fiitTender.equivalentDollarValue);
                                    } else {
                                        fiitRightString = _currency(fiitRightString);
                                    }

                                    receiptFactory
                                        .align()
                                        .textJustifiedAndWrapped([fiitLeftString, fiitRightString]);
                                }
                            } else {
                                let tenderDetail = tender.transactionTenderDetail || {};
                                let otherTenderType = tenderDetail.otherType;
                                let otherTenderTypeDisplayName = otherTenderType || 'Other Amount Received';
                                let mealUnitsDisplayName = 'Meal Units: ';

                                switch (otherTenderType) {
                                    case 'alphapay':
                                        otherTenderTypeDisplayName = 'WeChat Pay/Alipay';
                                        break;
                                    case 'fiitmps':
                                        otherTenderTypeDisplayName = 'DCB';
                                        break;
                                }

                                otherTenderTypeDisplayName = otherTenderTypeDisplayName + ': ';


                                if (tender.amountCents > 0) {
                                    receiptFactory
                                        .textJustified([otherTenderTypeDisplayName, _currency(tender.amountCents / 100)]);
                                }


                                if (tender.amountMeals > 0) {
                                    receiptFactory
                                        .textJustified([mealUnitsDisplayName, tender.amountMeals]);
                                }
                            }
                        }
                    }

                    _.each(response.tenders, function (tender) {
                        if (tender.transactionType === 'GIFTCARD') {
                            var giftCardId = (tender.giftCard) ? tender.giftCard.id : undefined;
                            var giftCard = _.findWhere(response.giftCardUsage, {id: giftCardId});
                            if (giftCard) {
                                if (giftCard.source === GIFTCARD_SOURCE.SOURCE_TRANSACTION || giftCard.source === GIFTCARD_SOURCE.SOURCE_API) {
                                    let giftCardDescription = 'Gift Card (' + $filter('giftCardMask')(giftCard.code) + '): ';
                                    let tenderAmount = (tender.amountCents + tender.tipAmountCents) / 100.0;
                                    let giftCardBalance = giftCard.currentBalanceCents / 100.0;

                                    receiptFactory
                                        .textJustified([giftCardDescription, _currency(tenderAmount)]);

                                    if (!isVoidOrRefund) {
                                        // for void and refund, the remaining balance is displayed in the
                                        // VOID/REFUND history below
                                        receiptFactory
                                            .text('   - Remaining: ' + _currency(giftCardBalance));
                                    }
                                } else {
                                    let storeCreditDescription = 'Store Credit Used: ';
                                    let tenderAmount = tender.amountCents / 100.0;

                                    receiptFactory
                                        .textJustified([storeCreditDescription, _currency(tenderAmount)]);
                                }
                            }
                        }
                    });

                    if (response.cashRounding) {
                        receiptFactory
                            .textJustified(['Cash Rounding: ', _currency(response.cashRounding)]);
                    }

                    if (transactionType === 'SALE') {
                        if (response.remainingBalance > 0) {
                            receiptFactory
                                .titleJustified(['Remaining Balance: ', _currency(response.remainingBalance)], true);
                        } else {
                            if (PosStatusService.isOffline()) {
                                receiptFactory
                                    .textJustified(['Change: ', _currency(response.change)]);
                            } else {
                                receiptFactory
                                    .textJustified(['Change: ', _currency(-response.change)]);
                            }
                        }
                    }

                    if (response.refundTransactions && response.refundTransactions.length > 0) {
                        receiptFactory
                            .align('center')
                            .newLine(1)
                            .title('--- ' + transactionTypeString + ' History ---')
                            .newLine(1);

                        for (let refundTransaction of response.refundTransactions) {
                            receiptFactory
                                .align('left')
                                .text(moment(refundTransaction.eventTime).format('YYYY-MM-DD HH:mm:ss'));

                            for (var refundTender of refundTransaction.tenders) {
                                var refundTenderType = refundTender.transactionType;
                                if (refundTenderType === 'MEAL') {
                                    receiptFactory
                                        .textJustified(['LOYALTY', refundTender.amountMeals + ' PTS']);
                                } else {
                                    var isGiftCardTender = refundTender.transactionType === 'GIFTCARD' && refundTender.giftCard;
                                    if (isGiftCardTender) {
                                        var refundTenderDescription;
                                        if (refundTender.giftCard.source == 0) {
                                            refundTenderDescription = 'Gift Card (' + $filter('giftCardMask')(refundTender.giftCard.code) + '): ';
                                            receiptFactory
                                                .textJustified([refundTenderDescription, _currency(refundTender.amountCents / 100.0)]);
                                            receiptFactory
                                                .text('   - Remaining: ' + _currency(refundTender.giftCard.currentBalanceCents / 100.0));
                                        } else {
                                            refundTenderDescription = 'Store Credit';
                                            receiptFactory
                                                .textJustified([refundTenderDescription, _currency(refundTender.amountCents / 100.0)]);
                                        }
                                    } else {
                                        receiptFactory
                                            .textJustified([refundTender.transactionType, _currency(refundTender.amountCents / 100.0)]);
                                    }
                                }
                            }

                            receiptFactory.newLine(1);
                        }
                    }

                    receiptFactory
                        .newLine(1);

                    receiptFactory
                        .align()
                        .textJustified(['', ''], false, {}, {}, '-'); // a line of asterisks

                    if (CurrentSession.getCompany().hasLoyalty && response.hasLoyalty) {
                        var rewardSummaryTitle = 'Rewards Summary';
                        if (response.patronName) {
                            rewardSummaryTitle = response.patronName + ' ' + rewardSummaryTitle;
                        }

                        var loyaltyEarned = (response.loyaltyAmount || 0);
                        var rewardCollectedDescription = loyaltyEarned + ' PTS';
                        var rewardRedemptionDescription = response.mealPlansRedeemed + ' PTS';
                        var rewardDiscountDescription = ((response.mealPlansRedeemed && response.percentDiscount) ?
                            '(' + (response.percentDiscount * 100).toFixed(0) + '%)' : '');
                        var rewardBalanceDescription = (response.currentLoyaltyPoints || 0).toFixed(0) + ' PTS';

                        receiptFactory
                            .align('center')
                            .text(rewardSummaryTitle)
                            .align('left')
                            .textJustified(['', ''], false, {}, {}, '-') // a line of asterisks
                            .textJustified(['Collected: ', rewardCollectedDescription])
                            .textJustified(['Redeemed : ', rewardRedemptionDescription + rewardDiscountDescription])
                            .textJustified(['Balance : ', rewardBalanceDescription]);
                    } else if (CurrentSession.getCompany().hasLoyalty) {
                        if (company && CompanyAttributesService.getCustomReceiptText()) {
                            // inserting new lines dynamically based on \n
                            var textSplit = CompanyAttributesService.getCustomReceiptText().split('\n');
                            _.each(textSplit, (line) => {
                                receiptFactory
                                    .align('left')
                                    .text(line)
                                    .newLine(1);
                            });
                        }

                        var appName = CurrentSession.getOrganizationAppName();
                        var guestLoyaltyReminder = translate('receipt.download.app', {appName: appName});
                        if (CompanyAttributesService.hasQRCodePrint() && response.transactionUuid) {
                            receiptFactory
                                .align('left')
                                .text(guestLoyaltyReminder)
                                .newLine(1);
                        }
                    }

                    // Offer summary
                    let offers = response.offersUsed || [];
                    for (let offer of offers) {
                        let offerSummaryTitle = translate('receipt.offers.summary');
                        let offerReward;

                        if (offer.offerType === 'EXTRA_POINTS' || offer.offerType === 'POINTS_MULTIPLIER') {
                            offerReward = offer.pointsIssued + ' PTS';
                        } else {
                            offerReward = _currency(offer.discountAmountCents / 100.0);
                        }
                        receiptFactory
                            .align('center')
                            .text(offerSummaryTitle)
                            .align('left')
                            .textJustified(['', ''], false, {}, {}, '-') // a line of dash
                            .textJustified([translate('receipt.offers.name') + ': ', offer.name])
                            .textJustified([translate('receipt.offers.reward') + ': ', offerReward])
                            .textJustified(['', ''], false, {}, {}, '-') // a line of dash
                            .newLine(1);
                    }

                    if (CompanyAttributesService.hasQRCodePrint() && response.transactionUuid) {
                        const appUrl = CurrentSession.getOrganization().mobileAppUrl;
                        const qrCode = appUrl + '#' + response.transactionUuid;
                        receiptFactory.align('center');
                        await receiptFactory.createQrCodeElement(qrCode);

                        receiptFactory
                            .align('left')
                            .textJustified(['', ''], false, {}, {}, '-'); // a line of asterisks*/

                        receiptFactory
                            .newLine(1);

                        if (response.heldOrder && response.heldOrder.isPayAtCounter) {
                            receiptFactory
                                .align('center')
                                .text(translate('kiosk.payAtCounter.label'), true, {width: 2, height: 2});
                        }
                    }

                    receiptFactory
                        .align('center')
                        .text(translate('receipt.thanksForVisit'))
                        .text(response.companyName)
                        .newLine(1);

                    if (company && CompanyAttributesService.getReceiptFooter()) {
                        receiptFactory
                            .align('center')
                            .text(CompanyAttributesService.getReceiptFooter())
                            .newLine(1);
                    }

                    if (company && CompanyAttributesService.getReceiptTaxRegistrationDetails()) {
                        receiptFactory
                            .align('center')
                            .text(CompanyAttributesService.getReceiptTaxRegistrationDetails())
                            .newLine(1);
                    }

                    for (let i of Object.keys(cardReceiptArr)) {
                        var cardReceipt = cardReceiptArr[i];

                        // some terminals like the Moneris Core family of terminals
                        // return a full string that we need to print. In this case
                        // we call "continue" and avoid formatting the receipt ourselves.
                        if (currentPrintType == PrintType.CUSTOMER && cardReceipt.rawStringCustomer) {
                            addLine2(receiptFactory, cardReceipt.rawStringCustomer);
                            receiptFactory.newLine(1);
                            continue;
                        } else if (currentPrintType == PrintType.MERCHANT && cardReceipt.rawStringMerchant) {
                            addLine2(receiptFactory, cardReceipt.rawStringMerchant);
                            receiptFactory.newLine(1);
                            continue;
                        }

                        if (!CompanyAttributesService.hasPrintCardTerminalResponse()) {

                            if (currentPrintType == PrintType.CUSTOMER) {
                                translation = localizations[cardReceipt.customerLanguage || 'en'];
                            } else {
                                translation = localizations['en'];
                            }

                            receiptFactory.newLine(1);
                            // request += builder.createTextElement({characterspace: 0});
                            // request += builder.createAlignmentElement({position: 'right'});
                            // request += builder.createLogoElement({number: 1});
                            // request += builder.createAlignmentElement({position: 'center'});
                            addLine2(receiptFactory, translation.transactionRecord);
                            receiptFactory.newLine(1);

                            var isCreditCard = cardReceipt.isCredit;
                            // var isTerminalDemoMode = cardReceipt.demoMode; // TODO

                            var transDate = new Date(response.transactionDateTime);
                            transactionDateTime = transDate.toLocaleString() + '\n';

                            if (cardReceipt.paymentProcessor === 'moneris') {
                                // currently only Moneris returns this information from
                                // the terminal.  For globalpayments, we need an
                                // alternate source to populate merchantname + address
                                addLine2(receiptFactory, cardReceipt.merchantName);
                                addLine2(receiptFactory, cardReceipt.addressLine1);
                                addLine2(receiptFactory, cardReceipt.addressLine2);
                                receiptFactory.newLine(1);
                            }

                            if (cardReceipt.paymentProcessor === 'globalpayments' && cardReceipt.demoMode) {
                                addLine2(receiptFactory, 'DEMO MODE');
                                receiptFactory.newLine(1);
                            }

                            addLine2(receiptFactory, 'TYPE:', translation[cardReceipt.transactionType] || cardReceipt.transactionType);
                            receiptFactory.newLine(1);

                            // VISA, MASTERCARD, AMERICAN EXPRESS, INTERAC

                            // if (cardReceipt.paymentProcessor === 'globalpayments' && cardReceipt.terminalId) {
                            // request += addLine(builder, request, "TID:", cardReceipt.terminalId);
                            // }
                            if (cardReceipt.paymentProcessor === 'globalpayments') {
                                if (cardReceipt.transactionSequenceNum) {
                                    addLine2(receiptFactory, 'SEQ: ', cardReceipt.transactionSequenceNum);
                                }

                                if (currentPrintType == PrintType.MERCHANT && cardReceipt.merchantId) {
                                    addLine2(receiptFactory, 'MID: ', cardReceipt.merchantId);
                                }
                            }

                            if (cardReceipt.isDebit) {
                                if (cardReceipt.debitAccountType === 'default') {
                                    addLine2(receiptFactory, translation.acct + ': ', translation[cardReceipt.debitAccountType]);
                                } else {
                                    addLine2(receiptFactory, translation.acct + ': ', cardReceipt.cardName + ' ' + (translation[cardReceipt.debitAccountType] || ''));
                                }
                            } else {
                                addLine2(receiptFactory, translation.acct + ': ', cardReceipt.cardName);
                            }
                            addLine2(receiptFactory, translation.amount + ': ', cardReceipt.transactionAmount);

                            // only need to show these is a partial transaction occurred.
                            if (cardReceipt.partiallyApproved) {
                                addLine2(receiptFactory, translation.partiallyApproved + ': ', cardReceipt.partiallyApproved);
                                addLine2(receiptFactory, translation.amountDue + ': ', cardReceipt.balanceDue);
                            }

                            if (cardReceipt.tipAmount && cardReceipt.tipAmount > 0) {
                                addLine2(receiptFactory, translation.tip + ': ', cardReceipt.tipAmount);
                            }

                            if (cardReceipt.isDebit && cardReceipt.transactionTypeCode === '00' && cardReceipt.surchargeAmount) {
                                addLine2(receiptFactory, translation.surcharge + ': ', cardReceipt.surchargeAmount);
                            }
                            addLine2(receiptFactory, translation.total + ': ', cardReceipt.totalAmount);

                            if (currentPrintType == PrintType.CUSTOMER &&
                                !cardReceipt.isDebit &&
                                ['00', '01', '60'].includes(cardReceipt.transactionTypeCode) &&
                                cardReceipt.cardBalance) {
                                addLine2(receiptFactory, translation.accountBalance + ': ', cardReceipt.cardBalance);
                            }


                            // TODO
                            // request += builder.createTextElement({ data: "DCC: " + dcc});

                            if (cardReceipt.cardholderName) {
                                addLine2(receiptFactory, 'Cardholder Name' + ': ', cardReceipt.cardholderName);
                            }

                            addLine2(receiptFactory, translation.cardNumber + ': ', cardReceipt.cardNumber);
                            addLine2(receiptFactory, translation.dateTime + ': ', cardReceipt.transactionDateTime);

                            if (cardReceipt.paymentProcessor === 'globalpayments' || cardReceipt.paymentProcessor === 'heartland') {
                                addLine2(receiptFactory, translation.cardEntryMethod + ': ', cardReceipt.cardEntryMethod);
                            } else if (cardReceipt.referenceNumber) {
                                var referenceNum = '';
                                referenceNum += cardReceipt.referenceNumber + ' ';

                                if (cardReceipt.cardEntryMethod) {
                                    referenceNum += cardReceipt.cardEntryMethod;
                                }
                                addLine2(receiptFactory, translation.referenceNum + ': ', referenceNum);
                            }

                            if (cardReceipt.success && cardReceipt.authorizationNumber) {
                                addLine2(receiptFactory, translation.authNum + ': ', cardReceipt.authorizationNumber);
                            }

                            if (cardReceipt.emvAppPreferredName) {
                                addLine2(receiptFactory, cardReceipt.emvAppPreferredName, '');
                            } else if (cardReceipt.emvAppLabel) {
                                addLine2(receiptFactory, cardReceipt.emvAppLabel, '');
                            } else if (cardReceipt.emvAid) {
                                // need to show something if EMV transaction
                                addLine2(receiptFactory, cardReceipt.cardName, '');
                            }

                            if (cardReceipt.emvAid) {
                                addLine2(receiptFactory, cardReceipt.emvAid, '');
                            }

                            var emvTvr = '';
                            if (cardReceipt.emvTvr) {
                                emvTvr = cardReceipt.emvTvr;
                            }
                            var emvTsi = '';
                            if (cardReceipt.emvTsi) {
                                emvTsi = cardReceipt.emvTsi;
                            }
                            addLine2(receiptFactory, emvTvr + ' ' + emvTsi, '');

                            if (cardReceipt.emvTc) {
                                addLine2(receiptFactory, cardReceipt.emvTc, '');
                            }

                            if (cardReceipt.ttq) {
                                addLine2(receiptFactory, cardReceipt.ttq, '');
                            }

                            if (cardReceipt.aac) {
                                addLine2(receiptFactory, cardReceipt.aac, '');
                            }

                            if (cardReceipt.emvCryptogramType && cardReceipt.emvCryptogram) {
                                addLine2(receiptFactory, cardReceipt.emvCryptogramType + ' ' + cardReceipt.emvCryptogram, '');
                            }

                            // TODO transactionStatus 11 and 19 are applicable only to Moneris, NOT GP
                            if (cardReceipt.emvAid && cardReceipt.transactionStatus === '11') {
                                addLine2(receiptFactory, translation.declinedByCard, '');
                            }
                            if (cardReceipt.emvAid && cardReceipt.transactionStatus === '19') {
                                addLine2(receiptFactory, translation.cardRemoved, '');
                            }

                            if (cardReceipt.verifiedByPin) {
                                addLine2(receiptFactory, translation.verifiedByPin, '');
                            }

                            if (cardReceipt.cardEntryMethod === 'F') {
                                // F means EMV with fallback swipe
                                addLine2(receiptFactory, translation.chipCardSwiped, '');
                            } else if (cardReceipt.emvAid && cardReceipt.cardEntryMethod === 'G') {
                                // G means swipe, so have have to verify it is EMV as well
                                addLine2(receiptFactory, translation.chipCardKeyed, '');
                            }

                            if (cardReceipt.approvedWithMalfunc
                                && cardReceipt.cardName == 'MASTERCARD'
                                && currentPrintType == PrintType.MERCHANT) {
                                addLine2(receiptFactory, translation.chipCardMalfunction, '');
                            }

                            if (cardReceipt.partiallyApproved) {
                                addLine2(receiptFactory, translation.transactionPartiallyApproved, '');
                            }

                            if (cardReceipt.invoiceNumber) {
                                addLine2(receiptFactory, translation.invoiceNumber + ': ', cardReceipt.invoiceNumber);
                            }

                            var isoCode = '';
                            if (cardReceipt.hostResponseIsoCode && !cardReceipt.isReversal) {
                                isoCode = cardReceipt.hostResponseIsoCode;
                            }

                            var hostResponseCode = '';
                            if (cardReceipt.hostResponseCode && !cardReceipt.isReversal) {
                                hostResponseCode = cardReceipt.hostResponseCode;
                            }

                            var statusLine = '';
                            receiptFactory.newLine(1);


                            /* 12 - Communication Error
                               13 - Cancelled by User
                               14 - Timed out on User Input
                               15 - Transaction Not Completed
                               16 - Transaction Not Completed, Card Removed
                               17 - Chip failure occurs during communication with the card
                               23 - Partial approval is cancelled by the merchant or the customer
                               30 - Transaction not Supported
                               31 - Transaction not Allowed
                               32 - Invalid ECR/PC Parameter
                               95 - Terminal is not in the Idle state or it is in Stand-Alone mode. Terminal Can't accept a new request. */

                            if (cardReceipt.success) {
                                statusLine = translation.n99approved;
                            } else {
                                if (cardReceipt.cancelledByUser) {
                                    statusLine = translation.transactionCancelled;
                                } else if (cardReceipt.partialApprovalCancelled) {
                                    statusLine = translation.partialAuthorizationCancelled;
                                } else if (cardReceipt.declinedByCardOnline) {
                                    statusLine = translation.transactionNotCompleted;
                                } else if (cardReceipt.cardRemoved) {
                                    statusLine = translation.transactionNotCompleted;
                                } else if (cardReceipt.terminalTimeout) {
                                    statusLine = translation.transactionNotCompleted;
                                } else if (cardReceipt.reversal) {
                                    statusLine = translation.transactionNotCompleted;
                                } else if (cardReceipt.isDebit) {
                                    if (['05', '51', '54', '55', '57', '58', '61', '62', '65', '75', '85', '92'].includes(cardReceipt.hostResponseIsoCode)) {
                                        statusLine = translation.n99transactionNotApproved;
                                    } else {
                                        statusLine = translation.n99transactionNotCompleted;
                                    }
                                } else if (
                                    ['060', '068', '069', '074', '075', '078', '087', '088', '097', '100',
                                        '101', '102', '104', '106', '108', '113', '115', '206', '212', '800',
                                        '801', '802', '810', '811', '821', '898'].includes(cardReceipt.hostResponseCode)
                                ) {
                                    statusLine = translation.n99transactionNotCompleted;
                                } else {
                                    statusLine = translation.n99transactionNotApproved;
                                }
                            }
                            statusLine = statusLine.replace(/XXX/, hostResponseCode);
                            statusLine = statusLine.replace(/99/, isoCode);
                            addLine2(receiptFactory, statusLine);

                            receiptFactory.newLine(1);

                            if (cardReceipt.formFactor) {
                                addLine2(receiptFactory, cardReceipt.formFactor, '');
                            }

                            if (
                                cardReceipt.success
                                && isCreditCard
                                && (cardReceipt.showCustomerSignatureLine || cardReceipt.showMerchantSignatureLine)
                                && currentPrintType == PrintType.MERCHANT
                                // && (cardReceipt.transactionTypeCode == '00' ||
                                // cardReceipt.transactionTypeCode == '03')
                            ) {
                                if (printUrlArray[0].receiptSize === 3) {
                                    addLine2(receiptFactory, 'X____________________________________', '');
                                } else {
                                    addLine2(receiptFactory, 'X_______________________________', '');
                                }

                                addLine2(receiptFactory, translation.cardHolderSignature, '');
                                receiptFactory.newLine(1);

                                if (cardReceipt.transactionTypeCode == '00') {
                                    addLine2(receiptFactory, translation.buyerAgrees, '');
                                    receiptFactory.newLine(1);
                                }
                            }

                            if (isCreditCard) {
                                addLine2(receiptFactory, translation.retainCopy);
                                receiptFactory.newLine(1);
                            }

                            if (isCreditCard && ['H', 'T'].includes(cardReceipt.cardEntryMethod) && !cardReceipt.showCustomerSignatureLine) {
                                addLine2(receiptFactory, translation.noSignatureRequired);
                                receiptFactory.newLine(1);
                            }
                        } else {
                            receiptFactory.newLine(1);
                            // print custom card terminal response - CUSTOMER COPY
                            if (currentPrintType == PrintType.CUSTOMER && cardReceipt.customerCopy.length) {
                                for (const el of cardReceipt.customerCopy) {
                                    let align = el.format[0];

                                    if (align === 'center') {
                                        receiptFactory.align('center').text(el.data[0] + el.data[1]);
                                        continue;
                                    }

                                    receiptFactory.textJustified(el.data);
                                }

                                receiptFactory.newLine(2);
                            }

                            // print custom card terminal response - MERCHANT COPY
                            if (currentPrintType == PrintType.MERCHANT && cardReceipt.merchantCopy.length) {
                                    for (const el of cardReceipt.merchantCopy) {
                                        let align = el.format[0];

                                        if (align === 'center') {
                                            receiptFactory.align('center').text(el.data[0] + el.data[1]);
                                            continue;
                                        }

                                        receiptFactory.textJustified(el.data);
                                    }

                                receiptFactory.newLine(2);
                            }

                        }

                    }

                    // ask for signature for non credit card refunds and voids
                    if (cardReceiptArr &&
                        cardReceiptArr.length == 0 &&
                        currentPrintType == PrintType.MERCHANT &&
                        (transactionType == 'VOID' || transactionType == 'REFUND' || transactionType == 'REFUND_PARTIAL')) {

                        if (printUrlArray[0].receiptSize === 3) {
                            addLine2(receiptFactory, 'X____________________________________');
                        } else {
                            addLine2(receiptFactory, 'X_______________________________');
                        }

                        addLine2(receiptFactory, 'Customer Signature');
                        receiptFactory.newLine(1);
                    }

                    if (currentPrintType == PrintType.MERCHANT) {
                        addLine2(receiptFactory, translation.merchantCopy);
                        receiptFactory.newLine(1);
                    } else if (currentPrintType == PrintType.CUSTOMER) {
                        if (!(response.heldOrder && response.heldOrder.isPayAtCounter)) {
                            addLine2(receiptFactory, translate('receipt.customerCopy'));
                            receiptFactory.newLine(1);
                        }
                    }

                    await receiptFactory.output();

                    for (const factory of receiptFactory.getFactoryList()) {
                        let request = factory.receiptData;

                        if (currentPrintType == PrintType.CUSTOMER) {
                            request += builder.createAlignmentElement({position: 'left'});
                            _.each(giftCardPurchaseMap, function (item) {
                                request += getCutElement(builder);
                                request = buildGiftCardRequest(request, builder, item);
                            });
                        }

                        receiptRequests.push(request);
                    }

                }

                var isCashTransaction = response.cashAmount && response.cashAmount > 0;
                var isCreditCardTransaction = response.creditCardAmount && response.creditCardAmount > 0;
                var restrictOpenDrawerToCashTransaction = company.attributes.receipt__restrict_open_cash_drawer === 'true';

                var openDrawer = (!skipCashDrawer);

                var openDrawerForTransaction = (restrictOpenDrawerToCashTransaction)
                    ? isCashTransaction
                    : isCashTransaction || isCreditCardTransaction;

                openDrawer = openDrawer && openDrawerForTransaction;

                var successCallback = function (data, attempt) { };
                var errorCallback = function (error, attempt) {
                    console.error(error);
                };

                if (openDrawer) {
                    openCashDrawer(printUrlArray, false, false, successCallback, errorCallback);
                }

                _.each(receiptRequests, function (receiptRequest, index) {
                    receiptRequest += builder.createTextElement({characterspace: 0});
                    receiptRequest += getCutElement(builder);

                    var config = {
                        urlArray: printUrlArray,
                        request: receiptRequest,
                        openDrawer: false,
                        successCallback: successCallback,
                        errorCallback: errorCallback
                    };

                    if (company && company.companyLogoUrl) {
                        config.iconUrl = company.companyLogoUrl;
                        config.useIconCache = true;
                    }

                    sendMessageArray(config);
                });
            });
        }

        function printReceiptV1Image (
            response,
            receipt,
            cardReceiptArr,
            skipCashDrawer = false,
            printType = PrintType.MERCHANT,
            isDuplicate = false
        ) {
            let receiptFactory = new ReceiptFactoryWrapper();
            var printTypesArr;
            if (printType == PrintType.ALL) {
                printTypesArr = [PrintType.MERCHANT, PrintType.CUSTOMER];
            } else {
                printTypesArr = [printType];
            }

            var company = CurrentSession.getCompany();
            let screenPrint = CompanyAttributesService.isScreenPrintEnabled();
            var localizations = {
                'en': {
                    transactionRecord: 'TRANSACTION RECORD',
                    type: 'TYPE',
                    acct: 'ACCT',
                    amount: 'AMOUNT',
                    partiallyApproved: 'PARTIALLY APPROVED',
                    amountDue: 'AMOUNT DUE',
                    tip: 'TIP',
                    surcharge: 'SURCHARGE',
                    total: 'TOTAL',
                    accountBalance: 'ACCOUNT BALANCE',
                    cardNumber: 'CARD #',
                    dateTime: 'DATE/TIME',
                    referenceNum: 'Reference #',
                    authNum: 'AUTH #',
                    verifiedByPin: 'VERIFIED BY PIN',
                    chipCardSwiped: 'CHIP CARD SWIPED',
                    chipCardKeyed: 'CHIP CARD KEYED',
                    chipCardMalfunction: 'CHIP CARD MALFUNCTION',
                    transactionPartiallyApproved: 'TRANSACTION PARTIALLY APPROVED',
                    invoiceNumber: 'INVOICE #',
                    transactionCancelled: 'Transaction Cancelled',
                    n99approved: '99 Approved - Thank You XXX',
                    partialAuthorizationCancelled: 'Partial Authorization Cancelled',
                    transactionNotCompleted: 'Transaction Not Completed',
                    n99transactionNotApproved: '99 Transaction Not Approved XXX',
                    n99transactionNotCompleted: '99 Transaction Not Completed XXX',
                    cardHolderSignature: 'Cardholder Signature',
                    buyerAgrees: 'CARDHOLDER WILL PAY CARD ISSUER ABOVE AMOUNT PURSUANT TO CARDHOLDER AGREEMENT',
                    retainCopy: '*Important - retain this copy for your records*',
                    merchantCopy: 'MERCHANT COPY',
                    customerCopy: 'CUSTOMER COPY',

                    default: 'FLASH DEFAULT',
                    savings: 'SAVINGS',
                    chequing: 'CHEQUING',

                    purchase: 'PURCHASE',
                    refund: 'REFUND',
                    purchaseCorrection: 'PURCHASE CORRECTION',
                    refundCorrection: 'REFUND CORRECTION',
                    batchClose: 'BATCH CLOSE',
                    balanceInquiry: 'BALANCE INQUIRY',
                    recallMessage: 'RECALL MESSAGE',

                    declinedByCard: 'DECLINED BY CARD - 990',
                    cardRemoved: 'CARD REMOVED - 991',
                    noSignatureRequired: 'NO SIGNATURE REQUIRED',

                    void: 'VOID',
                    cardBalanceInquiry: 'CARD BALANCE INQUIRY',
                    settlement: 'SETTLEMENT',
                    cardEntryMethod: 'ENTRY METHOD',
                },
                'es': {},
                'fr': {
                    transactionRecord: 'RELEVE DE TRANSACTION',
                    type: 'TYPE',
                    acct: 'COMPTE',
                    amount: 'MONTANT',
                    partiallyApproved: 'AUTORISATION PARTIEL',
                    amountDue: 'MONTANT DU',
                    tip: 'POURBOIRE',
                    surcharge: 'FRAIS',
                    total: 'TOTAL',
                    accountBalance: 'SOLDE DU COMPTE',
                    cardNumber: 'N. DE CARTE',
                    dateTime: 'DATE/HEURE',
                    referenceNum: '# Reference',
                    authNum: '# AUTH',
                    verifiedByPin: 'VERIFIEE PAR NIP',
                    chipCardSwiped: 'CARTE A PUCE GLISSEE',
                    chipCardKeyed: 'CARTE A PUCE TAPEE',
                    chipCardMalfunction: 'DEFAILLANCE CARTE A PUCE',
                    transactionPartiallyApproved: 'AUTORISATION PARTIEL DE LA TRANSACTION',
                    invoiceNumber: 'NO. DE FACTURE',
                    transactionCancelled: 'Autorisation Partiel Annule',
                    n99approved: '99 Approuvee - MERCI XXX',
                    partialAuthorizationCancelled: 'Autorisation Partiel Annule',
                    transactionNotCompleted: 'Operation non Completee',
                    n99transactionNotApproved: '99 Operation Refusee XXX',
                    n99transactionNotCompleted: '99 Operation non Completee XXX',
                    cardHolderSignature: 'SIGNATURE',
                    buyerAgrees: 'LE TITULAIRE VERSERA CE MONTANT A L\'EMETTEUR CONFORMEMENT AU CONTRAT ADHERENT',
                    retainCopy: '*Important - conserver cette copie pour vos dossiers*',
                    merchantCopy: 'COPIE DU DETAILLANT',
                    customerCopy: 'COPIE DU CLIENT',

                    default: 'DEFAUT FLASH',
                    savings: 'EPARGNE',
                    chequing: 'CHEQUE',

                    purchase: 'ACHAT',
                    refund: 'REMISE D\'ACHAT',
                    purchaseCorrection: 'CORRECTION D\'ACHAT',
                    refundCorrection: 'CORRECTION DE REMISE',
                    batchClose: 'BATCH CLOSE',
                    balanceInquiry: 'INTERROGATION DE SOLDE',
                    recallMessage: 'RECALL MESSAGE',

                    declinedByCard: 'REFUSEE PAR LA CARTE - 990',
                    cardRemoved: 'CARTE RETIREE - 991',
                    noSignatureRequired: 'SIGNATURE NON REQUISE',

                    void: 'VENTE',
                    cardBalanceInquiry: 'CARD BALANCE INQUIRY',
                    settlement: 'SETTLEMENT',
                    cardEntryMethod: 'ENTRY METHOD',
                },
            };

            var printUrlArrayOriginal = buildPrinterObjArray(response.posPrinters, false);
            var printUrlArrayGroupedByWidth = _.groupBy(printUrlArrayOriginal, 'receiptSize');

            var giftCardPurchaseMap = {};
            _.each(response.giftCardPurchases, function (giftCardPurchase) {
                giftCardPurchaseMap[giftCardPurchase.code] = giftCardPurchase;
            });

            var refundedTransactionItemMap = {};
            if (response.refundTransactions) {
                for (let refundTransaction of response.refundTransactions) {
                    for (var refundedTransactionItem of refundTransaction.items) {
                        var refundedQuantity = refundedTransactionItemMap[refundedTransactionItem.transactionItemId] || 0;
                        refundedQuantity += refundedTransactionItem.quantity;

                        refundedTransactionItemMap[refundedTransactionItem.transactionItemId] = refundedQuantity;
                    }
                }
            }

            var hasTransactionPercentDiscount = !!response.percentDiscount;

            Object.keys(printUrlArrayGroupedByWidth).forEach(async function (key) {
                var printUrlArray = printUrlArrayGroupedByWidth[key];
                var translation = localizations['en'];

                var receiptRequests = [];
                for (let i of Object.keys(printTypesArr)) {
                    var currentPrintType = printTypesArr[i];

                    var builder = new StarWebPrintBuilder();

                    var printerProtocol = printUrlArray[0].protocol;
                    var outputFormat = (printerProtocol && printerProtocol == 'usb') ? 'image/png' : 'image/jpeg';
                    receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.IMAGE_PRINTER, {
                        envConfig: EnvConfig,
                        printerDetails: printUrlArray[0],
                        type: 'receipt',
                        outputFormat: outputFormat
                    });
                    receiptFactory.initialize();

                    if (company && company.receiptLogoUrl) {
                        await receiptFactory.createImageElement(company.receiptLogoUrl);
                    }

                    // Print held order qr code
                    if (response.heldOrder && response.heldOrder.suspendId) {
                        await receiptFactory
                            .align('center')
                            .createQrCodeElement(`ho:1:${response.heldOrder.suspendId}`);
                        if (response.heldOrder.isPayAtCounter) {
                            receiptFactory
                                .align('center')
                                .text(translate('kiosk.payAtCounter.label'), true, {width: 2, height: 2});
                        }
                    }

                    receiptFactory
                        .align('center')
                        .text(response.locationName);

                    if (response.location && response.location.street && response.location.city && response.location.region) {
                        receiptFactory
                            .text(response.location.street)
                            .text(response.location.city + ', ' + response.location.region);
                    }

                    if (response.cashierName) {
                        receiptFactory
                            .text('Cashier Name: ' + response.cashierName);
                    }

                    if (response.stationName) {
                        receiptFactory
                            .text('POS Station: ' + response.stationName);
                    }

                    if (isDuplicate) {
                        receiptFactory
                            .align('center')
                            .text('DUPLICATE', true, {width: 1, height: 1});
                    }

                    var receiptCounterString = generateReceiptCounterString(response.receiptCounter, response.heldOrder);
                    if (receiptCounterString) {
                        receiptFactory
                            .align('center')
                            .title(receiptCounterString, true, {width: 4, height: 4, invert: true});
                    }

                    if (response.serviceMode) {
                        receiptFactory
                            .align('center')
                            .text(response.serviceMode);
                    }

                    if (response.patronName) {
                        if (response.heldOrder && response.heldOrder.isPayAtCounter) {
                            receiptFactory
                                .align('center')
                                .text(response.patronName, true, {width: 5, height: 5});
                        } else {
                            receiptFactory
                                .align('center')
                                .text(response.patronName);
                        }
                    }

                    // DELIVERY
                    if (response.isDelivery) {
                        // ALL DELIVERY RELATED ITEMS HERE NEEDS TO BE DOUBLE CHECKED
                        // TO SEE IF VARIABLES ARE CORRECT
                        if (response.deliveryAddress) {
                            receiptFactory.text(response.delivery.Address);
                        }
                    }

                    // MOBILE PICKUP
                    if (response.pickupTime) {
                        var pickupDate = moment(response.pickupTime);
                        var pickupDateString = pickupDate.format('MM/DD/YYYY');
                        var pickupTimeString = pickupDate.format('hh:mm:ss A');
                        receiptFactory.text('Estimated Pickup: ');
                        receiptFactory.text(pickupTimeString);
                        receiptFactory.text(pickupDateString);
                    }

                    receiptFactory.newLine(1);

                    var transactionDateTime = moment(response.transactionDateTime);
                    var transactionDateTimeString = transactionDateTime.format('YYYY-MM-DD hh:mm:ss A');
                    var transactionType = response.transactionType || 'SALE';
                    var isVoidOrRefund = (transactionType === 'VOID' || transactionType === 'REFUND' || transactionType === 'REFUND_PARTIAL');
                    var transactionTypeString = transactionType;

                    if (response.receiptId) {
                        let txIdString = `TX Id: ${response.receiptId}`;

                        if (!isVoidOrRefund) {
                            txIdString = `${txIdString} (${response.originalTransactionId ? 'EXCHANGE' : transactionTypeString})`;
                        }

                        receiptFactory
                            .align('left')
                            .text(txIdString);
                    } else {
                        if (response.heldOrder && !response.heldOrder.isPayAtCounter) {
                                receiptFactory
                                    .align('left')
                                    .text('Bill');
                        }
                    }

                    if (isVoidOrRefund) {
                        switch (transactionType) {
                            case 'VOID':
                                transactionTypeString = 'VOID';
                                break;
                            case 'REFUND':
                                transactionTypeString = 'REFUND';
                                break;
                            case 'REFUND_PARTIAL':
                                transactionTypeString = 'PART. REFUND';
                                break;
                        }

                        receiptFactory
                            .align('center')
                            .title(transactionTypeString, true, {width: 3, height: 3});

                        receiptFactory
                            .align('left')
                            .text('Authorized By: ' + response.userFullName);
                    }

                    if (response.mealPlansRedeemed && response.mealEqAmount) {
                        receiptFactory
                            .align('left')
                            .text('Discount Type: REWARDS REDEMPTION')
                            .text('Discount Authorized By: Mobile App');
                    } else if (response.percentDiscount) {
                        if (response.labelledDiscounts && response.labelledDiscounts.length > 0) {
                            receiptFactory
                                .align('left')
                                .text('Discount Type: ' + response.labelledDiscounts[0].discountName);

                            if (response.labelledDiscounts[0].authorizedByUser) {
                                receiptFactory
                                    .align('left')
                                    .text('Discount Authorized By: ' + response.labelledDiscounts[0].authorizedByUser.firstname
                                        + ' ' + response.labelledDiscounts[0].authorizedByUser.lastname);
                            } else {
                                receiptFactory
                                    .align('left')
                                    .text('Discount Authorized By: No PIN ');
                            }

                        } else {
                            receiptFactory
                                .align('left')
                                .text('Discount Type: GENERAL')
                                .text('Discount Authorized By: GENERAL');
                        }
                    } else if (response.dollarDiscountAmount) {
                        receiptFactory
                            .align('left')
                            .text('Discount Type: GENERAL')
                            .text('Discount Authorized By: GENERAL');
                    }


                    // / Move code to the bottom ends here
                    receiptFactory
                        .align('center')
                        .text('Date: ' + transactionDateTimeString)
                        .newLine(1);

                    receiptFactory
                        .textJustified(['Item   ', 'Price'])
                        .divider(true);

                    var decimalDisplayedPercentDiscountAmount = new Decimal(0);

                    var receiptHierarchy = generateReceiptHierarchy(receipt);

                    _.each(receiptHierarchy, function (item) {
                        if (item.locationServicePeriodMenuId === -99999) {
                            return; // buzzer
                        }

                        if (item.price < 0 && response.percentDiscount > 0) {
                            return; // skip trannsaction-wide discount
                        }

                        var leftString = item.quantity || 1;
                        leftString += '   ';
                        leftString += item.name;

                        var rightString = _currency($filter('itemDisplayPrice')(item));
                        var hasLoyaltyDiscount = _.some(item.children, {subtype: 'loyalty'});
                        if (hasLoyaltyDiscount) {
                            leftString = '*' + leftString;
                        }

                        receiptFactory
                            .align()
                            .textJustified([leftString, rightString], true);

                        if (item.subtype === 'giftcard.create' || item.subtype === 'giftcard.reload') {
                            var giftCardPurchase = giftCardPurchaseMap[item.upc];

                            if (giftCardPurchase) {
                                var balanceDollar = giftCardPurchase.currentBalanceCents / 100.0;
                                receiptFactory
                                    .text('    - Remaining:' + _currency(balanceDollar));
                            }
                        }

                        var receiptModifierPrintOptions = {
                            ignoreDiscount: hasTransactionPercentDiscount,
                            styles: {
                                width: 1,
                                height: 1
                            },
                            useLoyalty: hasLoyaltyDiscount
                        };

                        var modifiers = _groupDiscountModifiers(item.children);

                        if (modifiers && modifiers.length > 0) {
                            _.each(modifiers, function (subItem) {
                                if (subItem.type === 'modifier_group') {
                                    _.each(subItem.children, function (subSubItem) {
                                        printReceiptModifier(
                                            receiptFactory,
                                            subSubItem,
                                            receiptModifierPrintOptions,
                                            item
                                        );
                                    });
                                    // Individual Item
                                } else {
                                    printReceiptModifier(
                                        receiptFactory,
                                        subItem,
                                        receiptModifierPrintOptions,
                                        item
                                    );
                                }

                                if (subItem.subtype === 'discount' || subItem.subtype === 'loyalty') {
                                    decimalDisplayedPercentDiscountAmount = decimalDisplayedPercentDiscountAmount
                                        .plus(new Decimal(subItem.price));
                                }
                            });
                        }

                        if (item.notes) {
                            var noteString = 'Note: ' + item.notes;
                            receiptFactory.text(noteString, false, {width: 1, height: 1});
                        }

                        var itemRefundQuantity = refundedTransactionItemMap[item.receiptItemId];
                        if (itemRefundQuantity > 0) {
                            var refundVerb = '';
                            switch (transactionType) {
                                case 'VOID':
                                    refundVerb = 'voided';
                                    break;
                                case 'REFUND':
                                case 'REFUND_PARTIAL':
                                    refundVerb = (response.exchanged) ? 'exchanged' : 'refunded';
                                    break;
                            }

                            if (!refundVerb) {
                                // shouldn't happen, but adding this to be safe
                                return;
                            }

                            var refundActionString = (itemRefundQuantity == 1) ? ' has been ' : ' have been ';
                            refundActionString += refundVerb;
                            var refundString = '    *' + itemRefundQuantity + refundActionString;
                            receiptFactory.text(refundString);
                        }
                    });

                    if (response.percentDiscount) {
                        if (response.mealPlansRedeemed && response.mealEqAmount) {
                            var loyaltyDiscountDescription = (response.percentDiscount * 100).toFixed(0) + '%';
                            receiptFactory
                                .newLine(1)
                                .align()
                                .titleJustified(['Rewards Applied: ', loyaltyDiscountDescription])
                                .text('*on eligible items');
                        } else {
                            var percentageString = response.percentDiscount * 100 + '%';
                            var discountString = '';
                            if (response.labelledDiscounts && response.labelledDiscounts.length > 0) {
                                discountString = response.labelledDiscounts[0].discountName + ' (' + percentageString + '): ';
                            } else {
                                discountString = 'Discount (' + percentageString + '): ';
                            }

                            // Basket-level discount is applied pre-tax, so the amount discounted (and recorded in the db) is also
                            // the pre-tax amount. In order to display amount discounted in VAT-included countries, we have to dig
                            // the tax information from the receipt list. This ensures correct tax amount even with items with
                            // mixed tax rates
                            var displayedPercentDiscountAmount = (SharedDataService.taxIncludedInPrice)
                                ? decimalDisplayedPercentDiscountAmount.toNumber() // this is already recorded as negative cash flow
                                : -response.percentDiscountAmount; // this needs to be negated because it is recorded as an absolute amount

                            receiptFactory
                                .align()
                                .textJustified([discountString, _currency(displayedPercentDiscountAmount)]);
                        }
                    }

                    receiptFactory.divider(true);

                    if (response.dollarDiscountAmount) {
                        receiptFactory
                            .align()
                            .textJustified(['Discount: ', _currency(-response.dollarDiscountAmount)]);
                    }

                    receiptFactory
                        .align()
                        .textJustified(['Subtotal: ', _currency(response.transactionSubTotal)]);

                    // DELIVERY
                    if (response.isDelivery) {
                        receiptFactory
                            .align()
                            .textJustified(['Delivery Fee: ', _currency(response.deliveryFee)]);
                    }

                    // Temperary - add gst and qst tax amount when quebec
                    if (CurrentSession.getCompany() && CurrentSession.getCompany().operatingAddress.region == 'QC') {
                        let gstAmount = 0;
                        let qstAmount = 0;
                        let tpsNum = 'NA';
                        let tvqNum = 'NA';

                        const taxRegisDetails = CompanyAttributesService.getReceiptTaxRegistrationDetails();

                        if (taxRegisDetails) {
                            taxRegisDetails.split(/\s/).forEach(function (taxNum) {
                                let details = taxNum.split(/#/);

                                switch (details[0]) {
                                    case 'TPS':
                                        tpsNum = details[1];
                                        break;
                                    case 'TVQ':
                                        tvqNum = details[1];
                                        break;
                                    default:
                                }
                            });
                        }

                        if (response.transactionTax > 0) {
                            let totalTax = new Decimal(response.transactionTax);
                            let gstPercent = new Decimal(5);
                            let quebecTax = new Decimal(14.975);

                            gstAmount = totalTax.times(gstPercent.dividedBy(quebecTax)).toDecimalPlaces(2).toNumber();
                            qstAmount = totalTax.minus(gstAmount).toDecimalPlaces(2).toNumber();
                        }

                        receiptFactory
                            .align()
                            .textJustified([translate('receipt.gst') + ` (${tpsNum}): `, _currency(gstAmount)])
                            .textJustified([translate('receipt.pst') + ` (${tvqNum}): `, _currency(qstAmount)]);
                    }

                    receiptFactory
                        .align()
                        .textJustified(['Tax: ', _currency(response.transactionTax)]);

                    if (response.tipAmount > 0) {
                        receiptFactory
                            .textJustified(['Tip: ', _currency(response.tipAmount)]);
                    }
                    const numOfItems = _.filter(receipt, {level: 0}).length;
                    if (numOfItems <= 1) {
                        receiptFactory
                        .textJustified(['Total: (' + numOfItems + ' ' + translate('receipt.item') + ')', _currency(response.transactionTotal + response.tipAmount)]);

                    } else {
                        receiptFactory
                        .textJustified(['Total: (' + numOfItems + ' ' + translate('receipt.items') + ')', _currency(response.transactionTotal + response.tipAmount)]);
                    }

                    receiptFactory
                        .divider(true);

                    if (!isDuplicate && response.remainingBalance < 0) {
                        receiptFactory
                            .textJustified(['Cash Received: ', _currency(response.cashAmount + response.remainingBalance)])
                            .textJustified(['Card Amount Received: ', _currency(
                                (response.creditCardAmount > 0) ? response.creditCardAmount + response.tipAmount : 0
                            )]);
                    } else {
                        receiptFactory
                            .textJustified(['Cash Received: ', _currency(response.cashAmount)])
                            .textJustified(['Card Amount Received: ', _currency(
                                (response.creditCardAmount > 0) ? response.creditCardAmount + response.tipAmount : 0
                            )]);
                    }

                    if (response.otherAmount || response.fiitMealPlanCount || response.fiitDcbAmount) {
                        for (let tender of response.tenders) {
                            if (tender.transactionType != 'OTHER') {
                                continue;
                            }

                            if (tender.fiitTenders && tender.fiitTenders.length) {
                                for (var fiitTender of tender.fiitTenders) {
                                    var fiitLeftString = fiitTender.fiitMealPlanName || '';

                                    if (fiitTender.fiitMealPlanType === 'DCB') {
                                        fiitLeftString += ' (DCB) ';
                                    }

                                    var fiitRightString = fiitTender.unitsUsed;
                                    if (fiitTender.fiitMealPlanType === 'MEAL') {
                                        fiitRightString += (fiitTender.unitsUsed > 1) ? ' Meal Units' : ' Meal Unit';
                                        fiitRightString = '(' + fiitRightString + ') ';
                                        fiitRightString += _currency(fiitTender.equivalentDollarValue);
                                    } else {
                                        fiitRightString = _currency(fiitRightString);
                                    }

                                    receiptFactory
                                        .align()
                                        .textJustifiedAndWrapped([fiitLeftString, fiitRightString]);
                                }
                            } else {
                                let tenderDetail = tender.transactionTenderDetail || {};
                                let otherTenderType = tenderDetail.otherType;
                                let otherTenderTypeDisplayName = otherTenderType || 'Other Amount Received';
                                let mealUnitsDisplayName = 'Meal Units: ';

                                switch (otherTenderType) {
                                    case 'alphapay':
                                        otherTenderTypeDisplayName = 'WeChat Pay/Alipay';
                                        break;
                                    case 'fiitmps':
                                        otherTenderTypeDisplayName = 'DCB';
                                        break;
                                }
                                otherTenderTypeDisplayName = otherTenderTypeDisplayName + ': ';

                                if (tender.amountCents > 0) {
                                    receiptFactory
                                        .textJustified([otherTenderTypeDisplayName, _currency(tender.amountCents / 100)]);
                                }

                                if (tender.amountMeals > 0) {
                                    receiptFactory
                                        .textJustified([mealUnitsDisplayName, tender.amountMeals]);
                                }
                            }
                        }
                    }

                    _.each(response.tenders, function (tender) {
                        if (tender.transactionType === 'GIFTCARD') {
                            var giftCardId = (tender.giftCard) ? tender.giftCard.id : undefined;
                            var giftCard = _.findWhere(response.giftCardUsage, {id: giftCardId});
                            if (giftCard) {
                                if (giftCard.source === GIFTCARD_SOURCE.SOURCE_TRANSACTION || giftCard.source === GIFTCARD_SOURCE.SOURCE_API) {
                                    let giftCardDescription = 'Gift Card (' + $filter('giftCardMask')(giftCard.code) + '): ';
                                    let tenderAmount = (tender.amountCents + tender.tipAmountCents) / 100.0;
                                    let giftCardBalance = giftCard.currentBalanceCents / 100.0;

                                    receiptFactory
                                        .textJustified([giftCardDescription, _currency(tenderAmount)]);

                                    if (!isVoidOrRefund) {
                                        // for void and refund, the remaining balance is displayed in the
                                        // VOID/REFUND history below
                                        receiptFactory
                                            .text('   - Remaining: ' + _currency(giftCardBalance));
                                    }
                                } else {
                                    let storeCreditDescription = 'Store Credit Used: ';
                                    let tenderAmount = tender.amountCents / 100.0;

                                    receiptFactory
                                        .textJustified([storeCreditDescription, _currency(tenderAmount)]);
                                }
                            }
                        }
                    });

                    if (response.cashRounding) {
                        receiptFactory
                            .textJustified(['Cash Rounding: ', _currency(response.cashRounding)]);
                    }

                    if (transactionType === 'SALE') {
                        if (response.remainingBalance > 0) {
                            receiptFactory
                                .textJustified(['Remaining Balance: ', _currency(response.remainingBalance)]);
                        } else {
                            if (PosStatusService.isOffline()) {
                                receiptFactory
                                    .textJustified(['Change: ', _currency(response.change)]);
                            } else {
                                receiptFactory
                                    .textJustified(['Change: ', _currency(-response.change)]);
                            }
                        }
                    }

                    if (response.refundTransactions && response.refundTransactions.length > 0) {
                        receiptFactory
                            .align('center')
                            .newLine(1)
                            .title('--- ' + transactionTypeString + ' History ---')
                            .newLine(1);

                        for (let refundTransaction of response.refundTransactions) {
                            receiptFactory
                                .align('left')
                                .text(moment(refundTransaction.eventTime).format('YYYY-MM-DD HH:mm:ss'));

                            for (var refundTender of refundTransaction.tenders) {
                                var refundTenderType = refundTender.transactionType;
                                if (refundTenderType === 'MEAL') {
                                    receiptFactory
                                        .textJustified(['LOYALTY', refundTender.amountMeals + ' PTS']);
                                } else {
                                    var isGiftCardTender = refundTender.transactionType === 'GIFTCARD' && refundTender.giftCard;
                                    if (isGiftCardTender) {
                                        var refundTenderDescription;
                                        if (refundTender.giftCard.source === GIFTCARD_SOURCE.SOURCE_TRANSACTION || refundTender.giftCard.source === GIFTCARD_SOURCE.SOURCE_API) {
                                            refundTenderDescription = 'Gift Card (' + $filter('giftCardMask')(refundTender.giftCard.code) + '): ';
                                            receiptFactory
                                                .textJustified([refundTenderDescription, _currency(refundTender.amountCents / 100.0)]);
                                            receiptFactory
                                                .text('   - Remaining: ' + _currency(refundTender.giftCard.currentBalanceCents / 100.0));
                                        } else {
                                            refundTenderDescription = 'Store Credit';
                                            receiptFactory
                                                .textJustified([refundTenderDescription, _currency(refundTender.amountCents / 100.0)]);
                                        }
                                    } else {
                                        receiptFactory
                                            .textJustified([refundTender.transactionType, _currency(refundTender.amountCents / 100.0)]);
                                    }
                                }
                            }

                            receiptFactory.newLine(1);
                        }
                    }

                    receiptFactory.newLine(1);

                    if (CurrentSession.getCompany().hasLoyalty && response.hasLoyalty) {
                        receiptFactory.align().divider();
                        var rewardSummaryTitle = 'Rewards Summary';
                        if (response.patronName) {
                            rewardSummaryTitle = response.patronName + ' ' + rewardSummaryTitle;
                        }

                        var loyaltyEarned = (response.loyaltyAmount || 0);
                        var rewardCollectedDescription = loyaltyEarned + ' PTS';
                        var rewardRedemptionDescription = response.mealPlansRedeemed + ' PTS';
                        var rewardDiscountDescription = ((response.mealPlansRedeemed && response.percentDiscount) ?
                            '(' + (response.percentDiscount * 100).toFixed(0) + '%)' : '');
                        var rewardBalanceDescription = (response.currentLoyaltyPoints || 0).toFixed(0) + ' PTS';

                        receiptFactory
                            .align('center')
                            .text(rewardSummaryTitle)
                            .align('left')
                            .divider()
                            .textJustified(['Collected: ', rewardCollectedDescription])
                            .textJustified(['Redeemed : ', rewardRedemptionDescription + rewardDiscountDescription])
                            .textJustified(['Balance : ', rewardBalanceDescription]);

                    } else if (CurrentSession.getCompany().hasLoyalty) {
                        // No loyalty
                        var appName = CurrentSession.getOrganizationAppName();
                        var guestLoyaltyReminder = translate('receipt.download.app', {appName: appName});

                        // temporary pitaland contest info.
                        // should be removed after contest is over.
                        if (company && CompanyAttributesService.getCustomReceiptText()) {
                            // inserting new lines dynamically based on \n
                            var textSplit = CompanyAttributesService.getCustomReceiptText().split('\n');
                            _.each(textSplit, (line) => {
                                receiptFactory
                                    .align('left')
                                    .text(line);
                            });
                        }
                        receiptFactory
                            .align('left')
                            .text(guestLoyaltyReminder);
                    }

                    if (CompanyAttributesService.hasQRCodePrint() && response.transactionUuid) {
                        const appUrl = CurrentSession.getOrganization().mobileAppUrl;
                        const qrCode = appUrl + '#' + response.transactionUuid;
                        receiptFactory.align('center');
                        await receiptFactory.createQrCodeElement(qrCode);
                    }

                    let offers = response.offersUsed || [];

                    // Print divider if offers are present
                    if (offers && offers.length) {
                        receiptFactory.align('left').divider();
                    } else {
                        receiptFactory.newLine(1);
                    }

                    // Offer summary
                    for (let offer of offers) {
                        let offerSummaryTitle = translate('receipt.offers.summary');
                        let offerReward;

                        if (offer.offerType === 'EXTRA_POINTS' || offer.offerType === 'POINTS_MULTIPLIER') {
                            offerReward = offer.pointsIssued + ' PTS';
                        } else {
                            offerReward = _currency(offer.discountAmountCents / 100.0);
                        }
                        receiptFactory
                            .align('center')
                            .text(offerSummaryTitle)
                            .align('left')
                            .textJustified(['', ''], false, {}, {}, '-') // a line of asterisks
                            .textJustified([translate('receipt.offers.name') + ': ', offer.name])
                            .textJustified([translate('receipt.offers.reward') + ': ', offerReward]);
                    }

                    receiptFactory
                        .align('center')
                        .text(translate('receipt.thanksForVisit'))
                        .text(response.companyName)
                        .newLine(1);

                    for (let i of Object.keys(cardReceiptArr)) {
                        var cardReceipt = cardReceiptArr[i];

                        // some terminals like the Moneris Core family of terminals
                        // return a full string that we need to print. In this case
                        // we call "continue" and avoid formatting the receipt ourselves.
                        if (currentPrintType == PrintType.CUSTOMER && cardReceipt.rawStringCustomer) {
                            receiptFactory
                                .align('left');

                            receiptFactory.text(builder.createTextElement({data: cardReceipt.rawStringCustomer}));
                            continue;
                        } else if (currentPrintType == PrintType.MERCHANT && cardReceipt.rawStringMerchant) {
                            receiptFactory
                                .align('left');

                            receiptFactory.text(builder.createTextElement({data: cardReceipt.rawStringMerchant}));
                            continue;
                        }

                        if (currentPrintType == PrintType.CUSTOMER) {
                            translation = localizations[cardReceipt.customerLanguage || 'en'];
                        } else {
                            translation = localizations['en'];
                        }

                        receiptFactory.newLine(2);

                        receiptFactory
                            .newLine(2)
                            .align('center')
                            .text(translation.transactionRecord)
                            .newLine(1);

                        receiptFactory
                            .align('left');

                        var isCreditCard = cardReceipt.isCredit;
                        // var isTerminalDemoMode = cardReceipt.demoMode; // TODO

                        var transDate = new Date(response.transactionDateTime);
                        transactionDateTime = transDate.toLocaleString() + '\n';

                        if (cardReceipt.paymentProcessor === 'moneris') {
                            // currently only Moneris returns this information from
                            // the terminal.  For globalpayments, we need an
                            // alternate source to populate merchantname + address
                            receiptFactory
                                .text(cardReceipt.merchantName)
                                .text(cardReceipt.addressLine1)
                                .text(cardReceipt.addressLine2);
                        }

                        if (cardReceipt.paymentProcessor === 'globalpayments' && cardReceipt.demoMode) {
                            receiptFactory
                                .align('center')
                                .text('DEMO MODE')
                                .newLine(1);
                        }

                        receiptFactory
                            .align('left')
                            .text('TYPE: ' + (translation[cardReceipt.transactionType] || cardReceipt.transactionType))
                            .newLine(1);
                        // VISA, MASTERCARD, AMERICAN EXPRESS, INTERAC

                        // if (cardReceipt.paymentProcessor === 'globalpayments' && cardReceipt.terminalId) {
                        // receiptFactory.text('TID: ' + cardReceipt.terminalId);
                        // }
                        if (cardReceipt.paymentProcessor === 'globalpayments') {
                            if (cardReceipt.transactionSequenceNum) {
                                receiptFactory.text('SEQ: ' + cardReceipt.transactionSequenceNum);
                            }

                            if (currentPrintType == PrintType.MERCHANT && cardReceipt.merchantId) {
                                receiptFactory.text('MID: ' + cardReceipt.merchantId);
                            }
                        }

                        if (cardReceipt.isDebit) {
                            if (cardReceipt.debitAccountType === 'default') {
                                receiptFactory.text(translation.acct + ': ' + translation[cardReceipt.debitAccountType]);
                            } else {
                                receiptFactory.text(translation.acct + ': ' + cardReceipt.cardName + ' ' + (translation[cardReceipt.debitAccountType] || ''));
                            }
                        } else {
                            receiptFactory.text(translation.acct + ': ' + cardReceipt.cardName);
                        }
                        receiptFactory.text(translation.amount + ': ' + cardReceipt.transactionAmount);

                        // only need to show these is a partial transaction occurred.
                        if (cardReceipt.partiallyApproved) {
                            receiptFactory.text(translation.partiallyApproved + ': ' + cardReceipt.partiallyApproved);
                            receiptFactory.text(translation.amountDue + ': ' + cardReceipt.balanceDue);
                        }

                        if (cardReceipt.tipAmount && cardReceipt.tipAmount > 0) {
                            receiptFactory.text(translation.tip + ': ' + cardReceipt.tipAmount);
                        }

                        if (cardReceipt.isDebit && cardReceipt.transactionTypeCode === '00' && cardReceipt.surchargeAmount) {
                            receiptFactory.text(translation.surcharge + ': ' + cardReceipt.surchargeAmount);
                        }
                        receiptFactory.text(translation.total + ': ' + cardReceipt.totalAmount);

                        if (currentPrintType == PrintType.CUSTOMER &&
                            !cardReceipt.isDebit &&
                            ['00', '01', '60'].includes(cardReceipt.transactionTypeCode) &&
                            cardReceipt.cardBalance) {
                            receiptFactory.text(translation.accountBalance + ': ' + cardReceipt.cardBalance);
                        }

                        // TODO
                        // request += builder.createTextElement({ data: "DCC: " + dcc});

                        if (cardReceipt.cardholderName) {
                            receiptFactory.text('Cardholder Name' + ': ' + cardReceipt.cardholderName);
                        }

                        receiptFactory.text(translation.cardNumber + ': ' + cardReceipt.cardNumber);
                        receiptFactory.text(translation.dateTime + ': ' + cardReceipt.transactionDateTime);

                        if (cardReceipt.paymentProcessor === 'globalpayments' || cardReceipt.paymentProcessor === 'heartland') {
                            receiptFactory.text(translation.cardEntryMethod + ': ' + cardReceipt.cardEntryMethod);
                        } else if (cardReceipt.referenceNumber) {
                            var referenceNum = '';
                            referenceNum += cardReceipt.referenceNumber + ' ';

                            if (cardReceipt.cardEntryMethod) {
                                referenceNum += cardReceipt.cardEntryMethod;
                            }
                            receiptFactory.text(translation.referenceNum + ': ' + referenceNum);
                        }

                        if (cardReceipt.success && cardReceipt.authorizationNumber) {
                            receiptFactory.text(translation.authNum + ': ' + cardReceipt.authorizationNumber);
                        }

                        if (cardReceipt.emvAppPreferredName) {
                            receiptFactory.text(cardReceipt.emvAppPreferredName);
                        } else if (cardReceipt.emvAppLabel) {
                            receiptFactory.text(cardReceipt.emvAppLabel);
                        } else if (cardReceipt.emvAid) {
                            // need to show something if EMV transaction
                            receiptFactory.text(cardReceipt.cardName);
                        }

                        if (cardReceipt.emvAid) {
                            receiptFactory.text(cardReceipt.emvAid);
                        }

                        var emvTvr = '';
                        if (cardReceipt.emvTvr) {
                            emvTvr = cardReceipt.emvTvr;
                        }
                        var emvTsi = '';
                        if (cardReceipt.emvTsi) {
                            emvTsi = cardReceipt.emvTsi;
                        }
                        receiptFactory.text(emvTvr + ' ' + emvTsi);

                        if (cardReceipt.emvCryptogramType && cardReceipt.emvCryptogram) {
                            receiptFactory.text(cardReceipt.emvCryptogramType + ' ' + cardReceipt.emvCryptogram);
                        }

                        // TODO transactionStatus 11 and 19 are applicable only to Moneris, NOT GP
                        if (cardReceipt.emvAid && cardReceipt.transactionStatus === '11') {
                            receiptFactory.text(translation.declinedByCard);
                        }
                        if (cardReceipt.emvAid && cardReceipt.transactionStatus === '19') {
                            receiptFactory.text(translation.cardRemoved);
                        }

                        if (cardReceipt.verifiedByPin && currentPrintType == PrintType.MERCHANT) {
                            receiptFactory.text(translation.verifiedByPin);
                        }

                        if (cardReceipt.cardEntryMethod === 'F') {
                            // F means EMV with fallback swipe
                            receiptFactory.text(translation.chipCardSwiped);
                        } else if (cardReceipt.emvAid && cardReceipt.cardEntryMethod === 'G') {
                            // G means swipe, so have have to verify it is EMV as well
                            receiptFactory.text(translation.chipCardKeyed);
                        }

                        if (cardReceipt.approvedWithMalfunc
                            && cardReceipt.cardName == 'MASTERCARD'
                            && currentPrintType == PrintType.MERCHANT) {
                            receiptFactory.text(translation.chipCardMalfunction);
                        }

                        if (cardReceipt.partiallyApproved) {
                            receiptFactory.text(translation.transactionPartiallyApproved);
                        }

                        if (cardReceipt.invoiceNumber) {
                            receiptFactory.text(translation.invoiceNumber + ': ' + cardReceipt.invoiceNumber);
                        }

                        var isoCode = '';
                        if (cardReceipt.hostResponseIsoCode && !cardReceipt.isReversal) {
                            isoCode = cardReceipt.hostResponseIsoCode;
                        }

                        var hostResponseCode = '';
                        if (cardReceipt.hostResponseCode && !cardReceipt.isReversal) {
                            hostResponseCode = cardReceipt.hostResponseCode;
                        }

                        var statusLine = '';
                        receiptFactory
                            .newLine(1)
                            .align('center');

                        /* 12 - Communication Error
                            13 - Cancelled by User
                            14 - Timed out on User Input
                            15 - Transaction Not Completed
                            16 - Transaction Not Completed, Card Removed
                            17 - Chip failure occurs during communication with the card
                            23 - Partial approval is cancelled by the merchant or the customer
                            30 - Transaction not Supported
                            31 - Transaction not Allowed
                            32 - Invalid ECR/PC Parameter
                            95 - Terminal is not in the Idle state or it is in Stand-Alone mode. Terminal Can't accept a new request. */

                        if (cardReceipt.success) {
                            statusLine = translation.n99approved;
                        } else {
                            if (cardReceipt.cancelledByUser) {
                                statusLine = translation.transactionCancelled;
                            } else if (cardReceipt.partialApprovalCancelled) {
                                statusLine = translation.partialAuthorizationCancelled;
                            } else if (cardReceipt.declinedByCardOnline) {
                                statusLine = translation.transactionNotCompleted;
                            } else if (cardReceipt.cardRemoved) {
                                statusLine = translation.transactionNotCompleted;
                            } else if (cardReceipt.terminalTimeout) {
                                statusLine = translation.transactionNotCompleted;
                            } else if (cardReceipt.reversal) {
                                statusLine = translation.transactionNotCompleted;
                            } else if (cardReceipt.isDebit) {
                                if (['05', '51', '54', '55', '57', '58', '61', '62', '65', '75', '85', '92'].includes(cardReceipt.hostResponseIsoCode)) {
                                    statusLine = translation.n99transactionNotApproved;
                                } else {
                                    statusLine = translation.n99transactionNotCompleted;
                                }
                            } else if (
                                ['060', '068', '069', '074', '075', '078', '087', '088', '097', '100',
                                    '101', '102', '104', '106', '108', '113', '115', '206', '212', '800',
                                    '801', '802', '810', '811', '821', '898'].includes(cardReceipt.hostResponseCode)
                            ) {
                                statusLine = translation.n99transactionNotCompleted;
                            } else {
                                statusLine = translation.n99transactionNotApproved;
                            }
                        }
                        statusLine = statusLine.replace(/XXX/, hostResponseCode);
                        statusLine = statusLine.replace(/99/, isoCode);
                        receiptFactory
                            .text(statusLine)
                            .newLine(1);

                        if (cardReceipt.formFactor) {
                            receiptFactory
                                .align('left')
                                .text(cardReceipt.formFactor);
                        }

                        if (
                            cardReceipt.success
                            && isCreditCard
                            && (cardReceipt.showCustomerSignatureLine || cardReceipt.showMerchantSignatureLine)
                            && currentPrintType == PrintType.MERCHANT
                            // && (cardReceipt.transactionTypeCode == '00' ||
                            // cardReceipt.transactionTypeCode == '03')
                        ) {
                            if (printUrlArray[0].receiptSize === 3) {
                                receiptFactory
                                    .align('left')
                                    .text('X________________________________');
                            } else {
                                receiptFactory
                                    .align('left')
                                    .text('X___________________________');
                            }
                            receiptFactory
                                .align('left')
                                .text(translation.cardHolderSignature)
                                .newLine(1);

                            if (cardReceipt.transactionTypeCode == '00') {
                                receiptFactory
                                    .text(translation.buyerAgrees)
                                    .newLine(1);
                            }
                        }

                        if (isCreditCard) {
                            receiptFactory
                                .align('center')
                                .text(translation.retainCopy)
                                .newLine(1);
                        }

                        if (isCreditCard && ['H', 'T'].includes(cardReceipt.cardEntryMethod) && !cardReceipt.showCustomerSignatureLine) {
                            receiptFactory
                                .align('center')
                                .text(translation.noSignatureRequired)
                                .newLine(1);
                        }
                    }

                    // ask for signature for non credit card refunds and voids
                    if (cardReceiptArr &&
                        cardReceiptArr.length == 0 &&
                        currentPrintType == PrintType.MERCHANT &&
                        (transactionType == 'VOID' || transactionType == 'REFUND' || transactionType == 'REFUND_PARTIAL')) {
                        if (printUrlArray[0].receiptSize === 3) {
                            receiptFactory
                                .text('X________________________________');
                        } else {
                            receiptFactory
                                .text('X___________________________');
                        }
                        receiptFactory
                            .text('Customer Signature');
                    }

                    if (company && CompanyAttributesService.getReceiptFooter()) {
                        receiptFactory
                            .title(CompanyAttributesService.getReceiptFooter(), true, {width: 0.75, keepNewLine: true});
                    }

                    if (company && CompanyAttributesService.getReceiptTaxRegistrationDetails()) {
                        receiptFactory
                            .title(CompanyAttributesService.getReceiptTaxRegistrationDetails(), false, {width: 0.75});
                    }

                    if (currentPrintType == PrintType.MERCHANT) {
                        receiptFactory
                            .align('center')
                            .text(translation.merchantCopy)
                            .newLine(1);
                    } else if (currentPrintType == PrintType.CUSTOMER) {
                        if (!(response.heldOrder && response.heldOrder.isPayAtCounter)) {
                            receiptFactory.align('center')
                                .newLine(1)
                                .text(translate('receipt.customerCopy'))
                                .newLine(1);
                        }
                    }

                    await receiptFactory.output(screenPrint);
                    for (const factory of receiptFactory.getFactoryList()) {
                        receiptRequests.push(factory.receiptData);
                    }

                    if (currentPrintType == PrintType.CUSTOMER) {
                        for (var giftCardPurchaseKey in giftCardPurchaseMap) {
                            var item = giftCardPurchaseMap[giftCardPurchaseKey];

                            var giftCardPurchaseRequest = await buildGiftCardRequestImage(printUrlArray[0], item, outputFormat);
                            receiptRequests.push(giftCardPurchaseRequest);
                        }
                    }
                }

                var isCashTransaction = response.cashAmount && response.cashAmount > 0;
                var isCreditCardTransaction = response.creditCardAmount && response.creditCardAmount > 0;
                var restrictOpenDrawerToCashTransaction = company.attributes.receipt__restrict_open_cash_drawer === 'true';

                var openDrawer = (!skipCashDrawer);

                var openDrawerForTransaction = (restrictOpenDrawerToCashTransaction)
                    ? isCashTransaction
                    : isCashTransaction || isCreditCardTransaction;

                openDrawer = openDrawer && openDrawerForTransaction;

                var successCallback = function (data, attempt) { };
                var errorCallback = function (error, attempt) {
                    console.error(error);
                };

                if (openDrawer) {
                    openCashDrawer(printUrlArray, false, false, successCallback, errorCallback);
                }

                _.each(receiptRequests, function (receiptRequest, index) {
                    receiptRequest += getCutElement(builder);

                    var config = {
                        urlArray: printUrlArray,
                        request: receiptRequest,
                        openDrawer: false,
                        successCallback: successCallback,
                        errorCallback: errorCallback
                    };

                    if (company && company.companyLogoUrl) {
                        config.iconUrl = company.companyLogoUrl;
                        config.useIconCache = true;
                    }

                    sendMessageArray(config);
                });
            });
        }

        function printCreditCardReturnRefundReceipt (data, response) {
            var posPrinters = SharedDataService.posStation.posPrinters;

            var printUrlArrayOriginal = buildPrinterObjArray(posPrinters, false);
            var printUrlArrayGroupedByWidth = _.groupBy(printUrlArrayOriginal, 'receiptSize');

            Object.keys(printUrlArrayGroupedByWidth).forEach(function (key) {
                var printUrlArray = printUrlArrayGroupedByWidth[key];

                var builder = new StarWebPrintBuilder();
                var request = '';
                request += builder.createInitializationElement();

                request += builder.createAlignmentElement({position: 'center'});
                request += builder.createTextElement({data: 'Thank you for visiting. \n'});

                request += builder.createTextElement({data: '\n\n'});

                data.transactionDate = moment().format('YYYY-MM-DD HH:mm:ss');

                if (data.approvedAmount) {
                    data.approvedAmount = '$' + data.approvedAmount;
                }

                var returnRefundData = [
                    {name: 'Batch #', value: data.batchNum},
                    {name: 'Trans ID', value: data.transactionId},
                    {name: 'Trans Type', value: data.transactionType},
                    {name: 'Date/Time', value: data.transactionDate},
                    {name: 'Card Type', value: data.cardBrand},
                    {name: 'Card Number', value: data.maskedCardNumber},
                    {name: 'Entry Method', value: data.entryMethod},
                    {name: 'Original Auth Code', value: data.approvalCode},
                    {name: 'Total Amount', value: data.approvedAmount},
                    {name: 'Account Type', value: data.debitAccountType},
                    {name: 'EMV AID', value: data.emvAid},
                    {name: 'EMV TVR', value: data.emvTvr},
                    {name: 'EMV TSI', value: data.emvTsi},
                    {name: 'EMV App Label', value: data.emvAppLabel},
                    {name: 'Host Response Code', value: data.hostResponseCode},
                    {name: 'Host Response ISO', value: data.hostResponseIsoCode},

                ].filter(function (ele) {
                    return ele.value != undefined;
                });

                request += builder.createAlignmentElement({position: 'center'});

                _.each(returnRefundData, function (datum) {
                    if (datum.value != undefined && datum.value != null && datum.value != '') {
                        datum.nameSpacing = Array(20 - datum.name.length).join(' ');
                        datum.valueSpacing = Array(24 - datum.value.length).join(' ');

                        request += builder.createTextElement({data: datum.name + datum.nameSpacing + datum.value + datum.valueSpacing + '\n'});
                    }
                });

                request += builder.createTextElement({data: '\n\n'});

                request += builder.createTextElement({data: 'Approved - Thank You\n'});
                request += builder.createTextElement({data: '\n\n'});

                var receiptRequests = [];
                var customerCopyRequest = request;

                customerCopyRequest += builder.createAlignmentElement({position: 'center'});
                customerCopyRequest += builder.createTextElement({data: '\n' + translate('receipt.customerCopy') + '\n\n'});
                customerCopyRequest += builder.createTextElement({characterspace: 0});
                customerCopyRequest += getCutElement(builder);

                receiptRequests.push(customerCopyRequest);

                request += builder.createAlignmentElement({position: 'center'});
                request += builder.createTextElement({data: '\nMERCHANT COPY\n\n'});
                request += builder.createTextElement({characterspace: 0});
                request += getCutElement(builder);

                receiptRequests.push(request);

                var openDrawer = false;

                _.each(receiptRequests, function (receiptRequest, index) {
                    sendMessageArray({
                        urlArray: printUrlArray,
                        request: receiptRequest,
                        openDrawer: openDrawer,
                        successCallback: function (data, attempt) {
                            // PrintStatusService.success(
                            //     PrintStatusService.PRINTER_TYPE.POS,
                            //     response.posStation.posStationId,
                            //     printerUrl.printerUrl
                            // );
                        },
                        errorCallback: function (error, attempt) {
                            $log.error({
                                message: 'Credit Card Return/Refund receipts cannot be printed',
                                context: {
                                    activity: 'printCreditCardReturnRefundReceipt',
                                    urlArray: printUrlArray,
                                    attempt: (attempt + 1), // 0-based to 1-based
                                    error: error
                                }
                            });

                            // PrintStatusService.addMessage({
                            //     printerType: PrintStatusService.PRINTER_TYPE.POS,
                            //     printerId: response.posStation.posStationId,
                            //     printerUrl: printerUrl.printerUrl,
                            //     identifier: 'data.transactionId',
                            //     title: "Credit Card Refund Receipt Print Error",
                            //     description: "Credit Card Refund #" + response.receiptId + " receipt cannot be printed.",
                            //     data: error.data
                            // });
                        }
                    });
                });
            });


        }

        function printMobileOrderReceipt (response, receipt, successCallback, errorCallback) {
            var company = CurrentSession.getCompany();
            var companyAttributes = company.attributes || {};

            var useTransactionReceiptV2 = companyAttributes.use_transaction_receipt_v2 === 'true';
            var useTransactionReceiptV1Image = companyAttributes.use_transaction_receipt_v1_image === 'true';

            if (useTransactionReceiptV2 || useTransactionReceiptV1Image) {
                printMobileOrderReceiptV2(response, receipt, successCallback, errorCallback);
            } else {
                printMobileOrderReceiptV1(response, receipt, successCallback, errorCallback);
            }
        }

        const hasDigits = (stringToCheck) => {
            return /\d/.test(stringToCheck);
        };

        async function _generatePreorderReceipt (receiptFactory, response, receipt, isImagePrinting) {
            var receiptId = response.receiptId;
            let screenPrint = CompanyAttributesService.isScreenPrintEnabled();
            var truncatedReceiptId = receiptId % 1000; // assuming receiptId is always numerical
            truncatedReceiptId = '#' + padZero(truncatedReceiptId, 3);

            if (response.is_delivery) {
                if (response.is_third_party_preorder) {
                    const channelName = PreorderService.getChannelDisplayName(response.preorderDetails.channel);

                    receiptFactory
                        .align('center')
                        .title(channelName + ' Order ID:', true, {width: 2, height: 2, invert: false})
                        .title(response.preorderDetails.order_id, true, {width: 2, height: 2, invert: false})
                        .newLine(1);

                    receiptFactory
                        .align('center')
                        .title('DELIVERY ORDER', true, {width: 2, height: 2, invert: false})
                        .newLine(1);
                } else {
                    receiptFactory
                        .align('center')
                        .title('Delivery\n', true, {width: 2, height: 2, invert: false})
                        .title(response.truncatedReceiptId, true, {width: 2, height: 2, invert: false});
                }

                /*
                address format to print as follows:
                street (preorderDetails.address_line_one)
                apt number / other info ...etc. (preorderDetails.address_line_two) (optional)
                city (preorderDetails.city)
                postal code (preorderDetails.postcode)
                */

                // optional field
                if (response.preorderDetails.fixed_location_name) {
                    receiptFactory
                        .align('center')
                        .title(response.preorderDetails.fixed_location_name, true, {width: 2, height: 2, invert: false});
                }

                receiptFactory
                    .align('center')
                    .title(response.preorderDetails.address_line_one, true, {width: 1, height: 1, invert: false});

                // optional field
                if (response.preorderDetails.address_line_two && response.preorderDetails.address_line_two.length) {
                    receiptFactory
                        .align('center')
                        .title(response.preorderDetails.address_line_two, true, {width: 1, height: 1, invert: false});
                }

                // optional field
                if (response.preorderDetails.city) {
                    receiptFactory
                        .align('center')
                        .text(response.preorderDetails.city);
                }

                // optional field
                if (response.preorderDetails.postcode && response.preorderDetails.postcode.length) {
                    receiptFactory
                        .align('center')
                        .text(response.preorderDetails.postcode, true, {width: 1, height: 1, invert: false});
                }

                // optional field
                if (response.preorderDetails.apt_suite) {
                    receiptFactory
                        .align('center')
                        .title('APT/SUITE: ' + response.preorderDetails.apt_suite, true, {width: 2, height: 2, invert: false});
                }

                // optional field
                if (response.preorderDetails.delivery_instructions) {
                    receiptFactory
                        .align('center')
                        .title('DELIVERY INSTRUCTIONS: ' + response.preorderDetails.delivery_instructions, true, {width: 2, height: 2, invert: false});
                }

                // optional field
                if (response.preorderDetails.phone_number && hasDigits(response.preorderDetails.phone_number)) {
                    receiptFactory
                        .align('center')
                        .title('PHONE NUMBER: ' + response.preorderDetails.phone_number, true, {width: 2, height: 2, invert: false});

                    if (CompanyAttributesService.includePatronPhoneQR()) {
                        await receiptFactory
                            .align('center')
                            .createQrCodeElement(response.preorderDetails.phone_number);
                    }
                }

            }

            if (response.is_pickup) {
                if (response.pickup_phone_number && hasDigits(response.pickup_phone_number)) {
                    receiptFactory
                        .align('center')
                        .title('PHONE NUMBER: ' + response.pickup_phone_number, true, {width: 2, height: 2, invert: false});

                    if (CompanyAttributesService.includePatronPhoneQR()) {
                        await receiptFactory
                            .align('center')
                            .createQrCodeElement(response.pickup_phone_number);
                    }
                }

                if (response.is_third_party_preorder) {
                    const channelName = PreorderService.getChannelDisplayName(response.preorderDetails.channel);

                    receiptFactory
                        .align('center')
                        .title(channelName + ' Order ID:', true, {width: 2, height: 2, invert: false})
                        .title(response.preorderDetails.order_id, true, {width: 2, height: 2, invert: false})
                        .newLine(1);

                    receiptFactory
                        .align('center')
                        .title('PICKUP ORDER', true, {width: 2, height: 2, invert: false})
                        .newLine(1);
                } else {
                    if (response.status === 'cancelled') {
                        // print this on two lines since it is so big
                        receiptFactory
                            .align('center')
                            .title('ORDER', true, {width: 3, height: 3, invert: false})
                            .title('CANCELLED', true, {width: 3, height: 3, invert: false})
                            .title(truncatedReceiptId, true, {width: 2, height: 2, invert: false})
                            .newLine(2);
                    } else {
                        receiptFactory
                            .align('center')
                            .title('Mobile Order', true, {width: 2, height: 2, invert: false})
                            .title(truncatedReceiptId, true, {width: 2, height: 2, invert: false})
                            .newLine(2);
                    }
                }
            }


            // Patron Name
            var patronName = response.patronName || truncatedReceiptId;
            var patronNameSpacer = '  '; // add 2 spaces before and after patron name
            patronName = patronNameSpacer + patronName + patronNameSpacer;
            receiptFactory
                .align('center')
                .title(patronName, true, {width: 2, height: 2, invert: true});

            // Printed At
            var transDate = moment();
            var transDateString = transDate.format('MM/DD/YYYY');
            var transTimeString = transDate.format('hh:mm:ss A');
            receiptFactory
                .align('center')
                .text('Printed At:')
                .text(transDateString)
                .title(transTimeString, true, {width: 2, height: 2});

            // do not show these fields if it is a delivery order.
            if (response.is_pickup && response.pickupTime) {
                var pickupDate = moment(response.pickupTime);
                var pickupDateString = pickupDate.format('MM/DD/YYYY');
                var pickupTimeString = pickupDate.format('hh:mm:ss A');
                receiptFactory
                    .align('center')
                    .text('Estimated Pickup:')
                    .text(pickupDateString + ' ' + pickupTimeString)
                    .newLine(1)
                    .divider(false);
            }

            let textStyle = (!isImagePrinting) ? {width: 2, height: 2, invert: false} : null;

            if (response.status === 'cancelled') {
                // Strike through any cancelled items
                textStyle = (!isImagePrinting) ? {width: 2, height: 2, invert: false, textDecoration: 'line-through'} : {linethrough: true};
            }

            _.each(receipt, function (item, itemIndex) {
                var itemQuantity = (item.quantity || 1);
                var itemQuantitySpacing = Math.max(2 - Math.floor(itemQuantity / 10), 2); // 2 characters for item quantities

                var itemNameEllipsis = (((item.name || '').length > maxItemNameLength) ? defaultItemNameEllipsis : '');
                var itemName = (item.name || '').substring(0, maxItemNameLength - 1 - itemNameEllipsis.length) + itemNameEllipsis;

                var receiptItem = itemQuantity + 'x';
                receiptItem += Array(itemQuantitySpacing).join(' ');
                receiptItem += itemName;

                var itemUnitPrice = new Decimal(item.amount_cents).dividedBy(new Decimal(100));
                var itemPrice = new Decimal(itemUnitPrice * itemQuantity).toNearest(SharedDataService.baseDollar).toFixed(2);
                var itemPriceString = '$' + itemPrice;

                receiptFactory
                    .textJustified([receiptItem, itemPriceString], true, textStyle);

                var modifierGroups = (item.children || []).filter((item) => {
                    // skipthedishes uses either [description] or [name] to return the group
                    return item.type === 'modifier_group' || item.description === 'modifier group' || item.name === 'modifier group';
                });

                if (modifierGroups && modifierGroups.length > 0) {
                    var subItems = [];
                    _.each(modifierGroups, function (modifierGroup) {
                        subItems = subItems.concat(modifierGroup.children);
                    });

                    _.each(subItems, function (subItem) {
                        var subItemQuantity = subItem.quantity || 1;
                        // var subItemQuantitySpacing = Math.max(2 - Math.floor(subItemQuantity / 10), 1); // 2 characters for subitem quantities

                        var subItemNameEllipsis = (((subItem.name || '').length > maxSubItemNameLength) ? defaultItemNameEllipsis : '');
                        var subItemName = (subItem.name || '').substring(0, maxSubItemNameLength - 1 - subItemNameEllipsis.length) + subItemNameEllipsis;
                        var subItemUnitPrice = new Decimal(subItem.amount_cents).dividedBy(new Decimal(100));

                        const modifierMultiplier = new Decimal(subItemQuantity).div(item.quantity).toNearest(1).toFixed(2);

                        let multiplierToAdd = '';
                        if (modifierMultiplier > 1) {
                            multiplierToAdd = modifierMultiplier + 'x';
                        } else if (modifierMultiplier == 0) {
                            multiplierToAdd = 'NONE';
                        }

                        var receiptSubItem = '-  '; // 3-space indent for subitem
                        receiptSubItem += (multiplierToAdd) ? (multiplierToAdd + ' ' + subItemName) : subItemName;
                        var subItemPriceString = '';
                        if (subItemUnitPrice) {
                            var subItemPrice = new Decimal(subItemUnitPrice * subItemQuantity).toNearest(SharedDataService.baseDollar).toFixed(2);
                            subItemPriceString = '$' + subItemPrice;
                        }

                        receiptFactory
                            .textJustified([receiptSubItem, subItemPriceString], true, textStyle);
                    });
                }

                if (item.notes || item.note) {
                    const itemNotes = item.notes || item.note;
                    receiptFactory
                        .align('left')
                        .text('Note:', true, {width: 2, height: 2})
                        .text(itemNotes, true, {width: 2, height: 2});
                }

                receiptFactory
                    .newLine(1);

            });

            // order level notes
            if (response.notes || response.note) {
                receiptFactory
                    .align('left')
                    .text('ORDER NOTES:')
                    .text((response.notes || response.note), true);
            }

            receiptFactory.divider(false);

            var preorderSubtotalCentsObj = _.find(response.costBreakdown, {key: 'subtotal_cents'}) || {};
            var preorderSubtotalCents = preorderSubtotalCentsObj.val || 0;
            var preorderSubtotalDollar = preorderSubtotalCents / 100;

            var preorderDeliveryCentsObj = _.find(response.costBreakdown, {key: 'delivery_cents'}) || {};
            var preorderDeliveryCents = preorderDeliveryCentsObj.val || 0;
            var preorderDeliveryDollar = preorderDeliveryCents / 100;

            var preorderTaxCentsObj = _.find(response.costBreakdown, {key: 'tax_cents'}) || {};
            var preorderTaxCents = preorderTaxCentsObj.val || 0;
            var preorderTaxDollar = preorderTaxCents / 100;

            var subtotalDollarString = '$' + new Decimal(preorderSubtotalDollar).toNearest(SharedDataService.baseDollar).toFixed(2);
            var deliveryDollarString = '$' + new Decimal(preorderDeliveryDollar).toNearest(SharedDataService.baseDollar).toFixed(2);
            var taxDollarString = '$' + new Decimal(preorderTaxDollar).toNearest(SharedDataService.baseDollar).toFixed(2);

            var transactionTotalDollar = response.totalCents / 100;
            let transactionTotalString = '$' + new Decimal(transactionTotalDollar).toNearest(SharedDataService.baseDollar).toFixed(2);

            receiptFactory
                .textJustified(
                    ['SUBTOTAL: ', subtotalDollarString],
                    false,
                    {},
                    {fontWeight: '700'}
                );

            if (preorderDeliveryDollar) {
                receiptFactory
                    .textJustified(
                        ['DELIVERY: ', deliveryDollarString],
                        false,
                        {},
                        {fontWeight: '700'}
                    );
            }

            receiptFactory
                .textJustified(
                    ['TAX: ', taxDollarString],
                    false,
                    {},
                    {fontWeight: '700'}
                )
                .textJustified(
                    ['TOTAL: ', transactionTotalString],
                    false,
                    {},
                    {fontWeight: '700'}
                )
                .divider(false);

            if (CompanyAttributesService.includePatronNameBottom()) {
                receiptFactory
                    .align('center')
                    .title(patronName, true, {width: 2, height: 2, invert: true});
            }

            if (CompanyAttributesService.hasQRCodePrint() && response.transactionUuid) {
                const appUrl = CurrentSession.getOrganization().mobileAppUrl;
                const qrCode = appUrl + '#' + response.transactionUuid;
                await receiptFactory
                    .align('center')
                    .createQrCodeElement(qrCode);
            }

            receiptFactory
                .align('center')
                .text('\n');

            var appFid = response.appFid;
            var appDatum = appData[appFid];

            if (appDatum) {
                // var appLogoCanvasCtx = appDatum.logoCanvasCtx;
                // request += builder.createBitImageElement({context: appLogoCanvasCtx, x: 0, y: 0, width: 120, height: 120});
                receiptFactory
                    .align('center')
                    .text(appDatum.name)
                    .text(appDatum.url, true);
            }

            receiptFactory
                .align('center')
                .text(response.locationName)
                .newLine(1)
                .text(response.stationName)
                .newLine(1);

            let company = CurrentSession.getCompany();
            // make sure you remove this after expiry date
            if (company.companyId == 926 && Date.now() < 1631851200000) {
                // inserting new lines dynamically based on \n
                const textArr = ['Join us for our Grand Opening', ' event on Sep 15 and 16, 2021.', ' More details to come.', ' Follow us on Facebook,', ' Instagram and TikTok!'];
                _.each(textArr, (line) => {
                    receiptFactory
                        .align('center')
                        .text(line);
                });
            }

            receiptFactory.cut();

            await receiptFactory.output(screenPrint);
        }

        function printMobileOrderReceiptV1 (response, receipt, successCallback, errorCallback) {
            let receiptFactory = new ReceiptFactoryWrapper();
            var printUrlArrayOriginal = response.printUrlArray;
            var printUrlArrayGroupedByWidth = _.groupBy(printUrlArrayOriginal, 'receiptSize');

            if (!printUrlArrayOriginal || printUrlArrayOriginal.length == 0) {
                return errorCallback({reason: 'No Printers Available', code: 100});
            }

            Object.keys(printUrlArrayGroupedByWidth).forEach(async function (key) {
                var printUrlArray = printUrlArrayGroupedByWidth[key];

                if (response.status === 'print_pending') {
                    const lucovaTransactionId = response.transactionId;
                    Lucova.manager().acknowledgeMobileOrder({}, {
                        preorder_id: lucovaTransactionId
                    }, function (response) {
                        if (successCallback) {
                            successCallback(response);
                        }
                    }, function (err) {
                        if (errorCallback) {
                            errorCallback(err);
                        }
                    });
                } else if (response.status === 'cancelled') {
                    // We have already cancelled this order, so we succeed
                    // here regardless.
                    if (successCallback) {
                        successCallback(response);
                    }
                }

                receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.STAR_PRINTER, {
                    printerDetails: printUrlArray[0]
                });

                if (CompanyAttributesService.isScreenPrintEnabled()) {
                    receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.SCREEN_PRINTER, {
                        printerDetails: printUrlArray[0]
                    });
                }

                receiptFactory.initialize();

                await _generatePreorderReceipt(receiptFactory, response, receipt, false);
                receiptFactory.attachCutElement(CompanyAttributesService.partialCutEnabled());

                for (const factory of receiptFactory.getFactoryList()) {
                    sendMessageArray({
                        urlArray: response.printUrlArray,
                        request: factory.receiptData,
                        successCallback: function (data, attempt) {
                        },
                        errorCallback: function (error, attempt) {
                            console.error(error);
                        }
                    });
                }
            });
        }

        // Using image printer
        function printMobileOrderReceiptV2 (response, receipt, successCallback, errorCallback) {
            let receiptFactory = new ReceiptFactoryWrapper();
            var printUrlArrayOriginal = response.printUrlArray;
            var printUrlArrayGroupedByWidth = _.groupBy(printUrlArrayOriginal, 'receiptSize');

            if (!printUrlArrayOriginal || printUrlArrayOriginal.length == 0) {
                return errorCallback({reason: 'No Printers Available', code: 100});
            }

            Object.keys(printUrlArrayGroupedByWidth).forEach(async function (key) {
                var printUrlArray = printUrlArrayGroupedByWidth[key];

                if (response.status === 'print_pending') {
                    const lucovaTransactionId = response.transactionId;
                    Lucova.manager().acknowledgeMobileOrder({}, {
                        preorder_id: lucovaTransactionId
                    }, function (response) {
                        if (successCallback) {
                            successCallback(response);
                        }
                    }, function (err) {
                        if (errorCallback) {
                            errorCallback(err);
                        }
                    });
                } else if (response.status === 'cancelled') {
                    // We have already cancelled this order, so we succeed
                    // here regardless.
                    if (successCallback) {
                        successCallback(response);
                    }
                }

                var printerProtocol = printUrlArray[0].protocol;
                var outputFormat = (printerProtocol && printerProtocol == 'usb') ? 'image/png' : 'image/jpeg';
                receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.IMAGE_PRINTER, {
                    envConfig: EnvConfig,
                    printerDetails: printUrlArray[0],
                    type: 'report',
                    outputFormat: outputFormat
                });
                receiptFactory.initialize();

                await _generatePreorderReceipt(receiptFactory, response, receipt, true);
                receiptFactory.attachCutElement(CompanyAttributesService.partialCutEnabled());

                for (const factory of receiptFactory.getFactoryList()) {
                    sendMessageArray({
                        urlArray: response.printUrlArray,
                        request: factory.receiptData,
                        successCallback: function (data, attempt) {
                        },
                        errorCallback: function (error, attempt) {
                            console.error(error);
                        }
                    });
                }
            });
        }

        async function printProductLabel (item, posPrinter) {
            if (!posPrinter || !posPrinter.length) {
                return;
            }

            let printUrlArray = buildLabelPrinterArray(posPrinter, true);
            printUrlArray[0].receiptSize = 2;

            var receiptFactory = new ReceiptFactoryWrapper();
            receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.STAR_PRINTER, {
                printerDetails: printUrlArray[0]
            });

            if (CompanyAttributesService.isScreenPrintEnabled()) {
                receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.SCREEN_PRINTER, {
                    printerDetails: printUrlArray[0]
                });
            }

            receiptFactory.initialize();

            if (item.name) {
                receiptFactory
                    .align('center')
                    .text((item.name || '').toUpperCase(), true)
                    .newLine(1);

                receiptFactory
                    .align('center')
                    .text(_currency($filter('itemDisplayPrice')(item)), true)
                    .newLine(1);
            }

            if (item.upc) {
                receiptFactory
                    .align('center')
                    .createBarcodeElement(item.upc, 60)
                    .newLine(2);
            }

            receiptFactory.cut();

            await receiptFactory.output();

            for (const factory of receiptFactory.getFactoryList()) {
                sendMessageArray({
                    urlArray: printUrlArray,
                    request: factory.receiptData,
                    openDrawer: false,
                    successCallback: function (data, attempt) {
                    },
                    errorCallback: function (error, attempt) {
                        console.error(error);
                    }
                });
            }

        }

        // remove diacritics from string
        const deburr = (inputString) => inputString.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

        const getQuantityString = (item, parentQuantity = 1) => {
            let quant = '';
            if (!item) {
                return quant;
            }

            // we hide multipliers and quantities of '1' to perserve lable space
            if (item.multiplierInWords && item.multiplierInWords !== '1x') { // multiplierInWords is constructed in pos-modify.js
                quant = item.multiplierInWords + ' ';
            } else if (item.quantity && (item.quantity / parentQuantity !== 1)) {
                quant = (item.quantity / parentQuantity) + 'x ';
            }

            return quant;
        };

        function printLabels (response, receiptItems, posPrinter, mobileOrder, separateItems) {
            var printUrlArrayOriginal = buildLabelPrinterArray([posPrinter], true);
            var printUrlArrayGroupedByWidth = _.groupBy(printUrlArrayOriginal, 'receiptSize');
            let header = '';

            if (mobileOrder && response.patronName) {
                header += 'Order for ' + response.patronName + ' ';
            } else {
                const serviceMode = response.serviceMode;
                const receiptCounter = response.receiptCounter;

                if (serviceMode) {
                    header += serviceMode + ' ';
                }

                if (receiptCounter) {
                    if (!serviceMode) {
                        header += 'Order ';
                    }
                    header += '#' + String(response.receiptCounter) + ' ';
                }
            }

            let customAttributes = {};
            if (posPrinter.customAttributes) {
                try {
                    customAttributes = JSON.parse(posPrinter.customAttributes);
                } catch (e) {
                    console.log(e);
                }
            }

            const tsplXOffset = parseInt(customAttributes.tsplXOffset) || 2;
            const tsplYOffset = parseInt(customAttributes.tsplYOffset) || 2;
            const tsplTextWrapChars = parseInt(customAttributes.tsplTextWrapChars) || 9999; // 9999 -> pratically no wrap
            let tsplFontSize = '1';
            let tsplDirection = 0;
            let lineHeight = 18;

            /**
             * TSPL reference guide
             * https://meto.com/metoprint/files/meto_print_programming_manual_tspl_ez_en.pdf
             */
            switch (customAttributes.tsplFontSize) {
                case 'Small':
                    tsplFontSize = '1';
                    lineHeight = 18;
                    break;
                case 'Medium':
                    tsplFontSize = '2';
                    lineHeight = 30;
                    break;
                case 'Large':
                    tsplFontSize = '3';
                    lineHeight = 42;
                    break;
                default:
                    tsplFontSize = '1';
                    lineHeight = 18;
                    break;
            }

            switch (customAttributes.tsplDirection) {
                case 'Normal':
                    tsplDirection = 0;
                    break;
                case 'Inverted':
                    tsplDirection = 1;
                    break;
                default:
                    tsplDirection = 0;
                    break;
            }

            Object.keys(printUrlArrayGroupedByWidth).forEach(function (key) {
                var printUrlArray = printUrlArrayGroupedByWidth[key];

                // break out multiple grouped items if separateItems is enabled
                let receiptItemGroups = _.map(receiptItems, function (receiptItem) {
                    return Array((separateItems && receiptItem.quantity) || 1).fill(receiptItem);
                });

                let currentLabelNum = 0;
                let totalLabels = 0;

                _.each(receiptItemGroups, function (receiptItemGroup) {
                    _.each(receiptItemGroup, function (item) {
                        totalLabels += 1;
                    });
                });

                let currentLabelData = [];

                currentLabelData.push(TscBuffer.gapDetect());
                currentLabelData.push(TscBuffer.direction(tsplDirection));

                _.each(receiptItemGroups, function (receiptItemGroup) {
                    _.each(receiptItemGroup, function (item) {
                        currentLabelData.push(TscBuffer.cls());

                        currentLabelNum++;
                        let currentYOffset = tsplYOffset;
                        let pageNum = '[' + currentLabelNum + ' of ' + totalLabels + ']';

                        currentLabelData.push(TscBuffer.text(tsplXOffset, currentYOffset, tsplFontSize, 0, 1, 1, header + pageNum));
                        currentYOffset += lineHeight;

                        // text wraping for item name
                        const itemNameChunks = _.chunk(deburr(item.name), tsplTextWrapChars);
                        _.each(itemNameChunks, (itemNameChunk) => {
                            currentLabelData.push(TscBuffer.text(tsplXOffset, currentYOffset, tsplFontSize, 0, 1, 1, itemNameChunk.join('')));
                            currentYOffset += lineHeight;
                        });

                        const modifiers = item.children || [];

                        _.each(modifiers, function (subItem) {
                            let prefix = '> ';
                            let suffix = '';

                            if (subItem.type !== 'modifier_group' && subItem.name) {
                                prefix += getQuantityString(subItem, item.quantity) + deburr(subItem.name);
                            }

                            if (subItem.type === 'modifier_group' && subItem.children && subItem.children.length > 0) {
                                subItem.children.forEach((child) => {
                                    if (child.name) {
                                        if (suffix.length > 0) {
                                            suffix += ', ';
                                        }
                                        suffix += getQuantityString(child, item.quantity) + deburr(child.name);
                                    }
                                });
                            }

                            // text wraping
                            const subItemNameChunks = _.chunk(prefix + suffix, tsplTextWrapChars - 1);
                            _.each(subItemNameChunks, (subItemNameChunk) => {
                                currentLabelData.push(TscBuffer.text(tsplXOffset + 10, currentYOffset, tsplFontSize, 0, 1, 1, subItemNameChunk.join('')));
                                currentYOffset += lineHeight;
                            });
                        });

                        currentLabelData.push(TscBuffer.print(1));
                    });
                });

                currentLabelData.push(TscBuffer.end());

                let data = Buffer.concat(currentLabelData).toString('latin1');

                sendMessageArray({
                    urlArray: printUrlArray,
                    request: data,
                    openDrawer: false,
                    successCallback: function (data, attempt) {
                    },
                    errorCallback: function (error, attempt) {
                        console.error(error);
                    }
                });
            });
        }

        function buildGiftCardRequestSrm (receiptFactory, item) {

            var balance = item.currentBalanceCents / 100.0;

            receiptFactory
                .newLine(1)
                .text('GIFT CARD NUMBER: ' + $filter('giftCardMask')(item.code))
                .text('Remaining Balance: ' + _currency(balance))
                .text('Activation Code: ' + item.activationCode)
                .newLine(2);
        }

        function buildGiftCardRequest (request, builder, item) {
            request += addLine(builder, request);
            request += addLine(builder, request, 'GIFT CARD NUMBER: ' + $filter('giftCardMask')(item.code));
            var balance = item.currentBalanceCents / 100.0;
            request += addLine(builder, request, 'Remaining Balance: ' + _currency(balance));
            request += addLine(builder, request, 'Activation Code: ' + item.activationCode);

            return request;
        }

        async function buildGiftCardRequestImage (printerDetails, item, outputFormat) {
            let payloadList = [];
            let receiptFactory = new ReceiptFactoryWrapper();
            receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.IMAGE_PRINTER, {
                envConfig: EnvConfig,
                printerDetails: printerDetails,
                type: 'receipt',
                outputFormat: outputFormat
            });
            receiptFactory.initialize();

            var balance = item.currentBalanceCents / 100.0;
            let screenPrint = CompanyAttributesService.isScreenPrintEnabled();

            receiptFactory
                .newLine(1)
                .text('GIFT CARD NUMBER: ' + $filter('giftCardMask')(item.code))
                .text('Remaining Balance: ' + _currency(balance))
                .text('Activation Code: ' + item.activationCode);
            await receiptFactory.output(screenPrint);
            for (const factory of receiptFactory.getFactoryList()) {
                payloadList.push(factory.receiptData);
            }

            return payloadList;
        }

        function printGiftCardPurchaseStub (item, posPrinters) {
            let receiptFactory = new ReceiptFactoryWrapper();
            var printUrlArrayOriginal = buildPrinterObjArray(posPrinters, false);
            var printUrlArrayGroupedByWidth = _.groupBy(printUrlArrayOriginal, 'receiptSize');
            var builder = new StarWebPrintBuilder();

            Object.keys(printUrlArrayGroupedByWidth).forEach(async function (key) {
                var printUrlArray = printUrlArrayGroupedByWidth[key];

                receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.STAR_PRINTER, {
                    printerDetails: printUrlArray[0]
                });

                if (CompanyAttributesService.isScreenPrintEnabled()) {
                    receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.SCREEN_PRINTER, {
                        printerDetails: printUrlArray[0]
                    });
                }

                receiptFactory.initialize();

                await receiptFactory.output();

                for (const factory of receiptFactory.getFactoryList()) {
                    let request = factory.receiptData;

                    request = buildGiftCardRequest(request, builder, item);
                    request += builder.createTextElement({characterspace: 0});
                    request += getCutElement(builder);

                    sendMessageArray({
                        urlArray: printUrlArray,
                        request: request
                    });
                }
            });
        }

        function printKitchenSheets (response, receiptItems, posPrinter, mobileOrder, separateItems, {
            successCallback = () => { },
            orderName,
        } = {}) {
            var company = CurrentSession.getCompany();
            var companyAttributes = company.attributes || {};

            var useTransactionReceiptV2 = companyAttributes.use_transaction_receipt_v2 === 'true';
            var useTransactionReceiptV1Image = companyAttributes.use_transaction_receipt_v1_image === 'true';
            const textSize = companyAttributes.kitchen_print_text_size;
            let textScaleFactor = 1;
            switch (textSize) {
                case '1':
                    // medium
                    textScaleFactor = 1.4;
                    break;
                case '2':
                    // large
                    textScaleFactor = 1.8;
                    break;
                default:
                    break;
            }

            if (useTransactionReceiptV2 || useTransactionReceiptV1Image) {
                printKitchenSheetsV1Image(
                    response,
                    receiptItems,
                    posPrinter,
                    mobileOrder,
                    response.buzzerCode,
                    separateItems,
                    successCallback,
                    orderName,
                    textScaleFactor
                );
            } else {
                printKitchenSheetsV1(
                    response,
                    receiptItems,
                    posPrinter,
                    mobileOrder,
                    response.buzzerCode,
                    separateItems,
                    successCallback,
                    orderName,
                );
            }
        }

        function printKitchenSheetsV1 (response, receiptItems, posPrinter, mobileOrder, buzzerName, separateItems, successCallback, orderName) {
            let receiptFactory = new ReceiptFactoryWrapper();
            var printUrlArrayOriginal = buildPrinterObjArray(posPrinter, true);
            var printUrlArrayGroupedByWidth = _.groupBy(printUrlArrayOriginal, 'receiptSize');

            var totalReceiptSequenceCount = 0;
            var currentReceiptSequence = 0;

            Object.keys(printUrlArrayGroupedByWidth).forEach(function (key) {
                // var receiptItemGroups;
                if (separateItems) {
                    totalReceiptSequenceCount += receiptItems.length;
                } else {
                    totalReceiptSequenceCount += 1;
                }
            });

            Object.keys(printUrlArrayGroupedByWidth).forEach(async function (key) {
                var printUrlArray = printUrlArrayGroupedByWidth[key];

                receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.STAR_PRINTER, {
                    printerDetails: printUrlArray[0]
                });

                if (CompanyAttributesService.isScreenPrintEnabled()) {
                    receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.SCREEN_PRINTER, {
                        printerDetails: printUrlArray[0]
                    });
                }

                receiptFactory.initialize();

                var receiptItemGroups;
                if (separateItems) {
                    receiptItemGroups = _.map(receiptItems, function (receiptItem) {
                        return [receiptItem];
                    });
                } else {
                    receiptItemGroups = [receiptItems];
                }

                _.each(receiptItemGroups, function (receiptItemGroup, index) {
                    currentReceiptSequence++;

                    var buzzerCode = response.buzzerCode;
                    if (buzzerCode) {
                        buzzerName = buzzerCode.toString();
                    }

                    // Here is where we generate the order number
                    var receiptCounterString = generateReceiptCounterString(response.receiptCounter, response.heldOrder);
                    // Use the held order name if it is supplied
                    // First if condition checks for Not A "Pay at counter" Order name for kiosk orders
                    if (!(response.suspendId && response.guestTransaction === true
                        && response.receiptCounter)) {
                        if (orderName && receiptCounterString) {
                            receiptCounterString = orderName + '\n#' + padZero(response.receiptCounter, 3);
                        } else if (orderName) {
                            receiptCounterString = orderName;
                        }
                    }

                    if (receiptCounterString) {
                        receiptFactory
                            .align('center')
                            .title(receiptCounterString, true, {width: 4, height: 4, invert: true})
                            .newLine(1);
                    }

                    if (response.serviceMode) {
                        receiptFactory
                            .align('center')
                            .title(response.serviceMode)
                            .newLine(1);
                    }

                    if (response.patronName) {
                        var patronName = response.patronName;

                        if (mobileOrder && response.status === 'cancelled') {
                            patronName = 'ORDER CANCELLED: ' + patronName;
                        } else if (mobileOrder) {
                            patronName = 'Mobile Order: ' + patronName;
                        }

                        receiptFactory
                            .align('center')
                            .text(patronName, true, {width: 2, height: 2})
                            .newLine(1);
                    }

                    var receiptSequenceTotalString = totalReceiptSequenceCount;
                    var receiptSequenceString = currentReceiptSequence + ' of ' + receiptSequenceTotalString;
                    receiptFactory
                        .align('center')
                        .title(receiptSequenceString, false, {width: 2, height: 2})
                        .newLine(1);

                    if (buzzerName) {
                        let buzzerLabel = translate('general.buzzer');
                        receiptFactory
                            .align('center')
                            .title((buzzerLabel + ': ' + buzzerName), true, {width: 2, height: 2});
                    }

                    var transDate = moment(response.transactionDateTime);
                    var transDateString = transDate.format('MM/DD/YYYY');
                    var transTimeString = transDate.format('hh:mm:ss A');
                    receiptFactory
                        .align('center')
                        .newLine(2)
                        .text('Order Placed: ' + transDateString + ' ' + transTimeString);

                    if (response.pickupTime) {
                        var pickupDate = moment(response.pickupTime);
                        var pickupDateString = pickupDate.format('MM/DD/YYYY');
                        var pickupTimeString = pickupDate.format('hh:mm:ss A');

                        receiptFactory
                            .align('center')
                            .text('Estimated Pickup:')
                            .title(pickupDateString + '' + pickupTimeString, true, {width: 2, height: 1});
                    }

                    receiptFactory.divider().newLine(1);

                    var receiptModifierPrintOptions = {
                        ignoreDiscount: true,
                        emphasis: true,
                        styles: {
                            width: 2,
                            height: 2,
                            textDecoration: response.status === 'cancelled' ? 'line-through' : null
                        }
                    };

                    _.each(receiptItemGroup, function (item) {
                        if (item.noModifierNoKitchenPrint) {
                            return;
                        }
                        var itemQuantityString = item.quantity || 1;
                        itemQuantityString += 'x';
                        var itemName = (item.name || '');
                        var receiptItem = itemQuantityString + ' ' + itemName;

                        let styles = {
                            width: 2,
                            height: 2,
                            textDecoration: response.status === 'cancelled' ? 'line-through' : null
                        };

                        receiptFactory
                            .align('left')
                            .title(receiptItem, true, styles);

                        var modifiers = item.children;

                        if (modifiers && modifiers.length > 0) {
                            _.each(modifiers, function (subItem) {
                                // Group
                                if (subItem.type === 'modifier_group') {
                                    _.each(subItem.children, function (subSubItem) {
                                        subSubItem._ignorePrice = true;
                                        printReceiptModifier(receiptFactory, subSubItem, receiptModifierPrintOptions, item);
                                    });
                                    // Individual Item
                                } else {
                                    subItem._ignorePrice = true;
                                    printReceiptModifier(receiptFactory, subItem, receiptModifierPrintOptions, item);
                                }
                            });
                        }

                        if (item.notes || item.note) {
                            receiptFactory
                                .align('left')
                                .newLine(2)
                                .text('Note: ' + (item.notes || item.note), false, {width: 2, height: 2});
                        }
                    });

                    receiptFactory
                        .newLine(1);

                    // order level notes
                    if (response.notes || response.note) {
                        receiptFactory
                            .align('left')
                            .text('Order Notes: ' + (response.notes || response.note));
                    }

                    // for now, hack to make new line work with USB printer
                    /*
                    NOTE: There is a problem with some printers where the cutoff point of the kitchen sheet is right
                    before the last line of information. The code below has been changed from .newLine(1)
                    to .newLine(4) to counter this issue.
                    */
                    receiptFactory
                        .newLine(4);

                    receiptFactory.cut();
                });

                await receiptFactory.output();

                for (const factory of receiptFactory.getFactoryList()) {
                    sendMessageArray({
                        urlArray: printUrlArray,
                        request: factory.receiptData,
                        openDrawer: false,
                        successCallback: successCallback(),
                        errorCallback: function (error, attempt) {
                            console.error(error);
                            // PrintStatusService.addMessage({
                            //     printerType: PrintStatusService.PRINTER_TYPE.KITCHEN,
                            //     identifier: response.receiptId,
                            //     title: "Kitchen Receipt Printer Error",
                            //     description: kitchenSheetOrderIdentifier + " kitchen receipt cannot be printed.",
                            //     data: error.data
                            // });
                        }
                    });
                }
            });
        }

        function printKitchenSheetsV1Image (response, receiptItems, posPrinter, mobileOrder, buzzerName, separateItems, successCallback, orderName, textScaleFactor = 1) {
            // var builder = new StarWebPrintBuilder();
            // var request = '';
            let receiptFactory = new ReceiptFactoryWrapper();
            var printUrlArrayOriginal = buildPrinterObjArray(posPrinter, true);
            var printUrlArrayGroupedByWidth = _.groupBy(printUrlArrayOriginal, 'receiptSize');
            let screenPrint = CompanyAttributesService.isScreenPrintEnabled();

            var totalReceiptSequenceCount = 0;
            var currentReceiptSequence = 0;

            Object.keys(printUrlArrayGroupedByWidth).forEach(function (key) {
                // var receiptItemGroups;
                if (separateItems) {
                    totalReceiptSequenceCount += receiptItems.length;
                } else {
                    totalReceiptSequenceCount += 1;
                }
            });

            Object.keys(printUrlArrayGroupedByWidth).forEach(async function (key) {
                var printUrlArray = printUrlArrayGroupedByWidth[key];

                var builder = new StarWebPrintBuilder();

                var receiptItemGroups;
                if (separateItems) {
                    receiptItemGroups = _.map(receiptItems, function (receiptItem) {
                        return [receiptItem];
                    });
                } else {
                    receiptItemGroups = [receiptItems];
                }

                var receiptRequests = [];

                for (var receiptItemGroup of receiptItemGroups) {
                    currentReceiptSequence++;

                    var printerProtocol = printUrlArray[0].protocol;
                    var outputFormat = (printerProtocol && printerProtocol == 'usb') ? 'image/png' : 'image/jpeg';
                    receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.IMAGE_PRINTER, {
                        envConfig: EnvConfig,
                        printerDetails: printUrlArray[0],
                        type: 'receipt',
                        outputFormat: outputFormat
                    });
                    receiptFactory.initialize(textScaleFactor);

                    var buzzerCode = response.buzzerCode;
                    if (buzzerCode) {
                        buzzerName = buzzerCode.toString();
                    }

                    // Here is where we generate the order number
                    var receiptCounterString = generateReceiptCounterString(response.receiptCounter, response.heldOrder);
                    // Use the held order name if it is supplied
                    // First if condition checks for Not A "Pay at counter" Order name for kiosk orders
                    if (!(response.suspendId && response.guestTransaction === true
                        && response.receiptCounter)) {

                            if (orderName && receiptCounterString) {
                                // This printer doesn't accept a newline here (and there should be enough space for this)
                                receiptCounterString = orderName + ' - #' + padZero(response.receiptCounter, 3);
                            } else if (orderName) {
                                receiptCounterString = orderName;
                            }
                        }

                    if (receiptCounterString) {
                        receiptFactory
                            .align('center')
                            .title(receiptCounterString, true, {width: 3, height: 3, invert: true})
                            .newLine(1);
                    }

                    if (response.serviceMode) {
                        receiptFactory
                            .align('center')
                            .title(response.serviceMode)
                            .newLine(1);
                    }

                    if (response.patronName) {
                        var patronName = response.patronName;

                        if (mobileOrder && response.status === 'cancelled') {
                            patronName = 'ORDER CANCELLED: ' + patronName;
                        } else if (mobileOrder) {
                            patronName = 'Mobile Order: ' + patronName;
                        }

                        receiptFactory
                            .align('center')
                            .text(patronName)
                            .newLine(1);
                    }

                    var receiptSequenceTotalString = totalReceiptSequenceCount;
                    var receiptSequenceString = currentReceiptSequence + ' of ' + receiptSequenceTotalString;
                    receiptFactory
                        .align('center')
                        .title(receiptSequenceString, false, {width: 2, height: 2});

                    if (buzzerName) {
                        let buzzerLabel = translate('general.buzzer');
                        receiptFactory
                            .align('center')
                            .title((buzzerLabel + ': ' + buzzerName), true, {width: 2, height: 2});
                    }

                    var transDate = moment(response.transactionDateTime);
                    var transDateString = transDate.format('MM/DD/YYYY');
                    var transTimeString = transDate.format('hh:mm:ss A');
                    receiptFactory
                        .align('center')
                        .text('Order Placed: ' + transDateString + ' ' + transTimeString);

                    if (response.pickupTime) {
                        var pickupDate = moment(response.pickupTime);
                        var pickupDateString = pickupDate.format('MM/DD/YYYY');
                        var pickupTimeString = pickupDate.format('hh:mm:ss A');

                        receiptFactory
                            .align('center')
                            .text('Estimated Pickup:')
                            .title(pickupDateString + '' + pickupTimeString, true, {width: 2, height: 1});
                    }

                    receiptFactory.divider();

                    var receiptModifierPrintOptions = {
                        ignoreDiscount: true,
                        emphasis: true,
                        styles: {
                            width: 2,
                            height: 2,
                            textScale: ((textScaleFactor - 1) / 2) + 1, // looks resonable compared to the main item
                            linethrough: response.status === 'cancelled',
                        }
                    };

                    _.each(receiptItemGroup, function (item) {
                        if (item.noModifierNoKitchenPrint) {
                            return;
                        }
                        var itemQuantityString = item.quantity || 1;
                        itemQuantityString += 'x';
                        var itemName = (item.name || '');
                        var receiptItem = itemQuantityString + ' ' + itemName;

                        let styles = {
                            width: 2,
                            height: 2,
                            linethrough: response.status === 'cancelled',
                        };

                        receiptFactory
                            .align('left')
                            .title(receiptItem, true, styles);

                        var modifiers = item.children;

                        if (modifiers && modifiers.length > 0) {
                            _.each(modifiers, function (subItem) {
                                // Group
                                if (subItem.type === 'modifier_group') {
                                    _.each(subItem.children, function (subSubItem) {
                                        subSubItem._ignorePrice = true;
                                        printReceiptModifier(receiptFactory, subSubItem, receiptModifierPrintOptions, item);
                                    });
                                    // Individual Item
                                } else {
                                    subItem._ignorePrice = true;
                                    printReceiptModifier(receiptFactory, subItem, receiptModifierPrintOptions, item);
                                }
                            });
                        }

                        if (item.notes || item.note) {
                            receiptFactory
                                .align('left')
                                .text('Note: ' + (item.notes || item.note));
                        }
                    });

                    receiptFactory
                        .newLine(1);

                    // order level notes
                    if (response.notes || response.note) {
                        receiptFactory
                            .align('left')
                            .text('Order Notes: ' + (response.notes || response.note));
                    }

                    // for now, hack to make new line work with USB printer
                    receiptFactory
                        .newLine(1);

                    receiptFactory.cut();

                    await receiptFactory.output(screenPrint);
                    for (const factory of receiptFactory.getFactoryList()) {
                        receiptRequests.push(factory.receiptData);
                    }
                }

                _.each(receiptRequests, function (receiptRequest, index) {
                    receiptRequest += getCutElement(builder);

                    var config = {
                        urlArray: printUrlArray,
                        request: receiptRequest,
                        openDrawer: false,
                        successCallback: successCallback(),
                        errorCallback: function (error, attempt) {
                            console.error(error);
                        }
                    };
                    sendMessageArray(config);
                });
            });
        }

        function padZero (num, width) {
            var pad = '0';
            num = num + '';
            return (num.length >= width) ? num : new Array(width - num.length + 1).join(pad) + num;
        }

        async function printReceiptV2 (
            response,
            receipt,
            cardReceiptArr,
            skipCashDrawer = false,
            printType = PrintType.MERCHANT,
            isDuplicate = false
        ) {
            let receiptFactory = new ReceiptFactoryWrapper();
            var printTypesArr;
            if (printType == PrintType.ALL) {
                printTypesArr = [PrintType.MERCHANT, PrintType.CUSTOMER];
            } else {
                printTypesArr = [printType];
            }

            var company = CurrentSession.getCompany();

            var localizations = {
                'en': {
                    transactionRecord: 'TRANSACTION RECORD',
                    type: 'TYPE',
                    acct: 'ACCT',
                    amount: 'AMOUNT',
                    partiallyApproved: 'PARTIALLY APPROVED',
                    amountDue: 'AMOUNT DUE',
                    tip: 'TIP',
                    surcharge: 'SURCHARGE',
                    total: 'TOTAL',
                    accountBalance: 'ACCOUNT BALANCE',
                    cardNumber: 'CARD #',
                    dateTime: 'DATE/TIME',
                    referenceNum: 'Reference #',
                    authNum: 'AUTH #',
                    verifiedByPin: 'VERIFIED BY PIN',
                    chipCardSwiped: 'CHIP CARD SWIPED',
                    chipCardKeyed: 'CHIP CARD KEYED',
                    chipCardMalfunction: 'CHIP CARD MALFUNCTION',
                    transactionPartiallyApproved: 'TRANSACTION PARTIALLY APPROVED',
                    invoiceNumber: 'INVOICE #',
                    transactionCancelled: 'Transaction Cancelled',
                    n99approved: '99 Approved - Thank You XXX',
                    partialAuthorizationCancelled: 'Partial Authorization Cancelled',
                    transactionNotCompleted: 'Transaction Not Completed',
                    n99transactionNotApproved: '99 Transaction Not Approved XXX',
                    n99transactionNotCompleted: '99 Transaction Not Completed XXX',
                    cardHolderSignature: 'Cardholder Signature',
                    buyerAgrees: 'CARDHOLDER WILL PAY CARD ISSUER ABOVE AMOUNT PURSUANT TO CARDHOLDER AGREEMENT',
                    retainCopy: '*Important - retain this copy for your records*',
                    merchantCopy: 'MERCHANT COPY',
                    customerCopy: 'CUSTOMER COPY',

                    default: 'FLASH DEFAULT',
                    savings: 'SAVINGS',
                    chequing: 'CHEQUING',

                    purchase: 'PURCHASE',
                    refund: 'REFUND',
                    purchaseCorrection: 'PURCHASE CORRECTION',
                    refundCorrection: 'REFUND CORRECTION',
                    batchClose: 'BATCH CLOSE',
                    balanceInquiry: 'BALANCE INQUIRY',
                    recallMessage: 'RECALL MESSAGE',

                    declinedByCard: 'DECLINED BY CARD - 990',
                    cardRemoved: 'CARD REMOVED - 991',
                    noSignatureRequired: 'NO SIGNATURE REQUIRED',

                    void: 'VOID',
                    cardBalanceInquiry: 'CARD BALANCE INQUIRY',
                    settlement: 'SETTLEMENT',
                    cardEntryMethod: 'ENTRY METHOD',
                },
                'es': {},
                'fr': {
                    transactionRecord: 'RELEVE DE TRANSACTION',
                    type: 'TYPE',
                    acct: 'COMPTE',
                    amount: 'MONTANT',
                    partiallyApproved: 'AUTORISATION PARTIEL',
                    amountDue: 'MONTANT DU',
                    tip: 'POURBOIRE',
                    surcharge: 'FRAIS',
                    total: 'TOTAL',
                    accountBalance: 'SOLDE DU COMPTE',
                    cardNumber: 'N. DE CARTE',
                    dateTime: 'DATE/HEURE',
                    referenceNum: '# Reference',
                    authNum: '# AUTH',
                    verifiedByPin: 'VERIFIEE PAR NIP',
                    chipCardSwiped: 'CARTE A PUCE GLISSEE',
                    chipCardKeyed: 'CARTE A PUCE TAPEE',
                    chipCardMalfunction: 'DEFAILLANCE CARTE A PUCE',
                    transactionPartiallyApproved: 'AUTORISATION PARTIEL DE LA TRANSACTION',
                    invoiceNumber: 'NO. DE FACTURE',
                    transactionCancelled: 'Autorisation Partiel Annule',
                    n99approved: '99 Approuvee - MERCI XXX',
                    partialAuthorizationCancelled: 'Autorisation Partiel Annule',
                    transactionNotCompleted: 'Operation non Completee',
                    n99transactionNotApproved: '99 Operation Refusee XXX',
                    n99transactionNotCompleted: '99 Operation non Completee XXX',
                    cardHolderSignature: 'SIGNATURE',
                    buyerAgrees: 'LE TITULAIRE VERSERA CE MONTANT A L\'EMETTEUR CONFORMEMENT AU CONTRAT ADHERENT',
                    retainCopy: '*Important - conserver cette copie pour vos dossiers*',
                    merchantCopy: 'COPIE DU DETAILLANT',
                    customerCopy: 'COPIE DU CLIENT',

                    default: 'DEFAUT FLASH',
                    savings: 'EPARGNE',
                    chequing: 'CHEQUE',

                    purchase: 'ACHAT',
                    refund: 'REMISE D\'ACHAT',
                    purchaseCorrection: 'CORRECTION D\'ACHAT',
                    refundCorrection: 'CORRECTION DE REMISE',
                    batchClose: 'BATCH CLOSE',
                    balanceInquiry: 'INTERROGATION DE SOLDE',
                    recallMessage: 'RECALL MESSAGE',

                    declinedByCard: 'REFUSEE PAR LA CARTE - 990',
                    cardRemoved: 'CARTE RETIREE - 991',
                    noSignatureRequired: 'SIGNATURE NON REQUISE',

                    void: 'VENTE',
                    cardBalanceInquiry: 'CARD BALANCE INQUIRY',
                    settlement: 'SETTLEMENT',
                    cardEntryMethod: 'ENTRY METHOD',
                },
            };

            var printUrlArrayOriginal = buildPrinterObjArray(response.posPrinters, false);
            var printUrlArrayGroupedByWidth = _.groupBy(printUrlArrayOriginal, 'receiptSize');
            let screenPrint = CompanyAttributesService.isScreenPrintEnabled();

            var giftCardPurchaseMap = {};
            _.each(response.giftCardPurchases, function (giftCardPurchase) {
                giftCardPurchaseMap[giftCardPurchase.code] = giftCardPurchase;
            });

            var refundedTransactionItemMap = {};
            if (response.refundTransactions) {
                for (let refundTransaction of response.refundTransactions) {
                    for (var refundedTransactionItem of refundTransaction.items) {
                        var refundedQuantity = refundedTransactionItemMap[refundedTransactionItem.transactionItemId] || 0;
                        refundedQuantity += refundedTransactionItem.quantity;

                        refundedTransactionItemMap[refundedTransactionItem.transactionItemId] = refundedQuantity;
                    }
                }
            }

            var hasTransactionPercentDiscount = !!response.percentDiscount;

            for (var key in printUrlArrayGroupedByWidth) {
                var printUrlArray = printUrlArrayGroupedByWidth[key];
                var translation = localizations['en'];

                var receiptRequests = [];
                for (let i of Object.keys(printTypesArr)) {
                    var currentPrintType = printTypesArr[i];

                    var builder = new StarWebPrintBuilder();

                    var printerProtocol = printUrlArray[0].protocol;
                    var outputFormat = (printerProtocol && printerProtocol == 'usb') ? 'image/png' : 'image/jpeg';
                    receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.IMAGE_PRINTER, {
                        envConfig: EnvConfig,
                        printerDetails: printUrlArray[0],
                        type: 'receipt',
                        outputFormat: outputFormat
                    });
                    receiptFactory.initialize();

                    if (company && company.receiptLogoUrl) {
                        await receiptFactory.createImageElement(company.receiptLogoUrl);
                    }

                    // Print held order qr code
                    if (response.heldOrder && response.heldOrder.suspendId) {
                        await receiptFactory
                            .align('center')
                            .createQrCodeElement(`ho:1:${response.heldOrder.suspendId}`);

                        if (response.heldOrder.isPayAtCounter) {
                            receiptFactory
                                .align('center')
                                .text(translate('kiosk.payAtCounter.label'), true, {width: 2, height: 2});
                        }
                    }

                    receiptFactory
                        .align('center')
                        .title(response.locationName, true, {width: 1.5, height: 1.5});

                    if (response.location) {
                        if (response.location.street) {
                            receiptFactory.text(response.location.street);
                        }

                        var city = response.location.city;
                        var region = response.location.region;

                        var cityRegionArray = [city, region].filter(function (e) {
                            return e != null;
                        });
                        var cityRegion = cityRegionArray.join(', ');

                        if (cityRegion) {
                            receiptFactory.text(cityRegion);
                        }
                    }

                    receiptFactory.newLine(1);

                    if (isDuplicate) {
                        receiptFactory
                            .align('center')
                            .text('DUPLICATE', true, {width: 1, height: 1})
                            .newLine(1);
                    }

                    var receiptCounterString = generateReceiptCounterString(response.receiptCounter, response.heldOrder);
                    if (receiptCounterString) {
                        receiptFactory
                            .align('center')
                            .title(receiptCounterString, true, {width: 4, height: 4, invert: true})
                            .newLine(1);
                    }

                    if (response.serviceMode) {
                        receiptFactory
                            .align('center')
                            .title(response.serviceMode)
                            .newLine(1);
                    }

                    if (response.patronName) {
                        receiptFactory
                            .align('center')
                            .title(response.patronName)
                            .newLine(1);
                    }

                    var transactionType = response.transactionType || 'SALE';
                    var isVoidOrRefund = (transactionType === 'VOID' || transactionType === 'REFUND' || transactionType === 'REFUND_PARTIAL');
                    var transactionTypeString = transactionType;

                    if (isVoidOrRefund) {
                        switch (transactionType) {
                            case 'VOID':
                                transactionTypeString = 'VOID';
                                break;
                            case 'REFUND':
                                transactionTypeString = 'REFUND';
                                break;
                            case 'REFUND_PARTIAL':
                                transactionTypeString = 'PART. REFUND';
                                break;
                        }

                        // Somewhat temporary, this way the header is translated to non-english
                        // but "refund history" near the bottom will still be in english for now
                        if (transactionTypeString === 'REFUND') {
                            receiptFactory
                                .align('center')
                                .title(translate('receipt.return'), true, {width: 1, height: 1});
                        } else {
                            receiptFactory
                                .align('center')
                                .title(transactionTypeString, true, {width: 1, height: 1});
                        }

                        receiptFactory
                            .align('center')
                            .text('Authorized By: ' + response.userFullName)
                            .newLine(1);

                        if (response.refundTransactions && response.refundTransactions.length) {
                            var currentRefund = response.refundTransactions[response.refundTransactions.length - 1];

                            if (currentRefund) {
                                var refundAmount = currentRefund.totalCents - currentRefund.totalRoundingCents;
                                receiptFactory
                                    .align('center')
                                    .title(_currency(refundAmount / 100), true, {width: 2, height: 2})
                                    .newLine(1);
                            }
                        }

                    } else {
                        if (response.originalTransactionId) {
                            transactionTypeString = translate('receipt.exchange');
                        } else if (transactionTypeString === 'SALE') {
                            transactionTypeString = translate('receipt.sale');
                        }

                        receiptFactory
                            .align('center')
                            .title(transactionTypeString, true, {width: 1, height: 1});
                        receiptFactory
                            .align('center')
                            .title(_currency(response.transactionTotal + response.tipAmount), true, {width: 2, height: 2})
                            .newLine(1);
                    }

                    if (response.mealPlansRedeemed && response.mealEqAmount) {
                        receiptFactory
                            .align('center')
                            .text('Discount Type: REWARDS REDEMPTION')
                            .text('Discount Authorized By: Mobile App')
                            .newLine(1);

                    } else if (response.percentDiscount) {
                        if (response.labelledDiscounts && response.labelledDiscounts.length > 0) {
                            receiptFactory
                                .align('center')
                                .text('Discount Type: ' + response.labelledDiscounts[0].discountName);

                            if (response.labelledDiscounts[0].authorizedByUser) {
                                receiptFactory
                                    .align('center')
                                    .text('Discount Authorized By: ' + response.labelledDiscounts[0].authorizedByUser.firstname
                                        + ' ' + response.labelledDiscounts[0].authorizedByUser.lastname)
                                    .newLine(1);
                            } else {
                                receiptFactory
                                    .align('center')
                                    .text('Discount Authorized By: No PIN ')
                                    .newLine(1);
                            }

                        } else {
                            receiptFactory
                                .align('center')
                                .text('Discount Type: GENERAL')
                                .text('Discount Authorized By: GENERAL')
                                .newLine(1);
                        }
                    } else if (response.dollarDiscountAmount) {
                        receiptFactory
                            .align('left')
                            .text('Discount Type: GENERAL')
                            .text('Discount Authorized By: GENERAL')
                            .newLine(1);
                    }


                    // / Move code to the bottom ends here

                    receiptFactory
                        .textJustified([translate('receipt.item') + '   ', translate('receipt.price')], true)
                        .divider(true);

                    var decimalDisplayedPercentDiscountAmount = new Decimal(0);

                    var receiptHierarchy = generateReceiptHierarchy(receipt);

                    _.each(receiptHierarchy, function (item) {
                        if (item.printoutType && !(item.printoutType === PRINTOUT_TYPE.ALL || item.printoutType === PRINTOUT_TYPE.CUSTOMER_RECEIPT)) {
                            return;
                        }
                        if (item.locationServicePeriodMenuId === -99999) {
                            return; // buzzer
                        }

                        if (item.price < 0 && response.percentDiscount > 0) {
                            return; // skip trannsaction-wide discount
                        }

                        var leftString = item.quantity || 1;
                        leftString += '   ';
                        leftString += item.name;

                        var rightString = _currency($filter('itemDisplayPrice')(item));
                        var hasLoyaltyDiscount = _.some(item.children, {subtype: 'loyalty'});
                        if (hasLoyaltyDiscount) {
                            leftString = '*' + leftString;
                        }

                        receiptFactory
                            .align()
                            .textJustified([leftString, rightString], true);

                        if (item.subtype === 'giftcard.create' || item.subtype === 'giftcard.reload') {
                            var giftCardPurchase = giftCardPurchaseMap[item.upc];

                            if (giftCardPurchase) {
                                var balanceDollar = giftCardPurchase.currentBalanceCents / 100.0;
                                receiptFactory
                                    .text('    - Remaining:' + _currency(balanceDollar));
                            }
                        }

                        var receiptModifierPrintOptions = {
                            ignoreDiscount: hasTransactionPercentDiscount,
                            styles: {
                                width: 1,
                                height: 1
                            },
                            useLoyalty: hasLoyaltyDiscount
                        };

                        var modifiers = _groupDiscountModifiers(item.children);

                        if (modifiers && modifiers.length > 0) {
                            _.each(modifiers, function (subItem) {
                                if (subItem.printoutType && !(subItem.printoutType === PRINTOUT_TYPE.ALL ||
                                    subItem.printoutType === PRINTOUT_TYPE.CUSTOMER_RECEIPT)) {
                                    return;
                                }
                                if (subItem.type === 'modifier_group') {
                                    _.each(subItem.children, function (subSubItem) {
                                        printReceiptModifier(
                                            receiptFactory,
                                            subSubItem,
                                            receiptModifierPrintOptions,
                                            item
                                        );
                                    });
                                    // Individual Item
                                } else {
                                    printReceiptModifier(
                                        receiptFactory,
                                        subItem,
                                        receiptModifierPrintOptions,
                                        item
                                    );
                                }

                                if (subItem.subtype === 'discount' || subItem.subtype === 'loyalty') {
                                    decimalDisplayedPercentDiscountAmount = decimalDisplayedPercentDiscountAmount
                                        .plus(new Decimal(subItem.price));
                                }
                            });
                        }

                        if (item.notes) {
                            var noteString = 'Note: ' + item.notes;
                            receiptFactory.title(noteString, false, {width: 2, height: 2});
                        }

                        var itemRefundQuantity = refundedTransactionItemMap[item.receiptItemId];
                        if (itemRefundQuantity > 0) {
                            var refundVerb = '';
                            switch (transactionType) {
                                case 'VOID':
                                    refundVerb = 'voided';
                                    break;
                                case 'REFUND':
                                case 'REFUND_PARTIAL':
                                    refundVerb = (response.exchanged) ? 'exchanged' : 'refunded';
                                    break;
                            }

                            if (!refundVerb) {
                                // shouldn't happen, but adding this to be safe
                                return;
                            }

                            var refundActionString = (itemRefundQuantity == 1) ? ' has been ' : ' have been ';
                            refundActionString += refundVerb;
                            var refundString = '    *' + itemRefundQuantity + refundActionString;
                            receiptFactory.text(refundString);
                        }
                    });

                    if (response.percentDiscount) {
                        if (response.mealPlansRedeemed && response.mealEqAmount) {
                            var loyaltyDiscountDescription = (response.percentDiscount * 100).toFixed(0) + '%';
                            receiptFactory
                                .newLine(1)
                                .align()
                                .titleJustified(['Rewards Applied: ', loyaltyDiscountDescription])
                                .text('*on eligible items');
                        } else {
                            var percentageString = response.percentDiscount * 100 + '%';
                            var discountString = '';
                            if (response.labelledDiscounts && response.labelledDiscounts.length > 0) {
                                discountString = response.labelledDiscounts[0].discountName + ' (' + percentageString + '): ';
                            } else {
                                discountString = 'Discount (' + percentageString + '): ';
                            }

                            // Basket-level discount is applied pre-tax, so the amount discounted (and recorded in the db) is also
                            // the pre-tax amount. In order to display amount discounted in VAT-included countries, we have to dig
                            // the tax information from the receipt list. This ensures correct tax amount even with items with
                            // mixed tax rates
                            var displayedPercentDiscountAmount = (SharedDataService.taxIncludedInPrice)
                                ? decimalDisplayedPercentDiscountAmount.toNumber() // this is already recorded as negative cash flow
                                : -response.percentDiscountAmount; // this needs to be negated because it is recorded as an absolute amount

                            receiptFactory
                                .align()
                                .textJustified([discountString, _currency(displayedPercentDiscountAmount)]);
                        }
                    }

                    receiptFactory.divider(true);

                    if (response.dollarDiscountAmount) {
                        receiptFactory
                            .align()
                            .textJustified(
                                ['Discount: ', _currency(-response.dollarDiscountAmount)],
                                false,
                                {},
                                {fontWeight: '700'}
                            );
                    }

                    receiptFactory
                        .align()
                        .textJustified(
                            [translate('receipt.subtotal') + ': ', _currency(response.transactionSubTotal)],
                            false,
                            {},
                            {fontWeight: '700'}
                        );

                    // Temperary - add gst and qst tax amount when quebec
                    if (CurrentSession.getCompany() && CurrentSession.getCompany().operatingAddress.region == 'QC') {
                        let gstAmount = 0;
                        let qstAmount = 0;
                        let tpsNum = 'NA';
                        let tvqNum = 'NA';

                        if (response.transactionTax > 0) {
                            let totalTax = new Decimal(response.transactionTax);
                            let gstPercent = new Decimal(5);
                            let quebecTax = new Decimal(14.975);

                            gstAmount = totalTax.times(gstPercent.dividedBy(quebecTax)).toDecimalPlaces(2).toNumber();
                            qstAmount = totalTax.minus(gstAmount).toDecimalPlaces(2).toNumber();
                        }

                        const taxRegisDetails = CompanyAttributesService.getReceiptTaxRegistrationDetails();

                        if (taxRegisDetails) {
                            taxRegisDetails.split(/\s/).forEach(function (taxNum) {
                                let details = taxNum.split(/#/);

                                switch (details[0]) {
                                    case 'TPS':
                                        tpsNum = details[1];
                                        break;
                                    case 'TVQ':
                                        tvqNum = details[1];
                                        break;
                                    default:
                                }
                            });
                        }

                        receiptFactory
                            .align()
                            .textJustified(
                                [translate('receipt.gst') + ` (${tpsNum}): `, _currency(gstAmount)],
                                false,
                                {},
                                {fontWeight: '700'}
                            )
                            .textJustified(
                                [translate('receipt.pst') + ` (${tvqNum}): `, _currency(qstAmount)],
                                false,
                                {},
                                {fontWeight: '700'}
                            );
                    }

                    receiptFactory.textJustified(
                            [translate('receipt.tax') + ': ', _currency(response.transactionTax)],
                            false,
                            {},
                            {fontWeight: '700'}
                        );

                    if (response.tipAmount > 0) {
                        receiptFactory
                            .textJustified(
                                ['Tip: ', _currency(response.tipAmount)],
                                false,
                                {},
                                {fontWeight: '700'}
                            );
                    }
                    const numOfItems = _.filter(receipt, {level: 0}).length;
                    if (numOfItems <= 1) {
                        receiptFactory
                        .textJustified(
                            [translate('receipt.total') + ': (' + numOfItems + ' ' + translate('receipt.item') + ')', _currency(response.transactionTotal + response.tipAmount)],
                            false,
                            {},
                            {fontWeight: '700'}
                        );

                    } else {
                        receiptFactory
                        .textJustified(
                            [translate('receipt.total') + ': (' + numOfItems + ' ' + translate('receipt.items') + ')', _currency(response.transactionTotal + response.tipAmount)],
                            false,
                            {},
                            {fontWeight: '700'}
                        );
                    }

                    receiptFactory
                        .divider(true);

                    if (!isDuplicate && response.remainingBalance < 0) {
                        receiptFactory
                            .textJustified(
                                [translate('receipt.cashReceived') + ': ', _currency(response.cashAmount + response.remainingBalance)],
                                false,
                                {},
                                {fontWeight: '700'}
                            )
                            .textJustified(
                                [translate('receipt.credit') + ': ', _currency(response.creditCardAmount + response.tipAmount)],
                                false,
                                {},
                                {fontWeight: '700'}
                            );
                        } else {
                            receiptFactory
                            .textJustified(
                                [translate('receipt.cashReceived') + ': ', _currency(response.cashAmount)],
                                false,
                                {},
                                {fontWeight: '700'}
                            )
                            .textJustified(
                                [translate('receipt.credit') + ': ', _currency(response.creditCardAmount + response.tipAmount)],
                                false,
                                {},
                                {fontWeight: '700'}
                            );
                        }

                    if (response.otherAmount || response.fiitMealPlanCount || response.fiitDcbAmount) {
                        for (let tender of response.tenders) {
                            if (tender.transactionType != 'OTHER') {
                                continue;
                            }

                            if (tender.fiitTenders && tender.fiitTenders.length) {
                                for (var fiitTender of tender.fiitTenders) {
                                    var fiitLeftString = fiitTender.fiitMealPlanName || '';

                                    if (fiitTender.fiitMealPlanType === 'DCB') {
                                        fiitLeftString += ' (DCB) ';
                                    }

                                    var fiitRightString = fiitTender.unitsUsed;
                                    if (fiitTender.fiitMealPlanType === 'MEAL') {
                                        fiitRightString += (fiitTender.unitsUsed > 1) ? ' Meal Units' : ' Meal Unit';
                                        fiitRightString = '(' + fiitRightString + ') ';
                                        fiitRightString += _currency(fiitTender.equivalentDollarValue);
                                    } else {
                                        fiitRightString = _currency(fiitRightString);
                                    }

                                    receiptFactory
                                        .align()
                                        .textJustifiedAndWrapped([fiitLeftString, fiitRightString]);
                                }
                            } else {
                                let tenderDetail = tender.transactionTenderDetail || {};
                                let otherTenderType = tenderDetail.otherType;
                                let otherTenderTypeDisplayName = otherTenderType || 'Other Amount Received';
                                let mealUnitsDisplayName = 'Meal Units: ';

                                switch (otherTenderType) {
                                    case 'alphapay':
                                        otherTenderTypeDisplayName = 'WeChat Pay/Alipay';
                                        break;
                                    case 'fiitmps':
                                        otherTenderTypeDisplayName = 'DCB';
                                        break;
                                }
                                otherTenderTypeDisplayName = otherTenderTypeDisplayName + ': ';


                                if (tender.amountCents > 0) {
                                    receiptFactory
                                        .textJustified([otherTenderTypeDisplayName, _currency(tender.amountCents / 100)],
                                            false,
                                            {},
                                            {fontWeight: '700'});
                                }

                                if (tender.amountMeals > 0) {
                                    receiptFactory
                                        .textJustified([mealUnitsDisplayName, tender.amountMeals],
                                            false,
                                            {},
                                            {fontWeight: '700'});
                                }
                            }
                        }
                    }

                    _.each(response.tenders, function (tender) {
                        if (tender.transactionType === 'GIFTCARD') {
                            var giftCardId = (tender.giftCard) ? tender.giftCard.id : undefined;
                            var giftCard = _.findWhere(response.giftCardUsage, {id: giftCardId});
                            if (giftCard) {
                                if (giftCard.source === GIFTCARD_SOURCE.SOURCE_TRANSACTION || giftCard.source === GIFTCARD_SOURCE.SOURCE_API) {
                                    let giftCardDescription = 'Gift Card (' + $filter('giftCardMask')(giftCard.code) + '): ';
                                    let tenderAmount = (tender.amountCents + tender.tipAmountCents) / 100.0;
                                    let giftCardBalance = giftCard.currentBalanceCents / 100.0;

                                    receiptFactory
                                        .textJustified([giftCardDescription, _currency(tenderAmount)]);

                                    if (!isVoidOrRefund) {
                                        // for void and refund, the remaining balance is displayed in the
                                        // VOID/REFUND history below
                                        receiptFactory
                                            .text('   - Remaining: ' + _currency(giftCardBalance));
                                    }
                                } else {
                                    let storeCreditDescription = 'Store Credit Used: ';
                                    let tenderAmount = tender.amountCents / 100.0;

                                    receiptFactory
                                        .textJustified([storeCreditDescription, _currency(tenderAmount)]);
                                }
                            }
                        }
                    });

                    if (response.cashRounding) {
                        receiptFactory
                            .textJustified(
                                ['Cash Rounding: ', _currency(response.cashRounding)],
                                false,
                                {},
                                {fontWeight: '700'}
                            );
                    }

                    if (transactionType === 'SALE') {
                        if (response.remainingBalance > 0) {
                            receiptFactory
                                .textJustified(
                                    ['Remaining Balance: ', _currency(response.remainingBalance)],
                                    false,
                                    {},
                                    {fontWeight: '700'}
                                );
                        } else {
                            if (PosStatusService.isOffline()) {
                                receiptFactory
                                    .textJustified(
                                        [translate('receipt.change') + ': ', _currency(response.change)],
                                        false,
                                        {},
                                        {fontWeight: '700'}
                                    );
                            } else {
                                receiptFactory
                                    .textJustified(
                                        [translate('receipt.change') + ': ', _currency(-response.change)],
                                        false,
                                        {},
                                        {fontWeight: '700'}
                                    );
                            }
                        }
                    }

                    if (response.refundTransactions && response.refundTransactions.length > 0) {
                        receiptFactory
                            .align('center')
                            .newLine(1)
                            .title('--- ' + transactionTypeString + ' History ---')
                            .newLine(1);

                        for (let refundTransaction of response.refundTransactions) {
                            receiptFactory
                                .align('left')
                                .text(moment(refundTransaction.eventTime).format('YYYY-MM-DD HH:mm:ss'));

                            for (var refundTender of refundTransaction.tenders) {
                                var refundTenderType = refundTender.transactionType;
                                if (refundTenderType === 'MEAL') {
                                    receiptFactory
                                        .textJustified(['LOYALTY', refundTender.amountMeals + ' PTS']);
                                } else {
                                    var isGiftCardTender = refundTender.transactionType === 'GIFTCARD' && refundTender.giftCard;
                                    if (isGiftCardTender) {
                                        var refundTenderDescription;
                                        if (refundTender.giftCard.source == 0) {
                                            refundTenderDescription = 'Gift Card (' + $filter('giftCardMask')(refundTender.giftCard.code) + '): ';
                                            receiptFactory
                                                .textJustified([refundTenderDescription, _currency(refundTender.amountCents / 100.0)]);
                                            receiptFactory
                                                .text('   - Remaining: ' + _currency(refundTender.giftCard.currentBalanceCents / 100.0));
                                        } else {
                                            refundTenderDescription = 'Store Credit';
                                            receiptFactory
                                                .textJustified([refundTenderDescription, _currency(refundTender.amountCents / 100.0)]);
                                        }
                                    } else {
                                        receiptFactory
                                            .textJustified([refundTender.transactionType, _currency(refundTender.amountCents / 100.0)]);
                                    }
                                }
                            }

                            receiptFactory.newLine(1);
                        }
                    }

                    receiptFactory
                        .newLine(1);

                    receiptFactory
                        .align()
                        .textJustified(['', ''], false, {}, {}, '-'); // a line of asterisks

                    if (CurrentSession.getCompany().hasLoyalty && response.hasLoyalty) {
                        var rewardSummaryTitle = 'Rewards Summary';
                        if (response.patronName) {
                            rewardSummaryTitle = response.patronName + ' ' + rewardSummaryTitle;
                        }

                        var loyaltyEarned = (response.loyaltyAmount || 0);
                        var rewardCollectedDescription = loyaltyEarned + ' PTS';
                        var rewardRedemptionDescription = response.mealPlansRedeemed + ' PTS';
                        var rewardDiscountDescription = ((response.mealPlansRedeemed && response.percentDiscount) ?
                            '(' + (response.percentDiscount * 100).toFixed(0) + '%)' : '');
                        var rewardBalanceDescription = (response.currentLoyaltyPoints || 0).toFixed(0) + ' PTS';

                        receiptFactory
                            .align('center')
                            .text(rewardSummaryTitle)
                            .align('left')
                            .textJustified(['', ''], false, {}, {}, '-') // a line of asterisks
                            .textJustified(['Collected: ', rewardCollectedDescription])
                            .textJustified(['Redeemed : ', rewardRedemptionDescription + rewardDiscountDescription])
                            .textJustified(['Balance : ', rewardBalanceDescription]);
                    } else if (CurrentSession.getCompany().hasLoyalty) {
                        // No loyalty
                        var appName = CurrentSession.getOrganizationAppName();
                        var guestLoyaltyReminder = translate('receipt.download.app', {appName: appName});

                        // temporary pitaland contest info.
                        // should be removed after contest is over.
                        if (company && CompanyAttributesService.getCustomReceiptText()) {
                            // inserting new lines dynamically based on \n
                            var textSplit = CompanyAttributesService.getCustomReceiptText().split('\n');
                            _.each(textSplit, (line) => {
                                receiptFactory
                                    .align('left')
                                    .text(line);
                            });
                        }
                        receiptFactory
                            .align('left')
                            .text(guestLoyaltyReminder);
                    }

                    receiptFactory
                        .newLine(1);

                    var transactionDateTime = moment(response.transactionDateTime);
                    var transactionDateTimeString = transactionDateTime.format('YYYY-MM-DD hh:mm:ss A');

                    if (response.receiptId) {
                        receiptFactory
                            .align('center')
                            .text('Receipt: #' + response.receiptId);
                    } else {
                        if (response.heldOrder) {
                            receiptFactory
                                .align('center')
                                .text('Bill');
                        }
                    }

                    receiptFactory
                        .align('center')
                        .text('Date: ' + transactionDateTimeString);

                    if (response.cashierName) {
                        receiptFactory
                            .align('center')
                            .text('Sold By: ' + response.cashierName);
                    }

                    for (let i of Object.keys(cardReceiptArr)) {
                        var cardReceipt = cardReceiptArr[i];

                        if (currentPrintType == PrintType.CUSTOMER) {
                            translation = localizations[cardReceipt.customerLanguage || 'en'];
                        } else {
                            translation = localizations['en'];
                        }

                        if (!CompanyAttributesService.hasPrintCardTerminalResponse()) {
                            receiptFactory.newLine(2);

                            receiptFactory
                                .newLine(2)
                                .align('center')
                                .text(translation.transactionRecord)
                                .newLine(1);

                            receiptFactory
                                .align('left');

                            var isCreditCard = cardReceipt.isCredit;
                            // var isTerminalDemoMode = cardReceipt.demoMode; // TODO

                            var transDate = new Date(response.transactionDateTime);
                            transactionDateTime = transDate.toLocaleString() + '\n';

                            if (cardReceipt.paymentProcessor === 'moneris') {
                                // currently only Moneris returns this information from
                                // the terminal.  For globalpayments, we need an
                                // alternate source to populate merchantname + address
                                receiptFactory
                                    .text(cardReceipt.merchantName)
                                    .text(cardReceipt.addressLine1)
                                    .text(cardReceipt.addressLine2);
                            }

                            if (cardReceipt.paymentProcessor === 'globalpayments' && cardReceipt.demoMode) {
                                receiptFactory
                                    .align('center')
                                    .text('DEMO MODE')
                                    .newLine(1);
                            }

                            receiptFactory
                                .align('left')
                                .text('TYPE: ' + (translation[cardReceipt.transactionType] || cardReceipt.transactionType))
                                .newLine(1);
                            // VISA, MASTERCARD, AMERICAN EXPRESS, INTERAC

                            // if (cardReceipt.paymentProcessor === 'globalpayments' && cardReceipt.terminalId) {
                            // receiptFactory.text('TID: ' + cardReceipt.terminalId);
                            // }
                            if (cardReceipt.paymentProcessor === 'globalpayments') {
                                if (cardReceipt.transactionSequenceNum) {
                                    receiptFactory.text('SEQ: ' + cardReceipt.transactionSequenceNum);
                                }

                                if (currentPrintType == PrintType.MERCHANT && cardReceipt.merchantId) {
                                    receiptFactory.text('MID: ' + cardReceipt.merchantId);
                                }
                            }

                            if (cardReceipt.isDebit) {
                                if (cardReceipt.debitAccountType === 'default') {
                                    receiptFactory.text(translation.acct + ': ' + translation[cardReceipt.debitAccountType]);
                                } else {
                                    receiptFactory.text(translation.acct + ': ' + cardReceipt.cardName + ' ' + (translation[cardReceipt.debitAccountType] || ''));
                                }
                            } else {
                                receiptFactory.text(translation.acct + ': ' + cardReceipt.cardName);
                            }
                            receiptFactory.text(translation.amount + ': ' + cardReceipt.transactionAmount);

                            // only need to show these is a partial transaction occurred.
                            if (cardReceipt.partiallyApproved) {
                                receiptFactory.text(translation.partiallyApproved + ': ' + cardReceipt.partiallyApproved);
                                receiptFactory.text(translation.amountDue + ': ' + cardReceipt.balanceDue);
                            }

                            if (cardReceipt.tipAmount && cardReceipt.tipAmount > 0) {
                                receiptFactory.text(translation.tip + ': ' + cardReceipt.tipAmount);
                            }

                            if (cardReceipt.isDebit && cardReceipt.transactionTypeCode === '00' && cardReceipt.surchargeAmount) {
                                receiptFactory.text(translation.surcharge + ': ' + cardReceipt.surchargeAmount);
                            }
                            receiptFactory.text(translation.total + ': ' + cardReceipt.totalAmount);

                            if (currentPrintType == PrintType.CUSTOMER &&
                                !cardReceipt.isDebit &&
                                ['00', '01', '60'].includes(cardReceipt.transactionTypeCode) &&
                                cardReceipt.cardBalance) {
                                receiptFactory.text(translation.accountBalance + ': ' + cardReceipt.cardBalance);
                            }

                            // TODO
                            // request += builder.createTextElement({ data: "DCC: " + dcc});

                            if (cardReceipt.cardholderName) {
                                receiptFactory.text('Cardholder Name' + ': ' + cardReceipt.cardholderName);
                            }

                            receiptFactory.text(translation.cardNumber + ': ' + cardReceipt.cardNumber);
                            receiptFactory.text(translation.dateTime + ': ' + cardReceipt.transactionDateTime);

                            if (cardReceipt.paymentProcessor === 'globalpayments' || cardReceipt.paymentProcessor === 'heartland') {
                                receiptFactory.text(translation.cardEntryMethod + ': ' + cardReceipt.cardEntryMethod);
                            } else if (cardReceipt.referenceNumber) {
                                var referenceNum = '';
                                referenceNum += cardReceipt.referenceNumber + ' ';

                                if (cardReceipt.cardEntryMethod) {
                                    referenceNum += cardReceipt.cardEntryMethod;
                                }
                                receiptFactory.text(translation.referenceNum + ': ' + referenceNum);
                            }

                            if (cardReceipt.success && cardReceipt.authorizationNumber) {
                                receiptFactory.text(translation.authNum + ': ' + cardReceipt.authorizationNumber);
                            }

                            if (cardReceipt.emvAppPreferredName) {
                                receiptFactory.text(cardReceipt.emvAppPreferredName);
                            } else if (cardReceipt.emvAppLabel) {
                                receiptFactory.text(cardReceipt.emvAppLabel);
                            } else if (cardReceipt.emvAid) {
                                // need to show something if EMV transaction
                                receiptFactory.text(cardReceipt.cardName);
                            }

                            if (cardReceipt.emvAid) {
                                receiptFactory.text(cardReceipt.emvAid);
                            }

                            var emvTvr = '';
                            if (cardReceipt.emvTvr) {
                                emvTvr = cardReceipt.emvTvr;
                            }
                            var emvTsi = '';
                            if (cardReceipt.emvTsi) {
                                emvTsi = cardReceipt.emvTsi;
                            }
                            receiptFactory.text(emvTvr + ' ' + emvTsi);

                            if (cardReceipt.emvCryptogramType && cardReceipt.emvCryptogram) {
                                receiptFactory.text(cardReceipt.emvCryptogramType + ' ' + cardReceipt.emvCryptogram);
                            }

                            // TODO transactionStatus 11 and 19 are applicable only to Moneris, NOT GP
                            if (cardReceipt.emvAid && cardReceipt.transactionStatus === '11') {
                                receiptFactory.text(translation.declinedByCard);
                            }
                            if (cardReceipt.emvAid && cardReceipt.transactionStatus === '19') {
                                receiptFactory.text(translation.cardRemoved);
                            }

                            if (cardReceipt.verifiedByPin && currentPrintType == PrintType.MERCHANT) {
                                receiptFactory.text(translation.verifiedByPin);
                            }

                            if (cardReceipt.cardEntryMethod === 'F') {
                                // F means EMV with fallback swipe
                                receiptFactory.text(translation.chipCardSwiped);
                            } else if (cardReceipt.emvAid && cardReceipt.cardEntryMethod === 'G') {
                                // G means swipe, so have have to verify it is EMV as well
                                receiptFactory.text(translation.chipCardKeyed);
                            }

                            if (cardReceipt.approvedWithMalfunc
                                && cardReceipt.cardName == 'MASTERCARD'
                                && currentPrintType == PrintType.MERCHANT) {
                                receiptFactory.text(translation.chipCardMalfunction);
                            }

                            if (cardReceipt.partiallyApproved) {
                                receiptFactory.text(translation.transactionPartiallyApproved);
                            }

                            if (cardReceipt.invoiceNumber) {
                                receiptFactory.text(translation.invoiceNumber + ': ' + cardReceipt.invoiceNumber);
                            }

                            var isoCode = '';
                            if (cardReceipt.hostResponseIsoCode && !cardReceipt.isReversal) {
                                isoCode = cardReceipt.hostResponseIsoCode;
                            }

                            var hostResponseCode = '';
                            if (cardReceipt.hostResponseCode && !cardReceipt.isReversal) {
                                hostResponseCode = cardReceipt.hostResponseCode;
                            }

                            var statusLine = '';
                            receiptFactory
                                .newLine(1)
                                .align('center');

                            /* 12 - Communication Error
                               13 - Cancelled by User
                               14 - Timed out on User Input
                               15 - Transaction Not Completed
                               16 - Transaction Not Completed, Card Removed
                               17 - Chip failure occurs during communication with the card
                               23 - Partial approval is cancelled by the merchant or the customer
                               30 - Transaction not Supported
                               31 - Transaction not Allowed
                               32 - Invalid ECR/PC Parameter
                               95 - Terminal is not in the Idle state or it is in Stand-Alone mode. Terminal Can't accept a new request. */

                            if (cardReceipt.success) {
                                statusLine = translation.n99approved;
                            } else {
                                if (cardReceipt.cancelledByUser) {
                                    statusLine = translation.transactionCancelled;
                                } else if (cardReceipt.partialApprovalCancelled) {
                                    statusLine = translation.partialAuthorizationCancelled;
                                } else if (cardReceipt.declinedByCardOnline) {
                                    statusLine = translation.transactionNotCompleted;
                                } else if (cardReceipt.cardRemoved) {
                                    statusLine = translation.transactionNotCompleted;
                                } else if (cardReceipt.terminalTimeout) {
                                    statusLine = translation.transactionNotCompleted;
                                } else if (cardReceipt.reversal) {
                                    statusLine = translation.transactionNotCompleted;
                                } else if (cardReceipt.isDebit) {
                                    if (['05', '51', '54', '55', '57', '58', '61', '62', '65', '75', '85', '92'].includes(cardReceipt.hostResponseIsoCode)) {
                                        statusLine = translation.n99transactionNotApproved;
                                    } else {
                                        statusLine = translation.n99transactionNotCompleted;
                                    }
                                } else if (
                                    ['060', '068', '069', '074', '075', '078', '087', '088', '097', '100',
                                        '101', '102', '104', '106', '108', '113', '115', '206', '212', '800',
                                        '801', '802', '810', '811', '821', '898'].includes(cardReceipt.hostResponseCode)
                                ) {
                                    statusLine = translation.n99transactionNotCompleted;
                                } else {
                                    statusLine = translation.n99transactionNotApproved;
                                }
                            }
                            statusLine = statusLine.replace(/XXX/, hostResponseCode);
                            statusLine = statusLine.replace(/99/, isoCode);

                            if (cardReceipt.statusLine) {
                                statusLine = cardReceipt.statusLine;
                            }

                            receiptFactory
                                .text(statusLine)
                                .newLine(1);

                            if (cardReceipt.formFactor) {
                                receiptFactory
                                    .align('left')
                                    .text(cardReceipt.formFactor);
                            }

                            if (
                                cardReceipt.success
                                && isCreditCard
                                && (cardReceipt.showCustomerSignatureLine || cardReceipt.showMerchantSignatureLine)
                                && currentPrintType == PrintType.MERCHANT
                                // && (cardReceipt.transactionTypeCode == '00' ||
                                // cardReceipt.transactionTypeCode == '03')
                            ) {
                                if (printUrlArray[0].receiptSize === 3) {
                                    receiptFactory
                                        .align('left')
                                        .text('X________________________________');
                                } else {
                                    receiptFactory
                                        .align('left')
                                        .text('X___________________________');
                                }
                                receiptFactory
                                    .align('left')
                                    .text(translation.cardHolderSignature)
                                    .newLine(1);

                                if (cardReceipt.transactionTypeCode == '00') {
                                    receiptFactory
                                        .text(translation.buyerAgrees)
                                        .newLine(1);
                                }
                            }

                            if (isCreditCard) {
                                receiptFactory
                                    .align('center')
                                    .text(translation.retainCopy)
                                    .newLine(1);
                            }

                            if (isCreditCard && ['H', 'T'].includes(cardReceipt.cardEntryMethod) && !cardReceipt.showCustomerSignatureLine) {
                                receiptFactory
                                    .align('center')
                                    .text(translation.noSignatureRequired)
                                    .newLine(1);
                            }

                        }

                        receiptFactory.newLine(1);

                        // print custom copies
                        if (CompanyAttributesService.hasPrintCardTerminalResponse()
                            && currentPrintType == PrintType.CUSTOMER
                            && cardReceipt.customerCopy.length) {
                                for (const el of cardReceipt.customerCopy) {
                                    let align = el.format[0];

                                    if (align === 'center') {
                                        receiptFactory.align('center').text(el.data[0] + el.data[1]);
                                        continue;
                                    }

                                    receiptFactory.textJustified(el.data);
                                }

                            receiptFactory.newLine(2);
                        }

                        if (CompanyAttributesService.hasPrintCardTerminalResponse()
                            && currentPrintType == PrintType.MERCHANT
                            && cardReceipt.merchantCopy.length) {
                                for (const el of cardReceipt.merchantCopy) {
                                    let align = el.format[0];

                                    if (align === 'center') {
                                        receiptFactory.align('center').text(el.data[0] + el.data[1]);
                                        continue;
                                    }

                                    receiptFactory.textJustified(el.data);
                                }

                            receiptFactory.newLine(2);
                        }
                    }

                    // ask for signature for non credit card refunds and voids
                    if (cardReceiptArr &&
                        cardReceiptArr.length == 0 &&
                        currentPrintType == PrintType.MERCHANT &&
                        (transactionType == 'VOID' || transactionType == 'REFUND' || transactionType == 'REFUND_PARTIAL')) {
                        if (printUrlArray[0].receiptSize === 3) {
                            receiptFactory
                                .text('X________________________________');
                        } else {
                            receiptFactory
                                .text('X___________________________');
                        }
                        receiptFactory
                            .text('Customer Signature');
                    }

                    if (CompanyAttributesService.hasQRCodePrint() && response.transactionUuid) {
                        const appUrl = CurrentSession.getOrganization().mobileAppUrl;
                        const qrCode = appUrl + '#' + response.transactionUuid;
                        receiptFactory.align('center');
                        await receiptFactory.createQrCodeElement(qrCode);
                    }

                    if (company && CompanyAttributesService.getReceiptFooter()) {
                        receiptFactory
                            .title(CompanyAttributesService.getReceiptFooter(), true, {width: 0.75, keepNewLine: true});
                    }

                    if (company && CompanyAttributesService.getReceiptTaxRegistrationDetails()) {
                        receiptFactory
                            .title(CompanyAttributesService.getReceiptTaxRegistrationDetails(), false, {width: 0.75});
                    }

                    if (currentPrintType == PrintType.MERCHANT) {
                        receiptFactory
                            .align('center')
                            .text(translation.merchantCopy)
                            .newLine(1);
                    } else if (currentPrintType == PrintType.CUSTOMER) {
                        if (!(response.heldOrder && response.heldOrder.isPayAtCounter)) {
                            receiptFactory
                                .align('center')
                                .newLine(1)
                                .text(translate('receipt.customerCopy'))
                                .newLine(1);
                        }
                    }

                    await receiptFactory.output(screenPrint);
                    for (const factory of receiptFactory.getFactoryList()) {
                        receiptRequests.push(factory.receiptData);
                    }

                    if (currentPrintType == PrintType.CUSTOMER) {
                        for (var giftCardPurchaseKey in giftCardPurchaseMap) {
                            var item = giftCardPurchaseMap[giftCardPurchaseKey];

                            var giftCardPurchaseRequest = await buildGiftCardRequestImage(printUrlArray[0], item, outputFormat);
                            if (giftCardPurchaseRequest.length) {
                                Array.prototype.push.apply(receiptRequests, giftCardPurchaseRequest);
                            }
                        }
                    }
                }

                var isCashTransaction = response.cashAmount && response.cashAmount > 0;
                var isCreditCardTransaction = response.creditCardAmount && response.creditCardAmount > 0;
                var restrictOpenDrawerToCashTransaction = company.attributes.receipt__restrict_open_cash_drawer === 'true';

                var openDrawer = (!skipCashDrawer);

                var openDrawerForTransaction = (restrictOpenDrawerToCashTransaction)
                    ? isCashTransaction
                    : isCashTransaction || isCreditCardTransaction;

                openDrawer = openDrawer && openDrawerForTransaction;

                var successCallback = function (data, attempt) { };
                var errorCallback = function (error, attempt) {
                    console.error(error);
                };

                if (openDrawer) {
                    openCashDrawer(printUrlArray, false, false, successCallback, errorCallback);
                }

                _.each(receiptRequests, function (receiptRequest, index) {
                    receiptRequest += getCutElement(builder);

                    var config = {
                        urlArray: printUrlArray,
                        request: receiptRequest,
                        openDrawer: false,
                        successCallback: successCallback,
                        errorCallback: errorCallback
                    };

                    if (company && company.companyLogoUrl) {
                        config.iconUrl = company.companyLogoUrl;
                        config.useIconCache = true;
                    }

                    sendMessageArray(config);
                });
            }
        }

        async function printGiftReceiptV2 (
            response,
            receipt,
            cardReceiptArr,
            skipCashDrawer = true,
            printType = PrintType.MERCHANT,
            isDuplicate = false
        ) {
            let receiptFactory = new ReceiptFactoryWrapper();
            var company = CurrentSession.getCompany();
            var printUrlArrayOriginal = buildPrinterObjArray(response.posPrinters, false);
            var printUrlArrayGroupedByWidth = _.groupBy(printUrlArrayOriginal, 'receiptSize');
            let screenPrint = CompanyAttributesService.isScreenPrintEnabled();

            var giftCardPurchaseMap = {};
            _.each(response.giftCardPurchases, function (giftCardPurchase) {
                giftCardPurchaseMap[giftCardPurchase.code] = giftCardPurchase;
            });

            for (var key in printUrlArrayGroupedByWidth) {
                var printUrlArray = printUrlArrayGroupedByWidth[key];
                var receiptRequests = [];

                var builder = new StarWebPrintBuilder();

                var printerProtocol = printUrlArray[0].protocol;
                var outputFormat = (printerProtocol && printerProtocol == 'usb') ? 'image/png' : 'image/jpeg';
                receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.IMAGE_PRINTER, {
                    envConfig: EnvConfig,
                    printerDetails: printUrlArray[0],
                    type: 'receipt',
                    outputFormat: outputFormat
                });
                receiptFactory.initialize();

                receiptFactory
                    .align('center')
                    .title(response.locationName, true, {width: 1.5, height: 1.5});

                if (response.location) {
                    if (response.location.street) {
                        receiptFactory.text(response.location.street);
                    }

                    var city = response.location.city;
                    var region = response.location.region;

                    var cityRegionArray = [city, region].filter(function (e) {
                        return e != null;
                    });
                    var cityRegion = cityRegionArray.join(', ');

                    if (cityRegion) {
                        receiptFactory.text(cityRegion);
                    }
                }

                receiptFactory.newLine(1);

                if (isDuplicate) {
                    receiptFactory
                        .align('center')
                        .text('DUPLICATE', true, {width: 1, height: 1})
                        .newLine(1);
                }

                receiptFactory
                    .align('center')
                    .title('GIFT RECEIPT', true, {width: 1, height: 1});

                // / Move code to the bottom ends here

                receiptFactory
                    .textJustified(['Item   ', 'Qty'], true)
                    .divider(true);

                var receiptHierarchy = generateReceiptHierarchy(receipt);

                _.each(receiptHierarchy, function (item) {
                    if (item.locationServicePeriodMenuId === -99999) {
                        return; // buzzer
                    }

                    if (item.price < 0 && response.percentDiscount > 0) {
                        return; // skip trannsaction-wide discount
                    }

                    var leftString = item.name;
                    var rightString = '     ' + item.quantity;

                    receiptFactory
                        .align()
                        .textJustified([leftString, rightString], true);

                    if (item.subtype === 'giftcard.create' || item.subtype === 'giftcard.reload') {
                        var giftCardPurchase = giftCardPurchaseMap[item.upc];

                        if (giftCardPurchase) {
                            var balanceDollar = giftCardPurchase.currentBalanceCents / 100.0;
                            receiptFactory
                                .text('    - Remaining:' + _currency(balanceDollar));
                        }
                    }
                });

                receiptFactory
                    .newLine(1);

                receiptFactory
                    .align()
                    .textJustified(['', ''], false, {}, {}, '-'); // a line of asterisks

                receiptFactory
                    .newLine(1);

                var transactionDateTime = moment(response.transactionDateTime);
                var transactionDateTimeString = transactionDateTime.format('YYYY-MM-DD hh:mm:ss A');

                if (response.receiptId) {
                    receiptFactory
                        .align('center')
                        .text('Receipt: #' + response.receiptId);
                } else {
                    if (response.heldOrder) {
                        receiptFactory
                            .align('center')
                            .text('Bill');
                    }
                }

                receiptFactory
                    .align('center')
                    .text('Date: ' + transactionDateTimeString);

                if (response.cashierName) {
                    receiptFactory
                        .align('center')
                        .text('Sold By: ' + response.cashierName);
                }

                if (company && CompanyAttributesService.getReceiptFooter()) {
                    receiptFactory
                        .title(CompanyAttributesService.getReceiptFooter(), true, {width: 0.75, keepNewLine: true});
                }

                if (company && CompanyAttributesService.getReceiptTaxRegistrationDetails()) {
                    receiptFactory
                        .title(CompanyAttributesService.getReceiptTaxRegistrationDetails(), false, {width: 0.75});
                }

                await receiptFactory.output(screenPrint);
                for (const factory of receiptFactory.getFactoryList()) {
                    receiptRequests.push(factory.receiptData);
                }

                var isCashTransaction = response.cashAmount && response.cashAmount > 0;
                var isCreditCardTransaction = response.creditCardAmount && response.creditCardAmount > 0;
                var restrictOpenDrawerToCashTransaction = company.attributes.receipt__restrict_open_cash_drawer === 'true';

                var openDrawer = (!skipCashDrawer);

                var openDrawerForTransaction = (restrictOpenDrawerToCashTransaction)
                    ? isCashTransaction
                    : isCashTransaction || isCreditCardTransaction;

                openDrawer = openDrawer && openDrawerForTransaction;

                _.each(receiptRequests, function (receiptRequest, index) {
                    receiptRequest += getCutElement(builder);

                    var isLastRequest = (index === receiptRequests.length - 1);

                    var config = {
                        urlArray: printUrlArray,
                        request: receiptRequest,
                        openDrawer: openDrawer && isLastRequest,
                        successCallback: function (data, attempt) {
                        },
                        errorCallback: function (error, attempt) {
                            console.error(error);
                        }
                    };

                    if (company && company.companyLogoUrl) {
                        config.iconUrl = company.companyLogoUrl;
                        config.useIconCache = true;
                    }

                    sendMessageArray(config);
                });
            }
        }

        var generateReceiptCounterString = function (receiptCounter, heldOrder) {
            if (!receiptCounter) {
                return '';
            }

            var receiptCounterString;
            if (!isNaN(receiptCounter)) {
                receiptCounterString = '  #' + padZero(receiptCounter, 3) + '  ';
            } else {
                if (heldOrder && heldOrder.suspendId) {
                    receiptCounterString = '  H' + heldOrder.suspendId + '  ';
                }
            }

            return receiptCounterString;
        };

        let printTerminalReport = async function (printOutText, posStation) {
            let company = CurrentSession.getCompany();
            let companyAttributes = company.attributes || {};

            let useTransactionReceiptV2 = companyAttributes.use_transaction_receipt_v2 === 'true';
            let useTransactionReceiptV1Image = companyAttributes.use_transaction_receipt_v1_image === 'true';
            let useImageFactory = useTransactionReceiptV1Image || useTransactionReceiptV2;

            let printUrlArrayOriginal = buildPrinterObjArray(posStation.posPrinters, false);
            let printUrlArrayGroupedByWidth = _.groupBy(printUrlArrayOriginal, 'receiptSize');
            let screenPrint = CompanyAttributesService.isScreenPrintEnabled();

            for (let key in printUrlArrayGroupedByWidth) {
                let printUrlArray = printUrlArrayGroupedByWidth[key];
                let receiptFactory;
                let printerProtocol = printUrlArray[0].protocol;
                let outputFormat = (printerProtocol && printerProtocol == 'usb') ? 'image/png' : 'image/jpeg';

                if (useImageFactory) {
                    receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.IMAGE_PRINTER, {
                        envConfig: EnvConfig,
                        printerDetails: printUrlArray[0],
                        type: 'report',
                        outputFormat: outputFormat
                    });
                } else {
                    receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.STAR_PRINTER, {
                        printerDetails: printUrlArray[0]
                    });

                    if (CompanyAttributesService.isScreenPrintEnabled()) {
                        receiptFactory.registerFactory(ReceiptFactoryWrapper.factoryAlias.SCREEN_PRINTER, {
                            printerDetails: printUrlArray[0]
                        });
                    }
                }

                receiptFactory.initialize();

                receiptFactory.newLine(1);

                if (printOutText.length) {
                    for (const el of printOutText) {
                        let align = el.format[0];
                        let emphasis = el.format[1] === 'emphasis';

                        if (align === 'center') {
                            receiptFactory.align('center').text(el.data[0] + el.data[1], emphasis);
                            continue;
                        }

                        receiptFactory.textJustified(el.data);
                    }
                }

                receiptFactory.newLine(1);

                await receiptFactory.output(screenPrint);
                for (const factory of receiptFactory.getFactoryList()) {
                    receiptFactory.attachCutElement(CompanyAttributesService.partialCutEnabled());
                    let config = {
                        urlArray: printUrlArray,
                        request: factory.receiptData,
                        openDrawer: false,
                        successCallback: function (data, attempt) {
                        },
                        errorCallback: function (error, attempt) {
                            console.error(error);
                        }
                    };

                    if (company && company.companyLogoUrl) {
                        config.iconUrl = company.companyLogoUrl;
                        config.useIconCache = true;
                    }

                    sendMessageArray(config);
                }
            }
        };

        // https://www.star-m.jp/products/s_print/sdk/webprnt/manual/en/_StarWebPrintRequestElement.htm#cutpaper
        const getCutElement = (builder) => {
            try {
                if (CompanyAttributesService.partialCutEnabled()) {
                    return builder.createCutPaperElement({feed: true, type: 'partial'});
                }
            } catch (error) {
                console.error(error);
            }

            return builder.createCutPaperElement({feed: true});
        };

        var service = {
            clearSession: clearSession,
            updateSession: updateSession,
            testPrinter: testPrinter,
            printReceipt: printReceipt,
            printReceiptV1: printReceiptV1,
            printReceiptV1Image: printReceiptV1Image,
            printReceiptV2: printReceiptV2,
            printGiftReceiptV2: printGiftReceiptV2,
            printErrorReceipt: printErrorReceipt,
            printCreditCardReturnRefundReceipt: printCreditCardReturnRefundReceipt,
            printMobileOrderReceipt: printMobileOrderReceipt,
            printGiftCardPurchaseStub: printGiftCardPurchaseStub,
            // printEndOfShift: printEndOfShiftReport,
            printEndOfShift: printEndOfShiftReportV2,
            printTimeCards: printTimeCards,
            printOpenDrawerReceipt: printOpenDrawerReceipt,
            previewEndOfShiftReport: previewEndOfShiftReport,
            printKitchenSheets: printKitchenSheets,
            printLabels: printLabels,
            printProductLabel: printProductLabel,
            openCashDrawer: openCashDrawer,
            buildPrinterUrl: buildPrinterUrl,
            buildPrinterObjArray: buildPrinterObjArray,
            setReceiptSize: setReceiptSize,
            generateReceiptHierarchy: generateReceiptHierarchy,
            printTerminalReport: printTerminalReport,
        };
        return service;
    }
]).factory('BluetoothPrinter', [
    'BridgedPromise',
    function (BridgedPromise) {

        var print = function (config) {
            // var request = config.request;
            // var openDrawer = config.openDrawer;
            var attempt = config.attempt || 0;
            var successCallback = config.successCallback;
            var errorCallback = config.errorCallback;

            var payload = {
                interface: config.url.protocol,
                xml: config.request,
                openDrawer: config.openDrawer || false,
                printerEmulation: config.url.printerEmulation,
                printerName: config.url.printerName
            };

            var resolveFn, rejectFn;
            var promise = new Promise(function (resolve, reject) {
                resolveFn = resolve;
                rejectFn = reject;
            });

            var task = function () {
                BridgedPromise.dispatch('printReceipt', payload).then(function (data) {
                    resolveFn();
                    if (successCallback) {
                        successCallback.call(self, {data: data}, attempt);
                    }
                }).catch(function (data) {
                    rejectFn();
                    if (errorCallback) {
                        errorCallback.call(self, {data: data}, attempt);
                    }
                });
            };

            // returns printJob to be queued
            return {
                task: task,
                promise: promise,
                attempt: attempt,
                printSession: '' // need this!
            };
        };

        return {
            print: print
        };
    }]).factory('TCPPrinter', [
        'BridgedPromise',
        'StarWebPrint',
        'ElectronPrinter',
        'Platform',
        'PosAlertService',
        'IosTcpClient',
        '$log',
        'AppVersion',
        function (BridgedPromise, StarWebPrint, ElectronPrinter, Platform, PosAlertService, IosTcpClient, $log, AppVersion) {

            var mutePrinterAlert = false;
            var showPrinterAlert = function () {
                if (mutePrinterAlert) {
                    return;
                }
                PosAlertService.showAlertByName('general', {
                    title: 'pos.printer.error.title',
                    message: 'pos.printer.error.description'
                });
                mutePrinterAlert = true;
                setTimeout(function () {
                    mutePrinterAlert = false;
                }, (60000 * 60));
            };
            var print = function (config) {
                // Prints to specified IP with TCP/IP
                // var request = config.request;
                // var openDrawer = config.openDrawer;
                var attempt = config.attempt || 0;
                var successCallback = config.successCallback;
                var errorCallback = config.errorCallback;

                var payload = {
                    interface: config.url.protocol,
                    xml: config.request,
                    openDrawer: config.openDrawer || false,
                    printerEmulation: config.url.printerEmulation,
                    ipAddress: config.url.ipAddress,
                    port: config.url.printerPort,
                    cashDrawerType: config.cashDrawerType || config.url.cashDrawerType
                };

                var resolveFn, rejectFn;
                var promise = new Promise(function (resolve, reject) {
                    resolveFn = resolve;
                    rejectFn = reject;
                });

                var task = async function () {
                    if (payload.printerEmulation === 'webprint') {
                        // In Version 3 of our electron container, we move away from having a global proxy
                        // and opted to implement a dedicated proxy for webprint

                        // Disable this for now - we need more testing to ensure that all
                        // v3 containers have the right rpc handler for webprint
                        // Sadiq A. Dec 13, 2024
                        // if (Platform.isElectron() && AppVersion.isElectronV3()) {
                        //     const ipcRenderer = nodeRequire('electron').ipcRenderer;
                        //     const promise = ipcRenderer.invoke('webprint', config.url.printerUrl, config.request);
                        //     promise.then((data) => {
                        //         resolveFn();
                        //         if (successCallback) {
                        //             successCallback.call(self, {data: data}, attempt);
                        //         }
                        //     }).catch((data) => {
                        //         rejectFn();
                        //         if (errorCallback) {
                        //             errorCallback.call(self, {data: data}, attempt);
                        //         }
                        //     });
                        //     return;
                        // }

                        StarWebPrint.sendMessage(config).then((data) => {
                            resolveFn();
                            if (successCallback) {
                                successCallback.call(self, {data: data}, attempt);
                            }
                        }).catch((data) => {
                            rejectFn();
                            if (errorCallback) {
                                errorCallback.call(self, {data: data}, attempt);
                            }
                        });
                    } else if (payload.printerEmulation === 'escpos-tizen' || payload.printerEmulation === 'escpos-tizen-proxy') {
                        const escpos = require('escpos');
                        escpos.Console = require('escpos-console');
                        const device = new escpos.Console();
                        const options = {encoding: 'GB18030'};
                        const printer = new escpos.Printer(device, options);

                        if (payload.openDrawer) {
                            printer.cashdraw().font('a');
                        }

                        if (payload.xml) {
                            let Helper = require('../../electron/printer/print.helper');
                            let rows = Helper.xml2json(payload.xml);

                            for (let r of rows) {
                                if (!r) {
                                    continue;
                                }

                                switch (r.name) {
                                    case 'alignment': {
                                        printer.align(Helper.ALIGN_TYPE[r.attributes.position]);
                                    }
                                    break;
                                    case 'text': {
                                        let tempText = '';

                                        let textElements = r.elements;
                                        if (textElements) {
                                            textElements.forEach((t) => {
                                                tempText += Helper.unescapeXML(Helper.unpack(t.text));
                                            });
                                            printer.lineSpace(null); // sets line height to default
                                        } else {
                                            printer.lineSpace(1);
                                            tempText += '\n';
                                        }

                                        let emphasis = r.attributes ? (r.attributes.emphasis === 'true') : false;

                                        if (emphasis === true) {
                                            printer.style('B'); // BOLD
                                        } else {
                                            printer.style('normal');
                                        }

                                        printer.pureText(tempText);
                                    }
                                    break;
                                    case 'cutpaper': {
                                        printer.lineSpace(null);
                                        printer.pureText('\n');
                                        printer.cut();
                                    }
                                    break;
                                    case 'ruledline': {
                                        printer.text('__________________________________________');
                                    }
                                    break;
                                }
                            }
                        }
                        if (payload.printerEmulation === 'escpos-tizen-proxy') {
                            let tizenPrintPayload = {
                                msgType: 'printTcpCmd',
                                message: {
                                    ip: payload.ipAddress,
                                    port: payload.port,
                                    message: printer.buffer._buffer
                                }
                            };
                            window.parent.postMessage(tizenPrintPayload, '*');
                        } else {
                            window.parent.postMessage({msgType: 'printCmd', message: printer.buffer._buffer}, '*');
                        }

                        resolveFn();
                        if (successCallback) {
                            successCallback.call(self, {data: ''}, attempt);
                        }

                    } else if (payload.printerEmulation === 'star-prnt' || payload.printerEmulation === 'star-line') {

                        const encodeImage = (url) => {
                            return new Promise((resolve, reject) => {
                                let img = new Image();
                                img.src = url;
                                img.crossOrigin = 'Anonymous';

                                img.onload = () => {
                                    resolve(img);
                                };
                            });
                        };

                        let ReceiptPrinterEncoder = require('@point-of-sale/receipt-printer-encoder').default;
                        let encoder = new ReceiptPrinterEncoder({language: payload.printerEmulation, feedBeforeCut: 4});

                        if (payload.openDrawer) {
                            encoder.pulse();
                        }

                        const alignMap = {
                            ct: 'center',
                            lt: 'left',
                            rt: 'right'
                        };

                        /**
                         * Translate webprint xml to star-prnt commands
                         * https://github.com/NielsLeenheer/ReceiptPrinterEncoder/blob/main/documentation/usage.md
                         */
                        if (payload.xml) {
                            let Helper = require('../../electron/printer/print.helper');
                            let rows = Helper.xml2json(payload.xml);

                            try {
                                if (config.iconUrl) {
                                    let img = await encodeImage(config.iconUrl);
                                    encoder.align('center').image(img, 128, 128, 'atkinson');
                                }
                            } catch (error) {
                                $log.error(error);
                            }

                            for (let r of rows) {
                                if (!r) {
                                    continue;
                                }

                                switch (r.name) {
                                    case 'alignment': {
                                        encoder.align(alignMap[Helper.ALIGN_TYPE[r.attributes.position]]);
                                    }
                                    break;
                                    case 'text': {
                                        let textElements = r.elements;

                                        if (!textElements) {
                                            break;
                                        }

                                        let tempText = textElements
                                            .map((t) => Helper.unescapeXML(Helper.unpack(t.text)))
                                            .reduce((a, b) => a += b);

                                        let emphasis = r.attributes ? (r.attributes.emphasis === 'true') : false;
                                        let underline = r.attributes ? (r.attributes.underline === 'true') : false;
                                        let width = r.attributes ? Number(r.attributes.width) : 1;
                                        let height = r.attributes ? Number(r.attributes.height) : 1;

                                        encoder.width(width).height(height);

                                        if (r.attributes && !r.elements && underline) {
                                            encoder.rule({style: 'single'});
                                        } else if (r.attributes) {
                                            if (emphasis === true && underline === true) {
                                                encoder.bold().underline(); // BOLD & UNDERLINE
                                            } else if (emphasis === true) {
                                                encoder.bold();
                                            } else if (underline === true) {
                                                encoder.underline(); // UNDERLINE
                                            } else {
                                                encoder.bold(false);
                                            }
                                        } else {
                                            encoder.bold(false);
                                        }
                                        // replace all new line chars
                                        tempText = tempText.trim().replace(/^\s\n+|\s\n+$/g, '');
                                        encoder.line(tempText);
                                    }
                                    break;
                                    case 'cutpaper': {
                                        encoder.cut('partial');
                                    }
                                    break;
                                    case 'ruledline': {
                                        encoder.rule({style: 'single'});
                                    }
                                    break;
                                    case 'qrcode': {
                                        encoder.qrcode(r.elements[0].text || '');
                                    }
                                    break;
                                    case 'rawdata': {
                                        let data = atob(r.elements[0].text || '');
                                        let img = await encodeImage(data);
                                        // width and height must be a multiple of 8
                                        encoder.image(img, Math.floor(img.width/8)*8, Math.floor(img.height/8)*8, 'atkinson');
                                    }
                                    break;
                                }
                            }
                        }

                        // encode the star-prnt commands and output a unint8 array that can be sent over the socket
                        let result = encoder.encode();

                        if (Platform.isIosWebkit()) {
                            IosTcpClient.connect(payload.port.toString(), payload.ipAddress).then(function (client) {
                                client.onData(function (data) {
                                });

                                client.write(result);

                                resolveFn();
                                if (successCallback) {
                                    successCallback.call(self, {data: ''}, attempt);
                                }
                            }).catch(function (error) {
                                $log.error(error);
                            });
                        } else if (Platform.isElectron()) {
                            const net = nodeRequire('net');
                            const client = new net.Socket();

                            client.connect(payload.port, payload.ipAddress, () => {
                                client.write(result, () => {
                                    // This callback is only executed when the data has been written
                                    // and the buffer is drained
                                    client.end(() => {
                                        console.log('disconnecting from printer');
                                        resolveFn();
                                        if (successCallback) {
                                            successCallback.call(self, {data: ''}, attempt);
                                        }
                                    });
                                });
                            });

                            client.on('error', function (error) {
                                console.log(`Error occured ${error}`);
                                rejectFn();
                            });

                            client.on('end', () => {
                                console.log('disconnected from printer');
                            });
                        } else if (Platform.isTizen()) {
                            window.parent.postMessage({msgType: 'printCmd', message: result}, '*');

                            resolveFn();
                            if (successCallback) {
                                successCallback.call(self, {data: ''}, attempt);
                            }
                        } else {
                            // we didn't find an environment + printer configuration
                            // we can print in.
                            rejectFn();
                            showPrinterAlert();
                        }
                    } else if (payload.printerEmulation === 'tspl') {

                        if (Platform.isIosWebkit()) {
                          IosTcpClient.connect(payload.port.toString(), payload.ipAddress).then(function (client) {
                              client.onData(function (data) {
                              });

                              client.write(payload.xml);

                              resolveFn();
                              if (successCallback) {
                                  successCallback.call(self, {data: ''}, attempt);
                              }
                          }).catch(function (error) {
                              $log.error(error);
                          });
                        } else if (Platform.isElectron()) {
                            const net = nodeRequire('net');
                            const client = new net.Socket();

                            client.connect(payload.port, payload.ipAddress, function () {
                                client.on('close', function () {
                                    resolveFn();
                                    if (successCallback) {
                                        successCallback.call(self, {data: ''}, attempt);
                                    }
                                });

                                client.on('error', function () {
                                    rejectFn();
                                });

                                client.write(payload.xml);
                                client.end();
                            });
                        } else if (Platform.isTizen()) {
                            let tizenTsplPrintPayload = {
                                msgType: 'printTsplCmd',
                                message: {
                                    ip: payload.ipAddress,
                                    port: payload.port,
                                    message: payload.xml
                                }
                            };
                            window.parent.postMessage(tizenTsplPrintPayload, '*');

                            resolveFn();
                            if (successCallback) {
                                successCallback.call(self, {data: ''}, attempt);
                            }
                        }
                    } else {
                        if (Platform.isElectron()) {
                            var printPromise = ElectronPrinter.print(payload);

                            printPromise.then(function () {
                                resolveFn();
                                if (successCallback) {
                                    successCallback();
                                }
                            }).catch(function (error) {
                                rejectFn(error);
                                if (errorCallback) {
                                    errorCallback.call(self, {data: error}, attempt);
                                }
                                showPrinterAlert();
                            });
                        } else if (Platform.isIosWebkit()) {
                            BridgedPromise.dispatch('printReceipt', payload).then(function (data) {
                                resolveFn();
                                if (successCallback) {
                                    successCallback.call(self, {data: data}, attempt);
                                }
                            }).catch(function (data) {
                                rejectFn();
                                if (errorCallback) {
                                    errorCallback.call(self, {data: data}, attempt);
                                }
                                showPrinterAlert();
                            });
                        } else {
                            // we didn't find an environment + printer configuration
                            // we can print in.
                            rejectFn();
                            showPrinterAlert();
                        }
                    }
                };

                // Returns printJob to be queued
                return {
                    task: task,
                    promise: promise,
                    attempt: attempt,
                    retry: function (immediate, attempt) {
                        config.immediate = immediate;
                        config.attempt = attempt;
                        print(config);
                    },
                    printSession: '' // need this!
                };
            };

            return {
                print: print
            };
        }]).factory('USBPrinter', [
            'ElectronPrinter',
            function (ElectronPrinter) {

                var print = function (config) {
                    // Prints to specified IP with TCP/IP
                    // var request = config.request;
                    // var openDrawer = config.openDrawer;
                    var attempt = config.attempt || 0;
                    var successCallback = config.successCallback;
                    var errorCallback = config.errorCallback;

                    var payload = {
                        interface: config.url.protocol,
                        xml: config.request,
                        openDrawer: config.openDrawer || false,
                        printerEmulation: config.url.printerEmulation,
                        vid: config.url.printerVid,
                        pid: config.url.printerPid,
                        iconUrl: config.iconUrl,
                        useIconCache: config.useIconCache,
                        cashDrawerType: config.cashDrawerType || config.url.cashDrawerType
                    };

                    var resolveFn, rejectFn;
                    var promise = new Promise(function (resolve, reject) {
                        resolveFn = resolve;
                        rejectFn = reject;
                    });

                    var task = function () {
                        // Print through Electron
                        var printPromise = ElectronPrinter.print(payload);

                        printPromise.then(function () {
                            resolveFn();
                            if (successCallback) {
                                successCallback();
                            }
                        }).catch(function (error) {
                            rejectFn(error);
                            if (errorCallback) {
                                errorCallback(error);
                            }
                        });
                    };

                    // Returns printJob to be queued
                    return {
                        task: task,
                        promise: promise,
                        attempt: attempt,
                        printSession: '' // Need this!
                    };
                };

                return {
                    print: print
                };
            }]).factory('StarWebPrint', ['PrintParser', '$log', function (PrintParser, $log) {
                function sendMessage (config) {
                    return new Promise((resolve, reject) => {
                        var request = config.request;
                        var openDrawer = config.openDrawer;
                        // var attempt = config.attempt || 0;
                        var builder = new StarWebPrintBuilder();

                        // Retry up to 3 times
                        // const canRetry = () => attempt < 3;

                        const logTraderStatus = (response) => {
                            try {
                                response.xml = request;
                                $log.error({
                                    message: 'StarWebPrint Failure',
                                    context: response
                                });
                            } catch (error) {
                                console.error(error);
                            }
                        };

                        if (openDrawer) {
                            request += builder.createPeripheralElement({
                                channel: 1,
                                on: 200,
                                off: 100
                            });
                        }

                        var scheme = 'http://';
                        if (config.url.printerPort == 443) {
                            scheme = 'https://';
                        }

                        var url = scheme + config.url.ipAddress + ':' + config.url.printerPort + '/StarWebPRNT/SendMessage';
                        var trader = new StarWebPrintTrader({url: url});
                        // Default timeout for trader is 90s (not documented). This is too long and can cause printing to queue up.
                        // However, if the value is too low, it can cause the timeout to occur too early and result in duplicated
                        // printing due to the re-attempt. Setting the timeout to 5s for now as a (possibly) reasonable value.
                        trader.timeout = 5000;

                        trader.onReceive = function (response) {
                            try {
                                var data = PrintParser.parseStartWebPrintResponse(trader, response);
                                if (response.traderSuccess === 'true') {
                                    resolve(data);
                                } else {
                                    // Handle Retry
                                    // if (canRetry()) {
                                    //     send(true);
                                    //     return;
                                    // }
                                    logTraderStatus(data);
                                    reject(data);
                                }
                            } catch (e) {
                                // Handle Retry
                                // if (canRetry()) {
                                //     send(true);
                                //     return;
                                // }
                                logTraderStatus(data);
                                resolve({});
                            }
                        };

                        trader.onError = function (response) {
                            var data = {};
                            try {
                                data = PrintParser.parseStartWebPrintResponse(trader, response);
                            } catch (e) {
                                data.status = response.status;
                                data.offline = true;
                            }
                            // if (canRetry()) {
                            //     send(true);
                            //     return;
                            // }
                            logTraderStatus(data);
                            reject(data);
                        };

                        const send = (isRetry = false) => {
                            // Delay print by 1s if its a retry
                            let delay = isRetry ? 1000 : 0;
                            setTimeout(trader.sendMessage({request: request}), delay);
                            // attempt++;
                        };

                        send(false);
                    });
                }

                return {
                    sendMessage: sendMessage
                };
            }]).factory('PrintParser', function () {
                var parseStartWebPrintResponse = function (trader, response) {
                    /**
                     *
                    var msg = '- onReceive -\n\n';
                    msg += 'TraderSuccess : [ ' + response.traderSuccess + ' ]\n';
                    msg += 'TraderStatus : [ ' + response.traderStatus + ',\n';
                    if (trader.isCoverOpen({traderStatus: response.traderStatus})) {
                        msg += '\tCoverOpen,\n';
                    }
                    if (trader.isOffLine({traderStatus: response.traderStatus})) {
                        msg += '\tOffLine,\n';
                    }
                    if (trader.isCompulsionSwitchClose({traderStatus: response.traderStatus})) {
                        msg += '\tCompulsionSwitchClose,\n';
                    }
                    if (trader.isEtbCommandExecute({traderStatus: response.traderStatus})) {
                        msg += '\tEtbCommandExecute,\n';
                    }
                    if (trader.isHighTemperatureStop({traderStatus: response.traderStatus})) {
                        msg += '\tHighTemperatureStop,\n';
                    }
                    if (trader.isNonRecoverableError({traderStatus: response.traderStatus})) {
                        msg += '\tNonRecoverableError,\n';
                    }
                    if (trader.isAutoCutterError({traderStatus: response.traderStatus})) {
                        msg += '\tAutoCutterError,\n';
                    }
                    if (trader.isBlackMarkError({traderStatus: response.traderStatus})) {
                        msg += '\tBlackMarkError,\n';
                    }
                    if (trader.isPaperEnd({traderStatus: response.traderStatus})) {
                        msg += '\tPaperEnd,\n';
                    }
                    if (trader.isPaperNearEnd({traderStatus: response.traderStatus})) {
                        msg += '\tPaperNearEnd,\n';
                    }
                    msg += '\tEtbCounter = ' + trader.extractionEtbCounter({traderStatus: response.traderStatus}).toString() + ' ]\n';
                     */

                    var result = {
                        status: response.status,
                        traderSuccess: response.traderSuccess,
                        traderStatus: response.traderStatus,
                        traderCode: response.traderCode,
                        coverOpen: trader.isCoverOpen({traderStatus: response.traderStatus}),
                        offline: trader.isOffLine({traderStatus: response.traderStatus}),
                        compulsionSwitchClosed: trader.isCompulsionSwitchClose({traderStatus: response.traderStatus}),
                        etbCommandExecute: trader.isEtbCommandExecute({traderStatus: response.traderStatus}),
                        highTemperatureStop: trader.isHighTemperatureStop({traderStatus: response.traderStatus}),
                        nonRecoverableError: trader.isNonRecoverableError({traderStatus: response.traderStatus}),
                        autoCutterError: trader.isAutoCutterError({traderStatus: response.traderStatus}),
                        blackMarkError: trader.isBlackMarkError({traderStatus: response.traderStatus}),
                        paperEnd: trader.isPaperEnd({traderStatus: response.traderStatus}),
                        paperNearEnd: trader.isPaperNearEnd({traderStatus: response.traderStatus}),
                        etbCounter: trader.extractionEtbCounter({traderStatus: response.traderStatus})
                    };

                    return result;
                };

                return {
                    parseStartWebPrintResponse: parseStartWebPrintResponse
                };
            });

export default freshIdeasPrintServiceModule;
