'use strict';


const Sortable = require('sortablejs').default;
const CanvasCompress = require('canvas-compress');

module.exports = function (freshideasProducts) {
    freshideasProducts.controller('ProductItemCtrl', [
        '$scope',
        '$modal',
        '$q',
        '$timeout',
        '$translate',
        '$state',
        'ConfirmModalService',
        'CustomReceiptService',
        'ProductsService',
        'PosStatusService',
        'PosAlertService',
        'MenuService',
        'index',
        'item',
        'upc',
        'isRootCompany',
        'isCampus',
        'targetCategory',
        'CurrentSession',
        'Platform',
        'FileUploader',
        'organizationLanguages',
        'menus',
        'isNew',
        'refreshProducts',
        'Menu',
        'GatewayFiit',
        'PRINTOUT_TYPE',
        'SharedDataService',
        function (
            $scope,
            $modal,
            $q,
            $timeout,
            $translate,
            $state,
            ConfirmModalService,
            CustomReceiptService,
            ProductsService,
            PosStatusService,
            PosAlertService,
            MenuService,
            index,
            item,
            upc,
            isRootCompany,
            isCampus,
            targetCategory,
            CurrentSession,
            Platform,
            FileUploader,
            organizationLanguages,
            menus,
            isNew,
            refreshProducts,
            Menu,
            GatewayFiit,
            PRINTOUT_TYPE,
            SharedDataService) {

            let itemTagsToRemove = {};
            $scope.organizationLanguages = organizationLanguages;
            $scope.index = index;
            $scope.menus = menus;
            $scope.isNew = isNew;

            $scope.isMasterMenu = ProductsService.isMasterMenu;
            $scope.isRootCompany = isRootCompany;
            $scope.isCampus = isCampus;

            var currentCompany = CurrentSession.getCompany();

            $scope.isElectron = Platform.isElectron();
            $scope.isIosWebkit = Platform.isIosWebkit();

            $scope.kdsEnabled = currentCompany.attributes.kds_enabled === 'true';
            $scope.hasRetailMenu = currentCompany.attributes.retail__menu === 'true';
            $scope.loyaltyStepsEnabled = currentCompany.attributes.loyalty_steps_enabled === 'true';
            $scope.lowStockEmails = [];

            var LOAD_STATUS = $scope.LOAD_STATUS = {
                ERROR: -1,
                INITIAL: 0,
                LOADING: 1,
                LOADED: 2
            };

            $scope.loading = {
                tagNames: LOAD_STATUS.INITIAL
            };

            $scope.PRINTOUT_TYPE = PRINTOUT_TYPE;

            if (currentCompany.serviceConfig.lowStockReport) {
                $scope.lowStockEmails = currentCompany.serviceConfig.lowStockReport.emails || [];
            }

            $scope.ProductItemCreateForm = {};

            $scope.item = angular.copy(item) || {
                active: true,
                printOnAllTypes: true,
                printOnCustomerReceipt: true,
                printOnKitchenSheet: true,
                noModifierNoKitchenPrint: false,
                children: [],
                color: '#40d0ad',
                taxRules: {},
                upc: upc,
                type: 'item',
                expoEnabled: true
            };

            $scope.item.children = $scope.item.children || [];
            $scope.item.categories = [];
            $scope.PosStatusService = PosStatusService;

            $scope.isNew = !$scope.item.locationServicePeriodMenuId;

            $scope.allProductCategories = _.filter(ProductsService.productCategories, function (category) {
                return !!category.name && category.subtype !== 'page';
            });

            $scope.settings = {
                showAdvanced: false,
                toggleShowAdvanced: function () {
                    $scope.settings.showAdvanced = !$scope.settings.showAdvanced;
                }
            };

            $scope.loyaltySteps = SharedDataService.cachedLoyaltySteps;

            $scope.selectTaxRate = function () {
                var attributes = [];

                var modalAttribute = {
                    identifier: 'taxRateFid',
                    name: 'product.item.tax.label',
                    options: ProductsService.taxRates,
                    optionName: 'taxRateName',
                    optionIdentifier: 'taxRateFid',
                };

                attributes.push(modalAttribute);

                if (ProductsService.isTaxRuleEnabled('CA_ON_PREPARED_GOODS')) {
                    attributes.push({
                        identifier: 'taxRules.' + ProductsService.getTaxRule('CA_ON_PREPARED_GOODS').name,
                        name: 'taxRule.ca_on_prepared_goods.label',
                        description: 'taxRule.ca_on_prepared_goods.description',
                        options: 'toggle'
                    });
                }

                if (ProductsService.isTaxRuleEnabled('CA_ON_PREPARED_GOODS')) {
                    attributes.push({
                        identifier: 'taxRules.' + ProductsService.getTaxRule('CA_ON_PREPARED_GOODS_QUANTITY').name,
                        name: 'taxRule.ca_on_prepared_goods_quantity.label',
                        description: 'taxRule.ca_on_prepared_goods_quantity.description',
                        options: 'toggle'
                    });
                }

                var modalInstance = $modal.open({
                    templateUrl: 'products/templates/general.list.tpl.html',
                    controller: 'ProductGeneralListCtrl',
                    windowClass: 'modal-50 products2',
                    animation: true,
                    // backdrop: 'static'
                    resolve: {
                        title: function () {
                            return 'product.item.tax.action';
                        },
                        entity: function () {
                            return $scope.item;
                        },
                        attributes: function () {
                            return attributes;
                        }
                    }
                });

                modalInstance.result.finally(function () {
                    setTaxRateName();
                    $scope.ProductItemCreateForm.$dirty = true;
                });
            };

            var setTaxRateName = function () {
                var identifierObj = {
                    taxRateFid: $scope.item.taxRateFid || ProductsService.taxRateFidsConsts.DEFAULT
                };

                var foundTaxRate = _.findWhere(ProductsService.taxRates, identifierObj);

                if (foundTaxRate) {
                    $scope.item.taxRateId = null;
                    $scope.item.taxRateName = foundTaxRate.taxRateName;
                    $scope.item.taxRateFid = foundTaxRate.taxRateFid;
                }
            };

            var reloadTaxRates = function () {
                return new Promise(async function (resolve) {
                    await ProductsService.loadTaxRates();
                    resolve();
                });
            };

            var reloadPrinters = function () {
                return MenuService.loadPrintersByLocation();
            };

            var reloadTags = function () {
                ProductsService.loadTags().then(function () {
                    $scope.allTags = ProductsService.tags;
                });
            };
            var loadItemTagNames = function () {
                $scope.loading.tagNames = LOAD_STATUS.LOADING;
                return ProductsService.getMenuItemTagNames($scope.item, $scope.isNew).then(function (tagNames) {
                    $scope.item.tagNames = tagNames;

                    $scope.loading.tagNames = LOAD_STATUS.LOADED;
                }, function (error) {
                    $scope.loading.tagNames = LOAD_STATUS.ERROR;
                });
            };

            $scope.removeTagName = function (tagName, index) {
                if ($scope.item.tagNames[index] == tagName) {
                    let tagToRemove = $scope.item.tagNames.splice(index, 1);
                    itemTagsToRemove[tagToRemove] = tagToRemove;

                    $scope.ProductItemCreateForm.itemTagNames.$dirty = true;
                }
            };

            var parseTaxRules = function () {
                var taxRules = $scope.item.taxRules;
                _.each(taxRules, function (taxRule, key) {
                    if (taxRules[key] === 'false') {
                        taxRules[key] = false;
                    } else if (taxRules[key] === 'true') {
                        taxRules[key] = true;
                    }
                });
            };

            $scope.taxRule = function (name) {
                return ProductsService.getTaxRule(name);
            };

            $scope.selectLabelPrinter = function () {
                $modal.open({
                    templateUrl: 'products/templates/print.label.tpl.html',
                    controller: 'PrintLabelCtrl',
                    windowClass: 'modal-50 products2',
                    animation: true,
                    resolve: {
                        item: () => {
                            return $scope.item;
                        }
                    }
                });
            };

            /**
            *** Commented By Akash Mehta on July 8 2020
            *** This variable is introduced to track the state of whether
            *** the numpad Modal for the UPC field is open or not. The state
            *** of this field is used to control the behaviour for scanning.
            *** For further details, refer to function 'onBarcodeScanned'.
            ***/
            var isUpcNumpadOpen = false;

            /**
            *** Commented By Akash Mehta on July 8 2020
            *** Function to mark UPC Numpad Open.
            *** This is called when you click on the UPC field
            *** on the item products page. In order to change the state
            *** of the variable, use the call back function `markUpcNumpadClose`
            ***/
            $scope.markUpcNumpadOpen = function () {
                isUpcNumpadOpen = true;
            };

            /**
            *** Commented By Akash Mehta on July 8 2020
            *** Function to mark UPC Numpad CLOSE.
            *** This is called as a callback from the numpad-directive.js.
            *** This function is only called when the modal is CLOSED & NOT DISMISSED.
            ***/
            $scope.markUpcNumpadClose = function () {
                isUpcNumpadOpen = false;
            };


            /**
            *** Commented By Akash Mehta on July 8 2020
            *** Callback function to barCode/qrCode scanner
            *** As soon as a barCode/qrCode is scanned, this function is called.
            *** We check if the value is not present or if the upcNumpad is already open,
            *** then we do not do anything with the newly scanned value (This workflow is added
            *** because there are 2 scanners registered at the same time - The scanner on item create/edit (this) modal
            *** and 2nd one on the numpad modal. Hence we track the states above to ensure that only one callback always takes effect)
            *** Else, we store the newly scanned UPC in the upc field
            ***/
            $scope.onBarcodeScanned = function (newUpc) {
                if (!newUpc || isUpcNumpadOpen) {
                    return;
                }

                $timeout(function () {
                    $scope.item.upc = newUpc;
                }, 0);
            };

            $scope.pasteUPC = () => {
                // ui imposes max length of 60
                const NUMBERS_ONLY = new RegExp('^[0-9]{1,60}$');

                navigator.permissions.query({name: 'clipboard-read'}).then((result) => {
                    if (result.state == 'denied') {
                        PosAlertService.showError('error', 'Unable to paste UPC code!', '',
                        'There was an error pasting the UPC code. Please ensure clipboard-read permission is granted.');
                    } else {
                        navigator.clipboard.readText().then((clipText) => {
                            if (clipText && NUMBERS_ONLY.test(clipText)) {
                                // update the view
                                $timeout(() => {
                                    $scope.item.upc = clipText;
                                }, 0);
                            } else {
                                PosAlertService.showError('error', 'Unable to paste UPC code!', '',
                                'There was an error pasting the UPC code. Please ensure only numbers are used.');
                            }
                        });
                    }
                });
            };

            $scope.showLabelAlert = function () {
                PosAlertService.showError('error', 'Feature Disabled', '', 'Please use desktop version to print product labels. Thanks!');
            };

            $scope.selectKitchenPrinter = function () {
                let printers = _.where(MenuService.printers, {kitchenPrinter: true});
                let config = {
                    title: function () {
                        return 'product.item.printer.action';
                    },
                    entity: function () {
                        return $scope.item;
                    },
                    attributes: function () {
                        return [{
                            identifier: 'printerId',
                            name: 'product.item.printer.label',
                            options: printers,
                            optionName: '_displayName',
                            optionIdentifier: 'posPrinterId',
                        }];
                    }
                };
                selectPrinter(config).then(() => {
                    setPrinterName();
                    $scope.ProductItemCreateForm.$dirty = true;
                });
            };

            function selectPrinter (config) {
                return new Promise((resolve, reject) => {
                    var modalInstance = $modal.open({
                        templateUrl: 'products/templates/general.list.tpl.html',
                        controller: 'ProductGeneralListCtrl',
                        windowClass: 'modal-50 products2',
                        animation: true,
                        resolve: config
                    });

                    modalInstance.result.finally(function () {
                        resolve();
                    });
                });
            }
            var setPrinterName = function () {
                var foundPrinter = _.findWhere(MenuService.printers, {posPrinterId: $scope.item.printerId});

                if (foundPrinter) {
                    $scope.item.printerName = foundPrinter._displayName;
                }
            };

            var highlightInputsFn;
            var runHighlightInputs = function () {
                if (highlightInputsFn) {
                    return;
                }

                $scope.highlightInputs = true;
                highlightInputsFn = $timeout(function () {
                    $scope.highlightInputs = false;
                    highlightInputsFn = undefined;
                }, 1000);
            };

            var isItemValid = function () {
                if (!$scope.ProductItemCreateForm.$valid) {
                    runHighlightInputs();
                    return false;
                }

                return true;
            };

            var intiailizeSortableModifierList = function () {
                if ($scope.item._inherited) {
                    return;
                }

                var el = document.getElementById('modifier-list');
                if (!el) {
                    return;
                }

                var sortable = Sortable.create(el, {
                    handle: '.drag-handle',
                    onMove: function (event, origEvent) {
                        return true;
                    },
                    onStart: function (event) {
                    },
                    onEnd: function (event) {
                        var modifiers = $scope.item.children;
                        var mod1 = modifiers[event.oldIndex];
                        var mod2 = modifiers[event.newIndex];

                        // Ensure the new index is valid for sorting (ie. 0 or larger)
                        if (mod2.currentCategoryIndex >= 0) {
                            var minCategoryIndex = 0;
                            modifiers.splice(event.oldIndex, 1);
                            modifiers.splice(event.newIndex, 0, mod1);

                            for (var i = 0; i < modifiers.length; i++) {
                                if (modifiers[i].currentCategoryIndex >= 0) {
                                    modifiers[i].currentCategoryIndex = minCategoryIndex;
                                    minCategoryIndex++;
                                }
                            }
                        }
                    },
                });

                $scope.$on('$destroy', function () {
                    sortable.destroy();
                });
            };

            // this filter is used to determine the current menu when editing an item.
            $scope.menuFilter = (menu) => {
                return menu.menuId === $scope.menus.selected.menuId;
            };

            $scope.getMenuName = (menuId) => {
                return MenuService.getMenuName(menuId, $scope.menus.all);
            };

            $scope.saveItem = function () {
                if (!isItemValid()) {
                    return;
                }

                $scope.isSavingItem = true;
                if (!($scope.isRootCompany || $scope.isCampus)) {
                    MenuService.assignPrinterToItem($scope.item, $scope.menus).then(function () {
                        $scope.isSavingItem = false;
                        $scope.$close($scope.item);
                    }).catch(function (error) {
                        $scope.isSavingItem = false;
                    });
                } else {
                    if (!$scope.ProductItemCreateForm.$dirty) {
                        $scope.$close($scope.item);
                    }

                    var itemToSave = angular.copy($scope.item);

                    itemToSave.locationIdToUpdateFor = ProductsService.locationDetail.locationId;

                    var itemTagNamesUpdated = $scope.ProductItemCreateForm.itemTagNames
                        && $scope.ProductItemCreateForm.itemTagNames.$dirty;
                    if (itemTagNamesUpdated) {
                        itemToSave.updateTags = true;
                    }

                    var promise;
                    var finalResponse;
                    let menuVariationsToUpdate = [];

                    if ($scope.isNew) {
                        $scope.item.menuVariations = $scope.menuAssociations.filter((menu) => {
                            // only fetch those marked associated.
                            if (menu.associated) {
                                return {
                                    menuId: menu.menuId,
                                    associatedCompanyIds: menu.associatedCompanyIds || []
                                };
                            }
                        });

                        if (!$scope.item.menuVariations.length) {
                            PosAlertService.showAlertByName('general-error', {
                                title: 'product.masterProductModal.menuAssociations.error.ttl',
                                message: 'product.masterProductModal.menuAssociations.error.desc'
                            });
                            $scope.isSavingItem = false;
                            return;
                        }

                        menuVariationsToUpdate.push(...$scope.item.menuVariations);

                        itemToSave.menuVariations = menuVariationsToUpdate;

                        promise = MenuService.addMenuItemInQueue(itemToSave);
                    } else {
                        const currentMenuId = ProductsService.getSelectedMenuId();
                        const currentMenu = $scope.menus.all.find((menu) => {
                            return menu.menuId === currentMenuId;
                        });

                        if (!currentMenu) {
                            PosAlertService.showAlertByName('general-error', {
                                title: 'product.masterProductModal.menuAssociations.error.ttl',
                                message: 'product.masterProductModal.menuAssociations.error.desc'
                            });
                            $scope.isSavingItem = false;
                            return;
                        }

                        let menuVariation = {
                            menuId: currentMenu.menuId
                        };

                        itemToSave.itemTagsToRemove = Object.keys(itemTagsToRemove);

                        menuVariationsToUpdate.push(menuVariation);
                        promise = MenuService.updateMenuItemInQueue(itemToSave, $scope.menus);
                    }

                    return promise.then(function (savedItem) {
                        let childIdMap = {};

                        $scope.item.children.forEach((modifier, index) => {
                            const id = modifier.locationServicePeriodMenuId || modifier.menuItemId;

                            // only add to map if it contains an id.
                            if (id) {
                                childIdMap[id] = index;
                            }
                        });

                        $scope.modifiersToBeRemoved.forEach((id) => {
                            childIdMap[id] = -1;
                        });

                        let parentMap = {};

                        $scope.item.categories.forEach((category) => {
                            parentMap[category.locationServicePeriodMenuId] = null;
                        });

                        $scope.categoriesToRemove.forEach((category) => {
                            parentMap[category.locationServicePeriodMenuId] = -1;
                        });

                        let parentItemId = targetCategory ? targetCategory.locationServicePeriodMenuId : null;
                        let itemsToSave = [];
                        let promiseArr = _.each(menuVariationsToUpdate, (menuVariation) => {
                            let itemCopy = angular.copy(savedItem);

                            itemCopy.parentItemId = parentItemId;
                            itemCopy.parentMap = parentMap;
                            itemCopy.childMap = childIdMap;
                            itemCopy.menuId = menuVariation.menuId;

                            itemsToSave.push(itemCopy);
                        });

                        MenuService.addAssociations({
                            itemToSave: itemsToSave,
                        });

                        Promise.all(promiseArr);

                        $scope.item.locationServicePeriodMenuId = savedItem.menuItemId;
                        $scope.item.locationId = ProductsService.locationDetail.locationId;

                        if ($scope.isRootCompany) {
                            // only attempt to upload if an image is added
                            if ($scope.uploadStatus === UploadStatus.PREPARED) {
                                $scope.beginUpload();
                            }

                            // delete item image only if the user has confirmed to
                            // delete the item image
                            if ($scope.imageDeleteConfirmation) {
                                const languageImageMap = $scope.item.languageImageMap;
                                const toDelete = [];
                                for (const key in languageImageMap) {
                                    if (languageImageMap.hasOwnProperty(key)) {
                                        if (languageImageMap[key].imageDeleteConfirmation) {
                                            toDelete.push(languageImageMap[key].menuItemImageId);
                                        }
                                    }
                                }

                                MenuService.deleteMenuItemImages(itemToSave, toDelete).catch((err) => {
                                    PosAlertService.showAlertByName('general', {
                                        title: 'product.item.image.delete.error.title',
                                        message: err.data.error
                                    });
                                });
                            }
                        }

                        if ($scope.isNew) {
                            $scope.isNew = false;
                        }

                        finalResponse = savedItem;
                    }).then(function () {
                        MenuService.updateMobileMenu(ProductsService.locationDetail.locationId);
                    }).then(ProductsService.loadProducts)
                        .then(ProductsService.loadAllItems)
                        .then(function () {
                            $scope.isSavingItem = false;
                            $scope.$close(finalResponse);
                        })
                        .catch(function (e) {
                            $scope.isSavingItem = false;

                            // duplicate upc
                            if (e.data.exception.appCode === 468 || e.exception.appCode == 468) {
                                PosAlertService.showAlertByName('general-error', {
                                    title: 'product.item.upc.used',
                                    message: e.data.exception.message
                                });
                            }
                        });
                }
            };

            const UploadStatus = Object.freeze(
                {
                    'NONE': 1,
                    'PREPARED': 2,
                    'COMPLETED': 3,
                    'ERROR': 4,
                    'DELETED': 5
                }
            );

            function createSingleFileUploader (url, item) {
                var uploaderConfig = {};
                uploaderConfig.url = url;
                var uploader = new FileUploader(uploaderConfig);
                uploader.filters.push({
                    name: 'imageFilter',
                    fn: function (item /* {File|FileLikeObject} */, options) {
                        var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                        return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
                    }
                });
                uploader.onAfterAddingFile = function (fileItem) {
                    // The image uploader should be accepting only 1 file. However, there does not seem to be a way
                    // to remove or override the existing file, and `queueLimit` of 1 simply throws an error when there
                    // is already a file. This line ensures that only 1 file is chosen for upload.
                    this.queue = [this.queue[this.queue.length - 1]];
                };
                uploader.onAfterAddingAll = function (addedFileItems) {
                };
                return uploader;
            }

            var imageUploader = $scope.imageUploader = createSingleFileUploader('/freshideas/web/menu/item/images', $scope.item);
            imageUploader.onAfterAddingFile = function (fileItem) {
                const localeImage = $scope.item.languageImageMap[fileItem.languageId];

                // clear compressedImage if necessary
                localeImage.compressedImage = null;
                localeImage.isImageCompressing = true;

                const compressor = new CanvasCompress({
                    type: CanvasCompress.isSupportedType(fileItem.file.type) ? fileItem.file.type : CanvasCompress.MIME.JPEG,
                    width: 800,
                    height: 800,
                    quality: 0.3,
                });

                compressor.process(fileItem._file).then((res) => {
                    localeImage.compressedImage = res.result;
                    localeImage.compressedImage.src = URL.createObjectURL(res.result.blob);
                    localeImage.queueIndex = (this.queue.length - 1);
                    localeImage.fileName = fileItem._file.name;
                    localeImage.isImageCompressing = false;
                    $scope.uploadStatus = UploadStatus.PREPARED;
                });
            };

            imageUploader.onCompleteAll = function (fileItem, response, status, headers) {
                $scope.uploadStatus = UploadStatus.COMPLETED;
            };
            imageUploader.onBeforeUploadItem = function (item) {
                const localeImage = $scope.item.languageImageMap[item.languageId];
                // Upload compressed blob instead of original file.
                item._file = localeImage.compressedImage.blob;

                let imageId = '';
                if (localeImage && localeImage.menuItemImageId) {
                    imageId = localeImage.menuItemImageId;
                }

                if ($scope.item && $scope.item.locationServicePeriodMenuId) {
                    item.formData.push({
                        id: $scope.item.locationServicePeriodMenuId,
                        languageId: item.languageId,
                        imageId: imageId,
                        name: $scope.item.name
                    });
                }
            };
            imageUploader.onErrorItem = function (fileItem, response, status, headers) {
                // 413: Entity too large
                if (status == 413) {
                    PosAlertService.showAlertByName('file-upload-failed-large-file');
                } else if (response.exception.appCode === 454 || response.exception.appCode === 455 || response.exception.appCode === 456) {
                    PosAlertService.showAlertByName('general', {
                        title: 'product.item.image.upload.error.title',
                        message: response.exception.message
                    });
                } else {
                    PosAlertService.showAlertByName('file-upload-failed');
                }
            };

            $scope.beginUpload = function () {
                // Commented by chris lo, march 23rd, 2021.
                // we cannot simply upload all from the uploader's queue with imageUploader.uploadAll()
                // e.g. if a person adds 1 image for 2 locales, queue is 2 (this is fine)
                // if the person decides to switch one of the images, queue will now be 3.
                // while the newest image will overwrite the previous image,
                // this would have called /images 3 times unnecessarily.
                // thus we need to ensure that we only upload with the recorded queue index.
                const languageImageMap = $scope.item.languageImageMap;
                for (const key in languageImageMap) {
                    if (languageImageMap.hasOwnProperty(key)) {
                        if (languageImageMap[key].compressedImage) {
                            imageUploader.uploadItem(languageImageMap[key].queueIndex);
                        }
                    }
                }
            };

            $scope.deleteMenuItemImage = function (item, languageImageProperty) {
                PosAlertService.showAlertByName('general-alert', {
                    title: $translate.instant('product.item.image.delete.title'),
                    message: $translate.instant('product.item.image.delete.confirmation'),
                    modalCallback: function () {
                        languageImageProperty.imageDeleteConfirmation = true;
                        $scope.imageDeleteConfirmation = true;
                    }
                });
            };

            $scope.categoriesToRemove = [];
            $scope.removeCategory = function (category) {
                $scope.categoriesToRemove.push(category);
                var categoryIndex = $scope.item.categories.indexOf(category);
                if (categoryIndex > -1) {
                    $scope.item.categories.splice(categoryIndex, 1);
                }
            };

            $scope.colorOptions = [
                '#e6e9ed',
                '#ffcf47',
                '#40d0ad',
                '#69b5f8',
                '#ee85c1',
                '#ff585b',

                '#ddcfc7',
                '#f7efcb',
                '#b3e0b3',
                '#a2d5ff',
                '#9e88dd',
                '#ffaa8e'
            ];
            $scope.toggleColorOptions = function () {
                $scope.showColorOptions = !$scope.showColorOptions;
            };
            $scope.setColor = function (color) {
                $scope.item.color = color;
            };

            $scope.setFormDirty = function (field) {
                // `field` not needed for now since the input elements that are calling this
                // method are not being tracked (will nedd to update the directive for those
                // inputs to include ngModel).
                if (field === 'mealPlanEligible') {
                    $scope.item.mealEquivalencyEnabled = false;
                } else if (field === 'mealEquivalencyEnabled') {
                    $scope.item.mealPlanEligible = false;
                }

                $scope.ProductItemCreateForm.$dirty = true;
            };

            var areModifiersChanged = false;
            $scope.addModifier = function () {
                var modalInstance = $modal.open({
                    templateUrl: 'products/menuV2/templates/product.modifier.tpl.html',
                    controller: 'ProductModifierCtrl',
                    windowClass: 'modal-70 products2 products-modifier',
                    animation: true,
                    backdrop: 'static',
                    resolve: {
                        parentItem: function () {
                            return $scope.item;
                        },
                        currentPriceOverride: function () {
                            return $scope.item.priceReplacedBy;
                        },
                        isMasterMenu: function () {
                            return $scope.isMasterMenu;
                        },
                        isRootCompany: function () {
                            return $scope.isRootCompany;
                        },
                        isCampus: function () {
                            return CurrentSession.isCampus();
                        },
                        organizationLanguages: function () {
                            return $scope.organizationLanguages;
                        },
                        menus: function () {
                            return $scope.menus;
                        },
                        isNew: function () {
                            return true;
                        }
                    }
                });

                modalInstance.result.then(function (result) {
                    areModifiersChanged = true;

                    if (result.parentItem) {
                        $scope.item.children = result.parentItem.children;
                        $scope.item.priceReplacedBy = result.parentItem.priceReplacedBy;
                    }

                    if (result.modifier) {
                        $scope.item.children = $scope.item.children || [];
                        $scope.item.children.push(result.modifier);
                    }
                });
            };


            const findItem = async (param) => {
                return new Promise(async (resolve, reject) => {
                    try {
                        // search.servicePeriodMenuIds can be either locationServicePeriodMenuId or menuItemId
                        // as the modifier could be in menu v2 structure.
                        // e.g. when a modifier is created while editing a new item
                        // and it has been passed back to the item controller from the modifier controller
                        var search = {
                            servicePeriodMenuIds: param.locationServicePeriodMenuId || param.menuItemId,
                            menuId: $scope.menus.selected.menuId,
                            itemsOnly: false
                        };

                        let {posMenuItemEntries} = await Menu.itemSearch(search).$promise;
                        let items = (posMenuItemEntries && posMenuItemEntries.length) ? posMenuItemEntries[0].entries : [];
                        resolve(items);
                    } catch (error) {
                        console.log(error);
                        resolve([]);
                    }
                });
            };

            $scope.editModifier = async (modifier) => {
                let items = await findItem(modifier);
                let foundItem = items.find((item) => {
                    return item.locationServicePeriodMenuId === modifier.locationServicePeriodMenuId || modifier.menuItemId;
                });

                var modalInstance = $modal.open({
                    templateUrl: 'products/menuV2/templates/product.modifier.tpl.html',
                    controller: 'ProductModifierCtrl',
                    windowClass: 'modal-70 products2 products-modifier',
                    animation: true,
                    backdrop: 'static',
                    resolve: {
                        modifier: function () {
                            return foundItem;
                        },
                        parentItem: function () {
                            return $scope.item;
                        },
                        currentPriceOverride: function () {
                            return $scope.item.priceReplacedBy;
                        },
                        isMasterMenu: function () {
                            return $scope.isMasterMenu;
                        },
                        isRootCompany: function () {
                            return $scope.isRootCompany;
                        },
                        isCampus: function () {
                            return CurrentSession.isCampus();
                        },
                        organizationLanguages: function () {
                            return $scope.organizationLanguages;
                        },
                        menus: function () {
                            return $scope.menus;
                        },
                        isNew: function () {
                            return false;
                        }
                    }
                });

                modalInstance.result.then(function (result) {
                    areModifiersChanged = true;

                    if (result.parentItem) {
                        $scope.item.children = result.parentItem.children;
                        $scope.item.priceReplacedBy = result.parentItem.priceReplacedBy;
                    }

                    if (result.modifier) {
                        $scope.item.children = $scope.item.children || [];
                        var modifierIndex = $scope.item.children.indexOf(modifier);
                        $scope.item.children.splice(modifierIndex, 1, result.modifier);
                    }
                });
            };
            $scope.removeModifier = function (modifier, $event) {
                $event.preventDefault();
                $event.stopPropagation();

                var modifierIndex = $scope.item.children.indexOf(modifier);

                if (modifierIndex > -1) {
                    ConfirmModalService.openConfirmModal(
                        'product.item.modifier.delete.title',
                        'product.item.modifier.delete.confirmation',
                        undefined, // `clickAction` callback
                        {
                            modifierName: modifier.name
                        },
                        undefined, // `scope`
                        true // `allowCancel`
                    ).then(function () {
                        return confirmRemoveModifier(modifier, modifierIndex);
                    }).catch(function () {

                    });
                }
            };

            $scope.modifiersToBeRemoved = [];
            var confirmRemoveModifier = function (modifier, modifierIndex) {
                if ($scope.isNew) {
                    $scope.item.children.splice(modifierIndex, 1);
                } else {
                    $scope.modifiersToBeRemoved.push(modifier.locationServicePeriodMenuId);
                    $scope.item.children.splice(modifierIndex, 1);
                }
            };

            // this is commented out until a deleteMenuItem endpoint is available.
            // var confirmDeleteItem = function () {
            //     return MenuService.deleteMenuItem($scope.item)
            //             .then(function () {
            //                 $scope.item.deleted = true;
            //                 $scope.$close($scope.item);
            //             });
            // };

            $scope.deleteMenuItem = function () {
                ConfirmModalService.openConfirmModal(
                    'product.item.delete.title',
                    'product.item.delete.confirmation',
                    undefined, // `clickAction` callback
                    {
                        itemName: $scope.item.name
                    },
                    undefined, // `scope`
                    true // `allowCancel`
                ).then(function () {
                    return confirmDeleteMenuItem(item);
                }).catch(function () {

                });
            };

            var confirmDeleteMenuItem = function () {
                $scope.isSavingItem = true;
                const isClearUpc = true;
                return MenuService.deleteItemFromMenus($scope.item.locationServicePeriodMenuId, [ProductsService.getSelectedMenuId()], $scope.item.children, isClearUpc)
                    .then(ProductsService.loadProducts)
                    .then(ProductsService.loadAllItems)
                    .then(function () {
                        if (!$scope.isTopLevel) {
                            $state.reload();
                        }
                        $scope.isSavingItem = false;
                        MenuService.updateMobileMenu(ProductsService.locationDetail.locationId);
                        $scope.$close();
                    })
                    .catch(function () {
                        $scope.isSavingItem = false;
                    });
            };

            $scope.printers = MenuService.printers;

            $scope.transformNewTag = function (newTagName) {
                $scope.ProductItemCreateForm.itemTagNames.$dirty = true;

                return {
                    name: newTagName
                };
            };

            var checkIfAllReceiptsToggledOn = function (type) {
                switch (type) {
                    case 'customer':
                        if ($scope.item.printOnKitchenSheet) {
                            $scope.item.printOnAllTypes = true;
                        }
                        break;
                    case 'kitchen':
                        if ($scope.item.printOnCustomerReceipt) {
                            $scope.item.printOnAllTypes = true;
                        }
                        break;
                }
            };

            $scope.printTypeToggled = function (type, enabled) {
                if (enabled) {
                    checkIfAllReceiptsToggledOn(type);
                } else {
                    if (type === 'customer') {
                        var foundModifierPrice = $scope.item.children.find(function (modifier) {
                            return modifier.children.find(function (modifierOption) {
                                return modifierOption.price;
                            });
                        });
                        var foundItemPrice = $scope.item.price;
                        if (foundItemPrice || foundModifierPrice) {
                            PosAlertService.showAlertByName('general', {
                                title: 'pos.products.item.receipt.cannot.hide.title',
                                message: 'pos.products.item.receipt.cannot.hide.description'
                            });
                            // cancel locationToggle directive
                            return true;
                        }
                    }
                }
            };

            $scope.isHiddenOnCustomerReceipt = function () {
                if (!$scope.item.printOnCustomerReceipt) {
                    return true;
                }
                return false;
            };

            $scope.showReceiptErrorIfHidden = function () {
                if (!$scope.item.printOnCustomerReceipt) {
                    PosAlertService.showAlertByName('general', {
                        title: 'pos.products.item.receipt.cannot.set.price.title',
                        message: 'pos.products.item.receipt.cannot.set.price.description'
                    });
                }
            };

            $scope.removeItemThirdPartyPrice = function (item) {
                item.third_party_price = undefined;
            };

            $scope.init = async function () {
                setInitialOrder();

                await reloadTaxRates();
                setTaxRateName();
                parseTaxRules();

                await reloadPrinters();
                setPrinterName();

                reloadTags();
                loadItemTagNames();

                $scope.item.menuVariations = [];
                MenuService.setTranslations($scope.item, $scope.organizationLanguages);
                MenuService.setLanguageImageMap($scope.item, $scope.organizationLanguages);
                MenuService.setAssociatedMenuNames($scope.item);
                $scope.menuAssociations = MenuService.setMenuAssociations($scope.menus);
                $scope.originalItem = angular.copy($scope.item);

                $scope.uploadStatus = UploadStatus.NONE;
                $scope.imageDeleteConfirmation = false;

                // used to temporarily disable image uploads on iPad due to lack of camera permission
                $scope.platform = navigator.platform;

                if ($scope.isNew) {
                    $scope.item.categories = [];
                    $scope.item.loyaltyEnabled = true;
                    $scope.item.allowBackorders = true;
                    $scope.item.targetQuantity = 0;
                    $scope.item.currentQuantity = 0;
                    if (targetCategory) {
                        $scope.item.categories.push(targetCategory);
                    }
                } else {
                    $scope.item.categories = [];
                    _.each($scope.allProductCategories, function (productCategory) {
                        if ($scope.item.linkedParentIdsByMenu[ProductsService.getSelectedMenuId()]
                            && $scope.item.linkedParentIdsByMenu[ProductsService.getSelectedMenuId()].indexOf(productCategory.locationServicePeriodMenuId) > -1) {
                            $scope.item.categories.push(productCategory);
                        }
                    });

                    intiailizeSortableModifierList();
                }
            };
            var setInitialOrder = function () {
                if (index >= 0 && $scope.isNew) {
                    $scope.item.menuOrderId = index + 1;
                }
            };
            $scope.init();

            // Intercept closing event if modifiers have changed to force
            // a product list refresh
            $scope.$on('modal.closing', function (e, action, close) {
                if (areModifiersChanged && item && item.locationServicePeriodMenuId) {
                    e.preventDefault();

                    return ProductsService.loadProducts()
                        .then(ProductsService.loadAllItems)
                        .then(function () {
                            areModifiersChanged = false;

                            if (close) {
                                $scope.$close();
                            } else {
                                $scope.$dismiss();
                            }
                        });
                }
            });

            $scope.redirectToSetup = function () {
                var params = {};
                params.requestedPermission = 'settings';
                params.callback = function (pinResponse) {
                    $scope.$close();
                    $state.go('freshideas.setup');
                };
                params.errorCallback = function (error) {
                    if (error) {
                        var exception = error.exception || {};
                        if (exception.appCode === 412) {
                            PosAlertService.showAlertByName('manager-pin-invalid', {
                                title: 'general.error.tiles.setup.manager-pin.ttl'
                            });
                        } else {
                            PosAlertService.showAlertByName('manager-pin-invalid', {
                                title: 'general.error.tiles.setup.manager-pin.ttl',
                                message: exception.message || ''
                            });
                        }
                    }
                };
                params.verifyAllUserPinsForCompany = true;
                params.forceCheckIfManagerLogin = true;
                params.message = 'general.popup.manager-pin.ttl';
                $scope.$emit('PincodeAuthentication:Required', params);
            };
        }])
        .value('item', undefined)
        .value('index', -1)
        .value('upc', undefined)
        .value('targetCategory', undefined);
};
