'use strict';

/* globals ngGridFlexibleHeightPlugin */

const angular = require('angular');
const freshideasResourcesSettings = require('../common/resources/settings.js').default;

let freshideasSettings = angular.module('freshideas.settings', [freshideasResourcesSettings.name]).controller('SettingsCtrl', [
    '$scope',
    '$modal',
    '$q',
    '$timeout',
    '$filter',
    '$state',
    'Company',
    'Lookup',
    'Settings',
    'Security',
    '$translate',
    'notificationTranslationHelper',
    'ErrorUtil',
    'EnvConfig',
    'Utils',
    'NgGridSortService',
    'NgGridTemplateService',
    function ($scope, $modal, $q, $timeout, $filter, $state, Company, Lookup, Settings, Security, $translate, notificationTranslationHelper, ErrorUtil, EnvConfig, Utils, NgGridSortService, NgGridTemplateService) {
        $scope.transactionTypeOptions = [
            {
                id: 'CASH',
                name: 'settings.tenderTypes.cash'
            },
            {
                id: 'DECLINE',
                name: 'settings.tenderTypes.decline'
            },
            {
                id: 'INCLINE',
                name: 'settings.tenderTypes.incline'
            }
        ];
        $scope.tenderTypesPagingOptions = {
            currentRowNumber: 1,
            currentPage: 1,
            pageSize: 10,
            startRecord: 1,
            endRecord: 0,
            pageSizeOptions: Utils.buildDefaultPageSizes()
        };
        $scope.taxRatesPagingOptions = {
            currentRowNumber: 1,
            currentPage: 1,
            pageSize: 10,
            startRecord: 1,
            endRecord: 0,
            pageSizeOptions: Utils.buildDefaultPageSizes()
        };
        $scope.servicePeriodsPagingOptions = {
            currentRowNumber: 1,
            currentPage: 1,
            pageSize: 10,
            startRecord: 1,
            endRecord: 0,
            pageSizeOptions: Utils.buildDefaultPageSizes()
        };
        $scope.selectedTenderTypes = [];
        $scope.selectedServicePeriods = [];
        $scope.selectedTaxRates = [];
        $scope.newEntry = {
            code: '',
            description: '',
            transactionType: 'CASH'
        };
        $scope.taxRateEntry = {
            taxRate: 0,
            taxRateName: '',
            companyId: 0
        };
        $scope.widgets = {};
        $scope.widgets.users = {collapse: false};
        $scope.widgets.tenderTypes = {collapse: true};
        $scope.widgets.servicePeriods = {collapse: true};
        $scope.widgets.taxRates = {collapse: true};
        $scope.widgets.email = {collapse: true};
        $scope.widgets.roleAccess = {collapse: true};
        $scope.selectedCompany = {id: undefined};
        $scope.init = function () {
            $scope.isFullAdmin = Security.getUser().permission === 'FULLADMIN';
            Lookup.companies(function (response) {
                $scope.companies = response;
                var found = _.find($scope.companies, function (item) {
                    if (item.selected) {
                        return item;
                    }
                });

                if (!$scope.isFullAdmin) {
                    $scope.selectedCompany.id = found.id;
                }

                $scope.loadSettings();
            });
            Settings.getEmailSettings(function (response) {
                $scope.emailSettings = response;
            });
            Settings.getTenderTypes(function (response) {
                $scope.tenderTypes = response.entries;
                $scope.tenderTypesPagingOptions.endRecord = $scope.tenderTypes.length;
            });
        };
        $scope.loadSettings = function () {
            $scope.servicePeriods = [];
            $scope.taxRates = [];
            $scope.roles = {};
            $scope.roles.types = {};
            $scope.permissions = undefined;

            Settings.getServicePeriods({'companyId': $scope.selectedCompany.id}, function (response) {
                $scope.servicePeriods = response.entries;
                $scope.servicePeriodsPagingOptions.endRecord = $scope.servicePeriods.length;
            });
            $scope.getAllRoles($scope.selectedCompany.id);
        };
        $scope.backToMerchantList = () => {
            $state.go('freshideas.setup');
        };
        $scope.updateEmailSettings = function () {
            Settings.updateEmailSettings({}, $scope.emailSettings, function (response) {
                notificationTranslationHelper.notifyMessage('settings.emailSettings.update.success');
            }, function (error) {
                notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
            });
        };
        $scope.editCompanyById = function (companyId) {
            var modalInstance = $modal.open({
                templateUrl: 'common/modals/companyModal.tpl.html',
                controller: 'EditCompanyCtrl',
                backdrop: 'static',
                resolve: {
                    companyId: function () {
                        return companyId;
                    }
                }
            });
            modalInstance.result.then(function (success) {
                $scope.init(); // reload companies
            });
        };
        $scope.deleteCompanyById = function (companyId) {
            var company = _.findWhere($scope.companies, {'id': companyId});
            Company.deleteCompany({'companyId': companyId}, function (success) {
                notificationTranslationHelper.notifyMessage('users.deleteCompany.success', {companyName: company.name});
                $scope.init();
            }, function (error) {
                notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
            });
        };
        $scope.addTenderTypeEntry = function (tenderType) {
            Settings.addTenderType({}, tenderType, function (response) {
                tenderType.code = '';
                tenderType.description = '';
                tenderType.transactionType = 'DECLINE';
                $scope.tenderTypesForm.$setPristine();
                $scope.init();
                notificationTranslationHelper.notifyMessage('settings.tenderType.add.success', {code: tenderType.code});
            }, function (error) {
                notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
            });
        };
        $scope.addNewServicePeriod = function () {
            var modalInstance = $modal.open({
                templateUrl: 'settings/settings.service-periods.edit.tpl.html',
                controller: 'SettingsServicePeriodEditCtrl',
                windowClass: 'modal-fullscreen',
                resolve: {
                    selectedCompanyId: function () {
                        return $scope.selectedCompany.id;
                    },
                    companies: function () {
                        return $scope.companies;
                    },
                    isEdit: function () {
                        return false;
                    },
                    servicePeriod: function () {
                        return undefined;
                    }
                },
                backdrop: 'static'
            });
            modalInstance.result.then(function (servicePeriod) {
                notificationTranslationHelper.notifyMessage('settings.servicePeriod.add.success', {code: servicePeriod.code});
                $scope.init();
            });
        };
        $scope.updateServicePeriod = function (servicePeriod) {
            var modalInstance = $modal.open({
                templateUrl: 'settings/settings.service-periods.edit.tpl.html',
                controller: 'SettingsServicePeriodEditCtrl',
                windowClass: 'modal-fullscreen',
                resolve: {
                    selectedCompanyId: function () {
                        return $scope.selectedCompany.id;
                    },
                    companies: function () {
                        return $scope.companies;
                    },
                    isEdit: function () {
                        return true;
                    },
                    servicePeriod: function () {
                        return servicePeriod;
                    }
                },
                backdrop: 'static'
            });
            modalInstance.result.then(function (servicePeriod) {
                notificationTranslationHelper.notifyMessage('settings.servicePeriod.update.success', {code: servicePeriod.code});
                $scope.init();
            });
        };
        $scope.deleteServicePeriod = function (period, isBatch) {
            return Settings.deleteServicePeriod({servicePeriodId: period.servicePeriodId}, function (response) {
                if (!isBatch) {
                    notificationTranslationHelper.notifyMessage('freshideas.settings.servicePeriod.delete.success');
                    $scope.init();
                }
            }, function (error) {
                notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
            }).$promise;
        };
        $scope.deleteSelectedServicePeriods = function () {
            var deletedPromises = [];
            _.each($scope.selectedServicePeriods, function (entry) {
                deletedPromises.push($scope.deleteServicePeriod(entry, true));
            });
            $q.all(deletedPromises).then(function () {
                notificationTranslationHelper.notifyMessage('freshideas.settings.servicePeriod.deleteSelected.success');
                $scope.init();
            });
            $scope.selectedServicePeriods.length = 0;
        };
        $scope.deleteTenderType = function (tenderType, isBatch) {
            return Settings.deleteTenderType({tenderTypeId: tenderType.tenderTypeId}, function (response) {
                if (!isBatch) {
                    notificationTranslationHelper.notifyMessage('freshideas.settings.tenderType.delete.success');
                    $scope.init();
                }
            }, function (error) {
                notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
            }).$promise;
        };
        $scope.deleteSelectedTenderTypes = function () {
            var deletedPromises = [];
            _.each($scope.selectedTenderTypes, function (entry) {
                deletedPromises.push($scope.deleteTenderType(entry, true));
            });
            $q.all(deletedPromises).then(function () {
                notificationTranslationHelper.notifyMessage('freshideas.settings.tenderType.deleteSelected.success');
                $scope.init();
            });
            $scope.selectedTenderTypes.length = 0;
        };
        $scope.deleteTaxRate = function (taxRate, isBatch) {
            return Settings.deleteTaxRate({taxRateId: taxRate.taxRateId}, function (response) {
                if (!isBatch) {
                    notificationTranslationHelper.notifyMessage('freshideas.settings.taxRate.delete.success');
                    $scope.init();
                }
            }, function (error) {
                notificationTranslationHelper.notifyError(error.data.error, null, false);
            }).$promise;
        };
        $scope.deleteSelectedTaxRates = function () {
            var deletedPromises = [];
            _.each($scope.selectedTaxRates, function (entry) {
                deletedPromises.push($scope.deleteTaxRate(entry, true));
            });
            $q.all(deletedPromises).then(function () {
                notificationTranslationHelper.notifyMessage('freshideas.settings.taxRates.deleteSelected.success');
                $scope.init();
            });
            $scope.selectedTaxRates.length = 0;
        };
        $scope.gridOptions = {
            columnDefs: [
                {
                    field: 'tenderTypeId',
                    displayName: 'settings.tenderTypes.tenderTypeId',
                    width: '80',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'code',
                    displayName: 'settings.tenderTypes.code',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'description',
                    displayName: 'settings.tenderTypes.description',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'transactionType',
                    displayName: 'settings.tenderTypes.transactionType',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: '',
                    displayName: 'general.actions',
                    width: '140',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                    cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text><div class="action-buttons">' + '<a class="red" confirm-modal confirm-message="{{\'freshideas.settings.tenderTypes.confirmDelete\' | translate}}" ' + 'confirm-title="{{\'freshideas.settings.tenderTypes.confirmDelete.title\' | translate}}" on-confirm="deleteTenderType(row.entity)" has-perm resource="settings:tender_types:delete">' + '<i class="fa fa-trash-o bigger-130"></i></a></div></span></div>'
                }
            ],
            plugins: [new ngGridFlexibleHeightPlugin({minHeight: 250})],
            showFooter: true,
            footerRowHeight: 30,
            enableColumnResize: true,
            multiSelect: true,
            showSelectionCheckbox: true,
            selectedItems: $scope.selectedTenderTypes,
            enableSorting: true,
            selectWithCheckboxOnly: true,
            enableHighlighting: true,
            footerTemplate: '<div class="grid-footer"><div class="col-xs-4"></div><div class="col-xs-4"><pager align="false" ng-model="tenderTypesPagingOptions.currentPage" ' + 'total-items="tenderTypesPagingOptions.totalItems" items-per-page="tenderTypesPagingOptions.pageSize" ng-change="load();"></pager>' + '</div><div class="col-xs-4"><span translate="general.records"/> {{tenderTypesPagingOptions.startRecord}} - {{tenderTypesPagingOptions.endRecord}}</div></div>',
            data: 'tenderTypes'
        };
        $scope.taxRateGridOptions = {
            columnDefs: [
                {
                    field: 'taxRateId',
                    displayName: 'settings.taxRates.taxRateId',
                    width: '80',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'taxRateName',
                    displayName: 'settings.taxRates.taxRateName',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'taxRate',
                    displayName: 'settings.taxRates.taxRate',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                    cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text><span >{{COL_FIELD | percentage:2}}</span></span></div>'
                },
                {
                    field: '',
                    displayName: 'general.actions',
                    width: '140',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                    cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text><div class="action-buttons">' + '<a class="blue" ng-click="updateTaxRate(row.entity)" has-perm resource="settings:tax_rates:edit"><i class="fa fa-pencil bigger-130"></i></a>' + '<a class="red" confirm-modal confirm-message="{{\'freshideas.settings.taxRates.confirmDelete\' | translate}}" ' + 'confirm-title="{{\'freshideas.settings.taxRates.confirmDelete.title\' | translate}}" on-confirm="deleteTaxRate(row.entity)" has-perm resource="settings:tax_rates:delete">' + '<i class="fa fa-trash-o bigger-130"></i></a></div></span></div>'
                }
            ],
            plugins: [new ngGridFlexibleHeightPlugin({minHeight: 250})],
            showFooter: true,
            footerRowHeight: 30,
            multiSelect: true,
            enableColumnResize: true,
            showSelectionCheckbox: true,
            selectedItems: $scope.selectedTaxRates,
            enableSorting: true,
            selectWithCheckboxOnly: true,
            enableHighlighting: true,
            footerTemplate: '<div class="grid-footer"><div class="col-xs-4"></div><div class="col-xs-4"><pager align="false" ng-model="taxRatesPagingOptions.currentPage" ' + 'total-items="taxRatesPagingOptions.totalItems" items-per-page="taxRatesPagingOptions.pageSize" ng-change="load();"></pager>' + '</div><div class="col-xs-4"><span translate="general.records"/> {{taxRatesPagingOptions.startRecord}} - {{taxRatesPagingOptions.endRecord}}</div></div>',
            data: 'taxRates'
        };
        $scope.servicePeriodGridOptions = {
            columnDefs: [
                {
                    field: 'servicePeriodId',
                    displayName: 'settings.servicePeriods.servicePeriodId',
                    width: '80',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'code',
                    displayName: 'settings.servicePeriods.code',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'description',
                    displayName: 'settings.servicePeriods.description',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate
                },
                {
                    field: 'startTime',
                    displayName: 'settings.servicePeriods.startTime',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                    cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text><span >{{COL_FIELD | date:\'shortTime\'}}</span></span></div>'
                },
                {
                    field: 'endTime',
                    displayName: 'settings.servicePeriods.endTime',
                    width: '*',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                    cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text><span >{{COL_FIELD | date:\'shortTime\'}}</span></span></div>'
                },
                {
                    field: '',
                    displayName: 'general.actions',
                    width: '140',
                    headerCellTemplate: NgGridTemplateService.filterHeaderTemplate,
                    cellTemplate: '<div class="ngCellText" ng-class="col.colIndex()"><span ng-cell-text><div class="action-buttons">' + '<a class="blue" ng-click="updateServicePeriod(row.entity)" has-perm resource="settings:service_periods:edit"><i class="fa fa-pencil bigger-130"></i></a>' + '<a class="red" confirm-modal confirm-message="{{\'freshideas.settings.servicePeriods.confirmDelete\' | translate}}" ' + 'confirm-title="{{\'freshideas.settings.servicePeriods.confirmDelete.title\' | translate}}" on-confirm="deleteServicePeriod(row.entity)" has-perm resource="settings:service_periods:delete">' + '<i class="fa fa-trash-o bigger-130"></i></a></div></span></div>'
                }
            ],
            plugins: [new ngGridFlexibleHeightPlugin({minHeight: 250})],
            showFooter: true,
            enableColumnResize: true,
            footerRowHeight: 30,
            multiSelect: true,
            showSelectionCheckbox: true,
            selectedItems: $scope.selectedServicePeriods,
            enableSorting: true,
            selectWithCheckboxOnly: true,
            enableHighlighting: true,
            footerTemplate: '<div class="grid-footer"><div class="col-xs-4"></div><div class="col-xs-4"><pager align="false" ng-model="servicePeriodsPagingOptions.currentPage" ' + 'total-items="servicePeriodsPagingOptions.totalItems" items-per-page="servicePeriodsPagingOptions.pageSize" ng-change="loadComputers();"></pager>' + '</div><div class="col-xs-4"><span translate="general.records"/> {{servicePeriodsPagingOptions.startRecord}} - {{servicePeriodsPagingOptions.endRecord}}</div></div>',
            data: 'servicePeriods'
        };
        $scope.createRole = function (roleName) {
            var modalInstance = $modal.open({
                templateUrl: 'common/modals/modalCreateRole.tpl.html',
                controller: 'CreateRoleCtrl',
                windowClass: 'modal-50',
                backdrop: 'static',
                resolve: {
                    selectedCompanyId: function () {
                        return $scope.selectedCompany.id;
                    }
                }
            });
            modalInstance.result.then(function (role) {
                $scope.getAllRoles($scope.selectedCompany.id, function () {
                    $scope.roles.currentType = role.roleName;
                    $scope.setCurrentPermissions();
                });
            });
        };
        $scope.getAllRoles = function (companyId, callback) {
            $scope.roles = {};
            Settings.getRoles({companyId: companyId}, function (response) {
                $scope.roles.types = response;
                if (callback) {
                    callback.call();
                }
            });
        };
        $scope.getCurrentRolePermissions = function () {
            if ($scope.roles.currentType) {
                // var roleName = $scope.roles.currentType.roleName;
                return $scope.allPermissions[$scope.roles.currentType];
            }
            return undefined;
        };
        $scope.setCurrentRole = function (role) {
            $scope.roles.currentType = role.roleName;
            $scope.roles.currentId = role.roleId;
            $scope.setCurrentPermissions();
        };
        $scope.setCurrentPermissions = function () {
            Settings.getRolePermissions({'roleId': $scope.roles.currentId}, function (response) {
                initializePermissions(response.result);
            }); /*
                if ($scope.roles.currentType) {
                    var currentRole = _.find($scope.roles.types, function (role) {
                        return role.roleName == $scope.roles.currentType;
                    }) || {};
                    var currentPermissions = JSON.parse(currentRole.permissions) || {};
                    initializePermissions(currentPermissions);
                }
               */
        };
        var initializePermissions = function (permissions) {
            var permissionsCopy = angular.copy(permissions);
            $.extend(true, permissionsCopy, basePermissions);
            appendPermissionTypes(permissionsCopy);
            $scope.permissions = permissionsCopy;
            $scope.originalPermissions = angular.copy(permissionsCopy);
            $scope.rolePermissionChanges = {};
        };
        var appendPermissionTypes = function (permissions, ancestorTypes) {
            ancestorTypes = ancestorTypes || '';
            _.each(permissions, function (permission, key) {
                var permissionType = ancestorTypes ? ancestorTypes + '.' + key : key;
                permission.type = permissionType;
                if (permission.permissions) {
                    appendPermissionTypes(permission.permissions, permissionType);
                }
            });
        };
        $scope.restorePermissions = function () {
            $scope.setCurrentPermissions();
        };
        $scope.updatePermissions = function () {
            var permissionsCopy = angular.copy($scope.permissions);
            sanitizePermissions(permissionsCopy);
            var roleObject = _.find($scope.roles.types, function (role) {
                return role.roleName === $scope.roles.currentType;
            });
            if (roleObject) {
                roleObject.permissions = JSON.stringify(permissionsCopy);
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modalConfirmEditPermissions.tpl.html',
                    controller: 'ConfirmEditPermissions',
                    windowClass: 'modal-50',
                    backdrop: 'static',
                    resolve: {
                        roleObject: function () {
                            return roleObject;
                        },
                        permissionChanges: function () {
                            return $scope.rolePermissionChanges;
                        }
                    }
                });
                modalInstance.result.then(function (role) {
                });
            }
        };
        var sanitizePermissions = function (permissions) {
            _.each(permissions, function (permission) {
                delete permission.label;
                delete permission.order;
                delete permission.type;
                delete permission.base;
                delete permission.managerOverride;
                permission.enabled = !!permission.enabled;
                sanitizePermissions(permission.permissions);
            });
        };
        var basePermissions = {
            'pos': {
                'order': 1,
                'label': 'settings.roleAccess.pos.label',
                'permissions': {
                    'start_end_shift': {
                        'order': 1,
                        'label': 'settings.roleAccess.pos.startEndShift.label'
                    },
                    'view_counts': {
                        'order': 2,
                        'label': 'settings.roleAccess.pos.viewCounts.label'
                    },
                    'void_remove_items': {
                        'order': 3,
                        'label': 'settings.roleAccess.pos.voidRemoveItems.label',
                        'managerOverride': 1
                    },
                    'return_cancel_transactions': {
                        'order': 4,
                        'label': 'settings.roleAccess.pos.returnCancelTransaction.label',
                        'managerOverride': 1
                    },
                    'logout_without_ending_shift': {
                        'order': 5,
                        'label': 'settings.roleAccess.pos.logoutWithoutEndingShift.label',
                        'managerOverride': 1
                    },
                    'refunds': {
                        'order': 6,
                        'label': 'settings.roleAccess.pos.refunds.label',
                        'managerOverride': 1
                    }
                }
            },
            'reports': {
                'order': 2,
                'label': 'settings.roleAccess.reports.label',
                'permissions': {
                    'overview': {
                        'order': 1,
                        'label': 'Overview Report'
                    },
                    'overview_per_15_min': {
                        'order': 2,
                        'label': 'Overview Per 15 Minute Report'
                    },
                    'transaction_report': {
                        'order': 3,
                        'label': 'settings.roleAccess.reports.transactionHistory.label'
                    },
                    'reprint_end_of_shift_report': {
                        'order': 4,
                        'label': 'settings.roleAccess.reports.reprintEndOfShiftReport.label'
                    },
                    'sales_summary_report': {
                        'order': 5,
                        'label': 'settings.roleAccess.reports.salesSummaryReport.label'
                    },
                    'daily_sales_and_meals_served': {
                        'order': 6,
                        'label': 'settings.roleAccess.reports.dailySalesAndMealsServed.label'
                    },
                    'meal_plan_usage': {
                        'order': 7,
                        'label': 'settings.roleAccess.reports.mealPlanUsage.label'
                    },
                    'active_patron_on_meal_plans': {
                        'order': 8,
                        'label': 'settings.roleAccess.reports.activePatronOnMealPlans.label'
                    },
                    'patron_count_per_15_min': {
                        'order': 9,
                        'label': 'settings.roleAccess.reports.patronCountsPer15Min.label'
                    },
                    'patron_activity_statement': {
                        'order': 10,
                        'label': 'settings.roleAccess.reports.patronActivityStatement.label'
                    },
                    'deposit_refund_report': {
                        'order': 11,
                        'label': 'settings.roleAccess.reports.depositRefundReport.label'
                    },
                    'location_revenue_report': {
                        'order': 12,
                        'label': 'settings.roleAccess.reports.locationRevenueReport.label'
                    },
                    'session_audit_report': {
                        'order': 13,
                        'label': 'settings.roleAccess.reports.sessionAuditReport.label'
                    },
                    'mobile_transactions_report': {
                        'order': 14,
                        'label': 'settings.roleAccess.reports.mobileTransactionsReport.label'
                    },
                    'patron_meal_plan_history_report': {
                        'order': 15,
                        'label': 'settings.roleAccess.reports.patronMealPlanHistoryReport.label'
                    },
                    'item_sales_report': {
                        'order': 16,
                        'label': 'settings.roleAccess.reports.itemsSalesReport.label'
                    },
                    'patron_spend_report': {
                        'order': 17,
                        'label': 'settings.roleAccess.reports.patronSpendReport.label'
                    },
                    'participation_rate_report': {
                        'order': 18,
                        'label': 'settings.roleAccess.reports.participationRateReport.label'
                    },
                    'meal_plan_sales_report': {
                        'order': 19,
                        'label': 'settings.roleAccess.reports.mealPlanSalesReport.label'
                    }
                }
            },
            'meals': {
                'order': 3,
                'label': 'settings.roleAccess.mealPlans.label',
                'permissions': {
                    'view': {
                        'order': 1,
                        'label': 'settings.roleAccess.mealPlans.view.label'
                    },
                    'create': {
                        'order': 2,
                        'label': 'settings.roleAccess.mealPlans.create.label'
                    },
                    'edit': {
                        'order': 3,
                        'label': 'settings.roleAccess.mealPlans.edit.label'
                    },
                    'delete': {
                        'order': 4,
                        'label': 'settings.roleAccess.mealPlans.delete.label'
                    }
                }
            },
            'users_patrons': {
                'order': 4,
                'label': 'settings.roleAccess.usersAndPatrons.label',
                'permissions': {
                    'view': {
                        'order': 1,
                        'label': 'settings.roleAccess.usersAndPatrons.createCampus.label'
                    },
                    'create': {
                        'order': 2,
                        'label': 'settings.roleAccess.usersAndPatrons.editCampus.label'
                    },
                    'users': {
                        'order': 3,
                        'label': 'settings.roleAccess.users.label',
                        'permissions': {
                            'view': {
                                'order': 1,
                                'label': 'settings.roleAccess.users.view.label'
                            },
                            'add': {
                                'order': 2,
                                'label': 'settings.roleAccess.users.add.label'
                            },
                            'edit': {
                                'order': 3,
                                'label': 'settings.roleAccess.users.edit.label'
                            },
                            'edit_passwords': {
                                'order': 4,
                                'label': 'settings.roleAccess.users.editPasswords.label'
                            },
                            'delete': {
                                'order': 5,
                                'label': 'settings.roleAccess.users.delete.label'
                            }
                        }
                    },
                    'patrons': {
                        'order': 4,
                        'label': 'settings.roleAccess.patrons.label',
                        'permissions': {
                            'view': {
                                'order': 1,
                                'label': 'settings.roleAccess.patrons.view.label'
                            },
                            'add': {
                                'order': 2,
                                'label': 'settings.roleAccess.patrons.add.label'
                            },
                            'edit_information': {
                                'order': 3,
                                'label': 'settings.roleAccess.patrons.editInformation.label'
                            },
                            'edit_meal_plans': {
                                'order': 4,
                                'label': 'settings.roleAccess.patrons.editMealPlans.label'
                            },
                            'edit_meal_plan_balances': {
                                'order': 5,
                                'label': 'settings.roleAccess.patrons.editMealPlanBalances.label'
                            },
                            'view_transactions': {
                                'order': 6,
                                'label': 'settings.roleAccess.patrons.viewTransactions.label'
                            },
                            'delete': {
                                'order': 7,
                                'label': 'settings.roleAccess.patrons.delete.label'
                            }
                        }
                    },
                    'patron_actions': {
                        'order': 5,
                        'label': 'settings.roleAccess.patronActions.label',
                        'permissions': {
                            'import': {'label': 'settings.roleAccess.patronActions.import.label'},
                            'export': {'label': 'settings.roleAccess.patronActions.export.label'},
                            'export_template': {'label': 'settings.roleAccess.patronActions.exportTemplate.label'},
                            'import_balance_adjustments': {'label': 'settings.roleAccess.patronActions.importBalanceAdjustments.label'},
                            'export_balance_adjustments': {'label': 'settings.roleAccess.patronActions.exportBalanceAdjustments.label'},
                            'export_balance_adjustment_template': {'label': 'settings.roleAccess.patronActions.exportBalanceAdjustmentTemplate.label'}
                        }
                    }
                }
            },
            // Duplicated from users_patrons:patrons. However, with Users and Patrons separated, will need to look at separating users-related
            //   and patorns-related permissions.
            /* 'patrons': {
                'order': 5,
                'label': 'settings.roleAccess.patrons.label',
                'permissions': {
                    'add': {
                        'order': 1,
                        'label': 'settings.roleAccess.patrons.add.label',
                    },
                    'import': {
                        'order': 2,
                        'label': 'settings.roleAccess.patrons.importPatrons.label',
                    },
                    'edit_information': {
                        'order': 3,
                        'label': 'settings.roleAccess.patrons.editInformation.label',
                    },
                    'edit_meal_plan_balances': {
                        'order': 4,
                        'label': 'settings.roleAccess.patrons.editMealPlanBalances.label',
                    },
                }
            },*/
            'inventory': {
                'order': 6,
                'label': 'settings.roleAccess.inventory.label',
                'permissions': {
                    'add_invoice': {'label': 'settings.roleAccess.inventory.addInvoice.label'},
                    'edit_invoice': {'label': 'settings.roleAccess.inventory.editInvoice.label'},
                    'delete_invoice': {'label': 'settings.roleAccess.inventory.deleteInvoice.label'},
                    'maintenance': {
                        'label': 'settings.roleAccess.inventory.maintenance.label',
                        'permissions': {
                            'add_item': {'label': 'settings.roleAccess.inventory.maintenance.addItem.label'},
                            'import_upc_items': {'label': 'settings.roleAccess.inventory.maintenance.importUpcItems.label'},
                            'edit_item': {'label': 'settings.roleAccess.inventory.maintenance.editItem.label'}
                        }
                    }
                }
            },
            'locations': {
                'order': 7,
                'label': 'settings.roleAccess.locations.label',
                'permissions': {
                    'add': {'label': 'settings.roleAccess.locations.add.label'},
                    'edit': {'label': 'settings.roleAccess.locations.edit.label'},
                    'edit_pos_locations': {'label': 'settings.roleAccess.locations.editPosLocations.label'},
                    'export_location_pos_menu': {'label': 'settings.roleAccess.locations.exportLocationPosMenu.label'},
                    'import_menu_items': {'label': 'settings.roleAccess.locations.importMenuItems.label'},
                    'delete': {'label': 'settings.roleAccess.locations.delete.label'}
                }
            },
            'settings': {
                'order': 8,
                'label': 'settings.roleAccess.settings.label',
                'permissions': {
                    'tender_types': {
                        'order': 1,
                        'label': 'settings.roleAccess.settings.tenderTypes.label',
                        'permissions': {
                            'view': {'label': 'settings.roleAccess.settings.tenderTypes.view.label'},
                            'add': {'label': 'settings.roleAccess.settings.tenderTypes.add.label'},
                            'edit': {'label': 'settings.roleAccess.settings.tenderTypes.edit.label'},
                            'delete': {'label': 'settings.roleAccess.settings.tenderTypes.delete.label'}
                        }
                    },
                    'service_periods': {
                        'order': 2,
                        'label': 'settings.roleAccess.settings.servicePeriods.label',
                        'permissions': {
                            'add': {'label': 'settings.roleAccess.settings.servicePeriods.add.label'},
                            'view': {'label': 'settings.roleAccess.settings.servicePeriods.view.label'},
                            'edit': {'label': 'settings.roleAccess.settings.servicePeriods.edit.label'},
                            'delete': {'label': 'settings.roleAccess.settings.servicePeriods.delete.label'}
                        }
                    },
                    'tax_rates': {
                        'order': 3,
                        'label': 'settings.roleAccess.settings.taxRates.label',
                        'permissions': {
                            'view': {'label': 'settings.roleAccess.settings.taxRates.view.label'},
                            'edit': {'label': 'settings.roleAccess.settings.taxRates.edit.label'},
                            'delete': {'label': 'settings.roleAccess.settings.taxRates.delete.label'}
                        }
                    },
                    'emails': {
                        'order': 4,
                        'label': 'settings.roleAccess.settings.emails.label'
                    },
                    'role_access': {
                        'order': 5,
                        'label': 'settings.roleAccess.settings.roleAccess.label'
                    }
                }
            }
        };
        var flagBasePermissions = function (permissions) {
            _.each(permissions, function (permission) {
                permission.base = true;
                flagBasePermissions(permission.permissions);
            });
        };
        flagBasePermissions(basePermissions);
        $scope.toggleDescendantsRolePermission = function (permission) {
            if (permission) {
                _.each(permission.permissions, function (child) {
                    var isChildChanged = child.enabled !== permission.enabled;
                    child.enabled = permission.enabled;
                    if (isChildChanged) {
                        $scope.toggleRolePermissionChanges(child);
                    }
                    $scope.toggleDescendantsRolePermission(child);
                });
            }
        };
        $scope.toggleRolePermission = function (permission) {
            if (permission) {
                permission.enabled = !permission.enabled;
                $scope.toggleRolePermissionChanges(permission);
                $scope.toggleDescendantsRolePermission(permission);
            }
        };
        $scope.rolePermissionChanges = {};
        $scope.toggleRolePermissionChanges = function (permission) {
            var permissionType = permission.type;
            if ($scope.rolePermissionChanges[permissionType]) {
                delete $scope.rolePermissionChanges[permissionType];
            } else {
                $scope.rolePermissionChanges[permissionType] = permission.label;
            }
        };
        $scope.init();
    }
]).controller('EditServicePeriodCtrl', [
    '$scope',
    '$modalInstance',
    '$modal',
    'Settings',
    'servicePeriod',
    'companies',
    'isEdit',
    'selectedCompanyId',
    'Security',
    '$translate',
    'notificationTranslationHelper',
    'ErrorUtil',
    'EnvConfig',
    'Utils',
    'NgGridSortService',
    'NgGridTemplateService',
    function ($scope, $modalInstance, $modal, Settings, servicePeriod, companies, isEdit, selectedCompanyId, Security, $translate, notificationTranslationHelper, ErrorUtil, EnvConfig, Utils, NgGridSortService, NgGridTemplateService) {
        $scope.editMode = isEdit;
        $scope.companies = companies;
        $scope.selectedCompanyId = selectedCompanyId;
        $scope.addServicePeriodEntry = function (servicePeriod) {
            servicePeriod.companyId = selectedCompanyId;
            Settings.addServicePeriod({}, servicePeriod, function (response) {
                $modalInstance.close(response);
            }, function (error) {
                notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
            });
        };
        $scope.updateServicePeriodEntry = function (servicePeriod) {
            Settings.updateServicePeriod({}, servicePeriod, function (response) {
                $modalInstance.close(response);
            }, function (error) {
                notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
            });
        };
        $scope.init = function () {
            if ($scope.editMode === false) {
                $scope.servicePeriodsEntry = {
                    code: '',
                    description: '',
                    startTime: new Date(70, 1, 1, 0, 0, 0, 0),
                    endTime: new Date(70, 1, 1, 23, 59, 59, 0),
                    companyId: $scope.selectedCompanyId
                };
            } else {
                $scope.servicePeriodsEntry = angular.copy(servicePeriod);
                $scope.servicePeriodsEntry.startTime = new Date(servicePeriod.startTime);
                $scope.servicePeriodsEntry.endTime = new Date(servicePeriod.endTime);
            }
        };
        $scope.init();
    }
]).controller('EditLoyaltyPlanCtrl', [
    '$scope',
    '$modalInstance',
    'plan',
    'isEdit',
    'Company',
    'ConfirmModalService',
    'PosAlertService',
    'Settings',
    'CurrentSession',
    'CompanyAttributesService',
    function ($scope, $modalInstance, plan, isEdit, Company, ConfirmModalService, PosAlertService, Settings, CurrentSession, CompanyAttributesService) {
        $scope.editMode = isEdit;

        var DCB_PLAN = $scope.DCB_PLAN = {
            name: 'Cash Card',
            value: 'DCB',
            description: 'A cash card can be used to make any purchase.'
        };
        var MEAL_PLAN = $scope.MEAL_PLAN = {
            name: 'Item Units',
            value: 'MEAL',
            description: 'Only a specific item can be purchased with this plan'
        };
        $scope.planTypes = [MEAL_PLAN];

        if (CompanyAttributesService.hasCashcardMealPlansEnabled()) {
            $scope.planTypes.push(DCB_PLAN);
        }

        $scope.plan = plan || {
            name: '',
            description: '',
            type: '',
        };

        if (!$scope.plan.mealPlanAttribute) {
            $scope.plan.mealPlanAttribute = {};
        }

        $scope.isSaving = false;
        $scope.delete = function () {
            const payload = {
                mealPlanId: plan.mealPlanId
            };

            Settings.deleteMealPlan(payload).$promise.then(function (response) {
                $scope.isSaving = false;
                $modalInstance.close(response);
            }, function (error) {
                PosAlertService.showAlertByName('general-error', {
                    'title': 'settings.mealPlan.delete.error'
                });
                $scope.isSaving = false;
            });
        };

        $scope.isSaving = false;
        $scope.save = function () {
            if ($scope.isSaving) {
                return;
            }

            $scope.isSaving = true;
            const payload = {
                mealPlanId: plan.mealPlanId,
                name: plan.name,
                mealPlanDescription: plan.mealPlanDescription,
                mealPlanType: plan.mealPlanType,
                initialBalance: plan.initialBalance,
                organizationId: plan.organizationId || CurrentSession.getCompany().organizationId,
                mealPlanAttribute: plan.mealPlanAttribute,
                isLoyaltyType: true
            };

            let mealPlanResource = null;


            if (!isEdit) {
                mealPlanResource = Settings.addMealPlan;
            } else {
                mealPlanResource = Settings.updateMealPlan;
            }

            mealPlanResource(payload).$promise.then(function (response) {
                $scope.isSaving = false;
                $modalInstance.close(response);
            }, function (error) {
                PosAlertService.showAlertByName('general-error', {
                    'title': 'settings.mealPlan.save.error'
                });
                $scope.isSaving = false;
            });
        };
    }
]).controller('EditCustomerDisplayCtrl', [
    '$scope',
    '$modalInstance',
    'slide',
    'isEdit',
    'FileUploader',
    'Company',
    'ConfirmModalService',
    'PosAlertService',
    function ($scope, $modalInstance, slide, isEdit, FileUploader, Company, ConfirmModalService, PosAlertService) {
        $scope.editMode = isEdit;

        $scope.daysActive = [
            {
                name: 'Sunday',
                active: false,
            },
            {
                name: 'Monday',
                active: false,
            },
            {
                name: 'Tuesday',
                active: false,
            },
            {
                name: 'Wednesday',
                active: false,
            },
            {
                name: 'Thursday',
                active: false,
            },
            {
                name: 'Friday',
                active: false,
            },
            {
                name: 'Saturday',
                active: false,
            }
        ];

        function getBitPattern () {
            var state = '';
            $scope.daysActive.forEach((day) => {
                state += day.active ? '1' : '0';
            });
            return state;
        }

        function fromBitPattern (pattern) {
            var bitPattern = pattern.split('');
            for (let i = 0; i < $scope.daysActive.length; i++) {
                $scope.daysActive[i].active = (bitPattern[i] === '1') ? true : false;
            }
        }

        function createSingleFileUploader (url, alias, data) {
            var uploaderConfig = {};
            uploaderConfig.url = url;

            var uploader = new FileUploader(uploaderConfig);
            uploader.filters.push({
                name: 'imageFilter',
                fn: function (item /* {File|FileLikeObject} */, options) {
                    var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                    return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
                }
            });
            uploader.onAfterAddingFile = function (fileItem) {
                // The banner uploader should be accepting only 1 file. However, there does not seem to be a way
                // to remove or override the existing file, and `queueLimit` of 1 simply throws an error when there
                // is already a file. This line ensures that only 1 file is chosen for upload.
                this.queue = [this.queue[this.queue.length - 1]];
            };
            uploader.onAfterAddingAll = function (addedFileItems) {
            };
            return uploader;
        }

        function saveProperties (id) {
            let obj = {
                id: id,
                dayActive: getBitPattern()
            };
            Company.updateSecondaryDisplay(obj).$promise.then((res) => {
                $modalInstance.close();
            });
        }

        var imageUploader = $scope.imageUploader = createSingleFileUploader('/freshideas/web/company/upload/secondary-display');
        imageUploader.onCompleteItem = function (fileItem, response, status, headers) {
            if (status < 400) {
                saveProperties(response.id);
            }
        };
        imageUploader.onBeforeUploadItem = function (item) {
            if ($scope.customerDisplayImageEntry && $scope.customerDisplayImageEntry.id) {
                item.formData.push({
                    id: $scope.customerDisplayImageEntry.id
                });
            }
        };
        imageUploader.onErrorItem = function (fileItem, response, status, headers) {
            /**
             * 413: Entity too large
             */
            if (status == 413) {
                PosAlertService.showAlertByName('file-upload-failed-large-file');
            } else {
                PosAlertService.showAlertByName('file-upload-failed-large-file');
            }
        };

        $scope.beginUpload = () => {
            imageUploader.uploadAll();
            if ($scope.customerDisplayImageEntry.id && imageUploader.queue.length == 0) {
                saveProperties($scope.customerDisplayImageEntry.id);
            }
        };

        $scope.delete = () => {
            ConfirmModalService.openConfirmModal(
                'Delete Image',
                'Do you wish to delete this image?',
                null,
                null,
                $scope,
                true
            ).then(() => {
                if ($scope.customerDisplayImageEntry.id) {
                    Company.deleteSecondaryDisplaySlide({id: $scope.customerDisplayImageEntry.id}).$promise.then((res) => {
                        $modalInstance.close();
                    });
                }
            }).catch(() => {
                return; // cancel button is clicked on confirm modal
            });
        };

        $scope.init = function () {
            $scope.customerDisplayImageEntry = angular.copy(slide);
            fromBitPattern(slide.dayActive || '0000000');
        };
        $scope.init();
    }
]).controller('EditShopifyStoreCtrl', [
    '$scope',
    '$modalInstance',
    'shopifyStore',
    'reAuthenticateCallback',
    function ($scope, $modalInstance, shopifyStore, reAuthenticateCallback) {
        $scope.init = () => {
        };

        $scope.reconnect = () => {
            if (!reAuthenticateCallback) {
                return;
            }
            reAuthenticateCallback(shopifyStore)
                .then(() => $modalInstance.close())
                .catch((error) => { });
        };
        $scope.init();
    }
]).controller('EditTaxRateCtrl', [
    '$scope',
    '$modalInstance',
    '$modal',
    'Settings',
    'taxRate',
    'isEdit',
    'selectedCompanyId',
    'CurrentSession',
    '$translate',
    'notificationTranslationHelper',
    'ErrorUtil',
    'EnvConfig',
    'Utils',
    'NgGridSortService',
    'NgGridTemplateService',
    'ConfirmModalService',
    'PosAlertService',
    'isRootCompany',
    'allTaxRates',
    'Lookup',
    function ($scope, $modalInstance, $modal, Settings, taxRate, isEdit, selectedCompanyId, CurrentSession, $translate, notificationTranslationHelper, ErrorUtil, EnvConfig, Utils, NgGridSortService, NgGridTemplateService, ConfirmModalService, PosAlertService, isRootCompany, allTaxRates, Lookup) {
        $scope.editMode = isEdit;
        $scope.selectedCompanyId = selectedCompanyId;
        $scope.isRootCompany = isRootCompany;
        $scope.taxRate = {
            selected: undefined
        };

        $scope.locationName = CurrentSession.getCompany().companyName;

        var isDefaultTaxRatePresent = function (modifiedTaxRate, taxRatesList, isDelete = false) {
            var list = taxRatesList || [];
            if (modifiedTaxRate.default) {
                // Commented By Akash Mehta on 11th April 2020
                // If it is a delete command, we need to send false
                // as you always need a default tax rate
                // Hence, in the case of delete we continue ahead and our fallback
                // code at the bottom of the function takes care of it.
                if (!isDelete) {
                    return true;
                }
            }

            if (!modifiedTaxRate.default) {
                for (var rate of list) {
                    if (rate.taxRateId != modifiedTaxRate.taxRateId && rate.default) {
                        return true;
                    }
                }
            }

            var message = (isDelete) ? 'settings.taxRate.delete.error.no.default.msg' : 'settings.taxRate.no.default.msg';

            PosAlertService.showAlertByName('general-error', {
                title: 'settings.taxRate.no.default.title',
                message: message
            });

            return false;
        };


        $scope.addTaxRateEntry = function (taxRate) {
            if (!isDefaultTaxRatePresent(taxRate, allTaxRates)) {
                return;
            }

            var taxRateObject = {
                taxRateName: taxRate.taxRateName,
                taxRate: taxRate.taxRate,
                organizationTaxRateId: taxRate.organizationTaxRateId,
                companyId: $scope.selectedCompanyId,
                default: taxRate.default
            };

            if ($scope.isRootCompany) {
                taxRateObject.rootLocationTax = true;
            }

            Settings.addTaxRate({}, taxRateObject, function (response) {
                $modalInstance.close(response);
            }, function (error) {
                var message = '';
                if (error && error.data && error.data.exception) {
                    if (error.data.exception.appCode == 440 || error.data.exception.appCode == 441) {
                        message = 'setupWizard.merchant.taxRate.create.error.generic.msg';
                    } else {
                        message = error.data.error;
                    }
                } else {
                    message = 'setupWizard.merchant.taxRate.create.error.generic.msg';
                }

                PosAlertService.showAlertByName('manager-pin-invalid', {
                    title: 'setupWizard.merchant.taxRate.create.error.ttl',
                    message: message
                });
            });
        };

        $scope.updateTaxRateEntry = function (taxRate) {
            if (!isDefaultTaxRatePresent(taxRate, allTaxRates)) {
                return;
            }

            var taxRateObject = {
                taxRateName: taxRate.taxRateName,
                taxRate: taxRate.taxRate,
                taxRateId: taxRate.taxRateId,
                organizationTaxRateId: taxRate.organizationTaxRateId,
                companyId: $scope.selectedCompanyId,
                default: taxRate.default
            };

            Settings.updateTaxRate({}, taxRateObject, function (response) {
                $modalInstance.close(response);
            }, function (error) {
                var message = '';
                if (error && error.data && error.data.exception) {
                    if (error.data.exception.appCode == 440 || error.data.exception.appCode == 441
                        || error.data.exception.appCode == 442) {
                        message = 'setupWizard.merchant.taxRate.update.error.generic.msg';
                    } else {
                        message = error.data.error;
                    }
                } else {
                    message = 'setupWizard.merchant.taxRate.update.error.generic.msg';
                }

                PosAlertService.showAlertByName('manager-pin-invalid', {
                    title: 'setupWizard.merchant.taxRate.update.error.ttl',
                    message: message
                });
            });


        };

        $scope.deleteTaxRateEntry = function (taxRate) {
            PosAlertService.showAlertByName('general-alert', {
                title: 'settings.taxRate.delete.title',
                message: $translate.instant('settings.taxRate.delete.message', {
                    taxRateName: taxRate.taxRateName
                }),
                modalCallback: function () {
                    confirmDeleteTaxRateEntry(taxRate);
                }
            });
        };
        var confirmDeleteTaxRateEntry = function (taxRate) {
            if (!isDefaultTaxRatePresent(taxRate, allTaxRates, true)) {
                return;
            }

            var taxRateObject = {
                taxRateName: taxRate.taxRateName,
                taxRate: taxRate.taxRate,
                taxRateId: taxRate.taxRateId,
                organizationTaxRateId: taxRate.organizationTaxRateId,
                companyId: $scope.selectedCompanyId,
                default: taxRate.default,
                taxRateFid: taxRate.taxRateFid
            };

            Settings.deleteTaxRate({}, taxRateObject, function (response) {
                $modalInstance.close(response);
            }, function (error) {
                var message = 'setupWizard.merchant.taxRate.delete.error.generic.msg';
                var title = 'setupWizard.merchant.taxRate.delete.error.ttl';
                if (error && error.data && error.data.exception) {
                    if (error.data.exception.appCode == 454) {
                        title = 'settings.taxRate.no.default.title';
                        message = 'settings.taxRate.delete.error.no.default.msg';
                    } else if (error.data.exception.appCode == 455) {
                        title = 'settings.taxRate.in.use.title';
                        message = 'settings.taxRate.delete.in.use.default.msg';
                    } else {
                        message = error.data.error;
                    }
                }

                PosAlertService.showAlertByName('general-error', {
                    title: title,
                    message: message
                });
            });
        };

        $scope.selectTaxRate = function (filterValue) {
            $scope.taxRate.selected = filterValue;

            // This block of code checks if it is in edit mode or not. If it is , it will store the current
            // tax rate id and assign the new filtered tax rate to the taxEntry object
            // Since the new tax rate will always be an organization tax, we set the tax_rate_id = 0 if new,
            // or set the existing tax_rate_id back to the object and store the organization_tax_rate_id
            // to the entry object
            if ($scope.editMode) {
                var curTaxRateId = $scope.taxRateEntry.curTaxRateId || $scope.taxRateEntry.taxRateId;
                $scope.taxRateEntry = angular.copy(filterValue);
                var editOrgTaxRateId = $scope.taxRateEntry.taxRateId;
                $scope.taxRateEntry.taxRateId = curTaxRateId;
                $scope.taxRateEntry.organizationTaxRateId = editOrgTaxRateId;
            } else {
                $scope.taxRateEntry = angular.copy(filterValue);
                var orgTaxRateId = $scope.taxRateEntry.taxRateId;
                $scope.taxRateEntry.taxRateId = 0;
                $scope.taxRateEntry.organizationTaxRateId = orgTaxRateId;
            }
        };

        $scope.loadOrganizationTaxRates = function () {
            return Lookup.taxRatesByOrganization({organizationId: CurrentSession.getOrganization().organizationId}, function (response) {
                $scope.organizationTaxRates = response;
            }, function (error) {
                PosAlertService.showAlertByName('general-error', {

                });
            });
        };

        $scope.init = function () {
            $scope.loadOrganizationTaxRates().$promise.then(function () {
                if ($scope.editMode === false) {
                    $scope.taxRateEntry = {
                        taxRate: 0,
                        taxRateName: '',
                        companyId: $scope.selectedCompanyId
                    };
                } else {
                    $scope.taxRateEntry = angular.copy(taxRate);
                    var filteredTaxRate = _.filter($scope.organizationTaxRates, function (orgTaxRate) {
                        return $scope.taxRateEntry.organizationTaxRateId == orgTaxRate.taxRateId;
                    });
                    $scope.taxRate.selected = filteredTaxRate[0];
                }
            });
        };
        $scope.init();
    }
]).controller('CreateRoleCtrl', [
    '$scope',
    '$modalInstance',
    'Settings',
    'selectedCompanyId',
    function ($scope, $modalInstance, Settings, selectedCompanyId) {
        $scope.roleEntry = {companyId: selectedCompanyId};
        $scope.addRole = function (role) {
            Settings.createRole(role, function (response) {
                $modalInstance.close(response);
            });
        };
    }
]).controller('ConfirmEditPermissions', [
    '$scope',
    '$modalInstance',
    'Settings',
    'roleObject',
    'permissionChanges',
    function ($scope, $modalInstance, Settings, roleObject, permissionChanges) {
        $scope.roleObject = roleObject;
        $scope.permissionChanges = permissionChanges;
        $scope.updateRolePermissions = function () {
            Settings.updatePermissions(roleObject, function (response) {
                $scope.$close();
            });
        };
    }
]);

require('./settings.service-periods.edit.js')(freshideasSettings);
require('./settings.users.js')(freshideasSettings);

export default freshideasSettings;
