'use strict';


const moment = require('moment');
const TaxRuleService = require('../external/tax-rule-service.js');
const Decimal = require('decimal.js').default;
const currencyCodeLib = require('currency-codes');
const Sortable = require('sortablejs').default;
const Honeybadger = require('honeybadger-js');
const AppConstants = require('../common/freshideas/app-constants.js');

module.exports = function (freshideasSetup) {

    freshideasSetup.controller('MerchantSetupCtrl', [
        '$scope',
        '$rootScope',
        '$timeout',
        '$modal',
        '$translate',
        '$sce',
        '$filter',
        '$location',
        '$log',
        'Security',
        'WizardHandler',
        'FileUploader',
        'Locations',
        'Netsuite',
        'Users',
        'Shopify',
        'Settings',
        'LookupService',
        'Company',
        'CardTerminal',
        'PrintService',
        'PosStatusService',
        'PosAlertService',
        'Platform',
        'CompanyAttributesService',
        'ConfirmModalService',
        'MevBoxService',
        'SharedDataService',
        '$window',
        'Lookup',
        'CurrentSession',
        'Organization',
        // 'Drivers',
        'Homebase',
        'QuickBooks',
        'GatewayFiit',
        'EnvConfig',
        'LOCALES',
        'Deliverect',
        'Menu',
        'MenuService',
        'SmbPosService',
        'REQUEST_STATES',
        'DaysConstant',
        function (
            $scope,
            $rootScope,
            $timeout,
            $modal,
            $translate,
            $sce,
            $filter,
            $location,
            $log,
            Security,
            WizardHandler,
            FileUploader,
            Locations,
            Netsuite,
            Users,
            Shopify,
            Settings,
            LookupService,
            Company,
            CardTerminal,
            PrintService,
            PosStatusService,
            PosAlertService,
            Platform,
            CompanyAttributesService,
            ConfirmModalService,
            MevBoxService,
            SharedDataService,
            $window,
            Lookup,
            CurrentSession,
            Organization,
            // Drivers,
            Homebase,
            QuickBooks,
            GatewayFiit,
            EnvConfig,
            LOCALES,
            Deliverect,
            Menu,
            MenuService,
            SmbPosService,
            REQUEST_STATES,
            DaysConstant) {

            $scope.versionTag = EnvConfig.gitTag;
            $scope.isCampus = CurrentSession.isCampus();
            $scope.isIosWebkit = Platform.isIosWebkit();
            $scope.isElectron = Platform.isElectron();
            $scope.checkSetupPermission(true);
            $scope.wizardHandler = WizardHandler;
            $scope.currentUser = Security.getUser();
            $scope.currentCompanyId = $scope.currentUser.companyId;
            $scope.PosStatusService = PosStatusService;
            $scope.visual = {merchant: {}};
            $scope.isLoading = {};
            $scope.companyServices = AppConstants.companyServices;
            $scope.merchant = {};
            $scope.taxRule = TaxRuleService;
            $scope.employees = {};
            $scope.employees.isEditingUser = false;
            $scope.employees.label = $translate.instant('setupWizard.merchant.employees.label');
            $scope.selectedEmployee = {};
            $scope.employees.searchBar = {text: ''};
            $scope.employees.activeFilters = {};
            $scope.employees.statusBar = {toShow: false};
            $scope.employees.toShowImport = (!Platform.isElectron() && !Platform.isIosWebkit()) ? true : false;
            $scope.employees.hasChanged = false;
            $scope.employees.currentActivePage = 0;
            $scope.employees.recordsPerPage = 10;
            $scope.employees.totalPages = 0;
            $scope.timecards = {};
            $scope.timecards.label = $translate.instant('setupWizard.merchant.timecards.label');
            $scope.timecards.hasChanged = false;
            $scope.timecards.isEditingTimecard = false;
            $scope.timecards.currentActivePage = 0;
            $scope.timecards.recordsPerPage = 10;
            $scope.timecards.pagesPerBatch = 5;
            $scope.timecards.recordsPerBatch = 50;
            $scope.timecards.totalPages = 0;
            $scope.selectedTimecard = {};
            $scope.timecards.searchBar = {text: ''};
            $scope.timecards.timeFilters = {
                startTime: moment().startOf('day').valueOf(),
                endTime: moment().endOf('day').valueOf()
            };
            $scope.timecards.activeFilters = {};
            $scope.timecards.toShowExport = (!Platform.isElectron() && !Platform.isIosWebkit()) ? true : false;

            $scope.discounts = {};
            $scope.discounts.isEditingDiscount = false;
            $scope.discounts.label = $translate.instant('setupWizard.merchant.discounts.label');
            $scope.selectedDiscount = {};
            $scope.discounts.searchBar = {text: ''};
            $scope.discounts.activeFilters = {};
            $scope.discounts.statusBar = {toShow: false};
            $scope.discounts.toShowImport = (!Platform.isElectron() && !Platform.isIosWebkit()) ? true : false;
            $scope.discounts.hasChanged = false;
            $scope.discounts.currentActivePage = 0;
            $scope.discounts.recordsPerPage = 10;
            $scope.discounts.totalPages = 0;
            $scope.discounts.showAdvanced = false;
            $scope.discounts.toggleShowAdvanced = function () {
                $scope.discounts.showAdvanced = !$scope.discounts.showAdvanced;
            };
            $scope.pastFiitEvents = {};
            $scope.pastFiitEvents.currentActivePage = 0;
            $scope.pastFiitEvents.recordsPerPage = 10;
            $scope.pastFiitEvents.totalPages = 0;

            $scope.merchant.timecardsFiltered = [];
            $scope.REQUEST_STATES = REQUEST_STATES;
            $scope.requestState = {
                removeAllPrinterIds: REQUEST_STATES.NA,
                toggleKitchenPrintingOn: REQUEST_STATES.NA,
                toggleKitchenPrintingOff: REQUEST_STATES.NA
            };
            $scope.daysConstant = DaysConstant;
            const NOTIFICATION_DURATION = 2000; // ms

            $scope.forms = {
                otherTenderLabelsForm: {}
            };

            const FIIT_EVENT_RESULT_STATUS = $scope.FIIT_EVENT_RESULT_STATUS = Object.freeze({
                pending: 'PENDING',
                success: 'SUCCESS',
                insufficientBalance: 'INSUFFICIENT_BALANCE',
                error: 'ERROR'
            });

            $scope.hasMenuV2Enabled = () => {
                return CompanyAttributesService.hasMenuV2Enabled();
            };

            $scope.sessionBasedSettings = {
                receiptScreenPrint: CurrentSession.isScreenPrintSession(),
            };

            $scope.toggleSessionBasedSettings = function (option, value) {
                $scope.sessionBasedSettings[option] = value || !$scope.sessionBasedSettings[option];
                CurrentSession.toggleSessionSettings(option, $scope.sessionBasedSettings[option]);
            };

            $scope.toggleEventDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                $scope.fiitEventSettings.isDatePickerOpen = !$scope.fiitEventSettings.isDatePickerOpen;
            };

            $scope.loadingPatronGroups = false;
            $scope.loadingPatronGroupMembers = false;
            $scope.loadingFiitEvents = false;
            $scope.loadingPastFiitEvents = false;

            const loadPatronGroups = () => {
                $scope.loadingPatronGroups = true;
                Organization.getPatronGroups({}, function (response) {
                    $scope.patronGroups = response;
                    $scope.loadingPatronGroups = false;
                });
            };

            const loadFiitEvents = () => {
                $scope.loadingFiitEvents = true;
                Locations.getFiitEvents({}, function (response) {
                    $scope.fiitEvents = response;
                    $scope.loadingFiitEvents = false;
                });
            };

            const loadPastFiitEvents = (offset = 0) => {
                $scope.loadingPastFiitEvents = true;
                Locations.getPastFiitEvents({offset, limit: $scope.pastFiitEvents.recordsPerPage}, function (response) {
                    $scope.pastFiitEvents.entries = response.entries || [];
                    $scope.pastFiitEvents.totalPages = Math.ceil(response.totalCount / $scope.pastFiitEvents.recordsPerPage) || 1;
                    $scope.loadingPastFiitEvents = false;
                });
            };

            const loadFiitEventResults = (fiitEventId) => {
                $scope.fiitEventResults = {};
                $scope.loadingFiitEventResults = true;
                Locations.getFiitEventResults({fiitEventId}, function (response) {
                    $scope.fiitEventResults = response;
                    $scope.fiitEventResults.successCount = response.filter((r) => r.status === FIIT_EVENT_RESULT_STATUS.success).length;
                    $scope.loadingFiitEventResults = false;
                });
            };

            const findFiitPatrons = (patronKeys = []) => {
                if (!patronKeys.length) {
                    return Promise.resolve([]);
                }

                const promises = patronKeys.map((patronKey) => SmbPosService.fetchPatronFromFiitBackend(undefined, patronKey, {}, {}));
                return Promise.allSettled(promises);
            };

            const patronGroupIsValid = () => {
                if (!$scope.fiitPatronGroupSettings.patronGroupName) {
                    PosAlertService.showAlertByName('missing-information');
                    return false;
                }

                return true;
            };

            $scope.createOrUpdatePatronGroup = () => {
                $scope.fiitPatronGroupSettings.patronGroupMembers =
                Object.values($scope.fiitPatronGroupSettings.patronGroupMembersMap).map((member) => member);

                if (!patronGroupIsValid()) {
                    return;
                }
                const data = Object.assign({}, $scope.fiitPatronGroupSettings);
                Organization.createOrUpdatePatronGroup(data, function () {
                    settingsUpdated();
                    $scope.setFiitSecondaryPage('main');
                    loadPatronGroups();
                });
            };

            $scope.deletePatronGroup = (patronGroupId) => {
                ConfirmModalService.openConfirmModal(
                    'setupWizard.merchant.fiit.deleteGroup.title',
                    'setupWizard.merchant.fiit.deleteGroup.description',
                    undefined, // `clickAction` callback
                    undefined,
                    undefined, // `scope`
                    true // `allowCancel`
                ).then(function () {
                    Organization.deletePatronGroup({patronGroupId}, function () {
                        settingsUpdated();
                        $scope.patronGroups = $scope.patronGroups.filter((group) => group.patronGroupId != patronGroupId);
                    });
                });
            };

            $scope.duplicatePatronGroup = (patronGroup) => {
                const newGroup = angular.copy(patronGroup);
                delete newGroup.patronGroupId;
                newGroup.patronGroupName += ' - copy';
                newGroup.patronGroupMembers.forEach((member) => {
                    delete member.id;
                    delete member.patronGroupId;
                });
                $scope.setFiitSecondaryPage('group', newGroup);
            };

            const fiitEventIsValid = () => {
                if (!$scope.fiitEventSettings.fiitEventName ||
                    !$scope.fiitEventSettings.fiitServicePeriod ||
                    !$scope.fiitEventSettings.mealUnitsUsed ||
                    !$scope.fiitEventSettings.startDate ||
                    !$scope.fiitEventSettings.startTime) {

                    PosAlertService.showAlertByName('missing-information');
                    return false;
                }

                if (!$scope.fiitEventSettings.fiitEventStartTime ||
                   moment($scope.fiitEventSettings.fiitEventStartTime).isBefore(moment())) {

                    PosAlertService.showAlertByName('invalid-future-date-time');
                    return false;
                }

                return true;
            };

            $scope.createOrUpdateFiitEvent = () => {
                const dateString = new Date($scope.fiitEventSettings.startDate).toDateString();

                $scope.fiitEventSettings.fiitEventStartTime =
                    moment(dateString + ' ' + $scope.fiitEventSettings.startTime, 'ddd MMM DD YYYY hh:mm A').toDate();

                $scope.fiitEventSettings.attendingGroups = Object.keys($scope.fiitEventSettings.selectedGroups)
                    .filter((groupId) => $scope.fiitEventSettings.selectedGroups[groupId]);

                if (!fiitEventIsValid()) {
                    return;
                }

                Locations.createOrUpdateFiitEvent($scope.fiitEventSettings, function (res) {
                    settingsUpdated();
                    $scope.setFiitSecondaryPage('main');
                    loadFiitEvents();
                });
            };

            $scope.deleteFiitEvent = (fiitEventId) => {
                ConfirmModalService.openConfirmModal(
                    'setupWizard.merchant.fiit.deleteEvent.title',
                    'setupWizard.merchant.fiit.deleteEvent.description',
                    undefined, // `clickAction` callback
                    undefined,
                    undefined, // `scope`
                    true // `allowCancel`
                ).then(function () {
                    Locations.deleteFiitEvent({fiitEventId}, function () {
                        settingsUpdated();
                        $scope.fiitEvents = $scope.fiitEvents.filter((event) => event.fiitEventId != fiitEventId);
                    });
                });
            };

            $scope.setFiitSecondaryPage = (page, data) => {
                $scope.fiitSecondaryPage = page;
                switch (page) {
                    case 'main':
                        $scope.showSecondaryFiitPage = false;
                        $scope.fiitPatronGroupSettings = {};
                        $scope.fiitEventSettings = {};
                        break;

                    case 'group':
                        $scope.showSecondaryFiitPage = true;

                        $scope.fiitPatronGroupSettings = Object.assign({patronGroupMembersMap: {}}, data);

                        if (data && data.patronGroupMembers) {
                            const patronKeys = data.patronGroupMembers.map((patron) => patron.patronKey);
                            $scope.loadingPatronGroupMembers = true;
                            findFiitPatrons(patronKeys).then((res) => {
                                res.forEach((patronResult) => updatePatronGroupMembersMap(patronResult.value));
                                $scope.loadingPatronGroupMembers = false;
                                $scope.$apply();
                            });
                        }
                        break;

                    case 'event':
                        $scope.showSecondaryFiitPage = true;
                        $scope.fiitEventSettings = Object.assign({isDatePickerOpen: false, selectedGroups: {}}, data);

                        if (data) {
                            const fiitEventStartTime = new Date(data.fiitEventStartTime);
                            $scope.fiitEventSettings.startDate = moment(fiitEventStartTime).format('YYYY/MM/DD');
                            $scope.fiitEventSettings.startTime = moment(fiitEventStartTime).format('hh:mm A');
                            data.attendingGroups.forEach((patronGroupId) => $scope.fiitEventSettings.selectedGroups[patronGroupId] = true);
                        }
                        break;

                    case 'past-event':
                        $scope.showSecondaryFiitPage = true;
                        $scope.pastFiitEventSettings = Object.assign({}, data);
                        loadFiitEventResults(data.fiitEventId);
                        break;

                    case 'saved-meal-cards':
                        $scope.showSecondaryFiitPage = true;
                        if (!$scope.company.serviceConfig.fiitSavedMealCardReport) {
                            $scope.company.serviceConfig.fiitSavedMealCardReport = {
                                enabled: false,
                                emails: [],
                                config: {}
                            };
                        }
                        break;
                }
            };

            $scope.setSecondaryServicePage = (page, data) => {
                $scope.secondaryServicePage = page;
                $scope.selectedService = null;
                switch (page) {
                    case 'companyServiceConfig':
                        if (!$scope.company.serviceConfig[data]) {
                            $scope.company.serviceConfig[data] = {
                                enabled: false,
                                emails: [],
                                config: {},
                            };
                        }

                        $scope.selectedService = $scope.company.serviceConfig[data];
                        $scope.selectedService.service = data;
                        $scope.selectedService.label = $scope.companyServices[data].translation;
                        break;
                }
            };

            $scope.removeFromPatronGroupMembersMap = (patronKey) => {
                delete $scope.fiitPatronGroupSettings.patronGroupMembersMap[patronKey];
            };

            const updatePatronGroupMembersMap = (patron) => {
                if (!patron || !patron.fiitMpsAccount) {
                    $scope.$emit('checkIn::notification', false);
                    return;
                }

                const {fullName, fiitMpsAccount: {patronKey}} = patron;
                $scope.fiitPatronGroupSettings.patronGroupMembersMap[patronKey] = {
                    patronKey,
                    fullName,
                    patronType: 'FIIT'
                };
                $scope.$emit('checkIn::notification', true);
            };

            $scope.openPatronLookupModal = () => {
                $modal.open({
                    templateUrl: 'pos/smb/templates/pos.manual.checkin.tpl.html',
                    animation: true,
                    controller: 'SmbManualCheckinCtrl',
                    windowClass: 'smb-pos__checkin',
                    resolve: {
                        selectPatron: function () {
                            return updatePatronGroupMembersMap;
                        },
                        isGatewayFiitEnabled: function () {
                            return true;
                        },
                        isExistingOrder: function () {
                            return false;
                        },
                        showError: function () {
                            return false;
                        },
                        isQuickChargeEnabled: function () {
                            return false;
                        },
                        autoCloseModalAfterScan: () => false,
                        dismissButtonText: () => {}
                    },
                    keyboard: false
                });
            };

            $scope.togglePatronGroup = (id) => {
                $scope.fiitEventSettings.selectedGroups[id] = !$scope.fiitEventSettings.selectedGroups[id];
            };

            $scope.viewAttendingPatrons = () => {
                const uniquePatronKeys = new Set();

                $scope.patronGroups.forEach((group) => {
                    if ($scope.fiitEventSettings.selectedGroups[group.patronGroupId]) {
                        group.patronGroupMembers.forEach((member) => {
                            if (member.patronType === 'FIIT') {
                                uniquePatronKeys.add(member.patronKey);
                            }
                        });
                    }
                });

                $modal.open({
                    templateUrl: 'pos/smb/templates/pos.event.attendees.tpl.html',
                    animation: true,
                    controller: 'EventAttendees',
                    windowClass: '',
                    resolve: {
                        patronKeys: () => Array.from(uniquePatronKeys),
                        patrons: () => []
                    },
                    keyboard: false
                });
            };

            function fromBitPattern (pattern) {
                var daysActive = [
                    {
                        name: 'Sun',
                        active: false,
                    },
                    {
                        name: 'Mon',
                        active: false,
                    },
                    {
                        name: 'Tue',
                        active: false,
                    },
                    {
                        name: 'Wed',
                        active: false,
                    },
                    {
                        name: 'Thu',
                        active: false,
                    },
                    {
                        name: 'Fri',
                        active: false,
                    },
                    {
                        name: 'Sat',
                        active: false,
                    }
                ];
                var bitPattern = pattern.split('');
                for (let i = 0; i < daysActive.length; i++) {
                    daysActive[i].active = (bitPattern[i] === '1') ? true : false;
                }
                return daysActive;
            }

            var batchUpdateCompanyAttributes = function (companiesAttributesToUpdate) {
                return Company.updateAttributes({}, companiesAttributesToUpdate, function (response) {
                    getAllCompanies().then(function () {
                        settingsUpdated();
                        getAllCompaniesAttributes();
                    });
                }).$promise;
            };

            $scope.$watch(function () {
                return $scope.company;
            }, function (company) {
                if (company && company.secondaryDisplaySettings && company.secondaryDisplaySettings.slides) {
                    company.secondaryDisplaySettings.slides.forEach((slide) => {
                        slide.daysActive = fromBitPattern(slide.dayActive);
                    });
                }
            });
            $scope.addEmailField = function (listOfEmails) {
                var index = listOfEmails.length;
                listOfEmails[index] = '';
            };
            $scope.deleteEmail = function (listOfEmails, index) {
                listOfEmails.splice(index, 1);
            };

            var sortRoles = function (a, b) {
                return a.roleId - b.roleId;
            };

            $scope.merchant.attributes = {};

            $scope.getCurrentMerchant = function () {
                $scope.merchant.name = $scope.company.companyName,
                    $scope.merchant.userRoles = [{
                        roleName: 'Owner',
                        permission: [],
                        default: true
                    }, {
                        roleName: 'Manager',
                        permission: []
                    }];
                $scope.merchant.mobileFeatures = [{
                    name: 'setupWizard.merchant.mobileFeature.mobileOrder.label',
                    value: true
                }, {
                    name: 'setupWizard.merchant.mobileFeature.loyalty.label',
                    value: false
                }];

                $scope.merchant.attributes = angular.copy($scope.company.attributes) || {};
                $scope.merchant.kitchenPrintTextSizeOptions = ['Small (default)', 'Medium', 'Large'];
                if ($scope.merchant.attributes['other__cashier_discount_limit'] != undefined) {
                    $scope.visual.merchant.discount = $scope.merchant.attributes['other__cashier_discount_limit'] * 100;
                }

                if ($scope.merchant.attributes['other__other_tender_labels']) {
                    var labelString = $scope.merchant.attributes['other__other_tender_labels'];
                    var labels = labelString.split(',')
                        .filter(function (label) {
                            return !!label;
                        })
                        .map(function (label) {
                            return {
                                name: label
                            };
                        });
                    $scope.visual.merchant.otherTenderLabels = labels;
                }

                if (parseFloat($scope.merchant.attributes['delivery_small_order_threshold']) > 0) {
                    $scope.addFeeLevel('small');
                }

                if (parseFloat($scope.merchant.attributes['delivery_large_order_threshold']) > 0) {
                    $scope.addFeeLevel('large');
                }

                if ($scope.merchant.attributes['delivery_pre_clockin_checklist']) {
                    $scope.deliveryChecklist = JSON.parse($scope.merchant.attributes['delivery_pre_clockin_checklist']);
                }

                $scope.merchant.baseCurrencyId = $scope.company.baseCurrencyId;
                $scope.merchant.languageId = $scope.company.languageId;
            };

            $scope.selectUserStatusOptions = [{
                label: 'Active',
                value: 'ACTIVE'
            },
            {
                label: 'Inactive',
                value: 'INACTIVE'
            }];

            $scope.filters = {};
            $scope.filters.selectedUserFilter = '';
            $scope.filters.selectedLocationFilter = '';
            $scope.filters.selectedRoleFilter = '';
            $scope.filters.lastSelectedUser = '';
            $scope.filters.lastSelectedLocation = '';
            $scope.filters.lastSelectedRole = '';


            $scope.setPage = function (page, skipRedirect) {
                if ($scope.page != page && page == 'delivery-setup') {
                    $scope.setDeliveryPage('main');
                }

                if ($scope.page != page && page == 'gift-card-setup') {
                    if ($scope.organization) {
                        $scope.getDigitalGiftCardSettings();
                        $scope.setGiftCardPage('main');
                    }
                }

                if ($scope.page != page && page == 'mobile-setup') {
                    if ($scope.organization) {
                        $scope.getPromoImages();
                        $scope.setGiftCardPage('main');
                    }
                }

                if ($scope.wizardHandler.wizard()) {
                    var steps = $scope.wizardHandler.wizard().getEnabledSteps();

                    var currentStep = _.findWhere(steps, {title: $scope.page}) || {};
                    var currentStepIndex = steps.indexOf(currentStep);

                    var nextStep = _.findWhere(steps, {title: page}) || {};
                    var nextStepIndex = steps.indexOf(nextStep);

                    if (nextStepIndex > currentStepIndex && currentStep.canexit && !currentStep.canexit()) {
                        return;
                    }
                }

                if ($scope.page != page && page == 'employee-setup') {
                    $scope.employees.isEditingUser = false;
                    $scope.employees.label = $translate.instant('setupWizard.merchant.employees.label');
                    $scope.employees.currentActivePage = 0;
                    $scope.selectedEmployee = {};
                    if ($scope.merchant.users) {
                        $scope.filterData('employee-setup');
                    }

                }

                if ($scope.page != page && page == 'employee-setup' && $scope.showEditor) {
                    // if no admin pin is set
                    if ($scope.siteAdminUser && $scope.siteAdminUser.length === 1 && !$scope.siteAdminUser[0].pin) {
                        $scope.editUser($scope.siteAdminUser[0]);
                    }
                }

                if ($scope.page != page && page == 'employee-timecards') {
                    $scope.timecards.isEditingTimecard = false;
                    $scope.timecards.label = $translate.instant('setupWizard.merchant.timecards.label');
                    $scope.timecards.currentActivePage = 0;
                    $scope.selectedTimecard = {};
                    $scope.timecards.timeFilters = {
                        startTime: moment().startOf('day').valueOf(),
                        endTime: moment().endOf('day').valueOf()
                    };
                    if ($scope.merchant.timecards) {
                        $scope.filterData('employee-timecards');
                    }
                }

                if ($scope.page != page && page == 'integration-setup') {
                    Shopify.getStores().$promise.then((response) => {
                        if (response && response.length) {
                            $scope.shopifyStores = response; // Only allow one shopify store
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                }

                if ($scope.page != page && page == 'loyalty-setup') {
                    Lookup.companyMealPlans({companyId: $scope.currentCompanyId}).$promise.then((response) => {
                        $scope.mealPlans = response;
                    }).catch((error) => {
                        console.error(error);
                    });
                }

                $scope.page = page;
                $scope.addPageViewed(page);
                $scope.$broadcast('page-changed');

                if (!$scope.hideSetupWizard()) {
                    if (!skipRedirect) {
                        $timeout(function () {
                            if (WizardHandler && WizardHandler.wizard()) {
                                WizardHandler.wizard().goTo(page);
                            }
                        }, 0);
                    }
                }
            };

            $scope.setNextPage = function () {
                WizardHandler.wizard().next();
            };
            $scope.pagesViewed = {};
            $scope.addPageViewed = function (page) {
                $scope.pagesViewed[page] = true;
            };
            $scope.$on('wizard:stepChanged', function (event, args) {
                var step = args.step;
                var title = step.title;

                $scope.setPage(title, true);
            });

            $scope.togglePreorder = function (location) {
                location.preorder = !location.preorder;
            };


            $scope.loadServicePeriods = function (companyId) {
                Settings.getServicePeriods({companyId: companyId}, function (response) {
                    var servicePeriods = $scope.servicePeriods = response.entries;
                    var schedule = {};
                    _.each($scope.constants.days, function (day, index) {
                        // Re-purposing servicePeriod.code to identify which service period maps
                        // to which operating day. Some future improvements include
                        // - allow multiple periods per day
                        // - check if the day specified in `code` matches `serviceDays`
                        var dailySchedule = {
                            day: index + '',
                            dayName: moment().isoWeekday(day).format('ddd'),
                            hours: [],
                            isEnabled: true,
                        };

                        var foundServicePeriod = _.findWhere(servicePeriods, {code: index + ''});
                        if (foundServicePeriod) {
                            var foundServiceDay = _.findWhere(foundServicePeriod.serviceDays, {day: day});

                            if (foundServiceDay) {
                                var startTime = moment(foundServicePeriod.startTime, 'HH:mm A');
                                var endTime = moment(foundServicePeriod.endTime, 'HH:mm A');

                                var startHour = startTime.hours() + (startTime.minutes() / 60.0);
                                var endHour = endTime.hours() + (endTime.minutes() / 60.0);

                                if (endHour < startHour) {
                                    if (endHour + 24 > 30) {
                                        endHour = 30;
                                    } else {
                                        endHour += 24;
                                    }
                                }

                                if (endHour == 0) {
                                    endHour = 24;
                                }

                                dailySchedule.hours = [{
                                    start: startHour,
                                    end: endHour
                                }];
                                dailySchedule.isEnabled = foundServiceDay.enabled;
                            }
                        }

                        schedule[index + 1] = dailySchedule; // sunday = 1, saturday = 7
                    });

                    $scope.merchant.schedule = schedule;
                });
            };
            $scope.loadLocations = function () {
                return Locations.getLocations({}, function (response) {
                    $scope.locations = response.entries;
                });
            };

            $scope.openReasonModal = function () {
                PosAlertService.showAlertByName('contact-nown', {
                    message: 'setupWizard.merchants.needToUpdate'
                });
            };

            $scope.loadTaxRates = function (companyId) {
                $scope.isLoading.taxRates = true;
                return Settings.getTaxRates({companyId: companyId}, function (response) {
                    $scope.isLoading.taxRates = false;
                    $scope.merchant.taxRates = response.entries;
                }, function (error) {
                    $scope.isLoading.taxRates = false;
                });
            };
            $scope.loadStations = function (locationId) {
                $scope.isLoading.stations = true;
                return Locations.getLocationPOSStations({locationId: locationId}, function (response) {
                    $scope.isLoading.stations = false;
                    $scope.merchant.stations = response.entries;
                    _.each($scope.merchant.stations, function (station) {
                        if (station.cardTerminalProperties) {
                            if (station.cardTerminalProperties.port) {
                                station.cardTerminalProperties.port = parseInt(station.cardTerminalProperties.port) || undefined;
                            }
                        }

                        if (station.posPrinters) {
                            _.each(station.posPrinters, (printer) => {
                                if (printer.customAttributes) {
                                    try {
                                        printer.customAttributes = JSON.parse(printer.customAttributes);
                                    } catch (e) {
                                        console.log(e);
                                    }
                                }

                                if (printer.protocol === 'tcp') {
                                    printer.printerIpPort = printer.printerIpAddress + ':' + printer.printerPort;
                                }
                            });
                        }
                    });
                }, function (error) {
                    $scope.isLoading.stations = false;
                });
            };
            $scope.loadPrinters = function (locationId) {
                $scope.isLoading.printers = true;
                return Locations.getLocationPrinters({locationId: locationId}, function (response) {
                    $scope.isLoading.printers = false;
                    $scope.merchant.printers = response.entries;
                }, function (error) {
                    $scope.isLoading.printers = false;
                });
            };
            $scope.testStationPrinter = function (station) {
                // Fail if there are no printers registered on the POS station
                if (!station.posPrinters || station.posPrinters.length === 0) {
                    station.printerSuccess = -1;
                    return;
                }

                var printUrlArray = PrintService.buildPrinterObjArray(
                    station.posPrinters, false);

                // -1 = failed, 0 = uninitialized, 1 = success
                station.printerSuccess = 0;
                $scope.printerInTesting = true;
                PrintService.testPrinter(printUrlArray).then(function () {
                    station.printerSuccess = 1;
                    $scope.printerInTesting = false;
                }, function () {
                    station.printerSuccess = -1;
                    $scope.printerInTesting = false;
                });
            };
            $scope.testCardTerminal = function (station) {
                var cardTerminalProperties = station.cardTerminalProperties;

                // Fail if there is no card terminal IP/port set up on the station
                if (!cardTerminalProperties
                    || !cardTerminalProperties.type
                    || !cardTerminalProperties.ip
                    || !cardTerminalProperties.port) {
                    station.terminalSuccess = -1;
                    return;
                }

                cardTerminalProperties.port = cardTerminalProperties.port + ''; // ensure it's a string
                var terminalConfig = cardTerminalProperties;

                station.terminalSuccess = 0;
                $scope.cardTerminalInTesting = true;
                $timeout(function () {
                    var terminal = CardTerminal.init(terminalConfig);
                    return terminal.testSale().then(function () {
                        station.terminalSuccess = 1;
                        $scope.cardTerminalInTesting = false;
                    }, function () {
                        station.terminalSuccess = -1;
                        $scope.cardTerminalInTesting = false;
                    });
                }, 100);
            };

            $scope.srmDevices = [];
            $scope.tmpSrmDevices = [];
            $scope.isMevBoxCompany = CompanyAttributesService.hasMevBox();
            $scope.loadSrmDevices = function (companyId) {
                // only load if company is a mevbox enabled company.
                if (!$scope.company.attributes.has_mev_box) {
                    return;
                }

                $scope.isLoading.srmDevices = true;
                Locations.getLocationSrmDevices({companyId: companyId}).$promise
                    .then((list) => {
                        $scope.isLoading.srmDevices = false;

                        // this is being set initially in pos-service on loadShift.
                        // we perform an update here.
                        SharedDataService.locationSrmDevices = list;

                        // store two copies
                        // tmpSrmDevices will be the original copy, used to determine which srm devices was previously set to default.
                        // this is required as we do not want to blindly set all other srm devices to false (unnecessary calls to backend.
                        $scope.srmDevices = angular.copy(list);
                        $scope.tmpSrmDevices = angular.copy(list);
                    })
                    .catch((error) => {
                        $log.error(error);
                    });
            };

            $scope.otherTenderLabels = [];
            $scope.addOtherTenderLabel = function () {
                $scope.visual.merchant.otherTenderLabels = $scope.visual.merchant.otherTenderLabels || [];
                if ($scope.isAtMaxNumberOtherTenderLabels()) {
                    return;
                }

                var index = $scope.visual.merchant.otherTenderLabels.length;
                $scope.visual.merchant.otherTenderLabels[index] = {name: ''};
            };
            $scope.deleteOtherTenderLabel = function (index) {
                if (!$scope.isRootCompany) {
                    return;
                }

                $scope.visual.merchant.otherTenderLabels.splice(index, 1);
            };
            $scope.isAtMaxNumberOtherTenderLabels = function () {
                return ($scope.visual.merchant.otherTenderLabels && $scope.visual.merchant.otherTenderLabels.length >= 6);
            };
            $scope.validateOtherTenderLabel = function (labelToValidate, subform) {
                var hasDuplicate = _.some($scope.visual.merchant.otherTenderLabels, function (label) {
                    if (label === labelToValidate) {
                        return false;
                    }

                    if (!label.name) {
                        return false;
                    }

                    return label.name.toLowerCase() === labelToValidate.name.toLowerCase();
                });

                labelToValidate.hasDuplicate = hasDuplicate;

                subform.$setValidity('duplicate', !hasDuplicate);
            };

            var sortUsersByFirstName = function (a, b) {
                if (!a.firstname) {
                    return -1;
                } else if (!b.firstname) {
                    return 1;
                }

                if (a.firstname.toLowerCase() < b.firstname.toLowerCase()) {
                    return -1;
                }

                return 1;
            };

            $scope.loadUsers = function (companyId) {
                $scope.isLoading.users = true;
                return Users.listUsers({companyId: companyId, limit: -1}, function (response) {
                    $scope.isLoading.users = false;
                    var users = _.filter(response.entries, function (user) {
                        return (
                            (user.email != 'api@lucova.com' && user.email != 'lucova.logging@lucova.com')
                            || (user.firstname !== 'API USER' && user.lastname !== 'DONT DELETE')
                            || (!user.autoCreated)
                        );
                    });
                    $scope.merchant.users = users;
                    $scope.merchant.users.sort(sortUsersByFirstName);
                    $scope.merchant.usersFiltered = angular.copy($scope.merchant.users);
                    $scope.setFilterCriteria('employee-setup', 'status', {label: 'Active', value: 'ACTIVE'});
                    $scope.filters.selectedUserFilter = $scope.selectUserStatusOptions[0];
                    $scope.filters.selectedLocationFilter = $scope.allLocationsByCompany[0];
                    $scope.filters.selectedRoleFilter = $scope.companyRoleTypes[0];
                    $scope.siteAdminUser = _.filter(users, function (user) {
                        return user.permission === 'SITEADMIN' || user.permission === 'FULLADMIN';
                    });
                }, function (error) {
                    $scope.isLoading.users = false;
                });
            };

            $scope.driverTimecards = {
                currentActivePage: 0,
                totalPages: 0,
                pagesPerBatch: $scope.timecards.pagesPerBatch,
                recordsPerPage: $scope.timecards.recordsPerPage,
                index: 0,
                timecards: []
            };

            $scope.switchTimecardView = (view) => {
                $scope.timecardView = view;
                if (view === 'driverView') {
                    $scope.merchant.driverTimecardsFiltered = [];
                    $scope.driverTimecards.timecards = _.filter($scope.merchant.timecards, (timecard) => {
                        return timecard.user.is_driver;
                    });
                    $scope.driverTimecards.totalPages = Math.ceil($scope.driverTimecards.timecards.length / $scope.timecards.recordsPerPage) || 1;
                    $scope.filterData('employee-timecards__drivers');
                }
            };

            $scope.getEmployeeTimecardsDownloadUrl = function () {
                var reportSearch = {};
                reportSearch.startDateTime = moment($scope.timecards.timeFilters.startTime).startOf('day').valueOf();
                reportSearch.endDateTime = moment($scope.timecards.timeFilters.endTime).endOf('day').valueOf();

                 if ($scope.filters.selectedLocationFilter && $scope.filters.selectedLocationFilter.id) {
                    reportSearch.locationId = $scope.filters.selectedLocationFilter.id; // locationID ex - 100050
                }

                var downloadUrl = $location.protocol() + '://' + $location.host() + ':' + $location.port()
                    + '/freshideas/web/reports/employeeShifts/export';

                var queryString = jQuery.param(reportSearch);
                return downloadUrl + '?' + queryString;
            };


            $scope.getTimeInHoursAndMinutes = function (timestamp) {
                var seconds = timestamp % (60000);
                timestamp = timestamp - seconds;
                timestamp = timestamp / 60000;
                var minutes = timestamp % (60);
                timestamp = timestamp - minutes;
                var hours = timestamp / 60;


                hours = new Decimal(hours).plus(minutes / 60);
                return hours.toDecimalPlaces(2).toString();
            };

            var getTimeInMinutes = function (timestamp) {
                var minutes = Math.round(timestamp / 60000);
                return parseInt(minutes);
            };

            $scope.onDateFilterChanged = function () {
                var startDateTime = new Date($scope.timecards.timeFilters.startTime).getTime();
                var endDateTime = new Date($scope.timecards.timeFilters.endTime).getTime();
                if (endDateTime < startDateTime) {
                    PosAlertService.showAlertByName('invalid-date-range');
                    return false;
                }
                $scope.timecardView = 'employeeView';
                $scope.loadTimecards($scope.currentCompanyId);
            };

            var timer;

            /** This function is used to load timecards following a reactive search behaviour.
            **  The method has an input timeout of 500ms to prevent more than 1 query within the timeout interval
            **/
            $scope.loadTimecards = function (companyId) {
                if (timer) {
                    $timeout.cancel(timer);
                    $scope.isLoading.timecards = false;
                }
                let timeout = timer ? 500 : 0;
                timer = $timeout(function () {
                    $scope.isLoading.timecards = true;
                    var startDateTime = new Date($scope.timecards.timeFilters.startTime).getTime();
                    var endDateTime = new Date($scope.timecards.timeFilters.endTime).getTime();
                    var filteredCompanyId = companyId;
                    var nameFilter = '';
                    if ($scope.timecards.activeFilters.companyId) {
                        filteredCompanyId = $scope.timecards.activeFilters.companyId;
                    }

                    if ($scope.timecards.activeFilters.text) {
                        nameFilter = $scope.timecards.activeFilters.text.toLowerCase();
                    }
                    let search = {
                        companyId: filteredCompanyId,
                        limit: 50,
                        startDateTime: startDateTime,
                        endDateTime: endDateTime,
                        nameFilter: nameFilter,
                        offset: $scope.timecards.currentActivePage
                    };
                    Users.getEmployeeTimecards(search, function (response) {
                        $scope.isLoading.timecards = false;
                        parseTimeCardsResponse(response);
                    }, function (error) {
                        $scope.isLoading.timecards = false;
                    });
                }, timeout);
            };

            /** This function basically parses through all the timecards recieved
            **  and populates the fileds required. It also created a copy of the response
            **  in order to use it for local pagination.
            **/
            var parseTimeCardsResponse = function (response) {
                var timecards = response.entries;
                _.each(timecards, function (timecard) {
                    timecard.date = $filter('date')(timecard.startTime, 'MMM d yyyy');
                    timecard.clockInOut = $filter('date')(timecard.startTime, 'h:mm a') + ' - '
                        + ((timecard.endTime) ? $filter('date')(timecard.endTime, 'h:mm a') : '');
                    timecard.hours = (timecard.shiftClosed) ? $scope.getTimeInHoursAndMinutes(timecard.duration) : undefined;
                    timecard.hoursWithOutBreaks = (timecard.shiftClosed) ? $scope.getTimeInHoursAndMinutes(timecard.durationWithoutBreaks) : undefined;
                    timecard.totalBreakTime = (timecard.totalBreakMillis && timecard.shiftClosed)
                        ? getTimeInMinutes(timecard.totalBreakMillis) : undefined;
                });

                $scope.merchant.timecards = timecards;
                $scope.timecards.index = Math.floor(response.offset / 5);
                $scope.timecards.totalPages = response.totalCount || 1;
                $scope.filterData('employee-timecards');
            };

            $scope.loadDiscounts = function (companyId) {
                $scope.isLoading.discounts = true;
                return Settings.getDiscounts({companyId: companyId}, function (response) {
                    $scope.isLoading.discounts = false;
                    var discounts = _.filter(response, {internalName: null});
                    var permission = 'Site Owner, ';
                    _.each(discounts, function (discount) {
                        discount.status = (discount.active) ? 'ACTIVE' : 'INACTIVE';
                        if (discount.permissionRoles) {
                            discount.permissionRoles = permission + discount.permissionRoles;
                        } else {
                            discount.permissionRoles = permission.substr(0, permission.length - 2);
                        }
                    });
                    $scope.merchant.discounts = discounts;
                    $scope.merchant.discountsFiltered = angular.copy($scope.merchant.discounts);
                    $scope.setFilterCriteria('discount-setup', 'status', {label: 'Active', value: 'ACTIVE'});
                    $scope.filters.selectedUserFilter = $scope.selectUserStatusOptions[0];
                }, function (error) {
                    $scope.isLoading.discounts = false;
                });
            };

            $scope.loadNetSuiteIntegrations = function (companyId) {
                $scope.isLoading.netsuiteIntegration = true;
                return Netsuite.getNetsuiteConnections({}, function (response) {
                    $scope.merchant.netsuiteIntegration = response[0];
                }, function (error) {
                    $scope.isLoading.netsuiteIntegration = false;
                });
            };

            var updateCurrencyAndLanguage = function () {
                if ((!$scope.merchant.baseCurrency || !$scope.merchant.baseCurrency.currencyId)
                    && (!$scope.merchant.language || !$scope.merchant.language.languageId)) {
                    return Promise.reject();
                }

                let objectToPost = {};
                if ($scope.merchant.baseCurrency && $scope.merchant.baseCurrency.currencyId) {
                    objectToPost.currency = {
                        currencyName: $scope.merchant.baseCurrency.currencyName,
                        currencyCode: $scope.merchant.baseCurrency.currencyCode,
                        currencyId: $scope.merchant.baseCurrency.currencyId,
                        organizationId: CurrentSession.getCompany().organizationId,
                        companyId: $scope.currentCompanyId
                    };
                }

                if ($scope.merchant.language && $scope.merchant.language.languageId) {
                    objectToPost.language = {
                        locale: $scope.merchant.language.locale,
                        languageId: $scope.merchant.language.languageId,
                        organizationId: CurrentSession.getCompany().organizationId,
                        companyId: $scope.currentCompanyId
                    };
                }

                if ($scope.isRootCompany) {
                    return Organization.updateCurrencyAndLanguage(objectToPost, function () {
                        // $scope.loadCurrencyCodesMap();
                    }, function () {
                        // Please add error if required
                    }).$promise;
                } else {
                    return Company.updateCurrencyAndLanguage(objectToPost, function () {
                        // $scope.loadCurrencyCodesMap();
                    }, function () {
                        // Please add error if required
                    }).$promise;
                }

            };

            $scope.updateBasicInfo = async function () {
                if ($scope.isUpdating) return;
                $scope.isUpdating = true;
                const merchAttributes = [$scope.taxRule.get('CA_ON_PREPARED_GOODS').enableTag];

                try {
                    await $scope.updateCompany();
                    await updateCompanyAttributes(merchAttributes);
                    await $scope.updateLocation();
                    settingsUpdated();
                } catch (err) {
                    settingsUpdated(false);
                }
            };

            $scope.updateCurrencyAndLanguageSettings = async function () {
                if ($scope.isUpdating) return;
                $scope.isUpdating = true;

                try {
                    await updateCurrencyAndLanguage();
                    settingsUpdated();
                } catch (err) {
                    settingsUpdated(false);
                }
            };

            $scope.updateStationSettings = async function () {
                if ($scope.isUpdating) return;
                $scope.isUpdating = true;

                let otherTenderLabels = '';
                if ($scope.visual.merchant.otherTenderLabels) {
                    let otherTenderLabelNames = $scope.visual.merchant.otherTenderLabels
                        .filter(function (label) {
                            return label && label.name;
                        })
                        .map(function (label) {
                            return label.name;
                        });
                    otherTenderLabels = otherTenderLabelNames.join(',');
                }

                $scope.merchant.attributes['other__other_tender_labels'] = otherTenderLabels;
                const merchAttributes = ['other__other_tender_labels'];

                if ($scope.isDefaultSrmChanged) {
                    // find the original default srm
                    let originalDefaultSrmDevice = _.find($scope.tmpSrmDevices, (tmpSrmDevice) => {
                        return tmpSrmDevice.defaultDevice;
                    });

                    originalDefaultSrmDevice.defaultDevice = false;

                    $scope.updateSrmDevice(originalDefaultSrmDevice).then((res) =>
                        $scope.updateSrmDevice($scope.newDefaultSrmDevice)
                    );
                }

                try {
                    await $scope.updateCompany();
                    await updateCompanyAttributes(merchAttributes);
                    $scope.isDefaultSrmChanged = false;
                    $scope.newDefaultSrmDevice = undefined;
                    $scope.loadSrmDevices($scope.currentCompanyId);
                    settingsUpdated();
                } catch (err) {
                    settingsUpdated(false);
                }
            };

            $scope.updateDiscountSettings = async function () {
                if ($scope.merchant.attributes['other__activate_cashier_discount_limit']
                    && $scope.merchant.attributes['other__cashier_discount_limit'] == undefined) {
                    settingsUpdated(false);
                    return;
                }

                try {
                    await updateCompanyAttributes();
                    settingsUpdated();
                } catch (err) {
                    settingsUpdated(false);
                }
            };

            $scope.updateReceiptSettings = async function () {
                try {
                    await updateCompanyAttributes();
                    settingsUpdated();
                } catch (err) {
                    settingsUpdated(false);
                }

            };

            $scope.updateKioskSettings = async function () {
                $scope.merchant.attributes['kiosk__default_menu_page_index'] = $scope.merchant.defaultKioskMenuPage;
                const merchAttributes = [
                    'kiosk__default_menu_page_index',
                    'kiosk__split_tender'
                ];

                try {
                    await updateCompanyAttributes(merchAttributes);
                    settingsUpdated();
                } catch (err) {
                    settingsUpdated(false);
                }

            };

            $scope.updateFiitSettings = async function () {
                try {
                    await updateCompanyAttributes();
                    settingsUpdated();
                } catch (err) {
                    settingsUpdated(false);
                }

            };

            $scope.updateLocation = function () {
                var location = angular.copy($scope.currentLocation);
                location.street = $scope.merchant.address.street;
                location.city = $scope.merchant.address.city;
                location.region = $scope.merchant.address.province;
                location.zip = $scope.merchant.address.postalCode;
                location.latitude = $scope.merchant.address.latitude;
                location.longitude = $scope.merchant.address.longitude;

                var servicePeriods = _.filter($scope.servicePeriods, function (servicePeriod) {
                    return servicePeriod.serviceDays[0].enabled;
                });
                var servicePeriodIds = _.map(servicePeriods, function (servicePeriod) {
                    return servicePeriod.servicePeriodId;
                });
                location.servicePeriodIds = servicePeriodIds;

                return Locations.update({}, location).$promise;
            };

            $scope.updateCompany = function () {
                return Company.update({}, $scope.company, function (company) {
                    var currentUser = Object.assign({}, Security.getUser());
                    currentUser.company = company;
                    Security.updateUser(currentUser);
                }).$promise;
            };

            const updateCompanyAttributes = function (attributesToUpdate = []) {
                const companyAttributes = AppConstants.companyAttributesList.concat(attributesToUpdate);
                const attributesPayload = {};

                for (const attribute of companyAttributes) {
                    // Checking with undefined because value can also be true or false
                    if ($scope.merchant.attributes[attribute] != undefined) {
                        attributesPayload[attribute] = $scope.merchant.attributes[attribute];
                    }

                    // Commented By Akash Mehta on June 25th 2020
                    // This block is added to prevent additional testing
                    // and breaking existing code workflow. We should remove this
                    // going forward.
                    if (attribute == 'sevenshifts_api_key') {
                        var submittedAPIKey = $scope.merchant.attributes['sevenshifts_api_key'];

                        if (submittedAPIKey) {
                            attributesPayload['sevenshifts_api_key'] = submittedAPIKey;
                            attributesPayload['sevenshifts_enabled'] = true;
                        } else {
                            attributesPayload['sevenshifts_api_key'] = '';
                            attributesPayload['sevenshifts_enabled'] = false;
                        }
                    }

                    // Commented By Akash Mehta on June 25th 2020
                    // This block is added to prevent additional testing
                    // and breaking existing code workflow. We should remove this
                    // going forward
                    if (attribute == 'adp_username') {
                        var submittedUsername = $scope.merchant.attributes['adp_username'];
                        var submittedPassword = $scope.merchant.attributes['adp_password'];

                        if (submittedUsername && submittedPassword) {
                            attributesPayload['adp_username'] = submittedUsername;
                            attributesPayload['adp_password'] = submittedPassword;
                            $scope.company.attributes['adp_username'] = submittedUsername;
                            $scope.company.attributes['adp_password'] = submittedPassword;
                        } else {
                            $scope.company.attributes['adp_username'] = '';
                            $scope.company.attributes['adp_password'] = '';
                            attributesPayload['adp_username'] = '';
                            attributesPayload['adp_password'] = '';
                        }
                    }
                }

                const finalPayloadToSend = {};
                finalPayloadToSend[$scope.company.companyId] = attributesPayload;

                return Company.updateAttributes({}, finalPayloadToSend, function (response) {
                    let attributesCopy = angular.copy(response[$scope.company.companyId]);
                    $timeout(function () {
                        $scope.loadAttributes(attributesCopy);
                        $scope.currentUser.company.attributes = attributesCopy;
                        $scope.merchant.attributes = attributesCopy;
                    }, 0);
                    Security.updateCompanyAttributes(response[$scope.company.companyId]);
                    $scope.checkSevenshiftsConnectivity();
                    $scope.checkADPConnectivity();
                }).$promise;
            };

            $scope.updateDailySchedule = function (dailySchedule, save) {
                var day = dailySchedule.day;
                var dayName = dailySchedule.dayName;
                var newValue = dailySchedule.hours[0];

                var newStartTime;
                var newEndTime;

                if (newValue) {
                    var isStartTimeNextDay = false;
                    var isEndTimeNextDay = false;
                    if (newValue.start > 24) {
                        isStartTimeNextDay = true;
                        newValue.start -= 24;
                    }
                    if (newValue.end > 24) {
                        isEndTimeNextDay = true;
                        newValue.end -= 24;
                    }
                    var startHour = Math.floor(newValue.start);
                    var startMinute = (newValue.start - Math.floor(newValue.start)) * 60;
                    newStartTime = moment(startHour, 'HH').add(startMinute, 'm').format('h:mm a');
                    if (isStartTimeNextDay) {
                        newStartTime = '24+' + newStartTime;
                    }

                    var endHour = Math.floor(newValue.end);
                    var endMinute = (newValue.end - Math.floor(newValue.end)) * 60;
                    newEndTime = moment(endHour, 'HH').add(endMinute, 'm').format('h:mm a');
                    if (isEndTimeNextDay) {
                        newEndTime = '24+' + newEndTime;
                    }
                }

                if (save) {
                    var servicePeriod;

                    var foundServicePeriod = _.findWhere($scope.servicePeriods, {code: day});
                    if (foundServicePeriod) {
                        let toRemove = true;
                        // edit
                        servicePeriod = angular.copy(foundServicePeriod);
                        if (newValue) {
                            let serviceDayIndex = _.findIndex(foundServicePeriod.serviceDays, {day: $scope.constants.days[day]});

                            servicePeriod.startTime = newStartTime;
                            servicePeriod.endTime = newEndTime;
                            servicePeriod.serviceDays[serviceDayIndex].enabled = dailySchedule.isEnabled;
                            servicePeriod.mealEquivalentDayMap[day] = {
                                active: true,
                                allowedDollarAmount: 0.01,
                                allowedPerCycle: 100000, // max $1000 point redemptoin
                                enabled: true,
                                startTime: '12:00 AM',
                                endTime: '11:59 PM'
                            },
                            $scope.updateServicePeriod(servicePeriod, toRemove);
                        } else {
                            servicePeriod.serviceDays = [{
                                id: foundServicePeriod.serviceDays[0].id,
                                day: day,
                                enabled: false
                            }];
                            $scope.deleteServicePeriod(servicePeriod, day);
                        }
                    } else {
                        var mealEquivalentDayMap = {};
                        mealEquivalentDayMap[day] = {
                            active: true,
                            allowedDollarAmount: 0.01,
                            allowedPerCycle: 100000, // max $1000 point redemptoin
                            enabled: true,
                            startTime: '12:00 AM',
                            endTime: '11:59 PM'
                        };
                        servicePeriod = {
                            code: day,
                            description: dayName,
                            startTime: newStartTime,
                            endTime: newEndTime,
                            companyId: $scope.currentCompanyId,
                            serviceDays: [{
                                day: day,
                                enabled: true
                            }],
                            mealEquivalentDayMap: mealEquivalentDayMap
                        };
                        $scope.addServicePeriod(servicePeriod);
                    }
                }
            };
            $scope.addServicePeriod = function (servicePeriod) {
                Settings.addServicePeriod({}, servicePeriod, function (response) {
                    var location = {
                        locationId: $scope.currentLocationId,
                        servicePeriodIds: [response.servicePeriodId]
                    };
                    Locations.addServicePeriod({}, location, function (response) {
                        $scope.loadServicePeriods($scope.currentCompanyId);
                    });
                }, function (error) {
                    // notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
                });
            };
            $scope.updateServicePeriod = function (servicePeriod, toRemove) {
                Settings.updateServicePeriod({}, servicePeriod, function (response) {
                    var location = {
                        locationId: $scope.currentLocationId,
                        servicePeriodIds: [response.servicePeriodId]
                    };

                    if (!toRemove) {
                        Locations.addServicePeriod({}, location, function (response) {
                            $scope.loadServicePeriods($scope.currentCompanyId);
                        });
                    }
                }, function (error) {
                    // notificationTranslationHelper.notifyError(ErrorUtil.parseError(error), null, true);
                });
            };
            $scope.deleteServicePeriod = function (servicePeriod) {
                // simulate deleted service period by disabling every service day
                _.each(servicePeriod.serviceDays, function (serviceDay) {
                    serviceDay.enabled = false;
                });
                $scope.updateServicePeriod(servicePeriod, true);
            };

            $scope.addCustomerDisplayImage = function () {
                var slide = {
                    isNew: true,
                    companyId: $scope.currentCompanyId
                };
                $scope.editCustomerDisplayImage(slide);
            };
            $scope.getAssetUrl = function (url) {
                return 'url(' + url + ')';
            };
            $scope.editCustomerDisplayImage = function (slide) {
                var modalInstance = $modal.open({
                    templateUrl: 'setup/modals/wizardEditCustomerDisplay.tpl.html',
                    controller: 'EditCustomerDisplayCtrl',
                    windowClass: 'setup-wizard modal__setup-wizard',
                    resolve: {
                        selectedCompanyId: function () {
                            return Security.getUser().companyId;
                        },
                        isEdit: function () {
                            return !slide.isNew;
                        },
                        slide: function () {
                            return slide;
                        }
                    },
                    backdrop: 'static'
                });
                modalInstance.result.then(function () {
                    $scope.loadCompany().$promise.then(function (company) {
                        $scope.company = company;
                    });
                });
            };

            $scope.addLoyaltyPlan = function () {
                var plan = {
                    isNew: true,
                    companyId: $scope.currentCompanyId
                };
                $scope.editLoyaltyPlan(plan);
            };

            $scope.editLoyaltyPlan = function (plan) {
                var modalInstance = $modal.open({
                    templateUrl: 'setup/modals/wizardEditLoyaltyPlan.tpl.html',
                    controller: 'EditLoyaltyPlanCtrl',
                    windowClass: 'setup-wizard modal__setup-wizard',
                    resolve: {
                        selectedCompanyId: function () {
                            return Security.getUser().companyId;
                        },
                        isEdit: function () {
                            return !plan.isNew;
                        },
                        plan: function () {
                            return plan;
                        }
                    },
                    backdrop: 'static'
                });
                modalInstance.result.then(function () {
                    // $scope.loadCompany().$promise.then(function (company) {
                    //     $scope.company = company;
                    // });
                });
            };

            $scope.addTaxRate = function () {
                var newTaxRate = {
                    isNew: true,
                    companyId: $scope.currentCompanyId
                };
                $scope.editTaxRate(newTaxRate);
            };
            $scope.editTaxRate = function (taxRate, dismissTaxAlert) {
                var modalInstance = $modal.open({
                    templateUrl: 'setup/modals/wizardEditTaxRate.tpl.html',
                    controller: 'EditTaxRateCtrl',
                    windowClass: 'setup-wizard modal__setup-wizard',
                    resolve: {
                        selectedCompanyId: function () {
                            return Security.getUser().companyId;
                        },
                        isEdit: function () {
                            return !taxRate.isNew;
                        },
                        taxRate: function () {
                            return taxRate;
                        },
                        isRootCompany: function () {
                            return $scope.isRootCompany;
                        },
                        allTaxRates: function () {
                            return $scope.merchant.taxRates;
                        }
                    },
                    backdrop: 'static'
                });
                modalInstance.result.then(function (taxRate) {
                    $scope.loadTaxRates($scope.currentCompanyId);
                    if (dismissTaxAlert) {
                        $scope.provinceTaxAlert = undefined;
                    }
                    // notificationTranslationHelper.notifyMessage('settings.taxRate.add.success');
                });
            };

            $scope.removeAllPrinterIds = function () {
                if ($scope.requestState.removeAllPrinterIds != REQUEST_STATES.NA) {
                    return;
                }

                $scope.isUpdating = true;
                $scope.requestState.removeAllPrinterIds = REQUEST_STATES.LOADING;
                let unassignPrinterPromise;

                if (CompanyAttributesService.hasMenuV2Enabled()) {
                    unassignPrinterPromise = Menu.unassignPrintersFromAssociatedItems({}, {
                        unassignAll: true,
                        companyId: $scope.currentCompanyId
                    }).$promise;
                } else {
                    unassignPrinterPromise = Locations.unassignPrinterFromItems({
                        printerId: null,
                        locationId: $scope.currentLocationId,
                        assignAll: true,
                    }).$promise;
                }

                unassignPrinterPromise
                .then(() => {
                    $timeout(() => {
                        $scope.requestState.removeAllPrinterIds = REQUEST_STATES.SUCCESS;
                        $scope.printerStatusMessage = 'printer.defaultSecondaryPrinter';
                    });
                    settingsUpdated();
                })
                .catch(() => {
                    $timeout(() => {
                        $scope.requestState.removeAllPrinterIds = REQUEST_STATES.ERROR;
                        $scope.printerStatusMessage = 'printer.failedChangeSettings';
                    });
                    settingsUpdated(false);
                })
                .finally(() => {
                    // Clear the message after a delay.
                    $timeout(() => {
                        $scope.requestState.removeAllPrinterIds = REQUEST_STATES.NA;
                        $scope.printerStatusMessage = '';
                    }, NOTIFICATION_DURATION);
                });
            };

            $scope.toggleKitchenPrintingForAllItems = function (toggleValue, requestName) {
                if (!$scope.requestState[requestName] || $scope.requestState[requestName] != REQUEST_STATES.NA) {
                    return;
                }

                $scope.isUpdating = true;
                $scope.requestState[requestName] = REQUEST_STATES.LOADING;
                let unassignPrinterPromise;

                if (CompanyAttributesService.hasMenuV2Enabled()) {
                    unassignPrinterPromise = Menu.toggleKitchenPrintingForAllItems({}, {
                        companyId: $scope.currentCompanyId,
                        enableKitchenPrintingForAllItems: !!toggleValue
                    }).$promise;
                } else {
                    unassignPrinterPromise = Locations.toggleKitchenPrintingForAllItems({
                        locationId: $scope.currentLocationId,
                        enableKitchenPrintingForAllItems: !!toggleValue
                    }).$promise;
                }

                unassignPrinterPromise
                .then(() => {
                    $timeout(() => {
                        $scope.requestState[requestName] = REQUEST_STATES.SUCCESS;
                        $scope.printerStatusMessage = toggleValue ? 'printer.secondaryPrintingOn' : 'printer.secondaryPrintingOff';
                    });
                    settingsUpdated();
                })
                .catch(() => {
                    $timeout(() => {
                        $scope.requestState[requestName] = REQUEST_STATES.ERROR;
                        $scope.printerStatusMessage = 'printer.failedChangeSettings';
                    });
                    settingsUpdated(false);
                })
                .finally(() => {
                    // Clear the message after a delay.
                    $timeout(function () {
                        $scope.requestState[requestName] = REQUEST_STATES.NA;
                        $scope.printerStatusMessage = '';
                    }, NOTIFICATION_DURATION);
                });
            };

            $scope.addStation = function () {
                var newStation = {
                    isNew: true,
                    locationId: $scope.currentLocationId,
                    bluetoothEnabled: true,
                    posPrinters: [],
                    cardTerminalProperties: {
                        type: 'manual'
                    }
                };
                $scope.editStation(newStation);
            };
            $scope.editStation = function (station) {
                var location = {
                    locationId: $scope.currentLocationId,
                    selectedPosStation: station
                };
                var modalInstance = $modal.open({
                    // templateUrl: 'locations/location.edit.posStations.tpl.html',
                    templateUrl: 'setup/modals/wizardEditStation.tpl.html',
                    controller: 'EditLocationPosStationCtrl',
                    windowClass: 'setup-wizard modal__setup-wizard modal__edit-station',
                    resolve: {
                        selectedCompanyId: function () {
                            return Security.getUser().companyId;
                        },
                        location: function () {
                            return location;
                        },
                        isWizardMode: function () {
                            return true;
                        },
                        isMevBoxCompany: function () {
                            return $scope.isMevBoxCompany;
                        },
                        srmDevices: function () {
                            return $scope.srmDevices;
                        }
                    },
                    backdrop: 'static',
                });
                modalInstance.result.then(function (result) {
                    $scope.loadStations($scope.currentLocationId).$promise.then(function () {
                        _.each($scope.merchant.stations, function (station) {
                            if (result && result.posStationId === station.posStationId) {
                                station.updated = true;
                            }
                        });

                        $scope.updatedStations = $scope.updatedStations || [];
                        $scope.updatedStations.push(result);
                    });
                    // notificationTranslationHelper.notifyMessage('settings.station.add.success');
                });
            };
            $scope.addPrinter = function () {
                var newPrinter = {
                    isNew: true,
                    locationId: $scope.currentLocationId
                };
                $scope.editPrinter(newPrinter);
            };
            $scope.editPrinter = function (printer) {
                var location = {
                    selectedPrinter: printer
                };
                var modalInstance = $modal.open({
                    // templateUrl: 'locations/location.edit.printers.tpl.html',
                    templateUrl: 'setup/modals/wizardEditPrinter.tpl.html',
                    controller: 'EditLocationPrintersCtrl',
                    windowClass: 'setup-wizard modal__setup-wizard',
                    resolve: {
                        selectedCompanyId: function () {
                            return Security.getUser().companyId;
                        },
                        location: function () {
                            return location;
                        },
                        isWizardMode: function () {
                            return true;
                        }
                    },
                    backdrop: 'static',
                });
                modalInstance.result.then(function (result) {
                    $scope.loadPrinters($scope.currentLocationId).$promise.then(function () {
                        _.each($scope.merchant.printers, function (printer) {
                            if (result && result.printerId === printer.printerId) {
                                printer.updated = true;
                            }
                        });

                        $scope.updatedPrinters = $scope.updatedPrinters || [];
                        $scope.updatedPrinters.push(result);
                    });
                    // notificationTranslationHelper.notifyMessage('settings.printer.add.success');
                });
            };

            const updateCompanyServiceConfig = () => {
                const payload = $scope.company.serviceConfig;

                // remove extraneous keys (such as 'label' used in the UI)
                for (const service in payload) {
                    if (payload.hasOwnProperty(service)) {
                        payload[service] = _.pick(payload[service], ['enabled', 'emails', 'config']);
                    }
                }

                return Company.updateServiceConfig(payload, function (response) {
                    $scope.company.serviceConfig = response;
                }).$promise;
            };

            $scope.updateCompanyServiceConfig = () => {
                updateCompanyServiceConfig()
                .then(() => {
                    settingsUpdated();
                    $scope.setSecondaryServicePage();
                });
            };

            $scope.updateFiitSavedMealCardEmails = () => {
                updateCompanyServiceConfig()
                .then(() => {
                    settingsUpdated();
                    $scope.setFiitSecondaryPage('main');
                });
            };

            // MEVBOX AND SRM SETTINGS
            $scope.addSrmDevice = () => {
                var newSrmDevice = {
                    companyId: $scope.currentCompanyId,
                    defaultDevice: true
                };
                $scope.editSrmDevice(newSrmDevice, true);
            };
            $scope.editSrmDevice = function (srmDevice, isNew) {
                var modalInstance = $modal.open({
                    templateUrl: 'setup/modals/wizardEditSrmDevice.tpl.html',
                    controller: 'EditSrmDeviceCtrl',
                    windowClass: 'setup-wizard modal__setup-wizard modal__edit-srm-device',
                    resolve: {
                        isNew: function () {
                            return isNew;
                        },
                        currentSrmDevice: function () {
                            return srmDevice;
                        },
                        setDefault: function () {
                            if (srmDevice.id) {
                                // if srmDevice contains an id, the user is editing the device.
                                return srmDevice.defaultDevice;
                            } else {
                                // if srmDevice doesn't contain an id,
                                // we set defaultDevice only if this is the first srm device.
                                return !($scope.srmDevices.length);
                            }
                        }
                    },
                    backdrop: 'static',
                });
                modalInstance.result.then(function (result) {
                    $scope.loadSrmDevices($scope.currentCompanyId);
                });
            };
            $scope.newDefaultSrmDevice;
            $scope.isDefaultSrmChanged = false;
            $scope.onSrmDeviceDefaultChange = (selectedSrmDevice) => {
                _.each($scope.srmDevices, (srmDevice) => {
                    // find and set the previous default device to false.
                    // ensure we are not resetting the selectedSrmDevice to false in the case of a race condition.
                    if (srmDevice.id != selectedSrmDevice.id && srmDevice.defaultDevice === true) {
                        srmDevice.defaultDevice = false;
                    }
                });

                selectedSrmDevice.defaultDevice = true;
                $scope.newDefaultSrmDevice = selectedSrmDevice;

                // find the original default srm
                let originalDefaultSrmDevice = _.find($scope.tmpSrmDevices, (tmpSrmDevice) => {
                    return tmpSrmDevice.defaultDevice;
                });

                // this catches the case where
                // the original default was srm1, user selects srm2
                // then changes their mind and reselects srm1
                $scope.isDefaultSrmChanged = !($scope.newDefaultSrmDevice.id === originalDefaultSrmDevice.id);
            };

            $scope.updateSrmDevice = (srmDevice) => {
                return Locations.updateSrmDevice(srmDevice).$promise;
            };

            $scope.importUsers = function () {
                if (!$scope.isRootCompany) {
                    return;
                }

                var modalInstance = $modal.open({
                    templateUrl: 'setup/modals/importFile.tpl.html',
                    controller: 'ImportFileCtrl',
                    windowClass: 'employeeImportModal',
                    modal: true,
                    resolve: {
                    }
                });

                modalInstance.result.then(function (result) {
                    $scope.init();
                });
            };

            $scope.addUser = function () {
                var newUser = {
                    isNew: true,
                    companyId: $scope.currentCompanyId
                };
                $scope.editUser(newUser);
            };

            $scope.editUser = function (user) {
                if ($scope.showEditor && user.userId === $scope.siteAdminUser[0].userId) {
                    // will disable fields when trying to edit admin during company creation
                    $scope.askToSetAdminUserPin = true;
                } else {
                    $scope.askToSetAdminUserPin = false;
                }

                $modal.open({
                    templateUrl: 'setup/modals/wizardEditUser.tpl.html',
                    controller: 'EmployeeModifyCtrl',
                    windowClass: 'setup-wizard modal__setup-wizard',
                    scope: $scope,
                    resolve: {
                        selectedUser: () => user,
                        currentPage: () => $scope.page,
                        showEditor: () => $scope.showEditor
                    },
                    backdrop: 'static'
                });
            };

            $scope.addTimecard = function () {
                if (!$scope.isRootCompany) {
                    return;
                }

                var newTimecard = {
                    isNew: true,
                    companyId: $scope.currentCompanyId
                };
                $scope.editTimecard(newTimecard);
            };

            $scope.editTimecard = function (timeCard) {
                $modal.open({
                    templateUrl: 'setup/modals/wizardEditEmployeeTimecard.tpl.html',
                    controller: 'EmployeeTimecardsModifyCtrl',
                    windowClass: 'setup-wizard modal__setup-wizard',
                    scope: $scope,
                    resolve: {
                        selectedTimeCard: () => timeCard
                    },
                    backdrop: 'static'
                });
            };

            $scope.addDiscount = function () {
                if (!$scope.isRootCompany) {
                    return;
                }

                var newDiscount = {
                    isNew: true,
                    companyId: $scope.currentCompanyId
                };
                $scope.editDiscount(newDiscount);
            };

            $scope.editDiscount = function (discount) {
                $modal.open({
                    templateUrl: 'setup/modals/wizardEditDiscount.tpl.html',
                    controller: 'DiscountsModifyCtrl',
                    windowClass: 'setup-wizard modal__setup-wizard',
                    resolve: {
                        discounts: () => $scope.discounts,
                        selectedDiscount: () => discount
                    },
                    backdrop: 'static'
                }).result.finally(() => {
                    $scope.loadDiscounts($scope.currentCompanyId);
                });
            };

            $scope.returnToUsersPage = function (page, toReloadPage, toConfirmAction = false) {
                var modalInstance = null;
                var confirmationModal = {
                    templateUrl: 'setup/modals/wizardEdit.confirmation.html',
                    animation: false,
                    backdrop: 'static',
                    controller: 'SetupConfirmationModalCtrl',
                    windowClass: 'save_changes_modal products2',
                    keyboard: false,
                    resolve: {
                        modalType: function () {
                            return 'CONFIRM_EXIT';
                        }
                    }
                };

                if (toConfirmAction) {
                    modalInstance = $modal.open(confirmationModal);
                    modalInstance.result.then(function (response) {
                        if (page == 'employee-setup') {
                            $scope.employees.isEditingUser = false;
                            $scope.employees.label = $translate.instant('setupWizard.merchant.employees.label');
                            $scope.employees.statusBar = {toShow: false};
                            $scope.employees.hasChanged = false;
                            if (toReloadPage) {
                                $scope.loadUsers($scope.currentCompanyId);
                                LookupService.getRolePermissions().then(function (rolePermissions) {
                                    Security.rolePermissions = rolePermissions;
                                });
                            }
                        } else if (page == 'employee-timecards') {
                            $scope.timecards.isEditingTimecard = false;
                            $scope.timecards.label = $translate.instant('setupWizard.merchant.timecards.label');
                            $scope.timecards.statusBar = {toShow: false};
                            $scope.timecards.hasChanged = false;
                            if (toReloadPage) {
                                $scope.loadTimecards($scope.currentCompanyId);
                            }
                        } else if (page == 'discount-setup') {
                            $scope.discounts.isEditingDiscount = false;
                            $scope.discounts.label = $translate.instant('setupWizard.merchant.discounts.label');
                            $scope.discounts.statusBar = {toShow: false};
                            $scope.discounts.hasChanged = false;
                            if (toReloadPage) {
                                $scope.loadDiscounts($scope.currentCompanyId);
                            }
                        }
                    });
                } else {
                    if (page == 'employee-setup') {
                        $scope.employees.isEditingUser = false;
                        $scope.employees.label = $translate.instant('setupWizard.merchant.employees.label');
                        $scope.employees.statusBar = {toShow: false};
                        $scope.employees.hasChanged = false;
                        if (toReloadPage) {
                            $scope.loadUsers($scope.currentCompanyId);
                            LookupService.getRolePermissions().then(function (rolePermissions) {
                                Security.rolePermissions = rolePermissions;
                            });
                        }
                    } else if (page == 'employee-timecards') {
                        $scope.timecards.isEditingTimecard = false;
                        $scope.timecards.label = $translate.instant('setupWizard.merchant.timecards.label');
                        $scope.timecards.statusBar = {toShow: false};
                        $scope.timecards.hasChanged = false;
                        if (toReloadPage) {
                            $scope.loadTimecards($scope.currentCompanyId);
                        }
                    } else if (page == 'discount-setup') {
                        $scope.discounts.isEditingDiscount = false;
                        $scope.discounts.label = $translate.instant('setupWizard.merchant.discounts.label');
                        $scope.discounts.statusBar = {toShow: false};
                        $scope.discounts.hasChanged = false;
                        if (toReloadPage) {
                            $scope.loadDiscounts($scope.currentCompanyId);
                        }
                    }
                }
            };


            $scope.opened = {};

            $scope.$watch(function () {
                return $scope.timecards.timeFilters.startTime;
            }, function () {
                $timeout(function () {
                    $scope.opened.from = false;
                }, 0);

            });

            $scope.$watch(function () {
                return $scope.timecards.timeFilters.endTime;
            }, function () {
                $timeout(function () {
                    $scope.opened.to = false;
                }, 0);
            });

            $scope.toggleFromDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.from;
                var newStatus = !status;
                $scope.opened.from = newStatus;
                if (newStatus) {
                    $scope.opened.to = false;
                }
            };

            $scope.toggleToDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                var status = !!$scope.opened.to;
                var newStatus = !status;
                $scope.opened.to = newStatus;
                if (newStatus) {
                    $scope.opened.from = false;
                }
            };
            $scope.dateOptions = {
                formatYear: 'yy',
                startingDay: 1,
                closeOnDateSelection: true
            };

            $scope.mealCardConfig = {isDatePickerOpen: false, searchDate: moment(new Date()).local().format('YYYY/MM/DD')};
            $scope.toggleMealCardDatePicker = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                $scope.mealCardConfig.isDatePickerOpen = !$scope.mealCardConfig.isDatePickerOpen;
            };

            $scope.onProvinceChanged = function () {
                var currentProvince = _.findWhere($scope.constants.provinces, {id: $scope.merchant.address.province}) || {};

                $scope.removeProvinceTaxAlert();
                $translate(currentProvince.name).then(function (provinceName) {
                    var existingDefaultTax = getExistingDefaultTax(currentProvince);
                    if (!existingDefaultTax) {
                        $scope.provinceTaxAlert = {
                            companyId: $scope.currentCompanyId,
                            taxRate: currentProvince.taxRate,
                            taxRateName: provinceName,
                            default: true,
                        };
                    } else {
                        if (existingDefaultTax.taxRate != currentProvince.taxRate) {
                            $scope.provinceTaxAlert = {
                                companyId: $scope.currentCompanyId,
                                taxRateId: existingDefaultTax.taxRateId,
                                taxRate: currentProvince.taxRate,
                                taxRateName: provinceName,
                                default: true,
                                isUpdate: true
                            };
                        }
                    }
                });
            };
            var getExistingDefaultTax = function (province) {
                var foundTaxRate = _.find($scope.merchant.taxRates, function (taxRate) {
                    return taxRate.default === true;
                });
                return foundTaxRate;
            };
            $scope.removeProvinceTaxAlert = function () {
                $scope.provinceTaxAlert = undefined;
            };


            $scope.getUserFullName = function (user) {
                return (user.lastname) ? user.firstname + ' ' + user.lastname : user.firstname;
            };
            $scope.formatFullAddress = function (address) {
                var fullAddress = '';

                if (address) {
                    fullAddress = '<div class="text-phrase">' + address.street + '</div>'
                        + '<div class="text-phrase">' + address.city + ', ' + address.province + '</div>'
                        + '<div class="text-phrase">' + address.postalCode + '</div>';
                }

                return $sce.trustAsHtml(fullAddress);
            };
            $scope.formatOperatingHours = function (hours) {
                var timeStrings = _.map(hours, function (hour) {
                    var startMinute = (hour.start - Math.floor(hour.start)) * 60;
                    var endMinute = (hour.end - Math.floor(hour.end)) * 60;

                    var startHour = 0;
                    var endHour = 0;

                    if (hour.start > 24) {
                        startHour = hour.start - 24;
                    } else {
                        startHour = hour.start;
                    }

                    if (hour.end > 24) {
                        endHour = hour.end - 24;
                    } else {
                        endHour = hour.end;
                    }

                    var startTimeString = moment(startHour, 'HH').add(startMinute, 'm').format('hh:mm a');
                    var endTimeString = moment(endHour, 'HH').add(endMinute, 'm').format('hh:mm a');
                    return startTimeString + ' - ' + endTimeString;
                });

                return timeStrings.join(', ');
            };

            var createSingleFileUploader = function (url, alias, data) {
                var uploaderConfig = {};
                uploaderConfig.url = url;

                if (alias) {
                    uploaderConfig.alias = alias;
                }

                if (data) {
                    var formData = [];
                    _.each(data, function (value, key) {
                        var datum = {};
                        datum[key] = value;
                        formData.push(datum);
                    });
                    uploaderConfig.formData = formData;
                }

                var uploader = new FileUploader(uploaderConfig);
                uploader.filters.push({
                    name: 'imageFilter',
                    fn: function (item /* {File|FileLikeObject} */, options) {
                        var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                        return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
                    }
                });
                uploader.onWhenAddingFileFailed = function (item /* {File|FileLikeObject} */, filter, options) {
                };
                uploader.onAfterAddingFile = function (fileItem) {
                    // The banner uploader should be accepting only 1 file. However, there does not seem to be a way
                    // to remove or override the existing file, and `queueLimit` of 1 simply throws an error when there
                    // is already a file. This line ensures that only 1 file is chosen for upload.
                    this.queue = [this.queue[this.queue.length - 1]];
                };
                uploader.onAfterAddingAll = function (addedFileItems) {
                };
                uploader.onBeforeUploadItem = function (item) {
                    item.removeAfterUpload = true;
                };
                uploader.onProgressItem = function (fileItem, progress) {
                };
                uploader.onProgressAll = function (progress) {
                };
                uploader.onSuccessItem = function (fileItem, response, status, headers) {
                };
                uploader.onErrorItem = function (fileItem, response, status, headers) {
                };
                uploader.onCancelItem = function (fileItem, response, status, headers) {
                };
                uploader.onCompleteItem = function (fileItem, response, status, headers) {
                };
                uploader.onCompleteAll = function (response) {

                };

                return uploader;
            };

            var bannerUploader = $scope.bannerUploader = createSingleFileUploader('/freshideas/web/location/upload/banner-img');
            bannerUploader.onCompleteItem = function (fileItem, response, status, headers) {
                if (status == 413) {
                    return; // Do this to avoid double error modal.
                }
                if (response.success) {
                    $scope.currentLocation.locationImageUrl = response.assetUrl;
                    settingsUpdated();
                }
            };

            bannerUploader.onErrorItem = function (fileItem, response, status, headers) {
                /**
                 * 413: Entity too large
                 */
                bannerUploader.clearQueue();
                if (status == 413) {
                    PosAlertService.showAlertByName('file-upload-failed-large-file');
                } else {
                    PosAlertService.showAlertByName('file-upload-failed');
                }
            };

            const createLoyaltyCardImageUploader = (url) => {
                const uploaderConfig = {};
                uploaderConfig.url = url;

                const uploader = new FileUploader(uploaderConfig);
                uploader.filters.push({
                    name: 'imageFilter',
                    fn: function (item /* {File|FileLikeObject} */, options) {
                        const type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                        return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
                    }
                });

                uploader.onAfterAddingFile = function (fileItem) {
                };
                uploader.onAfterAddingAll = function (addedFileItems) {
                };
                return uploader;
            };

            const loyaltyCardUploader = $scope.loyaltyCardUploader = createLoyaltyCardImageUploader('/freshideas/web/company/upload/loyalty-card-image');

            loyaltyCardUploader.onAfterAddingFile = (fileItem) => {

                // check if the image size is exactly 1146 x 723 px
                const uploadedImg = new Image();
                uploadedImg.src = URL.createObjectURL(fileItem._file);
                uploadedImg.onload = () => {
                    if (uploadedImg.width !== 1146 || uploadedImg.height !== 723) {
                        loyaltyCardUploader.clearQueue();
                        PosAlertService.showAlertByName('file-upload-failed-wrong-dimentions');
                    }
                };
            };

            loyaltyCardUploader.onBeforeUploadItem = (item) => {

                item.removeAfterUpload = true;
                if ($scope.company.loyaltyCardImageId && $scope.company.loyaltyCardImageId !== '') {
                    item.formData.push({
                        id: '',
                        replacesId: +$scope.company.loyaltyCardImageId,
                        resizeWidth: 1146,
                        resizeHeight: 723
                    });
                } else {
                    item.formData.push({
                        id: '',
                        resizeWidth: 1146,
                        resizeHeight: 723
                    });
                }

            };

            loyaltyCardUploader.onCompleteItem = (fileItem, response, status, headers) => {

                // response: { createdAt, deletedAt, fileName, id, updatedAt, url }
                if (response) {
                    $scope.company.loyaltyCardImage = response;
                    $scope.company.loyaltyCardImageId = response.id;
                    settingsUpdated();
                }
            };

            loyaltyCardUploader.onErrorItem = (fileItem, response, status, headers) => {
                // 413: Entity too large
                // loyaltyCardUploader.clearQueue();
                if (status == 413) {
                    PosAlertService.showAlertByName('file-upload-failed-large-file');
                } else {
                    PosAlertService.showAlertByName('file-upload-failed');
                }
            };

            var logoUploader = $scope.logoUploader = createSingleFileUploader('/freshideas/web/company/upload/logo-img');
            logoUploader.onCompleteItem = function (fileItem, response, status, headers) {
                if (response.success) {
                    $scope.company.companyLogoUrl = response.assetUrl;
                    settingsUpdated();
                }
            };

            logoUploader.onErrorItem = function (fileItem, response, status, headers) {
                /**
                 * 413: Entity too large
                 */
                // logoUploader.clearQueue();
                if (status == 413) {
                    PosAlertService.showAlertByName('file-upload-failed-large-file');
                } else {
                    PosAlertService.showError('error', 'general.error.file-upload-failed.ttl', '', response.message);
                }
            };

            const receiptLogoUploader = $scope.receiptLogoUploader = createSingleFileUploader('/freshideas/web/company/upload/receipt-logo');
            receiptLogoUploader.onCompleteItem = function (fileItem, response, status, headers) {
                if (response.success) {
                    $scope.company.receiptLogoUrl = response.assetUrl;
                    settingsUpdated();
                }
            };

            receiptLogoUploader.onErrorItem = function (fileItem, response, status, headers) {
                /**
                 * 413: Entity too large
                 */
                if (status == 413) {
                    PosAlertService.showAlertByName('file-upload-failed-large-file');
                } else {
                    PosAlertService.showError('error', 'general.error.file-upload-failed.ttl', '', response.message);
                }
            };

            $scope.clearReceiptLogo = () => {
                var modalInstance = $modal.open({
                    templateUrl: 'common/freshideas/modalConfirmDelete.tpl.html',
                    controller: 'ConfirmDeleteModalCtrl',
                    resolve: {
                        message: function () {
                            return 'Are you sure you want to clear the receipt logo?';
                        },
                        paramMap: function () {
                            return {};
                        },
                        title: function () {
                            return 'Clear Receipt Logo';
                        },
                        allowCancel: function () {
                            return true;
                        }
                    }
                });
                modalInstance.result.then(function () {
                    $scope.company.receiptLogoUrl = null;
                    $scope.updateCompany()
                    .then((updatedCompany) => {
                        $scope.company = updatedCompany;
                    });
                });
            };

            var unescapeHtml = function (unsafe) {
                return unsafe
                    .replace(/&amp;/g, '&')
                    .replace(/&lt;/g, '<')
                    .replace(/&gt;/g, '>')
                    .replace(/&quot;/g, '"')
                    .replace(/&#039;/g, '\'');
            };

            $scope.setCashierDiscountLimit = function () {
                if (!$scope.isRootCompany) {
                    return;
                }

                var percentValidator = function (oldVal, newVal) {
                    if (parseInt(oldVal + '' + newVal) > 100) {
                        return false;
                    }
                    return true;
                };

                var resolveCallback = function (editedValue) {
                    $scope.merchant.attributes['other__cashier_discount_limit'] = parseFloat(editedValue);
                    $scope.visual.merchant.discount = $scope.merchant.attributes['other__cashier_discount_limit'] * 100;
                    $scope.merchant.attributes['other__activate_cashier_discount_limit'] = true;
                };
                var rejectCallback = function () {
                    // Checking with undefined because value can be true or false
                    if ($scope.merchant.attributes['other__cashier_discount_limit'] != undefined) {
                        $scope.merchant.attributes['other__activate_cashier_discount_limit'] = true;
                    } else {
                        $scope.merchant.attributes['other__activate_cashier_discount_limit'] = false;
                    }
                };

                return showPinPad('percentage', $scope.merchant.attributes['other__cashier_discount_limit'],
                    resolveCallback, rejectCallback, percentValidator);
            };


            var translate = function () {
                $translate(
                    'setupWizard.merchant.title',
                    {merchantName: $scope.merchant.name},
                    undefined, // interpolationId
                    undefined, // defaultTranslationText
                    undefined, // forceLanguage
                    'sanitize' // sanitizeStrategy
                ).then(function (title) {
                    $scope.setupTitle = $sce.trustAsHtml(unescapeHtml(title));
                });

                $translate(
                    'setupWizard.merchant.welcome.mainMessage',
                    {}, // interpolateParams
                    undefined, // interpolationId
                    undefined, // defaultTranslationText
                    undefined, // forceLanguage
                    'sanitize' // sanitizeStrategy
                ).then(function (mainMessage) {
                    $scope.welcomeMainMessage = $sce.trustAsHtml(unescapeHtml(mainMessage));
                });
                $translate(
                    'setupWizard.merchant.welcome.paragraphs',
                    {}, // interpolateParams
                    undefined, // interpolationId
                    undefined, // defaultTranslationText
                    undefined, // forceLanguage
                    'sanitize' // sanitizeStrategy)
                ).then(function (welcomeMessage) {
                    $scope.welcomeMessage = $sce.trustAsHtml(unescapeHtml(welcomeMessage));
                });
                $translate(
                    'setupWizard.merchant.review.paragraphs',
                    {}, // interpolateParams
                    undefined, // interpolationId
                    undefined, // defaultTranslationText
                    undefined, // forceLanguage
                    'sanitize' // sanitizeStrategy)
                ).then(function (reviewMessage) {
                    $scope.reviewMessage = $sce.trustAsHtml(unescapeHtml(reviewMessage));
                });
            };

            $scope.isComplete = {
                default: function () {
                    return true;
                },
                basicInfo: function () {
                    var address = $scope.merchant.address || {};
                    var isAddressComplete = !!address.street && !!address.city && !!address.province && !!address.postalCode;

                    var taxRates = $scope.merchant.taxRates;
                    var isTaxRateComplete = taxRates && taxRates.length;

                    return !!((isAddressComplete && isTaxRateComplete) || ($scope.company && $scope.company.setupComplete));
                },
                currencySetup: function () {
                    return !!(($scope.merchant.baseCurrencyId || ($scope.merchant.baseCurrency && $scope.merchant.baseCurrency.currencyId))
                        && ($scope.merchant.languageId || ($scope.merchant.language && $scope.merchant.language.languageId)));
                },
                ownerSetup: function () {
                    return $scope.usersPinNotDefault();
                },
                stationSetup: function () {
                    return !!($scope.merchant.stations && $scope.merchant.stations.length);
                },
                discountSetup: function () {
                    return true;
                },
                employeeSetup: function () {
                    return $scope.showEditor ? $scope.usersPinNotDefault() : !!($scope.merchant.users && $scope.merchant.users.length);
                },
                employeeTimecards: function () {
                    return true;
                },
                mobileSetup: function () {
                    return !!(($scope.company && $scope.company.companyLogoUrl) && ($scope.currentLocation && $scope.currentLocation.locationImageUrl));
                },
                shopify: function () {
                    return !!($scope.shopify_products.length);
                }
            };

            var allZeroRegEx = RegExp('^[0]*$');
            $scope.usersPinNotDefault = function () {
                if (!$scope.siteAdminUser || !$scope.siteAdminUser[0].pin || allZeroRegEx.test($scope.siteAdminUser[0].pin)) {
                    return false;
                }

                return true;
            };

            $scope.isStepCompleted = function (stepName) {
                var steps = $scope.wizardHandler.wizard().getEnabledSteps();
                var step = _.findWhere(steps, {title: stepName});
                return step.completed || (step.canexit && step.canexit());
            };

            $scope.searchAction = function (page) {
                $scope.setFilterCriteria(page);
            };

            $scope.clearSearch = function (page) {
                $scope.employees.searchBar.text = '';
                $scope.timecards.searchBar.text = '';
                $scope.setFilterCriteria(page);
            };

            $scope.populateFilterValue = function () {
                if ($scope.filters.lastSelectedUser) {
                    $scope.filters.selectedUserFilter = angular.copy($scope.filters.lastSelectedUser);
                }
                if ($scope.filters.lastSelectedLocation) {
                    $scope.filters.selectedLocationFilter = angular.copy($scope.filters.lastSelectedLocation);
                }
                if ($scope.filters.lastSelectedRole) {
                    $scope.filters.selectedRoleFilter = angular.copy($scope.filters.lastSelectedRole);
                }
            };

            $scope.setFilterCriteria = function (page, type, filterValue) {
                if (page === 'employee-setup') {
                    if (type == 'status') {
                        if (filterValue.value == '') {
                            delete $scope.employees.activeFilters.status;
                        } else {
                            $scope.employees.activeFilters.status = angular.copy(filterValue.value);
                            $scope.filters.lastSelectedUser = angular.copy(filterValue);
                        }
                    }

                    if (type == 'location') {
                        $scope.employees.activeFilters.location = angular.copy(filterValue.companyId);
                        $scope.filters.lastSelectedLocation = angular.copy(filterValue);
                    }

                    if (type == 'role') {
                        $scope.employees.activeFilters.role = angular.copy(filterValue);
                        $scope.filters.lastSelectedRole = angular.copy(filterValue);
                    }

                    if ($scope.employees.searchBar.text) {
                        $scope.employees.activeFilters.text = angular.copy($scope.employees.searchBar.text);
                    } else {
                        delete $scope.employees.activeFilters.text;
                    }

                    $scope.filterData(page);
                }

                if (page === 'employee-timecards') {
                    if (type == 'location') {
                        $scope.timecards.activeFilters.companyId = filterValue.companyId;
                        $scope.filters.lastSelectedLocation = angular.copy(filterValue);
                    }

                    if ($scope.timecards.searchBar.text) {
                        $scope.timecards.activeFilters.text = $scope.timecards.searchBar.text;
                    } else {
                        delete $scope.timecards.activeFilters.text;
                    }

                    $scope.loadTimecards($scope.currentCompanyId);

                }

                if (page === 'discount-setup') {
                    if (type == 'status') {
                        if (filterValue.value == '') {
                            delete $scope.discounts.activeFilters.status;
                        } else {
                            $scope.discounts.activeFilters.status = filterValue.value;
                        }
                    }

                    if ($scope.discounts.searchBar.text) {
                        $scope.discounts.activeFilters.text = $scope.discounts.searchBar.text;
                    } else {
                        delete $scope.discounts.activeFilters.text;
                    }

                    $scope.filterData(page);
                }
            };


            $scope.filterData = function (page) {
                if (page === 'employee-setup') {
                    var filteredResult = angular.copy($scope.merchant.users);
                    var dataToFilter = [];
                    if ($scope.employees.activeFilters.status) {
                        dataToFilter = filteredResult;
                        filteredResult = [];
                        for (var i = 0; i < dataToFilter.length; i++) {
                            if (dataToFilter[i].status == $scope.employees.activeFilters.status) {
                                filteredResult.push(dataToFilter[i]);
                            }
                        }
                    }

                    if ($scope.employees.activeFilters.location) {
                        dataToFilter = filteredResult;
                        filteredResult = [];
                        for (var x = 0; x < dataToFilter.length; x++) {
                            if ($scope.employees.activeFilters.location == 0) {
                                filteredResult.push(dataToFilter[x]);
                            } else {
                                if (dataToFilter[x].companyId == $scope.employees.activeFilters.location) {
                                    filteredResult.push(dataToFilter[x]);
                                }
                            }
                        }
                    }

                    if ($scope.employees.activeFilters.role && $scope.employees.activeFilters.role != {}) {
                        dataToFilter = filteredResult;
                        filteredResult = [];
                        for (var k = 0; k < dataToFilter.length; k++) {
                            if ($scope.employees.activeFilters.role.roleId == -1) {
                                filteredResult.push(dataToFilter[k]);
                            } else {
                                if ($scope.employees.activeFilters.role.roleId == 0 &&
                                    dataToFilter[k].roleId == $scope.employees.activeFilters.role.roleId) {
                                    filteredResult.push(dataToFilter[k]);
                                } else if (dataToFilter[k].roleName == $scope.employees.activeFilters.role.roleName) {
                                    filteredResult.push(dataToFilter[k]);
                                }
                            }
                        }
                    }

                    if ($scope.employees.activeFilters.text) {
                        dataToFilter = filteredResult;
                        filteredResult = [];
                        for (var y = 0; y < dataToFilter.length; y++) {
                            var searchTextLowerCase = $scope.employees.activeFilters.text.toLowerCase();
                            var firstName = dataToFilter[y].firstname.toLowerCase();
                            var lastName = dataToFilter[y].lastname.toLowerCase();
                            if (firstName.indexOf(searchTextLowerCase) !== -1 || lastName.indexOf(searchTextLowerCase) !== -1) {
                                filteredResult.push(dataToFilter[y]);
                            }
                        }
                    }

                    $scope.merchant.usersFiltered = filteredResult;

                    var length = $scope.merchant.usersFiltered.length;
                    var hasOneMorePage = false;

                    if ((length % 10 !== 0 && length > 10) || length < 10) {
                        hasOneMorePage = true;
                    }
                    length = length - (length % 10);
                    $scope.employees.totalPages = length / 10;
                    $scope.employees.totalPages = (hasOneMorePage) ? $scope.employees.totalPages + 1 : $scope.employees.totalPages;

                    var usersStartIndex = 0;
                    var usersEndIndex = 0;


                    if ($scope.employees.currentActivePage > $scope.employees.totalPages) {
                        $scope.employees.currentActivePage = 0;
                    }
                    usersStartIndex = $scope.employees.currentActivePage * $scope.employees.recordsPerPage;
                    usersEndIndex = (usersStartIndex + $scope.employees.recordsPerPage);

                    $scope.merchant.usersFiltered = $scope.merchant.usersFiltered.slice(usersStartIndex, usersEndIndex);
                }

                /* eslint-disable */
                if (page === 'employee-timecards') {
                    /** This code block calculates the current active page and the local extremes (pagination limits).
                    **  If the current Active page is less than or more than the limit, fetch data from the backend
                    **  else splice the already present data and show the desired page
                    **/
                    var currentActivePage = $scope.timecards.currentActivePage + 1;
                    var forwardTotal = (($scope.timecards.index * $scope.timecards.pagesPerBatch)
                        + $scope.timecards.pagesPerBatch);
                    var minusTotal = ((($scope.timecards.index - 1) * $scope.timecards.pagesPerBatch)
                        + $scope.timecards.pagesPerBatch) + 1;
                    if (currentActivePage > forwardTotal || currentActivePage < minusTotal) {
                        $scope.loadTimecards($scope.currentCompanyId);
                    } else {
                        usersStartIndex = ($scope.timecards.currentActivePage % 5) * $scope.timecards.recordsPerPage;
                        usersEndIndex = (usersStartIndex + $scope.timecards.recordsPerPage);

                        if ($scope.timecards.currentActivePage > $scope.timecards.totalPages) {
                            $scope.timecards.currentActivePage = 0;
                        }

                        $scope.merchant.timecardsFiltered.length = 0;
                        $scope.merchant.timecardsFiltered.push(...$scope.merchant.timecards.slice(usersStartIndex, usersEndIndex));
                    }
                }

                if (page === 'employee-timecards__drivers') {
                    $scope.merchant.driverTimecardsFiltered = [];
                    var currentActivePage = $scope.driverTimecards.currentActivePage + 1;
                    var forwardTotal = (($scope.driverTimecards.index * $scope.driverTimecards.pagesPerBatch)
                        + $scope.driverTimecards.pagesPerBatch);
                    var minusTotal = ((($scope.driverTimecards.index - 1) * $scope.driverTimecards.pagesPerBatch)
                        + $scope.driverTimecards.pagesPerBatch) + 1;
                    if (currentActivePage > forwardTotal || currentActivePage < minusTotal) {
                        $scope.loadTimecards($scope.currentCompanyId);
                    } else {
                        usersStartIndex = ($scope.driverTimecards.currentActivePage % 5) * $scope.driverTimecards.recordsPerPage;
                        usersEndIndex = (usersStartIndex + $scope.driverTimecards.recordsPerPage);

                        if ($scope.driverTimecards.currentActivePage > $scope.driverTimecards.totalPages) {
                            $scope.driverTimecards.currentActivePage = 0;
                        }

                        $scope.merchant.driverTimecardsFiltered.length = 0;
                        $scope.merchant.driverTimecardsFiltered.push(...$scope.driverTimecards.timecards.slice(usersStartIndex, usersEndIndex));
                    }
                }

                if (page === 'discount-setup') {
                    var filteredResult = angular.copy($scope.merchant.discounts);
                    var dataToFilter = [];
                    if ($scope.discounts.activeFilters.status) {
                        dataToFilter = filteredResult;
                        filteredResult = [];
                        for (var i = 0; i < dataToFilter.length; i++) {
                            if (dataToFilter[i].status == $scope.discounts.activeFilters.status) {
                                filteredResult.push(dataToFilter[i]);
                            }
                        }
                    }

                    if ($scope.discounts.activeFilters.text) {
                        dataToFilter = filteredResult;
                        filteredResult = [];
                        for (var x = 0; x < dataToFilter.length; x++) {
                            var searchTextLowerCase = $scope.discounts.activeFilters.text.toLowerCase();
                            var discountName = dataToFilter[x].discountName.toLowerCase();
                            if (discountName.indexOf(searchTextLowerCase) !== -1) {
                                filteredResult.push(dataToFilter[x]);
                            }
                        }
                    }

                    $scope.merchant.discountsFiltered = filteredResult;

                    var length = $scope.merchant.discountsFiltered.length;
                    var hasOneMorePage = false;

                    if ((length % 10 !== 0 && length > 10) || length < 10) {
                        hasOneMorePage = true;
                    }
                    length = length - (length % 10);
                    $scope.discounts.totalPages = length / 10;
                    $scope.discounts.totalPages = (hasOneMorePage) ? $scope.discounts.totalPages + 1 : $scope.discounts.totalPages;

                    var usersStartIndex = 0;
                    var usersEndIndex = 0;


                    if ($scope.discounts.currentActivePage > $scope.discounts.totalPages) {
                        $scope.discounts.currentActivePage = 0;
                    }
                    usersStartIndex = $scope.discounts.currentActivePage * $scope.discounts.recordsPerPage;
                    usersEndIndex = (usersStartIndex + $scope.discounts.recordsPerPage);

                    $scope.merchant.discountsFiltered = $scope.merchant.discountsFiltered.slice(usersStartIndex, usersEndIndex);
                }
                /* eslint-enable */
            };


            $scope.changePage = function (changeType, page) {
                // var elementToChange = undefined;
                if (page == 'employee-setup') {
                    if (changeType == 'PREV') {
                        if ($scope.employees.currentActivePage == 0) {
                            return;
                        }
                        $scope.employees.currentActivePage -= 1;
                    } else if (changeType == 'NEXT') {
                        if ($scope.employees.currentActivePage + 1 == $scope.employees.totalPages) {
                            return;
                        }
                        $scope.employees.currentActivePage += 1;
                    }
                    $scope.filterData('employee-setup');
                } else if (page == 'employee-timecards') {
                    if (changeType == 'PREV') {
                        if ($scope.timecards.currentActivePage == 0) {
                            return;
                        }
                        $scope.timecards.currentActivePage -= 1;
                    } else if (changeType == 'NEXT') {
                        if ($scope.timecards.currentActivePage + 1 == $scope.timecards.totalPages) {
                            return;
                        }
                        $scope.timecards.currentActivePage += 1;
                    }
                    $scope.filterData('employee-timecards');
                } else if (page == 'employee-timecards__drivers') {
                    if (changeType == 'PREV') {
                        if ($scope.driverTimecards.currentActivePage == 0) {
                            return;
                        }
                        $scope.driverTimecards.currentActivePage -= 1;
                    } else if (changeType == 'NEXT') {
                        if ($scope.driverTimecards.currentActivePage + 1 == $scope.driverTimecards.totalPages) {
                            return;
                        }
                        $scope.driverTimecards.currentActivePage += 1;
                    }
                    $scope.filterData('employee-timecards__drivers');
                } else if (page == 'discount-setup') {
                    if (changeType == 'PREV') {
                        if ($scope.discounts.currentActivePage == 0) {
                            return;
                        }
                        $scope.discounts.currentActivePage -= 1;
                    } else if (changeType == 'NEXT') {
                        if ($scope.discounts.currentActivePage + 1 == $scope.discounts.totalPages) {
                            return;
                        }
                        $scope.discounts.currentActivePage += 1;
                    }
                    $scope.filterData('discount-setup');
                } else if (page == 'past-fiit-event') {
                    if (changeType == 'PREV') {
                        if ($scope.pastFiitEvents.currentActivePage == 0) {
                            return;
                        }
                        $scope.pastFiitEvents.currentActivePage -= 1;
                    } else if (changeType == 'NEXT') {
                        if ($scope.pastFiitEvents.currentActivePage + 1 == $scope.pastFiitEvents.totalPages) {
                            return;
                        }
                        $scope.pastFiitEvents.currentActivePage += 1;
                    }

                    loadPastFiitEvents($scope.pastFiitEvents.currentActivePage * $scope.pastFiitEvents.recordsPerPage);
                }
            };

            $scope.exitMerchantSetting = async function () {
                try {
                    await bannerUploader.uploadAll();
                    await logoUploader.uploadAll();
                    await receiptLogoUploader.uploadAll();
                    await loyaltyCardUploader.uploadAll();
                } catch (err) {
                    Honeybadger.notify(err);
                }
            };
            $scope.completeSetup = function () {
                Company.completeSetup({}, {companyId: $scope.currentCompanyId}, function () {
                    $scope.loadCompany().$promise.then(function (company) {
                        // Update currentUser in Security to reflect changed attributes
                        $scope.$broadcast('setup::companyLoaded');
                        $rootScope.$broadcast('setup::companyLoaded');
                    });
                });
            };

            var sortLocations = function (a, b) {
                if (a.companyId == 0) {
                    return -1;
                } else if (b.companyId == 0) {
                    return 1;
                } else if ($scope.companyHierarchy.id == a.companyId) {
                    return -1;
                } else if ($scope.companyHierarchy.id == b.companyId) {
                    return 1;
                } else {
                    return a.companyId - b.companyId;
                }
            };

            $scope.loadCompanyHierarchy = function (companyId) {
                return Lookup.locationsByCompany({}, function (response) {
                    $scope.companyHierarchy = _.findWhere(response, {id: companyId});
                    $scope.allLocationsByCompany = [];
                    var allLocations = {
                        companyId: 0, // Do not change this id
                        name: 'All Locations'
                    };

                    $scope.allLocationsByCompany.push(allLocations);
                    var childrenLength = $scope.companyHierarchy.children.length || 0;
                    _.each($scope.companyHierarchy.children, function (location) {
                        if ($scope.companyHierarchy.id == location.companyId
                            && childrenLength > 1) {
                            location.name += ' (HQ)';
                        }
                        $scope.allLocationsByCompany.push(location);
                    });
                    $scope.allLocationsByCompany.sort(sortLocations);
                }).$promise;
            };

            var showPinPad = function (type, model, resolveCallback, rejectCallback, validator, note) {
                var pinPadInstance = $modal.open({
                    templateUrl: 'pos/pos.numpad.tpl.html',
                    controller: 'PosNumpadCtrl',
                    animation: false,
                    windowClass: 'modal-numpad modal-fullscreen-transparent modal-right',
                    resolve: {
                        initial: function () {
                            return model;
                        },
                        type: function () {
                            return type;
                        },
                        note: function () {
                            return note;
                        }
                    },
                    backdrop: true
                });
                pinPadInstance.lucovaValidator = validator;
                return pinPadInstance.result.then(function (value) {
                    if (resolveCallback) {
                        resolveCallback(value);
                    }
                }, function () {
                    if (rejectCallback) {
                        rejectCallback();
                    }
                });
            };

            $scope.shopifyStores = undefined;
            $scope.connectShopifyModel = {
                name: '',
            };

            $scope.connectNetsuiteModel = {
                connectionName: '',
                accountId: '',
                consumerSecret: '',
                consumerToken: '',
                userAccessToken: '',
                userAccessSecret: '',
                restletEndpoint: ''
            };

            $scope.isConnectingNetsuite = false;

            $scope.toUpdateNetsuiteIntegration = false;
            $scope.addNetsuiteConnection = function () {
                var keysToCheck = ['connectionName', 'accountId', 'consumerToken',
                    'consumerSecret', 'userAccessToken', 'userAccessSecret', 'restletEndpoint'];
                var objectKeys = Object.keys($scope.connectNetsuiteModel);
                for (var cntr = 0; cntr < objectKeys.length; cntr++) {
                    var key = objectKeys[cntr];
                    if (keysToCheck.includes(key) && !$scope.connectNetsuiteModel[key]) {
                        PosAlertService.showAlertByName('contact-nown', {
                            title: 'setupWizard.merchant.integrationSetup.netsuite.invalid.error.ttl',
                            message: $translate.instant('setupWizard.merchant.integrationSetup.netsuite.invalid.error.msg',
                                {field: $translate.instant('setupWizard.merchant.integrationSetup.netsuite.' + key)})
                        });
                        return;
                    }
                }

                $scope.isConnectingNetsuite = true;
                var timerToCancel = $timeout(function () {
                    $scope.isConnectingNetsuite = false;
                }, 10000);

                var netSuiteConnectionObj = Object.assign({}, $scope.connectNetsuiteModel);
                netSuiteConnectionObj.companyId = CurrentSession.getCompany().companyId;
                Netsuite.upsertNetsuiteConnection(netSuiteConnectionObj, function (successResponse) {
                    $timeout.cancel(timerToCancel);
                    $scope.isConnectingNetsuite = false;
                    PosAlertService.showAlertByName('contact-nown', {
                        title: 'setupWizard.merchant.integrationSetup.netsuite.valid.ttl',
                        message: 'setupWizard.merchant.integrationSetup.netsuite.valid.msg'
                    });
                    $scope.toUpdateNetsuiteIntegration = false;
                    $scope.loadNetSuiteIntegrations($scope.currentCompanyId);

                }, function (error) {
                    $timeout.cancel(timerToCancel);
                    $scope.isConnectingNetsuite = false;
                    PosAlertService.showAlertByName('contact-nown', {
                        title: 'setupWizard.merchant.integrationSetup.netsuite.invalid.error.ttl',
                        message: 'Invalid Credentials'
                    });
                });

            };


            $scope.editNetsuiteConnection = function (netSuiteConnectionObj) {
                $scope.toUpdateNetsuiteIntegration = true;
                $scope.connectNetsuiteModel = Object.assign({}, netSuiteConnectionObj);
                delete $scope.connectNetsuiteModel.consumerToken;
                delete $scope.connectNetsuiteModel.consumerSecret;
                delete $scope.connectNetsuiteModel.userAccessToken;
                delete $scope.connectNetsuiteModel.userAccessSecret;
            };

            $scope.cancelNetSuiteConnectionUpdate = function () {
                $scope.toUpdateNetsuiteIntegration = false;
                $scope.connectNetsuiteModel = undefined;
            };

            $scope.editShopifyStore = function (store) {
                var modalInstance = $modal.open({
                    templateUrl: 'setup/modals/wizardEditShopifyStore.tpl.html',
                    controller: 'EditShopifyStoreCtrl',
                    windowClass: 'setup-wizard modal__setup-wizard modal__edit-station',
                    resolve: {
                        shopifyStore: function () {
                            return store;
                        },
                        reAuthenticateCallback: function () {
                            return $scope.connectShopifyStore;
                        }
                    },
                    backdrop: 'static',
                });
                modalInstance.result.then(function (result) {
                });
            };

            let connectingToShopify = false;

            $scope.connectShopifyStore = function (selectedStore) {
                return new Promise((resolve, reject) => {
                    if ((!selectedStore && !$scope.connectShopifyModel.name.length) || connectingToShopify) {
                        return reject();
                    }

                    let request = {};
                    if (selectedStore) {
                        request.domain = selectedStore.myShopifyDomain;
                    }
                    if ($scope.connectShopifyModel.name) {
                        request.storeName = $scope.connectShopifyModel.name;
                    }

                    connectingToShopify = true;
                    Shopify.getInstallationUrl(request).$promise.then((response) => {
                        connectingToShopify = false;
                        var installationURL = response.installationURL;

                        if (installationURL) {
                            var isIosWebkit = Platform.isIosWebkit();
                            if (!isIosWebkit) {
                                $window.open(installationURL, '_blank');
                            }
                        }
                        resolve();
                    }).catch((error) => {
                        connectingToShopify = false;
                        return reject(error);
                    });
                });
            };

            $scope.checkSevenshiftsConnectivity = function () {
                if ($scope.merchant.attributes['sevenshifts_api_key']) { // no point in checking if api key is blank
                    Company.checkSevenshiftsConnectivity({}).$promise.then(function (response) {
                        $scope.sevenshiftsConnected = (response.status == 'success');
                    });
                }
            };

            $scope.checkADPConnectivity = function () {
                if ($scope.merchant.attributes['adp_username'] && $scope.merchant.attributes['adp_password']) {
                    Company.checkADPConnectivity({}).$promise.then(function (response) {
                        $scope.adpConnected = (response.success == true);
                    });
                }
            };

            $scope.saveSevenshiftsAPIKey = function () {
                updateCompanyAttributes(['sevenshifts_api_key']);
            };

            let isClicked7Import = false;
            $scope.importSevenshifts = function () {
                if (isClicked7Import) {
                    return;
                }
                isClicked7Import = true;
                Company.sevenshiftsImport({}).$promise.then(function (response) {
                    PosAlertService.showAlertByName('contact-nown', {
                        title: 'setupWizard.merchant.integrationSetup.sevenshifts.import',
                        message: response.newUserCount + ' users imported. '
                            + response.updatedUserCount + ' existing users updated.'
                    });
                    $scope.loadUsers($scope.currentCompanyId);
                }).catch((err) => {
                    PosAlertService.showAlertByName('contact-nown', {
                        title: 'setupWizard.merchant.integrationSetup.sevenshifts.import',
                        message: err.error || 'Unable to import from 7shifts'
                    });
                }).then(() => isClicked7Import = false);
            };

            $scope.importADP = function () {
                Company.adpImport({}).$promise.then(function (response) {
                    PosAlertService.showAlertByName('contact-nown', {
                        title: 'setupWizard.merchant.integrationSetup.adp.import',
                        message: response.userCount + ' users imported.'
                    });
                    $scope.loadUsers($scope.currentCompanyId);
                });
            };

            let isClicked7Export = false;
            $scope.exportSevenshifts = function () {
                if (isClicked7Export) {
                    return;
                }
                isClicked7Export = true;
                Company.sevenshiftsExport({}).$promise.then(function (response) {
                    PosAlertService.showAlertByName('contact-nown', {
                        title: 'setupWizard.merchant.integrationSetup.sevenshifts.export',
                        message: response.userCount + ' users exported.'
                    });
                    $scope.loadUsers($scope.currentCompanyId);
                })
                    .catch((e) => $log.error('Failed to export 7shifts employee data.', e))
                    .then(() => isClicked7Export = false);
            };

            $scope.saveADPCredentials = function () {
                updateCompanyAttributes(['adp_username']);
            };

            $scope.loadRoles = function () {
                return Settings.getRoles({companyId: $scope.currentCompanyId}, function (response) {
                    $scope.companyRoleTypes = [];
                    var allRoles = {
                        roleId: -1, // // Do not change this id
                        roleName: 'All Roles'
                    };
                    var ownerRole = {
                        roleId: 0,
                        roleName: 'Owner'
                    };
                    $scope.companyRoleTypes.push(allRoles, ownerRole);
                    _.each(response, function (role) {
                        $scope.companyRoleTypes.push(role);
                    });
                    $scope.companyRoleTypes.sort(sortRoles);
                });
            };

            $scope.loadOrganization = function (companyId) {
                $scope.organization = {
                    currencyCodes: [],
                    settings: null
                };

                $scope.loadOrganizationSettings();
                $scope.loadCurrencyCodesMap(companyId);
                $scope.loadLanguages();
            };

            $scope.loadOrganizationSettings = function () {
                $scope.isLoading.organizationSettings = true;
                var organizationId = CurrentSession.getCompany().organizationId;
                Organization.getOrganizationSettings({organizationId: organizationId}, function (response) {
                    $scope.organization.settings = response.toJSON();
                    $scope.isLoading.organizationSettings = false;
                });
            };

            $scope.loadLanguages = function () {
                $scope.isLoading.availableLanguages = true;

                $scope.allLanguages = [];
                Object.keys(LOCALES).forEach((key) => {
                    $scope.allLanguages.push({
                        locale: key,
                        name: LOCALES[key]
                    });
                });

                var organizationId = CurrentSession.getCompany().organizationId;
                return Organization.getAllLanguagesForOrganization({organizationId: organizationId}, function (response) {
                    $scope.merchant.availableLanguages = response;
                    const localesInUse = response.map((lang) => lang.locale);
                    for (const lang of $scope.allLanguages) {
                        if (localesInUse.includes(lang.locale)) {
                            lang.inUse = true;
                        } else {
                            lang.inUse = false;
                        }
                    }
                    $scope.isLoading.availableLanguages = false;
                    $scope.loadLanguageForCurrentLocation();
                }, function (error) {
                    $scope.isLoading.availableLanguages = false;
                }).$promise;
            };

            $scope.loadLanguageForCurrentLocation = function () {
                var merchantLanguage = _.find($scope.merchant.availableLanguages, function (language) {
                    return language.languageId == $scope.merchant.languageId;
                });

                $scope.merchant.language = merchantLanguage;
            };

            $scope.loadCurrencyCodesMap = function (companyId) {
                $scope.isLoading.availableCurrency = true;

                var currencyCodesArr = currencyCodeLib.codes();
                _.each(currencyCodesArr, function (currencyCode) {
                    $scope.organization.currencyCodes.push(currencyCodeLib.code(currencyCode));
                });

                $scope.loadCurrencies().then(function () {
                    $scope.loadBaseCurrency();
                });
            };

            $scope.loadBaseCurrency = function () {
                var baseCur = _.find($scope.merchant.availableCurrency, function (curr) {
                    return curr.currencyId == $scope.merchant.baseCurrencyId;
                });

                $scope.merchant.baseCurrency = baseCur;
            };

            $scope.loadCurrencies = function () {
                var organizationId = CurrentSession.getCompany().organizationId;
                return Organization.getAllCurrenciesForOrganization({organizationId: organizationId}, function (response) {
                    $scope.merchant.availableCurrency = response;
                    const currenciesInUse = response.map((currency) => currency.currencyCode);
                    for (const currency of $scope.organization.currencyCodes) {
                        if (currenciesInUse.includes(currency.code)) {
                            currency.inUse = true;
                        } else {
                            currency.inUse = false;
                        }
                    }
                    $scope.isLoading.availableCurrency = false;
                }, function (error) {
                    $scope.isLoading.availableCurrency = false;
                }).$promise;
            };

            $scope.addCurrency = function () {
                if ($scope.merchant.selectedCurrency) {
                    var currencyObj = {
                        currencyName: $scope.merchant.selectedCurrency.currency,
                        currencyCode: $scope.merchant.selectedCurrency.code,
                        organizationId: CurrentSession.getCompany().organizationId
                    };
                    Organization.addCurrencyToOrganization(currencyObj, function () {
                        $scope.loadCurrencyCodesMap();
                        settingsUpdated();
                    }, function () {
                        settingsUpdated(false);
                    });
                }
            };

            $scope.addLanguage = function () {
                if ($scope.merchant.selectedLanguage) {
                    var languageObj = {
                        locale: $scope.merchant.selectedLanguage.locale,
                        name: $scope.merchant.selectedLanguage.name,
                        organizationId: CurrentSession.getCompany().organizationId
                    };
                    Organization.addLanguageToOrganization(languageObj, function () {
                        $scope.loadLanguages();
                        settingsUpdated();
                    }, function () {
                        settingsUpdated(false);
                    });
                }
            };

            $scope.loadAllRequiredData = function () {
                $scope.loadCompanyHierarchy($scope.currentCompanyId).then(function () {
                    $scope.loadServicePeriods($scope.currentCompanyId);
                    $scope.loadTaxRates($scope.currentCompanyId);
                    $scope.loadStations($scope.currentLocationId);
                    $scope.loadPrinters($scope.currentLocationId);
                    $scope.loadSrmDevices($scope.currentCompanyId);
                    $scope.loadUsers($scope.currentCompanyId);
                    $scope.loadTimecards($scope.currentCompanyId);
                    $scope.loadDiscounts($scope.currentCompanyId);
                    $scope.loadNetSuiteIntegrations($scope.currentCompanyId);
                    $scope.loadOrganization($scope.currentCompanyId);
                    $scope.loadCurrencyCodesMap($scope.currentCompanyId);
                    loadPatronGroups();
                    loadFiitEvents();
                    loadPastFiitEvents();
                    getHomebaseConnection();
                    getDeliverectCompanyIntegrations();
                    getFlytCompanyIntegrations();
                    if ($scope.isCampus) {
                        $scope.fiitServicePeriods = GatewayFiit.getServicePeriods() || [];
                        $scope.loadSavedMealCards();
                    }
                });
            };

            $scope.isHomebaseConnected = false;

            $scope.connectHomebaseModel = {
                apiKey: ''
            };

            $scope.connectToHomebase = function () {
                var homebaseConnectionData = {
                    apiKey: $scope.connectHomebaseModel.apiKey
                };
                Homebase.connectToHomebase(homebaseConnectionData, function (response) {
                    settingsUpdated();
                    getHomebaseConnection();
                }, function (error) {
                    $log.error('Failed to create Homebase connection.', error);
                });
            };

            $scope.disconnectFromHomebase = function () {
                Homebase.disconnectFromHomebase(function (response) {
                    settingsUpdated();
                    getHomebaseConnection();
                }, function (error) {
                    $log.error('Failed to delete Homebase connection.', error);
                });
            };

            var getHomebaseConnection = function () {
                Homebase.getHomebaseConnection(function (response) {
                    if (response.connected) {
                        $scope.isHomebaseConnected = true;
                        getHomebaseCompanyIntegrations();
                        CurrentSession.setIsHomebase(true);
                    } else {
                        $scope.isHomebaseConnected = false;
                        CurrentSession.setIsHomebase(false);
                    }
                }, function (error) {
                    $log.error('Failed to fetch Homebase connection.', error);
                });
            };

            $scope.homebaseLocations = [];

            $scope.addNewHomebaseCompanyIntegration = function () {
                $scope.homebaseLocations.push({
                    selectedLocation: {id: -1},
                    homebaseLocationUuid: ''
                });
            };

            $scope.saveHomebaseCompanyIntegrations = function () {
                var companyIntegrations = [];
                for (var l of $scope.homebaseLocations) {
                    if (l.selectedLocation.id > 0 && l.homebaseLocationUuid) {
                        companyIntegrations.push({
                            id: l.integrationId,
                            companyId: l.selectedLocation.companyId,
                            homebaseLocationUuid: l.homebaseLocationUuid
                        });
                    }
                }
                if (!companyIntegrations.length) {
                    return;
                }
                Homebase.integrateCompanies(companyIntegrations, function (response) {
                    settingsUpdated();
                    getHomebaseCompanyIntegrations();
                }, function (error) {
                    $log.error('Error integrating Homebase locations with Nown locations.', error);
                });
            };

            $scope.deleteHomebaseCompanyIntegration = function (integrationId, index) {
                if (!integrationId) {
                    /* Entry is new and not saved to the database. No need for a network request. Just delete
                    from the UI. */
                    $scope.homebaseLocations.splice(index, 1);
                    return;
                }
                Homebase.deleteCompanyIntegration({
                    homebaseCompanyIntegrationId: integrationId
                }, function (response) {
                    settingsUpdated();
                    $scope.homebaseLocations.splice(index, 1);
                }, function (error) {
                    $log.error('Error deleting Homebase location integration.', error);
                });
            };

            var processExistingCompanyIntegrations = function (companyIntegrations) {
                for (var companyIntegration of companyIntegrations) {
                    var foundLocation = $scope.allLocationsByCompany.find((l) => {
                        return l.companyId == companyIntegration.companyId;
                    });
                    $scope.homebaseLocations.push({
                        integrationId: companyIntegration.id,
                        selectedLocation: foundLocation,
                        homebaseLocationUuid: companyIntegration.homebaseLocationUuid
                    });
                }
            };

            var getHomebaseCompanyIntegrations = function () {
                Homebase.getHomebaseCompanyIntegrations(function (response) {
                    $scope.homebaseLocations.length = 0;
                    processExistingCompanyIntegrations(response);
                    if (!$scope.homebaseLocations.length) {
                        $scope.addNewHomebaseCompanyIntegration();
                    }
                }, function (error) {
                    $log.error('Error getting Homebase company integrations.', error);
                });
            };

            $scope.importHomebaseUsers = function () {
                $scope.isLoading.homebaseImport = true;
                Homebase.importHomebaseEmployees(function (response) {
                    $scope.isLoading.homebaseImport = false;
                    PosAlertService.showAlertByName('general', {
                        title: 'setupWizard.merchant.integrationSetup.homebase.import.title',
                        message: 'setupWizard.merchant.integrationSetup.homebase.import.pending.disclaimer'
                    });
                }, function (error) {
                    $scope.isLoading.homebaseImport = false;
                    $log.error('Error requesting user import from Homebase.', error);
                });
            };

            const updateThirdPartyIntegrationSetup = (companyAttributesToUpdate) => {
                batchUpdateCompanyAttributes(companyAttributesToUpdate).then(function () {
                    if ($scope.hasMenuV2Enabled()) {
                        const companyLocationMap = {};
                        $scope.allCompanies.forEach((companyObj) => {
                            if (companyAttributesToUpdate[companyObj.companyId] && companyObj.locationId) {
                                companyLocationMap[companyObj.companyId] = companyObj.locationId;
                            }
                        });

                        Object.values(companyLocationMap).forEach((locationId) => {
                            MenuService.updateMobileMenu(locationId);
                        });
                    }
                });
            };

            // DELIVERECT INTEGRATION
            $scope.deliverectInformation = {
                // added a 'none' option to allow merchants to disconnect from deliverect if needed.
                allLocations: [{
                    _id: -1,
                    name: $translate.instant('general.none')
                }],
                activeMenusForCompanies: {}
            };


            const getDeliverectCompanyIntegrations = async () => {
                $scope.deliverectLocations = [];
                const accountId = $scope.company.attributes['deliverect_account_id'] || '';
                if (!accountId) {
                    $scope.isDeliverectAccount = false;
                    return;
                }

                $scope.isDeliverectAccount = true;
                const accountLocationResponse = await (Deliverect.getDeliverectAccountLocations({'accountId': accountId}).$promise)
                .catch((error) => {
                    return null;
                });

                if (accountLocationResponse && accountLocationResponse._items) {
                    $scope.deliverectInformation.allLocations.push(...accountLocationResponse._items);
                }

                $scope.deliverectInformation.activeMenusForCompanies = await (Menu.activeMenusAndCompaniesMap({}).$promise)
                .catch((error) => {
                    return {};
                });

                // setting deliverectCompanies to display.
                $scope.allCompanies.forEach((company) => {
                    if (!company.attributes) {
                        return;
                    }

                    const attributes = company.attributes;
                    const locationKey = 'deliverect_location_id';
                    const menuKey = 'deliverect_menu_id';

                    // ensure the company has the property & attribute value is not '-1' or null
                    // (when someone selects 'none' from dropdown)
                    if (attributes.hasOwnProperty(locationKey) && attributes[locationKey] && attributes[locationKey] != -1) {

                        // instead of storing the company obj, we store the location obj.
                        // this is because the ui-select-choices is using allLocationsByCompany.
                        const location = $scope.allLocationsByCompany.find((location) => {
                            return location.companyId === company.companyId;
                        });

                        // get the assigned deliverect location
                        const deliverectLocation = $scope.deliverectInformation.allLocations.find((location) => {
                            return location._id == attributes[locationKey];
                        });

                        let deliverectMenu = null;

                        // get the assigned deliverect menu (Menu V2)
                        if ($scope.hasMenuV2Enabled() &&
                        attributes.hasOwnProperty(menuKey) && attributes[menuKey] && attributes[menuKey] != -1) {
                            const companyMenus = $scope.deliverectInformation.activeMenusForCompanies[company.companyId] || [];
                            deliverectMenu = companyMenus.find((menu) => {
                                return menu.menuId == attributes[menuKey];
                            });
                        }

                        // if one of these don't exist, do not push.
                        if (!location || !deliverectLocation) {
                            return;
                        }

                        $scope.deliverectLocations.push({
                            selectedLocation: location,
                            selectedDeliverectLocation: deliverectLocation,
                            selectedDeliverectMenu: deliverectMenu
                        });
                    }
                });
            };

            $scope.addNewDeliverectIntegration = () => {
                $scope.deliverectLocations.push({
                    selectedLocation: '',
                    selectedDeliverectLocation: '',
                    selectedDeliverectMenu: ''
                });
            };

            $scope.saveDeliverectIntegrations = () => {
                const companiesToUpdate = {};

                $scope.deliverectLocations.forEach((deliverectLocation) => {
                    // for whatever reason, if it doesn't contain either objects, skip this iteration.
                    if (!deliverectLocation.selectedLocation || !deliverectLocation.selectedDeliverectLocation || ($scope.hasMenuV2Enabled() && !deliverectLocation.selectedDeliverectMenu)) {
                        return;
                    }

                    const id = deliverectLocation.selectedLocation.companyId;
                    const deliverectLocationId = deliverectLocation.selectedDeliverectLocation._id;
                    const deliverectMenuId = deliverectLocation.selectedDeliverectMenu ? deliverectLocation.selectedDeliverectMenu.menuId : null;

                    if ($scope.hasMenuV2Enabled()) {
                        companiesToUpdate[id] = {
                            'deliverect_location_id': deliverectLocationId,
                            'deliverect_menu_id': deliverectMenuId
                        };
                    } else {
                        companiesToUpdate[id] = {
                            'deliverect_location_id': deliverectLocationId
                        };
                    }
                });

                updateThirdPartyIntegrationSetup(companiesToUpdate);
            };

            $scope.deleteDeliverectLocationIntegration = (deliverectLocation, index) => {
                const id = deliverectLocation.selectedLocation.companyId;

                // set deliverect location id to none.
                let companyToUpdate = {};
                $scope.deliverectLocations.splice(index, 1);
                if ($scope.hasMenuV2Enabled()) {
                    companyToUpdate = {
                        [id]: {
                            'deliverect_location_id': -1,
                            'deliverect_menu_id': -1
                        }
                    };
                } else {
                    companyToUpdate = {
                        [id]: {
                            'deliverect_location_id': -1
                        }
                    };
                }

                updateThirdPartyIntegrationSetup(companyToUpdate);
            };

            $scope.clearSelectedDeliverectMenu = () => {
                if ($scope.deliverectLocation && $scope.deliverectLocation.selectedLocation && $scope.deliverectLocation.selectedLocation.companyId > 0) {
                    $scope.deliverectLocation.selectedDeliverectMenu = {};
                }
            };

            // FLYT INTEGRATION
            $scope.flytInformation = {
                // added a 'none' option to allow merchants to disconnect from deliverect if needed.
                allLocations: [{
                    _id: -1,
                    name: $translate.instant('general.none')
                }],
                activeMenusForCompanies: {}
            };

            $scope.newFlytConnectionData = {
                companyId: '',
                apiKey: ''
            };

            const getFlytCompanyIntegrations = async () => {
                $scope.flytLocations = [];
                const flytApiKey = $scope.company.attributes['flyt_api_key'] || '';
                const flytCompanyId = $scope.company.attributes['flyt_company_id'] || '';
                if (!flytApiKey || !flytCompanyId) {
                    $scope.isFlytAccount = false;
                    return;
                }

                $scope.isFlytAccount = true;

                $scope.flytInformation.activeMenusForCompanies = await (Menu.activeMenusAndCompaniesMap({}).$promise)
                .catch((error) => {
                    return {};
                });

                // setting deliverectCompanies to display.
                $scope.allCompanies.forEach((company) => {
                    if (!company.attributes) {
                        return;
                    }

                    const attributes = company.attributes;
                    const locationKey = 'flyt_location_id';
                    const menuKey = 'flyt_menu_id';

                    // ensure the company has the property & attribute value is not '-1' or null
                    // (when someone selects 'none' from dropdown)
                    if (attributes.hasOwnProperty(locationKey) && attributes[locationKey] && attributes[locationKey] != -1) {

                        // instead of storing the company obj, we store the location obj.
                        // this is because the ui-select-choices is using allLocationsByCompany.
                        const location = $scope.allLocationsByCompany.find((location) => {
                            return location.companyId === company.companyId;
                        });

                        // get the assigned deliverect location
                        const flytLocation = attributes[locationKey];

                        let flytMenu = null;

                        // get the assigned deliverect menu (Menu V2)
                        if ($scope.hasMenuV2Enabled() &&
                        attributes.hasOwnProperty(menuKey) && attributes[menuKey] && attributes[menuKey] != -1) {
                            const companyMenus = $scope.flytInformation.activeMenusForCompanies[company.companyId] || [];
                            flytMenu = companyMenus.find((menu) => {
                                return menu.menuId == attributes[menuKey];
                            });
                        }

                        // if one of these don't exist, do not push.
                        if (!location || !flytLocation) {
                            return;
                        }

                        $scope.flytLocations.push({
                            selectedLocation: location,
                            selectedFlytLocation: flytLocation,
                            selectedFlytMenu: flytMenu
                        });
                    }
                });
            };

            $scope.addNewFlytIntegration = () => {
                $scope.flytLocations.push({
                    selectedLocation: '',
                    selectedFlytLocation: '',
                    selectedFlytMenu: ''
                });
            };

            $scope.saveFlytIntegrations = () => {
                const companiesToUpdate = {};

                $scope.flytLocations.forEach((flytLocation) => {
                    // for whatever reason, if it doesn't contain either objects, skip this iteration.
                    if (!flytLocation.selectedLocation || !flytLocation.selectedFlytLocation || ($scope.hasMenuV2Enabled() && !flytLocation.selectedFlytMenu)) {
                        return;
                    }

                    const id = flytLocation.selectedLocation.companyId;
                    const flytLocationId = flytLocation.selectedFlytLocation;
                    const flytMenuId = flytLocation.selectedFlytMenu ? flytLocation.selectedFlytMenu.menuId : null;

                    if ($scope.hasMenuV2Enabled()) {
                        companiesToUpdate[id] = {
                            'flyt_location_id': flytLocationId,
                            'flyt_menu_id': flytMenuId
                        };
                    } else {
                        companiesToUpdate[id] = {
                            'flyt_location_id': flytLocationId
                        };
                    }

                });

                updateThirdPartyIntegrationSetup(companiesToUpdate);
            };

            $scope.connectFlytIntegration = () => {
                const flytApiKey = $scope.newFlytConnectionData.apiKey || '';
                const flytCompanyId = $scope.newFlytConnectionData.companyId || '';

                if (!flytApiKey || !flytCompanyId) {
                    return;
                }

                $scope.merchant.attributes['flyt_api_key'] = flytApiKey;
                $scope.merchant.attributes['flyt_company_id'] = flytCompanyId;
                $scope.company.attributes['flyt_api_key'] = flytApiKey;
                $scope.company.attributes['flyt_company_id'] = flytCompanyId;

                updateCompanyAttributes(['flyt_api_key', 'flyt_company_id']);
                getFlytCompanyIntegrations();
            };

            $scope.deleteFlytLocationIntegration = (flytLocation, index) => {
                const id = flytLocation.selectedLocation.companyId;

                // set deliverect location id to none.
                $scope.flytLocations.splice(index, 1);
                let companyToUpdate;
                if ($scope.hasMenuV2Enabled()) {
                    companyToUpdate = {
                        [id]: {
                            'flyt_location_id': -1,
                            'flyt_menu_id': -1
                        }
                    };
                } else {
                    companyToUpdate = {
                        [id]: {
                            'flyt_location_id': -1
                        }
                    };
                }


                updateThirdPartyIntegrationSetup(companyToUpdate);
            };

            $scope.clearSelectedFlytMenu = () => {
                if ($scope.flytLocation) {
                    $scope.flytLocation.selectedFlytMenu = {};
                }
            };

            $scope.unselectedLocationsFilter = function (selectionArray, position) {
                return function (item, index) {
                    return selectionArray.map((element) => {
                        return element.selectedLocation.id;
                    }).indexOf(item.id) == -1 || item.id == selectionArray[position].selectedLocation.id;
                };
            };

            $scope.unselectedDeliverectLocationsFilter = function (selectionArray, position) {
                return function (item, index) {
                    return selectionArray.map((element) => {
                        return element.selectedDeliverectLocation._id;
                    }).indexOf(item._id) == -1 || item._id == selectionArray[position].selectedDeliverectLocation._id || item._id == -1;
                };
            };

            $scope.allLocationsFilter = (location) => {
                return location.companyId != 0;
            };

            $scope.showSaveSuccess = false;
            $scope.showSaveError = false;
            $scope.isUpdating = false;

            const settingsUpdated = function (success = true) {
                $timeout(() => {
                    $scope.isUpdating = true;
                    success ? $scope.showSaveSuccess = true : $scope.showSaveError = true;
                });

                $timeout(function () {
                    $scope.isUpdating = false;
                    $scope.showSaveSuccess = false;
                    $scope.showSaveError = false;
                }, NOTIFICATION_DURATION);
            };

            var getQuickBooksConnection = function () {
                QuickBooks.get(function (response) {
                    $scope.quickBooksConnected = response.connected;
                }, function (error) {
                    $scope.quickBooksConnected = false;
                });
            };

            $scope.connectToQuickBooks = function () {
                QuickBooks.connectToQuickBooks(function (response) {
                    if (response.authUri) {
                        $window.open(response.authUri);
                    }
                }, function (error) {
                    PosAlertService.showAlertByName('oops-general');
                });
            };

            $scope.disconnectFromQuickBooks = function () {
                QuickBooks.disconnectFromQuickBooks(function (response) {
                    getQuickBooksConnection();
                }, function (error) {
                    PosAlertService.showAlertByName('oops-general');
                });
            };

            var getAllCompanies = function () {
                return Company.list({}, function (response) {
                    var locationsWithoutCompanyName = response.map(function (company) {
                        if (company.companyName.includes(' - ')) {
                            company.companyName = company.companyName.slice(company.companyName.indexOf(' - ') + 3, company.companyName.length);
                        }
                        return company;
                    });
                    $scope.allCompanies = locationsWithoutCompanyName;
                }).$promise;
            };

            var getAllCompaniesAttributes = function () {
                $scope.allCompaniesAttributes = {};
                $scope.allCompanies.forEach((company) => {
                    if (company.companyId === 0) {
                        return;
                    }
                    $scope.allCompaniesAttributes[company.companyId] = angular.copy(company.attributes);

                    // Convert from string to boolean.
                    $scope.allCompaniesAttributes[company.companyId]['delivery_fixed_locations_enabled'] = company.attributes['delivery_fixed_locations_enabled'] === 'true';

                    // Populates UI with localized delivery fees (child companies' delivery fees that don't match the parent company's).
                    // company.attributes['delivery_taxable'] is converted from a string to a boolean because
                    // $scope.company.attributes['delivery_taxable'] is a boolean.
                    if (company.attributes['delivery_fee'] != $scope.company.attributes['delivery_fee'] ||
                        company.attributes['delivery_large_order_fee'] != $scope.company.attributes['delivery_large_order_fee'] ||
                        company.attributes['delivery_large_order_threshold'] != $scope.company.attributes['delivery_large_order_threshold'] ||
                        company.attributes['delivery_small_order_fee'] != $scope.company.attributes['delivery_small_order_fee'] ||
                        company.attributes['delivery_small_order_threshold'] != $scope.company.attributes['delivery_small_order_threshold'] ||
                        (company.attributes['delivery_taxable'] === 'true') != $scope.company.attributes['delivery_taxable']) {
                        var deliveryFeeAttributes = {
                            'delivery_fee': company.attributes['delivery_fee'],
                            'delivery_small_order_threshold': company.attributes['delivery_small_order_threshold'],
                            'delivery_small_order_fee': company.attributes['delivery_small_order_fee'],
                            'delivery_large_order_threshold': company.attributes['delivery_large_order_threshold'],
                            'delivery_large_order_fee': company.attributes['delivery_large_order_fee'],
                            'delivery_taxable': (company.attributes['delivery_taxable'] === 'true'),
                        };
                        $scope.addLocalizedFees(company.companyId, deliveryFeeAttributes);
                    }

                    company.deliveryLocations = JSON.parse(company.attributes['delivery_fixed_locations']);
                });
            };

            $scope.isAttributeValueGreaterThanZero = function (value) {
                return parseFloat(value) > 0;
            };

            // var getCompanyDrivers = function (searchFilter) {
            //     if (searchFilter.companyId === 0) {
            //         searchFilter.companyId = null;
            //     }
            //     Drivers.getDrivers(searchFilter, function (drivers) {
            //         drivers.forEach(function (driver) {
            //             driver.masked_driver_license_id = '****' + driver.driver_license_id.slice(driver.driver_license_id.length - 4, driver.driver_license_id.length);
            //         });
            //         $scope.driversList = drivers;
            //     });
            // };

            $scope.init = function () {
                $scope.getCurrentMerchant();

                $scope.loadLocations().$promise.then(function () {
                    $scope.currentLocation = $scope.locations[0] || {};
                    $scope.currentLocationId = $scope.currentLocation.locationId;
                    $scope.merchant.address = {
                        street: $scope.currentLocation.street,
                        city: $scope.currentLocation.city,
                        province: $scope.currentLocation.region,
                        postalCode: $scope.currentLocation.zip,
                        latitude: $scope.currentLocation.latitude,
                        longitude: $scope.currentLocation.longitude
                    };
                    $scope.merchant.manager = $scope.company.manager;
                    $scope.merchant.owner = $scope.company.owner;
                    $scope.merchant.businessAddress = $scope.company.businessAddress;

                    $scope.merchant.mobileFeatures[0].value = $scope.company.hasPreorder;
                    $scope.merchant.mobileFeatures[1].value = $scope.company.hasLoyalty;

                    // switch between driver and regular employee view on timecard setup page
                    $scope.timecardView = 'employeeView';


                    $scope.loadRoles().$promise.then(function () {
                        $scope.loadAllRequiredData();
                    }, function () {
                        $log.error({
                            message: 'Error while loading Roles on setup page',
                            context: {
                                activity: 'Merchant setup',
                                company: $scope.company,
                                user: CurrentSession.getUser()
                            }
                        });
                        $scope.loadAllRequiredData();
                    });

                    LookupService.getRolePermissions().then(function (rolePermissions) {
                        Security.rolePermissions = rolePermissions;
                        const userCanSetup = $scope.userCanSetup = Security.hasPermissionForUser('settings');

                        $scope.sideBarNavPages = [
                            {
                                'translate': 'setupWizard.merchant.basicInfo.label',
                                'setupType': 'basic-info',
                                'isVisible': userCanSetup
                            },
                            {
                                'translate': 'setupWizard.merchant.currencySetup.label',
                                'setupType': 'currency-setup',
                                'isVisible': userCanSetup
                            },
                            {
                                'translate': 'setupWizard.merchant.stationSetup.label',
                                'setupType': 'station-setup',
                                'isVisible': true
                            },
                            {
                                'translate': 'setupWizard.merchant.kisokSetup.label',
                                'setupType': 'kiosk-setup',
                                'isVisible': userCanSetup && CompanyAttributesService.hasKioskEnabled()
                            },
                            {
                                'translate': 'setupWizard.merchant.employees.label',
                                'setupType': 'employee-setup',
                                'isVisible': userCanSetup
                            },
                            {
                                'translate': 'setupWizard.merchant.timecards.label',
                                'setupType': 'employee-timecards',
                                'isVisible': userCanSetup
                            },
                            {
                                'translate': 'setupWizard.merchant.discounts.label',
                                'setupType': 'discount-setup',
                                'isVisible': userCanSetup && $scope.isRootCompany
                            },
                            {
                                'translate': 'setupWizard.merchant.giftCardSetup.label',
                                'setupType': 'gift-card-setup',
                                'isVisible': userCanSetup && ($scope.isRootCompany && $scope.company.attributes['digital_gift_card_enabled'])
                            },
                            {
                                'translate': 'setupWizard.merchant.mobileSetup.label',
                                'setupType': 'mobile-setup',
                                'isVisible': userCanSetup
                            },
                            {
                                'translate': 'setupWizard.merchant.deliverySetup.label',
                                'setupType': 'delivery-setup',
                                'isVisible': userCanSetup && CompanyAttributesService.deliveryFeatureEnabled()
                            },
                            {
                                'translate': 'setupWizard.merchant.loyalty.setup.label',
                                'setupType': 'loyalty-setup',
                                'isVisible': userCanSetup && $scope.isRootCompany
                            },
                            {
                                'translate': 'setupWizard.merchant.fiit.title',
                                'setupType': 'fiit-setup',
                                'isVisible': userCanSetup && $scope.isCampus
                            },
                            {
                                'translate': 'setupWizard.merchant.receiptSetup.label',
                                'setupType': 'receipt-setup',
                                'isVisible': userCanSetup
                            },
                            {
                                'translate': 'setupWizard.merchant.services.label',
                                'setupType': 'service-setup',
                                'isVisible': userCanSetup
                            },
                            {
                                // keep this as last on the list
                                'translate': 'setupWizard.merchant.integrationSetup.label',
                                'setupType': 'integration-setup',
                                'isVisible': userCanSetup
                            },
                        ];

                        if ($scope.showEditor) {
                            $scope.setPage('welcome');
                        } else if (userCanSetup) {
                            $scope.setPage('basic-info');
                        } else {
                            $scope.setPage('station-setup');
                        }
                    });

                    $scope.checkSevenshiftsConnectivity();
                    $scope.checkADPConnectivity();
                    getQuickBooksConnection();
                });

                getAllCompanies().then(function () {
                    getAllCompaniesAttributes();
                });

                translate();
            };

            $scope.$on('setup::companyLoaded', function () {
                $scope.isCompanyLoaded = true;
                $scope.enhanceSecurityMeasures = CompanyAttributesService.enhanceSecurityMeasures();

                $scope.showEditor = $scope.company && !$scope.company.isSetupComplete;
                $scope.init();

                // DELIVERY

                /* For changing pages in the Delivery Settings. Two variables are needed: `currentSecondaryDeliveryPage`
                 for determining which page is loaded and `isSecondaryDeliveryPageVisible` for triggering the sliding
                 animation. Using one variable for both of these behaviours would break the animation. */
                $scope.currentSecondaryDeliveryPage = '';
                $scope.isSecondaryDeliveryPageVisible = false;
                $scope.setDeliveryPage = function (page) {
                    if (page !== 'main') {
                        $scope.currentSecondaryDeliveryPage = page;
                        $scope.isSecondaryDeliveryPageVisible = true;
                        getAllCompanies().then(function () {
                            getAllCompaniesAttributes();
                        });
                    } else {
                        $scope.isSecondaryDeliveryPageVisible = false;
                    }
                };

                $scope.editingDeliveryFees = false;
                $scope.editingClockinChecklist = false;
                $scope.deliveryFees = {
                    levels: [],
                    localizedFees: [],
                    deletedLocalizedFeesCompanyIds: []
                };

                $scope.editDeliveryFees = function () {
                    $scope.editingDeliveryFees = true;
                };

                $scope.cancelEditDeliveryFees = function () {
                    $scope.editingDeliveryFees = false;
                };

                $scope.editClockinChecklist = function () {
                    $scope.editingClockinChecklist = true;
                    $scope.newDeliveryChecklist = angular.copy($scope.deliveryChecklist);
                };

                $scope.cancelEditClockinChecklist = function () {
                    $scope.editingClockinChecklist = false;
                };

                $scope.deliveryFeeOptions = [{
                    type: 'small',
                    name: 'Under'
                }, {
                    type: 'large',
                    name: 'Over'
                }];

                $scope.localizedDeliveryFeeOptions = [{
                    type: 'small',
                    name: 'Under'
                }, {
                    type: 'large',
                    name: 'Over'
                }];

                $scope.unselectedFeeLevels = function (selectionArray, position) {
                    return function (item, index) {
                        return selectionArray.map((element) => {
                            return element.type;
                        }).indexOf(item.type) == -1 || item.type == selectionArray[position].type;
                    };
                };

                $scope.feeLevelTypeChanged = function (type) {
                    var changedExistingValue;
                    if (type === 'small') {
                        changedExistingValue = !$scope.deliveryFees.levels.find((level) => {
                            return level.type === 'large';
                        });
                        if (changedExistingValue) {
                            $scope.merchant.attributes['delivery_large_order_threshold'] = '0.0';
                            $scope.merchant.attributes['delivery_large_order_fee'] = '0.0';
                        }
                    } else if (type === 'large') {
                        changedExistingValue = !$scope.deliveryFees.levels.find((level) => {
                            return level.type === 'small';
                        });
                        if (changedExistingValue) {
                            $scope.merchant.attributes['delivery_small_order_threshold'] = '0.0';
                            $scope.merchant.attributes['delivery_small_order_fee'] = '0.0';
                        }
                    }
                };

                $scope.localizedFeeLevelTypeChanged = function (type, localizedFee) {
                    var changedExistingValue;
                    if (type === 'small') {
                        changedExistingValue = !localizedFee.levels.find((level) => {
                            return level.type === 'large';
                        });
                        if (changedExistingValue) {
                            localizedFee.attributes['delivery_large_order_threshold'] = '0.0';
                            localizedFee.attributes['delivery_large_order_fee'] = '0.0';
                        }
                    } else if (type === 'large') {
                        changedExistingValue = !localizedFee.levels.find((level) => {
                            return level.type === 'small';
                        });
                        if (changedExistingValue) {
                            localizedFee.attributes['delivery_small_order_threshold'] = '0.0';
                            localizedFee.attributes['delivery_small_order_fee'] = '0.0';
                        }
                    }
                };

                $scope.addFeeLevel = function (type) {
                    if ($scope.deliveryFees.levels.length >= 2) {
                        return;
                    }
                    $scope.deliveryFees.levels.push({type: type});
                };

                $scope.deleteFeeLevel = function (levelType, allLevels) {
                    if (levelType === 'small') {
                        $scope.merchant.attributes['delivery_small_order_threshold'] = '0.0';
                        $scope.merchant.attributes['delivery_small_order_fee'] = '0.0';
                    } else if (levelType === 'large') {
                        $scope.merchant.attributes['delivery_large_order_threshold'] = '0.0';
                        $scope.merchant.attributes['delivery_large_order_fee'] = '0.0';
                    }
                    var types = allLevels.map((e) => {
                        return e.type;
                    });
                    allLevels.splice(types.indexOf(levelType), 1);
                };

                $scope.deleteLocalizedFeeLevel = function (levelType, localizedFee) {
                    if (levelType === 'small') {
                        localizedFee.attributes['delivery_small_order_threshold'] = '0.0';
                        localizedFee.attributes['delivery_small_order_fee'] = '0.0';
                    } else if (levelType === 'large') {
                        localizedFee.attributes['delivery_large_order_threshold'] = '0.0';
                        localizedFee.attributes['delivery_large_order_fee'] = '0.0';
                    }
                    var types = localizedFee.levels.map((e) => {
                        return e.type;
                    });
                    localizedFee.levels.splice(types.indexOf(levelType), 1);
                };

                $scope.addLocalizedFees = function (companyId, existingAttributes) {
                    var attributes = existingAttributes;
                    if (!attributes) {
                        attributes = {
                            'delivery_fee': 0,
                            'delivery_small_order_threshold': 0,
                            'delivery_small_order_fee': 0,
                            'delivery_large_order_threshold': 0,
                            'delivery_large_order_fee': 0,
                            'delivery_taxable': false,
                        };
                    }

                    var levels = [];
                    if (existingAttributes) {
                        if (parseFloat(existingAttributes['delivery_small_order_threshold']) > 0) {
                            levels.push({type: 'small'});
                        }

                        if (parseFloat(existingAttributes['delivery_large_order_threshold']) > 0) {
                            levels.push({type: 'large'});
                        }
                    }
                    $scope.deliveryFees.localizedFees.push({
                        companyId: companyId,
                        attributes: attributes,
                        levels: levels
                    });

                    $timeout(() => {
                        $scope.$digest();
                    }, 3000);
                };

                $scope.deleteLocalizedFee = function (index) {
                    var toDelete = $scope.deliveryFees.localizedFees[index].companyId;
                    if (toDelete) {
                        $scope.deliveryFees.deletedLocalizedFeesCompanyIds.push(toDelete);
                    }
                    $scope.deliveryFees.localizedFees.splice(index, 1);
                };

                // TODO: Refer to $scope.addFeeLevel
                $scope.addLocalizedFeeLevel = function (localizedFee, type) {
                    if (localizedFee.levels.length >= 2) {
                        return;
                    }
                    localizedFee.levels.push({type: type});
                };

                $scope.newDeliveryChecklistString = {item: ''};
                $scope.deliveryChecklist = [];
                $scope.newDeliveryChecklist = [];

                $scope.addDeliveryChecklistItem = function () {
                    if ($scope.newDeliveryChecklistString.item) {
                        $scope.newDeliveryChecklist.push($scope.newDeliveryChecklistString.item);
                    }
                    $scope.newDeliveryChecklistString.item = '';
                };

                $scope.editDeliveryChecklistItem = function (itemIndex) {
                    $scope.editingDeliveryChecklistItem = itemIndex;
                    $scope.deliveryChecklistItemToEdit = {
                        item: $scope.newDeliveryChecklist[itemIndex]
                    };
                };

                $scope.saveDeliveryChecklistItem = function () {
                    $scope.newDeliveryChecklist[$scope.editingDeliveryChecklistItem] = $scope.deliveryChecklistItemToEdit.item;
                    $scope.editingDeliveryChecklistItem = undefined;
                    $scope.deliveryChecklistItemToEdit = undefined;
                };

                $scope.editingDeliveryChecklistItem;

                $scope.deleteDeliveryChecklistItem = function (itemIndex) {
                    $scope.newDeliveryChecklist.splice(itemIndex, 1);
                };

                $scope.showAddDeliveryLocationModal = function (company) {
                    $modal.open({
                        templateUrl: 'common/modals/modalAddDeliveryLocation.tpl.html',
                        controller: [
                            '$scope',
                            'saveLocation',
                            'PosAlertService',
                            function ($scope, saveLocation, PosAlertService) {
                                $scope.newLocation = {
                                    name: '',
                                    address: ''
                                };

                                $scope.cancel = function () {
                                    $scope.$dismiss();
                                };

                                $scope.save = function () {
                                    if (!($scope.newLocation.name && $scope.newLocation.address)) {
                                        var alertParams = {
                                            title: 'Missing Required Information',
                                            message: 'Please fill in both the name and the address of the delivery location!'
                                        };
                                        PosAlertService.showAlertByName('general', alertParams);
                                        return;
                                    }
                                    saveLocation($scope.newLocation);
                                    $scope.$close();
                                };
                            }],
                        windowClass: 'modal-add-delivery-location',
                        resolve: {
                            saveLocation: function () {
                                return function (deliveryLocation) {
                                    if (!company.deliveryLocations) {
                                        company.deliveryLocations = [];
                                    }
                                    company.deliveryLocations.push(deliveryLocation);
                                };
                            }
                        },
                        backdrop: 'static'
                    }, function (error) {
                    });
                };

                $scope.showEditDeliveryLocationModal = function (company, deliveryLocation, index) {
                    $modal.open({
                        templateUrl: 'common/modals/modalAddDeliveryLocation.tpl.html',
                        controller: [
                            '$scope',
                            'saveLocation',
                            'deliveryLocation',
                            function ($scope, saveLocation, deliveryLocation) {
                                $scope.newLocation = angular.copy(deliveryLocation);

                                $scope.cancel = function () {
                                    $scope.$dismiss();
                                };

                                $scope.save = function () {
                                    saveLocation($scope.newLocation);
                                    $scope.$close();
                                };
                            }],
                        windowClass: 'modal-add-delivery-location',
                        resolve: {
                            saveLocation: function () {
                                return function (deliveryLocationEdit) {
                                    company.deliveryLocations[index] = deliveryLocationEdit;
                                };
                            },
                            deliveryLocation: function () {
                                return deliveryLocation;
                            }
                        },
                        backdrop: 'static'
                    }, function (error) {
                    });
                };

                $scope.showDeleteDeliveryLocationModal = function (company, index) {
                    $modal.open({
                        templateUrl: 'common/modals/modalDeleteDeliveryLocation.tpl.html',
                        controller: [
                            '$scope',
                            'deleteLocation',
                            function ($scope, deleteLocation) {
                                $scope.cancel = function () {
                                    $scope.$dismiss();
                                };

                                $scope.deleteLocation = function () {
                                    deleteLocation();
                                    $scope.$close();
                                };
                            }],
                        windowClass: 'modal-delete-delivery-location',
                        resolve: {
                            deleteLocation: function () {
                                return function () {
                                    company.deliveryLocations.splice(index, 1);
                                };
                            }
                        },
                        backdrop: 'static'
                    }, function (error) {
                    });
                };

                $scope.updateDeliveryEnabled = function () {
                    updateCompanyAttributes(['delivery_enabled'])
                        .then(function () {
                            getAllCompanies();
                            settingsUpdated();
                        })
                        .catch(() => settingsUpdated(false));
                };

                $scope.updateDeliveryFees = function (toExit) {
                    var merchAttributes = ['delivery_fee', 'delivery_small_order_fee',
                        'delivery_small_order_threshold', 'delivery_large_order_fee', 'delivery_large_order_threshold', 'delivery_taxable'];

                    for (var x in merchAttributes) {
                        // Checking with undefined because value can be true or false
                        if ($scope.merchant.attributes[merchAttributes[x]] != undefined) {
                            $scope.company.attributes[merchAttributes[x]] = $scope.merchant.attributes[merchAttributes[x]];
                        }
                    }

                    var localizedFees = $scope.deliveryFees.localizedFees;
                    var deletedLocalizedFeesCompanyIds = $scope.deliveryFees.deletedLocalizedFeesCompanyIds;

                    var allCompaniesAttributes = {};
                    allCompaniesAttributes[$scope.company.companyId] = $scope.company.attributes;
                    if (localizedFees) {
                        localizedFees.forEach((localizedFee) => {
                            if (localizedFee.companyId) {
                                allCompaniesAttributes[localizedFee.companyId] = localizedFee.attributes;
                            }
                        });
                    }

                    if (deletedLocalizedFeesCompanyIds) {
                        deletedLocalizedFeesCompanyIds.forEach((companyId) => {
                            allCompaniesAttributes[companyId] = {
                                'delivery_fee': '',
                                'delivery_small_order_fee': '',
                                'delivery_small_order_threshold': '',
                                'delivery_large_order_fee': '',
                                'delivery_large_order_threshold': '',
                                'delivery_taxable': ''
                            };
                        });
                    }

                    var updatePromise = batchUpdateCompanyAttributes(allCompaniesAttributes);

                    updatePromise.then(function () {
                        $scope.editingDeliveryFees = false;
                        getAllCompanies();
                        if (toExit) {
                            $scope.exitMerchantSetting();
                        }
                    }).catch(function () {
                        $scope.editingDeliveryFees = false;
                    });
                };

                $scope.updateDeliveryChecklist = function (toExit) {
                    $scope.merchant.attributes['delivery_pre_clockin_checklist'] = JSON.stringify($scope.newDeliveryChecklist);
                    var updatePromise = updateCompanyAttributes(['delivery_pre_clockin_checklist']);

                    updatePromise.then(function () {
                        getAllCompanies();
                        $scope.editingClockinChecklist = false;
                        $scope.deliveryChecklist = $scope.newDeliveryChecklist;
                        if (toExit) {
                            $scope.exitMerchantSetting();
                        }
                    }).then(function () {
                        $scope.editingClockinChecklist = false;
                    });
                };

                $scope.saveDeliveryZoneSettings = function () {
                    var merchAttributes = ['delivery_zone_radius_kms', 'delivery_fixed_locations_enabled'];
                    var allCompaniesAttributes = angular.copy($scope.allCompaniesAttributes);
                    var companiesAttributesToUpdate = {};

                    for (var x in merchAttributes) {
                        // Checking with undefined because value can be true or false
                        if (merchAttributes[x] != undefined) {
                            for (let companyId in allCompaniesAttributes) {
                                if (allCompaniesAttributes[companyId][merchAttributes[x]] != undefined) {
                                    if (!companiesAttributesToUpdate[companyId]) {
                                        companiesAttributesToUpdate[companyId] = {};
                                    }
                                    companiesAttributesToUpdate[companyId][merchAttributes[x]] = allCompaniesAttributes[companyId][merchAttributes[x]];
                                }
                            }
                        }
                    }

                    $scope.allCompanies.forEach(function (company) {
                        var companyId = company.companyId;
                        if (!companiesAttributesToUpdate[companyId]) {
                            companiesAttributesToUpdate[companyId] = {};
                        }
                        if (company.deliveryLocations) {
                            companiesAttributesToUpdate[companyId]['delivery_fixed_locations'] = JSON.stringify(company.deliveryLocations);
                        } else {
                            companiesAttributesToUpdate[companyId]['delivery_fixed_locations'] = '[]';
                        }
                    });

                    if (companiesAttributesToUpdate) {
                        batchUpdateCompanyAttributes(companiesAttributesToUpdate)
                        .then(() => settingsUpdated())
                        .catch(() => settingsUpdated(false));
                    }
                };

                // $scope.showAddDriverModal = function () {
                //     var modalInstance = $modal.open({
                //         templateUrl: 'common/modals/modalAddDeliveryDriver.tpl.html',
                //         controller: [
                //             '$scope',
                //             'companies',
                //             'currentCompanyId',
                //             'Drivers',
                //             function ($scope, companies, currentCompanyId, Drivers) {
                //                 $scope.companies = companies;

                //                 $scope.cancel = function () {
                //                     $scope.$dismiss()
                //                 };

                //                 $scope.currentStep = 1;

                //                 var nextStep = function () {
                //                     if ($scope.currentStep < 4) {
                //                         $scope.currentStep++;
                //                     }
                //                 };

                //                 $scope.completeStep = function () {
                //                     switch ($scope.currentStep) {
                //                         case 1:
                //                             if ($scope.driverObject.firstName &&
                //                                 $scope.driverObject.lastName &&
                //                                 $scope.driverObject.phoneNumber) {
                //                                     nextStep();
                //                             } else {
                //                                 PosAlertService.showAlertByName('missing-information');
                //                             }
                //                             break;
                //                         case 2:
                //                             if ($scope.driverObject.driversLicenseNumber &&
                //                                 $scope.driverObject.driversLicenseExpiration) {
                //                                     nextStep();
                //                             } else {
                //                                 PosAlertService.showAlertByName('missing-information');
                //                             }
                //                             break;
                //                         case 3:
                //                             if (selectedAtLeastOneLocation()) {
                //                                 nextStep();
                //                             } else {
                //                                 PosAlertService.showAlertByName('missing-information');
                //                             }
                //                             break;
                //                     }
                //                 };

                //                 var selectedAtLeastOneLocation = function () {
                //                     var found;
                //                     for (var company in $scope.enabledCompanies) {
                //                         if ($scope.enabledCompanies[company]) {
                //                             found = true;
                //                             break;
                //                         }
                //                     }
                //                     return found;
                //                 };

                //                 $scope.previousStep = function () {
                //                     if ($scope.currentStep > 1) {
                //                         $scope.currentStep--;
                //                     }
                //                 };

                //                 $scope.driverObject = {
                //                     driverCompanyId: currentCompanyId,
                //                     enabled: true,
                //                     firstName: '',
                //                     lastName: '',
                //                     phoneNumber: '',
                //                     adpNumber: '',
                //                     driversLicenseNumber: '',
                //                     driversLicenseExpiration: '',
                //                     enabledCompanies: []
                //                 };

                //                 $scope.enabledCompanies = {};

                //                 $scope.addDriver = function () {
                //                     for (var companyId in $scope.enabledCompanies) {
                //                         if ($scope.enabledCompanies[companyId]) {
                //                             $scope.driverObject.enabledCompanies.push(companyId);
                //                         }
                //                     }
                //                     var newDriverObject = {
                //                         driverCompanyId: $scope.driverObject.driverCompanyId,
                //                         enabled: $scope.driverObject.enabled,
                //                         firstName: $scope.driverObject.firstName,
                //                         lastName: $scope.driverObject.lastName,
                //                         phoneNumber: $scope.driverObject.phoneNumber,
                //                         adpNumber: $scope.driverObject.adpNumber,
                //                         driver_license_id: $scope.driverObject.driversLicenseNumber,
                //                         license_id_expiry: $scope.driverObject.driversLicenseExpiration,
                //                         enabledCompanies: $scope.driverObject.enabledCompanies
                //                     };

                //                     Drivers.createDriver(newDriverObject, function(response) {
                //                         $scope.$close(response);
                //                     }, function (e) {
                //                         if (e.data.error) {
                //                             PosAlertService.showAlertByName('general', {
                //                                 title: 'Oops!',
                //                                 message: e.data.error
                //                             });
                //                         }
                //                     });
                //                 };
                //             }],
                //         windowClass: 'modal-add-delivery-driver',
                //         resolve: {
                //             currentCompanyId: function () {
                //                 return $scope.currentCompanyId;
                //             },
                //             companies: function () {
                //                 return $scope.allCompanies;
                //             }
                //         },
                //         backdrop: 'static'
                //     });

                //     modalInstance.result.then(function (driverObject) {
                //         getCompanyDrivers($scope.driverSearchFilter);
                //     });
                // };

                // $scope.showEditDriverModal = function (driver) {
                //     var modalInstance = $modal.open({
                //         templateUrl: 'common/modals/modalEditDeliveryDriver.tpl.html',
                //         controller: [
                //             '$scope',
                //             'driver',
                //             'companies',
                //             'Drivers',
                //             function ($scope, driver, companies, Drivers) {
                //                 $scope.companies = companies;

                //                 $scope.cancel = function () {
                //                     $scope.$dismiss()
                //                 };

                //                 var selectedAtLeastOneLocation = function () {
                //                     var found;
                //                     for (var company in $scope.enabledCompanies) {
                //                         if ($scope.enabledCompanies[company]) {
                //                             found = true;
                //                             break;
                //                         }
                //                     }
                //                     return found;
                //                 };

                //                 $scope.driverObject = angular.copy(driver);
                //                 $scope.driverObject.enabled = $scope.driverObject.status === 'ACTIVE';
                //                 $scope.enabledCompanies = {};
                //                 $scope.driverObject.driverCompaniesEnabled.forEach(function (company) {
                //                     if (company.enabled) {
                //                         $scope.enabledCompanies[company.companyId] = true;
                //                     }
                //                 });

                //                 $scope.getSelectedCompanyCount = function () {
                //                     var count = 0;
                //                     for (var companyId in $scope.enabledCompanies) {
                //                         if ($scope.enabledCompanies[companyId]) {
                //                             count++;
                //                         }
                //                     }
                //                     return count;
                //                 };

                //                 $scope.updateDriver = function () {
                //                     var updatedDriverObject = {
                //                         driverUserId: $scope.driverObject.userId,
                //                         driverCompanyId: $scope.driverObject.companyId,
                //                         enabled: $scope.driverObject.enabled,
                //                         firstName: $scope.driverObject.firstname,
                //                         lastName: $scope.driverObject.lastname,
                //                         phoneNumber: $scope.driverObject.phone,
                //                         adpNumber: $scope.driverObject.adpEmployeeId,
                //                         driver_license_id: $scope.driverObject.driver_license_id,
                //                         license_id_expiry: $scope.driverObject.license_id_expiry,
                //                         enabledCompanies: []
                //                     }

                //                     for (var companyId in $scope.enabledCompanies) {
                //                         if ($scope.enabledCompanies[companyId]) {
                //                             updatedDriverObject.enabledCompanies.push(companyId);
                //                         }
                //                     }

                //                     Drivers.editDriver(updatedDriverObject, function(response) {
                //                         $scope.$close(response);
                //                     }, function (e) {
                //                         if (e.data.error) {
                //                             PosAlertService.showAlertByName('general', {
                //                                 title: 'Oops!',
                //                                 message: e.data.error
                //                             });
                //                         }
                //                     });
                //                 };
                //             }],
                //         windowClass: 'modal-edit-delivery-driver',
                //         resolve: {
                //             companies: function () {
                //                 return $scope.allCompanies;
                //             },
                //             driver: function () {
                //                 return driver;
                //             }
                //         },
                //         backdrop: 'static'
                //     });

                //     modalInstance.result.then(function (driverObject) {
                //         getCompanyDrivers($scope.driverSearchFilter);
                //     });
                // };

                // $scope.driverSearchFilter = {
                //     companyId: null,
                //     driversLicenseFilter: '',
                // };

                // $scope.refreshDriverSearch = function (companyId) {
                //     if (companyId) {
                //         $scope.driverSearchFilter.companyId = companyId;
                //     }
                //     getCompanyDrivers($scope.driverSearchFilter);
                // };

                var driverTextSearchTimeout = null;

                $scope.onDriverSearchTextChange = function () {
                    if (driverTextSearchTimeout) {
                        $timeout.cancel(driverTextSearchTimeout);
                    }
                    if ($scope.driverSearchFilter.driversLicenseFilter.length < 4) {
                        driverTextSearchTimeout = $timeout(function () {
                            $scope.refreshDriverSearch();
                        }, 1000);
                    } else {
                        $scope.refreshDriverSearch();
                    }
                };

                $scope.driverSearchCompanyId = null;

                $scope.getDriverEnabledCompanyCount = function (driver) {
                    var enabledCompanies = driver.driverCompaniesEnabled.filter(function (company) {
                        return company.enabled === true;
                    });
                    return enabledCompanies.length;
                };

                // DIGITAL GIFT CARD

                $scope.updateOrganizationSettings = () => {
                    return Organization.updateOrganizationSettings($scope.organization.settings).$promise;
                };

                $scope.updateDigitalGiftCardEnabled = () => {
                    $timeout(() => {
                        if ($scope.organization.settings.digitalGiftCardEnabled) {
                            $scope.getDigitalGiftCardSettings();
                            $scope.setDigitalGiftCardSettings();
                        } else {
                            $scope.setDigitalGiftCardSettings();
                        }
                    });
                };

                var initializeSortableGiftCardList = () => {
                    var el = document.getElementById('digitalGiftCardImageList');
                    if (!el) {
                        return;
                    }

                    var sortable = Sortable.create(el, {
                        handle: '.drag-handle',
                        onMove: function (event, origEvent) {
                            return true;
                        },
                        onStart: function (event) {
                        },
                        onEnd: function (event) {
                            var images = $scope.digitalGiftCardSettings.images;
                            var movedItem = images[event.oldIndex];

                            images.splice(event.oldIndex, 1);
                            images.splice(event.newIndex, 0, movedItem);
                            $scope.$digest();
                        },
                    });

                    $scope.$on('$destroy', function () {
                        sortable.destroy();
                    });
                };

                $scope.currentSecondaryGiftCardPage = '';
                $scope.isSecondaryGiftCardPageVisible = false;
                $scope.setGiftCardPage = (page) => {
                    if (page !== 'main') {
                        if (page === 'design') {
                            $timeout(function () {
                                initializeSortableGiftCardList();
                            }, 500);
                        }
                        $scope.currentSecondaryGiftCardPage = page;
                        $scope.isSecondaryGiftCardPageVisible = true;
                    } else {
                        // reset digital gift card settings if not saved.
                        if ($scope.isGiftCardSettingsChanged()) {
                            $scope.digitalGiftCardSettings = angular.copy($scope.tmpDigitalGiftCardSettings);
                        }

                        if ($scope.isSecondaryGiftCardPageVisible) {
                            $scope.isSecondaryGiftCardPageVisible = false;
                        }
                    }
                };

                $scope.showSecondaryKisokPage = false;
                $scope.kioskBackgroundsList = angular.copy($scope.company.secondaryDisplaySettings.kioskBackgrounds);
                $scope.setKioskBackgroundPage = function (page) {
                    if (page !== 'main') {
                        $scope.showSecondaryKisokPage = true;
                        assignDetailedDaysActive();
                    } else {
                        $scope.showSecondaryKisokPage = false;
                    }
                };

                var filterImages = (imageArr) => {
                    // filters out images with a deletedAt field.
                    $scope.digitalGiftCardSettings.images = _.filter(imageArr, (image) => {
                        return !image.deletedAt;
                    });
                };

                $scope.getDigitalGiftCardSettings = () => {
                    let settings;
                    // sets a default JSON for organizations
                    // that do not have a digitalGiftCardSettings
                    settings = {
                        images: [],
                        messageSuggestions: [],
                        supportedCurrencies: []
                    };

                    // for each currency, different settings is required.
                    // So load default template first and load data for each available currency
                    _.each($scope.merchant.availableCurrency, (currency) => {
                        /*
                        COMMENTED BY CHRIS 2020/08/19
                        setting minAmount = $5, maxAmount = $250
                        suggestedAmounts = $10, $25, $50, $100
                        we ignore conversions (approved by Riley)
                        */
                        let supportedCurrency = {
                            currencyCode: currency.currencyCode,
                            minAmountCents: 500,
                            maxAmountCents: 25000,
                            suggestedDenominationsCents: [
                                1000, 2500, 5000, 10000
                            ],
                            discount: {
                                minAmountCents: 0,
                                maxAmountCents: 0,
                                discountPercentage: 0
                            }
                        };

                        settings.supportedCurrencies.push(supportedCurrency);
                    });

                    // if organization has DGC settings then override corresponding data
                    if ($scope.organization.settings.digitalGiftCardSettings) {
                        let orgDGCSettings = JSON.parse($scope.organization.settings.digitalGiftCardSettings);

                        _.each(orgDGCSettings.supportedCurrencies, function (supportedCurrency) {
                            let index = _.findIndex(settings.supportedCurrencies, {currencyCode: supportedCurrency.currencyCode});

                            settings.supportedCurrencies[index] = supportedCurrency;
                        });

                        settings.images = orgDGCSettings.images;
                        settings.messageSuggestions = orgDGCSettings.messageSuggestions;
                    }

                    $scope.digitalGiftCardSettings = settings;
                    filterImages($scope.digitalGiftCardSettings.images);
                    $scope.tmpDigitalGiftCardSettings = angular.copy($scope.digitalGiftCardSettings);
                };

                $scope.setDigitalGiftCardSettings = ({notify = true, onSuccessCb} = {}) => {
                    $scope.organization.settings.digitalGiftCardSettings = JSON.stringify($scope.digitalGiftCardSettings);
                    $scope.tmpDigitalGiftCardSettings = angular.copy($scope.digitalGiftCardSettings);
                    $scope.updateOrganizationSettings()
                        .then(() => {
                            if (notify) settingsUpdated();
                            if (onSuccessCb) onSuccessCb();
                        })
                        .catch(() => notify && settingsUpdated(false));
                };

                $scope.isGiftCardSettingsChanged = () => {
                    return !angular.equals($scope.digitalGiftCardSettings, $scope.tmpDigitalGiftCardSettings);
                };

                $scope.saveBalanceLimits = (supportedCurrencies) => {
                    if ($scope.isGiftCardSettingsChanged()) {
                        $scope.isSavingBalanceLimits = true;

                        // verifying min amount <= max amount
                        var verified = _.every(supportedCurrencies, (currency, index) => {
                            var suggestedAmounts = currency.suggestedDenominationsCents;

                            var verifiedMinMaxAmounts = currency.minAmountCents <= currency.maxAmountCents;

                            /*
                            verifying suggestedAmounts are in incremental order.
                            e.g. suggested amount should display as 10,20,30,40
                            and not 10,30,40,20
                            */

                            var verifiedSuggestedAmounts = _.every(suggestedAmounts, (suggestedAmount, index) => {
                                return !index || suggestedAmounts[index - 1] <= suggestedAmount;
                            });

                            return (verifiedMinMaxAmounts && verifiedSuggestedAmounts);
                        });

                        // only update organization on backend if validations pass
                        if (verified) {
                            $scope.setDigitalGiftCardSettings();
                        } else {
                            PosAlertService.showAlertByName('general', {
                                title: 'general.error.gift-card-invalid-input.ttl',
                                message: 'general.error.gift-card-invalid-input.balance-limit.msg'
                            });
                        }

                        $scope.isSavingBalanceLimits = false;
                    }
                };

                $scope.showCreditLimits = false;
                $scope.editCreditLimits = () => {
                    $scope.showCreditLimits = !$scope.showCreditLimits;
                };

                $scope.saveCreditLimits = (supportedCurrencies) => {
                    if ($scope.isGiftCardSettingsChanged()) {
                        $scope.isSavingCreditLimits = true;
                        $scope.organization.settings.digitalGiftCardSettings = JSON.stringify($scope.digitalGiftCardSettings);

                        // verify min amount <= max amount & percentage <= 100%
                        var verified = _.every(supportedCurrencies, (currency) => {
                            var discount = currency.discount;
                            var verifiedMinMaxAmounts = discount.minAmountCents <= discount.maxAmountCents;
                            var verifiedPecentage = discount.discountPercentage <= 100;

                            return (verifiedMinMaxAmounts && verifiedPecentage);
                        });

                        // only update company if validations pass
                        if (verified) {
                            $scope.setDigitalGiftCardSettings({onSuccessCb: $scope.editCreditLimits});
                        } else {
                            PosAlertService.showAlertByName('general', {
                                title: 'general.error.gift-card-invalid-input.ttl',
                                message: 'general.error.gift-card-invalid-input.msg'
                            });
                        }

                        $scope.isSavingCreditLimits = false;
                    } else {
                        $scope.editCreditLimits();
                    }
                };

                const UploadStatus = Object.freeze(
                    {
                        'NONE': 1,
                        'PREPARED': 2,
                        'COMPLETED': 3,
                        'ERROR': 4,
                        'DELETED': 5
                    }
                );

                function createImageUploader (url) {
                    var uploaderConfig = {};
                    uploaderConfig.url = url;

                    var uploader = new FileUploader(uploaderConfig);
                    uploader.filters.push({
                        name: 'imageFilter',
                        fn: function (item /* {File|FileLikeObject} */, options) {
                            var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                            return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
                        }
                    });
                    uploader.onAfterAddingFile = function (fileItem) {
                    };
                    uploader.onAfterAddingAll = function (addedFileItems) {
                    };
                    return uploader;
                }

                $scope.tmpGiftCardImages = [];
                var giftCardUploader = $scope.giftCardUploader = createImageUploader('/freshideas/web/company/image');

                giftCardUploader.onAfterAddingFile = function (fileItem) {
                    var tmpGiftCardImage = {
                        compressedImage: fileItem._file,
                        fileName: fileItem._file.name,
                        isImageCompressing: false
                    };
                    tmpGiftCardImage.compressedImage.src = URL.createObjectURL(fileItem._file);
                    $scope.uploadStatus = UploadStatus.PREPARED;
                    $scope.tmpGiftCardImages.push(tmpGiftCardImage);
                };

                giftCardUploader.onCompleteItem = function (fileItem, response, status, headers) {
                    // response: { createdAt, deletedAt, fileName, id, updatedAt, url }
                    if (response) {
                        var uploadedImage = {
                            id: response.id,
                            name: response.fileName,
                            url: response.url
                        };

                        $scope.digitalGiftCardSettings.images.push(uploadedImage);
                        filterImages($scope.digitalGiftCardSettings.images);
                        $scope.isCardDesignUploading = false;
                        $scope.organization.settings.digitalGiftCardSettings = JSON.stringify($scope.digitalGiftCardSettings);
                    }
                };
                giftCardUploader.onCompleteAll = function (fileItem, response, status, headers) {
                    // clear temp. gift cards.
                    $scope.tmpGiftCardImages.splice(0, $scope.tmpGiftCardImages.length);
                    $scope.setDigitalGiftCardSettings();
                    $scope.uploadStatus = UploadStatus.COMPLETED;
                    settingsUpdated();
                };
                giftCardUploader.onBeforeUploadItem = function (item) {
                    // Upload compressed blob instead of original file.
                    var tmpGiftCardImage = $scope.tmpGiftCardImages[(item.index - 1)];

                    item._file = tmpGiftCardImage.compressedImage;
                    item.removeAfterUpload = true;
                    item.formData.push({
                        id: '',
                        resize_width: 1146,
                        resize_height: 723
                    });
                };
                giftCardUploader.onErrorItem = function (fileItem, response, status, headers) {
                    $scope.isCardDesignUploading = false;
                    // 413: Entity too large
                    giftCardUploader.clearQueue();
                    if (status == 413) {
                        PosAlertService.showAlertByName('file-upload-failed-large-file');
                    } else {
                        PosAlertService.showAlertByName('file-upload-failed');
                    }
                };

                $scope.daysActive = [
                    {
                        name: 'Sunday',
                        active: true,
                    },
                    {
                        name: 'Monday',
                        active: true,
                    },
                    {
                        name: 'Tuesday',
                        active: true,
                    },
                    {
                        name: 'Wednesday',
                        active: true,
                    },
                    {
                        name: 'Thursday',
                        active: true,
                    },
                    {
                        name: 'Friday',
                        active: true,
                    },
                    {
                        name: 'Saturday',
                        active: true,
                    }
                ];

                function setDaysActivePattern (imageObj) {
                    let state = '';

                    imageObj.detailedDaysActive.forEach((day) => {
                        state += day.active ? '1' : '0';
                    });

                    imageObj.dayActive = state;
                }

                function assignDetailedDaysActive () {
                    let kioskBackgroundsList = $scope.kioskBackgroundsList;

                    _.each(kioskBackgroundsList, function (item) {
                        let daysActivePattern = item.dayActive.split('');
                        let detailedDaysActive = angular.copy($scope.daysActive);

                        for (let i = 0; i < daysActivePattern.length; i++) {
                            detailedDaysActive[i].active = (daysActivePattern[i] === '1') ? true : false;
                        }

                        item.detailedDaysActive = detailedDaysActive;
                        item.isDayActiveChanged = false;
                        item.isKioskPropertiesLoading = false;
                    });
                }
                // if the kiosk background is from root company or parent company don't allow them to be updated or removed
                $scope.checkAccessToUpdate = function (imageObj) {
                    return (imageObj.companyId === $scope.company.companyId);
                };

                $scope.toggleDayActive = function (event, image, index) {
                    event.preventDefault();
                    event.stopPropagation();

                    image.detailedDaysActive[index].active = !image.detailedDaysActive[index].active;
                    image.isDayActiveChanged = true;
                };

                $scope.pageLabels = [
                    {index: 0, value: 'Page 1'},
                    {index: 1, value: 'Page 2'},
                    {index: 2, value: 'Page 3'},
                    {index: 3, value: 'Page 4'}
                ];
                $scope.merchant.defaultKioskMenuPage = Number.parseInt($scope.merchant.attributes['kiosk__default_menu_page_index']) || 0;

                $scope.updateKioskBackground = function (event, imageObj) {
                    event.preventDefault();
                    event.stopPropagation();

                    setDaysActivePattern(imageObj);
                    imageObj.isKioskPropertiesLoading = true;

                    saveProperties(imageObj);
                };

                $scope.uploadKioskBackground = function (event, index) {
                    let item = kioskBackgroundImageUploader.queue[index];

                    kioskBackgroundImageUploader.uploadItem(item);
                    $scope.kioskBackgroundsToUpload[index].uploading = true;
                };

                $scope.removeKioskBackgroundToUpload = function (event, index) {
                    updateKioskItemstoUpload(index);
                };

                function updateKioskItemstoUpload (index) {
                    let filteredArray = _.filter($scope.kioskBackgroundsToUpload, function (item, itemIndex) {
                        return itemIndex != index;
                    });

                    $scope.kioskBackgroundsToUpload = filteredArray;
                    $scope.kioskBackgroundImageUploader.removeFromQueue(index);
                }

                $scope.removeKioskBackground = function (event, id) {
                    event.preventDefault();
                    event.stopPropagation();

                    let params = {
                        id: id,
                    };

                    Company.deleteSecondaryDisplaySlide(params).$promise.then(function (response) {
                        let newArray = _.filter($scope.kioskBackgroundsList, function (item, index) {
                            return item.id !== id;
                        });

                        $scope.kioskBackgroundsList = newArray;
                    });
                };

                function saveProperties (imageObj) {
                    let params = {
                        id: imageObj.id,
                        dayActive: imageObj.dayActive || '1111111',
                    };

                    Company.updateSecondaryDisplay(params).$promise.then(function (response) {
                        imageObj.isKioskPropertiesLoading = false;
                        imageObj.isDayActiveChanged = false;

                        if (imageObj.isNew) {
                            $scope.kioskBackgroundsList.push(imageObj);
                        }
                    });
                }

                $scope.kioskBackgroundsToUpload = [];
                var kioskBackgroundImageUploader = $scope.kioskBackgroundImageUploader = createImageUploader('/freshideas/web/company/upload/secondary-display');
                kioskBackgroundImageUploader.onAfterAddingFile = function (fileItem) {
                    var kioskBackgroundToUpload = {
                        compressedImage: fileItem._file,
                        fileName: fileItem._file.name,
                        detailedDaysActive: angular.copy($scope.daysActive),
                        isDayActiveChanged: true,
                        dayActive: '',
                        uploading: false,
                        isNew: true,
                        companyId: $scope.company.companyId,
                    };
                    kioskBackgroundToUpload.compressedImage.src = URL.createObjectURL(fileItem._file);
                    $scope.kioskBackgroundsToUpload.push(kioskBackgroundToUpload);
                };
                kioskBackgroundImageUploader.onCompleteItem = function (fileItem, response, status, headers) {
                    let imageObj = _.find($scope.kioskBackgroundsToUpload, function (item) {
                        return item.uploading;
                    });
                    let uploadedItemIndex = $scope.kioskBackgroundsToUpload.indexOf(imageObj);

                    setDaysActivePattern(imageObj);

                    if (status < 400) {
                        imageObj.id = response.id;
                        imageObj.resourceUrl = response.resourceUrl;
                        imageObj.uuid = response.uuid;

                        saveProperties(imageObj);

                        if (uploadedItemIndex >= 0) {
                            updateKioskItemstoUpload(uploadedItemIndex);
                        }
                    }

                };
                kioskBackgroundImageUploader.onBeforeUploadItem = function (item) {
                    item.formData.push({
                        isKioskImage: true,
                    });
                };
                kioskBackgroundImageUploader.onErrorItem = function (fileItem, response, status, headers) {
                    /**
                     * 413: Entity too large
                     */
                    if (status == 413) {
                        PosAlertService.showAlertByName('file-upload-failed-large-file');
                    } else {
                        PosAlertService.showAlertByName('file-upload-failed-large-file');
                    }
                };

                $scope.imagesPendingRemoval = [];

                // used to confirm removal of images.
                $scope.addImageToRemovalArray = (id) => {
                    ConfirmModalService.openConfirmModal(
                        'setupWizard.merchant.giftCardSetup.confirmation.title',
                        'setupWizard.merchant.giftCardSetup.confirmation.description',
                        undefined, // `clickAction` callback
                        undefined,
                        undefined, // `scope`
                        true // `allowCancel`
                    ).then(function () {
                        var _image = {
                            id: id,
                            deletedAt: moment().unix()
                        };

                        $scope.imagesPendingRemoval.push(_image);

                        var image = _.find($scope.digitalGiftCardSettings.images, (image) => {
                            return image.id === id;
                        });

                        image.deletedAt = moment().unix();

                        filterImages($scope.digitalGiftCardSettings.images);
                    });
                };

                $scope.removeTmpImage = function (index) {
                    $scope.tmpGiftCardImages.splice(index, 1);
                };

                $scope.saveCardDesigns = () => {
                    // only attempt to upload if a tmp (compressed) image exists.
                    if ($scope.tmpGiftCardImages.length) {
                        $scope.isCardDesignUploading = true;
                        giftCardUploader.uploadAll();
                    }

                    if ($scope.imagesPendingRemoval.length) {
                        $scope.imagesPendingRemoval.splice(0, $scope.imagesPendingRemoval.length);
                    }

                    filterImages($scope.digitalGiftCardSettings.images);

                    // reordering gift cards or removing gift cards.
                    $scope.setDigitalGiftCardSettings({notify: false});
                };

                var initializeSortableMessages = () => {
                    var el = document.getElementById('messageSuggestionsList');
                    if (!el) {
                        return;
                    }

                    var sortable = Sortable.create(el, {
                        handle: '.drag-handle',
                        onMove: function (event, origEvent) {
                            return true;
                        },
                        onStart: function (event) {
                        },
                        onEnd: function (event) {
                            var messages = $scope.messagesShown;
                            var messageSuggestions = $scope.digitalGiftCardSettings.messageSuggestions;
                            var movedItem = messages[event.oldIndex];
                            var movedSuggestion = messageSuggestions[event.oldIndex];

                            messages.splice(event.oldIndex, 1);
                            messageSuggestions.splice(event.oldIndex, 1);
                            messages.splice(event.newIndex, 0, movedItem);
                            messageSuggestions.splice(event.newIndex, 0, movedSuggestion);
                            $scope.$digest();
                        },
                    });

                    $scope.$on('$destroy', function () {
                        sortable.destroy();
                    });
                };


                $scope.showMessageSuggestions = false;

                $scope.messagesShown = [
                    $translate.instant('setupWizard.merchant.giftCardSetup.messageSuggestion.default.1'),
                    $translate.instant('setupWizard.merchant.giftCardSetup.messageSuggestion.default.2'),
                    $translate.instant('setupWizard.merchant.giftCardSetup.messageSuggestion.default.3'),
                ];

                var mapMessageSuggestions = () => {
                    $scope.messagesShown = _.map($scope.digitalGiftCardSettings.messageSuggestions, (message, index) => {
                        return {
                            id: 'message_' + index,
                            text: message
                        };
                    });
                };

                $scope.editMessageSuggestions = () => {
                    $scope.showMessageSuggestions = !$scope.showMessageSuggestions;
                    if ($scope.showMessageSuggestions) {
                        // opening personalized messages here
                        // allows for sortable messages
                        $timeout(function () {
                            initializeSortableMessages();
                        }, 500);

                        mapMessageSuggestions();
                    } else {
                        // saving personalized messages here
                        $scope.digitalGiftCardSettings.messageSuggestions = _.map($scope.messagesShown, (message) => {
                            return message.text;
                        });
                    }
                };

                $scope.saveMessageSuggestions = (messageSuggestions) => {
                    if ($scope.isGiftCardSettingsChanged()) {
                        $scope.isSavingMessageSuggestions = true;
                        $scope.organization.settings.digitalGiftCardSettings = JSON.stringify($scope.digitalGiftCardSettings);

                        // verify messages are not empty
                        var verified = _.every(messageSuggestions, (messageSuggestion) => {
                            return !!messageSuggestion.length;
                        });

                        // only update company if validations pass
                        if (verified) {
                            $scope.setDigitalGiftCardSettings({onSuccessCb: () => {
                                $scope.isEditingMessageSuggestion = {};
                                $scope.editMessageSuggestions();
                            }});
                        } else {
                            PosAlertService.showAlertByName('general', {
                                title: 'general.error.gift-card-invalid-message.ttl',
                                message: 'general.error.gift-card-invalid-message.msg'
                            });
                        }
                        $scope.isSavingMessageSuggestions = false;
                    } else {
                        $scope.editMessageSuggestions();
                    }
                };

                $scope.newMessageSuggestion = {
                    message: ''
                };

                $scope.addMessageSuggestion = () => {
                    $scope.digitalGiftCardSettings.messageSuggestions.push($scope.newMessageSuggestion.message);
                    $scope.messagesShown.push({
                        id: 'message_' + $scope.messagesShown.length,
                        text: $scope.newMessageSuggestion.message
                    });

                    // clear new message suggestion after adding one.
                    $scope.newMessageSuggestion.message = '';
                };

                $scope.isEditingMessageSuggestion = {};

                $scope.editMessageSuggestion = (key) => {
                    $scope.isEditingMessageSuggestion[key] = !$scope.isEditingMessageSuggestion[key];
                };

                $scope.removeMessageSuggestion = (index) => {
                    $scope.messagesShown.splice(index, 1);
                    $scope.digitalGiftCardSettings.messageSuggestions.splice(index, 1);
                };

                $scope.getPromoImages = () => {
                    let settings = [];

                    if ($scope.organization.settings.promotionalImageSettings) {
                        // has shape [{id, url, fileName}]
                        settings = JSON.parse($scope.organization.settings.promotionalImageSettings);
                    }

                    $scope.promoImages = settings;
                };

                $scope.setPromoImages = () => {
                    $scope.organization.settings.promotionalImageSettings = JSON.stringify($scope.promoImages);
                    $scope.updateOrganizationSettings();
                };

                const initializeSortablePromoImages = () => {
                    const el = document.getElementById('promoImagesList');
                    if (!el) {
                        return;
                    }

                    const sortable = Sortable.create(el, {
                        handle: '.drag-handle',
                        onMove: function (event, origEvent) {
                            return true;
                        },
                        onStart: function (event) {
                        },
                        onEnd: function (event) {
                            const images = $scope.promoImages;
                            const movedItem = images[event.oldIndex];

                            if (movedItem) {
                                images.splice(event.oldIndex, 1);
                                images.splice(event.newIndex, 0, movedItem);
                                $scope.promoImagesMoved = true;
                                $scope.$digest();
                            }
                        },
                    });

                    $scope.$on('$destroy', function () {
                        sortable.destroy();
                    });
                };

                $scope.setMobilePage = (page) => {
                    if (page !== 'main') {
                        if (page === 'promo') {
                            $timeout(function () {
                                initializeSortablePromoImages();
                            }, 500);
                        }
                        $scope.isSecondaryMobileVisible = true;
                        $scope.currentSecondaryMobilePage = page;
                    } else {
                        if ($scope.isSecondaryMobileVisible) {
                            $scope.isSecondaryMobileVisible = false;
                        }
                        if ($scope.currentSecondaryMobilePage) {
                            $scope.currentSecondaryMobilePage = null;
                        }
                    }
                };

                $scope.tempPromoImages = [];
                var promoImagesUploader = $scope.promoImagesUploader = createImageUploader('/freshideas/web/company/image');

                promoImagesUploader.onAfterAddingFile = function (fileItem) {
                    var tempPromoImage = {
                        compressedImage: fileItem._file,
                        fileName: fileItem._file.name,
                        isImageCompressing: false
                    };
                    tempPromoImage.compressedImage.src = URL.createObjectURL(fileItem._file);
                    $scope.uploadStatus = UploadStatus.PREPARED;
                    $scope.tempPromoImages.unshift(tempPromoImage);
                };

                promoImagesUploader.onCompleteItem = function (fileItem, response, status, headers) {
                    // response: { createdAt, deletedAt, fileName, id, updatedAt, url }
                    if (response) {
                        const uploadedImage = {
                            id: response.id,
                            url: response.url,
                            fileName: response.fileName
                        };
                        $scope.promoImages.unshift(uploadedImage);
                    }
                };
                promoImagesUploader.onCompleteAll = function (fileItem, response, status, headers) {
                    // clear temp. gift cards.
                    $scope.tempPromoImages.splice(0, $scope.tempPromoImages.length);
                    $scope.setPromoImages();
                    $scope.uploadStatus = UploadStatus.COMPLETED;
                    $scope.isPromoImagesUploading = false;
                    settingsUpdated();
                };
                promoImagesUploader.onBeforeUploadItem = function (item) {
                    // Upload compressed blob instead of original file.
                    var tempPromoImage = $scope.tempPromoImages[(item.index - 1)];

                    item._file = tempPromoImage.compressedImage;
                    item.removeAfterUpload = true;
                };
                promoImagesUploader.onErrorItem = function (fileItem, response, status, headers) {
                    $scope.isPromoImagesUploading = false;
                    // 413: Entity too large
                    promoImagesUploader.clearQueue();
                    if (status == 413) {
                        PosAlertService.showAlertByName('file-upload-failed-large-file');
                    } else {
                        PosAlertService.showAlertByName('file-upload-failed');
                    }
                };

                $scope.removeTempPromoImage = function (index) {
                    $scope.tempPromoImages.splice(index, 1);
                };

                $scope.savePromoImages = () => {
                    if ($scope.tempPromoImages.length) {
                        $scope.isPromoImagesUploading = true;
                        promoImagesUploader.uploadAll();
                    } else if ($scope.promoImagesMoved) {
                        $scope.setPromoImages();
                        $scope.promoImagesMoved = false;
                    }
                };

                $scope.removePromoImage = (id) => {
                    ConfirmModalService.openConfirmModal(
                        'setupWizard.merchant.mobileSetup.confirmation.title',
                        'setupWizard.merchant.mobileSetup.confirmation.description',
                        undefined, // `clickAction` callback
                        undefined,
                        undefined, // `scope`
                        true // `allowCancel`
                    ).then(() => {
                        if (id) {
                            Company.deleteImage({id}).$promise.then(() => {
                                $scope.promoImages = $scope.promoImages.filter((image) => image.id != id);
                                $scope.setPromoImages();
                            });
                        } else {
                            // this will remove all images w/o IDs
                            $scope.promoImages = $scope.promoImages.filter((image) => image && image.id);
                            $scope.setPromoImages();
                        }
                    }).catch(() => {
                        return;
                    });
                };

                $scope.loadSavedMealCards = () => {
                    $scope.loadingSavedMealCards = true;
                    const fromTime = moment(new Date($scope.mealCardConfig.searchDate)).valueOf();
                    const toTime = moment(new Date($scope.mealCardConfig.searchDate)).add(1, 'days').valueOf();
                    Organization.getSavedMealCards({fromTime, toTime}, function (savedMealCards) {
                        const result = [];
                        savedMealCards.forEach((mealCard) => {
                            result.push({
                                name: mealCard.name,
                                mealCardNumber: mealCard.mealCardNumber,
                                time: moment(mealCard.createdAt).format('hh:mm a'),
                                menuPeriod: mealCard.menuPeriod,
                                servicePeriod: mealCard.servicePeriod || '',
                                isQuickCharge: !!mealCard.quickchargeSessionId,
                                notes: mealCard.notes || ''
                            });
                        });
                        $scope.savedMealCards = result;
                        $scope.loadingSavedMealCards = false;
                    }, function () {
                        $scope.loadingSavedMealCards = false;
                    });
                };

                $scope.emailSavedMealCards = () => {
                    const fromTime = moment(new Date($scope.mealCardConfig.searchDate)).valueOf();
                    const toTime = moment(new Date($scope.mealCardConfig.searchDate)).add(1, 'days').valueOf();

                    ConfirmModalService.openConfirmModal(
                        'setupWizard.merchant.fiit.confirmMealCardEmail',
                        undefined,
                        undefined, // `clickAction` callback
                        undefined,
                        undefined, // `scope`
                        true // `allowCancel`
                    ).then(function () {
                        Organization.emailSavedMealCards({fromTime, toTime}, null, null, () => {
                            PosAlertService.showAlertByName('general', {
                                message: 'general.error.sendingEmail'
                            });
                        });
                    }).catch(() => {
                        // noop
                    });
                };
            });
        },
    ]);
};
