'use strict';

const Decimal = require('decimal.js').default;
const LoyaltyRedemption = require('../../external/loyalty/redemption.js');
const AppConstants = require('../../common/freshideas/app-constants.js');

module.exports = function (freshideasSmbPos) {
    freshideasSmbPos.controller('SmbPosTenderCtrl', [
        '$scope',
        '$modal',
        '$log',
        '$q',
        'PrintService',
        'PrintType',
        'Pure',
        'ReceiptBuilderService',
        'SharedFunctionService',
        'SharedDataService',
        'SmbPosService',
        'SecondaryDisplay',
        'TRANSACTION_STATUS',
        'Locations',
        'Security',
        'CurrentSession',
        '$translate',
        'GatewayFiit',
        'GatewayAlphaPay',
        '$rootScope',
        'KioskService',
        'PosAlertService',
        'PosStatusService',
        'OfflineEmailSignupService',
        'TransactionService',
        'CompanyAttributesService',
        'UI_TENDER_TYPE',
        'PAYMENT_METHODS',
        'HelpService',
        'EnvConfig',
        'SolinkService',
        'LucovaWebSocket',
        '$timeout',
        function (
            $scope,
            $modal,
            $log,
            $q,
            PrintService,
            PrintType,
            Pure,
            ReceiptBuilderService,
            SharedFunctionService,
            SharedDataService,
            SmbPosService,
            SecondaryDisplay,
            TRANSACTION_STATUS,
            Locations,
            Security,
            CurrentSession,
            $translate,
            GatewayFiit,
            GatewayAlphaPay,
            $rootScope,
            KioskService,
            PosAlertService,
            PosStatusService,
            OfflineEmailSignupService,
            TransactionService,
            CompanyAttributesService,
            UI_TENDER_TYPE,
            PAYMENT_METHODS,
            HelpService,
            EnvConfig,
            SolinkService,
            LucovaWebSocket,
            $timeout) {

            if (KioskService.isKiosk()) {
                $scope.$on('kiosk::items-modified', function () {
                    $scope.totalAmountFromPosData = $scope.tenderAmounts.totalSales;
                });
            }

            var currentCompany = Security.getUser().company;
            $scope.logoUrl = currentCompany.logoUrl;
            $scope.giftCardsEnabled = CompanyAttributesService.hasGiftCardsEnabled();
            $scope.kioskEmailsEnabled = CompanyAttributesService.hasKioskEmailsEnabled();
            $scope.patronEmail = '';
            $scope.patrons = [];
            $scope.showPatronSignupButton = false;
            $scope.showInputForm = false;
            $scope.patronSelected = false;
            const SEARCH_DEBOUNCE = EnvConfig.searchDebounce || 500; // ms

            $scope.kdsEnabled = CompanyAttributesService.hasKDSEnabled();
            const organizationSettings = CurrentSession.getCompany().organization ? CurrentSession.getCompany().organization.settings : null;
            const loyaltyRedemptionRules = organizationSettings ? organizationSettings.loyaltyRedemptionRules : null;
            const loyaltyRedemptionPerDollar = organizationSettings ? organizationSettings.loyaltyRedemptionPerDollar : null;

            var destroyMobileFlowListener = $scope.$on('pos::tender::mobile::showNonMobileFlow', function (event, args) {
                $scope.showNonMobileFlow();
            });

            var destroyCustomerProfileListener = $rootScope.$on('customer.profile', (event, p) => {
                let tempP = {
                    fiitPatron: p,
                    lucovaUser: null
                };
                let patron = SmbPosService.transformPatron(tempP);
                $scope.patron = patron;

                if (canRedeemPoints() && !GatewayFiit.isEnabled()
                    && (!$scope.onPage($scope.PAGE.RECEIPT) || !$scope.onPage($scope.PAGE.TENDER_PENDING))) {
                    $scope.showLucovaManualTenderFlow(PAYMENT_METHODS.APP);
                }
            });

            $scope.$on('$destroy', () => {
                destroyMobileFlowListener();
                destroyCustomerProfileListener();
            });

            $scope.PAGE = {
                TENDER_OPTIONS: 'tender_options',
                TENDER_INPUT: 'tender_input',
                TENDER_PENDING: 'tender_pending',
                RECEIPT: 'receipt',
                PAYATCOUNTERRECEIPT: 'pay_at_counter_receipt'
            };
            $scope.page = $scope.PAGE.TENDER_OPTIONS;
            $scope.onPage = function (pages) {
                if (!(pages instanceof Array)) {
                    pages = [pages];
                }

                return pages.indexOf($scope.page) > -1;
            };
            var pageStack = [];

            $scope.setPage = function (page, forwards = true) {
                if (page == $scope.PAGE.RECEIPT || page == $scope.PAGE.TENDER_PENDING
                    || page == $scope.PAGE.PAYATCOUNTERRECEIPT) {
                    KioskService.disableTimeoutModal(true);
                }
                if (forwards) {
                    pageStack.push($scope.page);
                }

                $scope.page = page;
                SecondaryDisplay.updateTransaction($scope.receipt, $scope.currentOrder.balance, $scope.tenderAmounts, $scope.currentOrder.patron, $scope.page);
            };

            $scope.goBack = function () {
                if (pageStack.length > 0) {
                    $scope.setPage(pageStack.pop(), false);
                } else {
                    $scope.$dismiss();
                }
            };

            $scope.isRetry = false;
            const generateTenderTypes = function (mobile = false) {
                $scope.loadingTenders = true;
                return TransactionService.getAvailableTenderTypes(mobile, KioskService.isKiosk())
                    .then((result) => {
                        $scope.tenderTypes = result;
                        $scope.loadingTenders = false;
                    });
            };

            $scope.showNonMobileFlow = function () {
                $scope.isRetry = true;

                $scope.unselectTenderType();
                generateTenderTypes().then($scope.$parent.showNonMobileFlow);
            };

            $scope.getPatronPhoto = function (photoUrl, isCompleteUrl) {
                return SmbPosService.getPatronPhoto(photoUrl, isCompleteUrl);
            };

            $scope.isGuestTransaction = function (user) {
                return SmbPosService.isGuestTransaction(user);
            };

            var currentAdjustmentString = $scope.currentAdjustmentString = '';

            $scope.tenderType = null;

            const showOfflineAlert = () => {
                if (PosStatusService.isOffline()) {
                    let alertParams = {
                        title: 'general.error.offline.ttl',
                        message: 'general.error.offline.function.disabled'
                    };
                    PosAlertService.showAlertByName('general', alertParams, true);
                    return true;
                }
                return false;
            };

            $scope.canUseFiitGateway = true;
            // Clicking pay with credit or cash sets this tender type
            $scope.selectTenderType = function (uiTenderType, isMobilePayment = false) {
                var tenderType = TransactionService.getTenderByUiTenderType(uiTenderType, isMobilePayment);
                let queryText = '';

                switch (tenderType.value) {
                    case 'cash':
                        queryText = 'cash';
                    break;
                    case 'card':
                        queryText = 'card terminal';
                    break;
                    case 'giftcard':
                        queryText = 'card terminal';
                    break;
                    case 'manualMobile':
                        queryText = 'card terminal';
                    break;
                    case 'payAtCounter':
                        queryText = 'pay at counter';
                    break;
                    default:
                        queryText = 'transaction';
                }

                HelpService.setQueryText(queryText);

                if (!tenderType) {
                    PosAlertService.showAlertByName('general-error', {
                        title: 'smb.pos.error.invalid.tender.type'
                    });
                    return;
                }

                if (KioskService.isKiosk()) {
                    const amountToCharge = $scope.tenderData.isSplit ? $scope.tenderAmounts.remainingBalance : $scope.totalAmountFromPosData;
                    KioskService.setTotalAmountToCharge(amountToCharge);
                    KioskService.setTotalAmountToCharge($scope.totalAmountFromPosData);

                    if (!(tenderType.value === UI_TENDER_TYPE.PAYATCOUNTER)) {
                        // We don't need to set Transaction status for "Pay At Counter" so as to keep same workflow as held orders
                        LucovaWebSocket.setTransactionStatus(TRANSACTION_STATUS.INITIAL);
                    }
                }

                /**
                *** Commented By Akash Mehta on August 4 2020
                *** We are adding this check to avoid negative balances or negative total
                *** to go through. RF once reported that a negative total LOYALTY transaction had
                *** occured. Since we were unable to recreate it, we are just adding a check to avoid
                *** transactions with a negative subtotal or total from being completed.
                ***/
                if ($scope.tenderAmounts.subtotal < 0 || $scope.tenderAmounts.totalSales < 0) {
                    var alertParams = {
                        title: 'smb.pos.negative.total.title',
                        message: 'smb.pos.negative.total.description'
                    };
                    PosAlertService.showAlertByName('general', alertParams);
                    return;
                }

                let toShowTenderInputs = true;

                switch (tenderType.value) {
                    case 'giftcard':
                        if (showOfflineAlert()) return;
                        $scope.showLucovaManualTenderFlow(PAYMENT_METHODS.CARD, '', tenderType);
                    break;
                    case 'manualMobile':
                        if (showOfflineAlert()) return;
                        $scope.showLucovaManualTenderFlow(PAYMENT_METHODS.APP, '', tenderType);
                    break;
                    case 'gateway':
                        if (showOfflineAlert()) return;
                        if (GatewayFiit.isEnabled() && !$scope.canUseFiitGateway) {
                            let alertParams = {
                                title: 'Cannot use Meal Plans',
                                message: 'smb.lucova.tender.alert.taxableTenderUsed'
                            };
                            PosAlertService.showAlertByName('general', alertParams, true);
                            break;
                        }

                        $scope.canUseFiitGateway = false;
                        $scope.showLucovaManualTenderFlow(PAYMENT_METHODS.APP, '', tenderType);
                    break;
                    case 'mobile':
                        $scope.lastActiveTender = undefined;
                        $scope.currentTender = TransactionService.selectTender(tenderType);
                        $scope.tender();
                    break;
                    case 'payAtCounter':
                        if (!KioskService.isKiosk()) {
                            break;
                        }

                        suspendForPayAtCounter()
                        .then(() => {
                            $scope.setPage($scope.PAGE.PAYATCOUNTERRECEIPT);
                        });

                    break;
                    default: // cash/card
                        $scope.lastActiveTender = undefined;
                        $scope.currentTender = TransactionService.selectTender(tenderType);
                        $scope.tenderType = tenderType;
                        currentAdjustmentString = '';

                        if ($scope.tenderData.isSplit) {
                            toShowTenderInputs = processSplitTender();
                        } else {
                            toShowTenderInputs = processSingleTender();
                        }

                        if (toShowTenderInputs) {
                            if ($scope.tenderType) {
                                $scope.setPage($scope.PAGE.TENDER_INPUT);

                                if (tenderType.value === 'other') {
                                    $scope.showOtherTenderLabelOptions();
                                }
                            } else {
                                $scope.setPage($scope.PAGE.TENDER_OPTIONS);
                            }
                        } else { // don't show tender options. proceed to tender here
                            $scope.tender();
                        }
                }

            };

            var printSuspendedBill = function (suspendedTransaction) {
                var response = {
                    stationName: SmbPosService.shift.posStation.name || '(POS Station)',
                    locationName: SmbPosService.shift.location.name || '(Location)',
                    location: SmbPosService.shift.location.address,
                    cashierName: '',
                    companyName: SharedDataService.company.companyName || '(Company)',
                    patronName: (suspendedTransaction.guestTransaction && suspendedTransaction.receiptCounter) ? 'Order Number: ' + suspendedTransaction.orderName
                        : 'Order Name: ' + suspendedTransaction.orderName,
                    transactionDateTime: Date.now(),
                    transactionSubTotal: $scope.$parent.posData.currentOrderBalance.subTotalAmount,
                    transactionDeliveryFee: $scope.$parent.tenderAmounts.deliveryFee,
                    transactionTax: $scope.$parent.tenderAmounts.totalTaxes,
                    transactionTotal: $scope.$parent.tenderAmounts.totalSales,
                    cashAmount: 0,
                    cashRounding: 0,
                    creditCardAmount: 0,
                    debitCardAmount: 0,
                    otherAmount: 0,
                    change: 0,
                    mealEqAmount: 0,
                    tipAmount: 0,
                    remainingBalance: $scope.$parent.tenderAmounts.remainingBalance,
                    dcbAmount: 0,
                    icbAmount: 0,
                    mealPlansRedeemed: 0,
                    mealPlanIds: undefined,
                    dcbMealPlanIds: undefined,
                    loyaltyAmount: 0,
                    dollarDiscountAmount: $scope.$parent.tenderAmounts.dollarDiscountAmount,
                    percentDiscountAmount: $scope.$parent.tenderAmounts.percentDiscountAmount,
                    percentDiscount: $scope.$parent.tenderAmounts.percentDiscount,
                    posPrinters: SmbPosService.shift.posStation.posPrinters,
                    giftCardPurchases: [],
                    giftCardUsage: []
                };
                response.heldOrder = {
                    suspendId: suspendedTransaction.suspendId,
                    orderName: suspendedTransaction.orderName,
                    orderEmail: suspendedTransaction.orderEmail,
                    orderPhoneNumber: suspendedTransaction.orderPhoneNumber,
                    isPayAtCounter: suspendedTransaction.isPayAtCounter
                };

                var receipt = $scope.receipt;
                var cardReceiptArr = [];
                var skipCashDrawer = true;
                var printType = PrintType.CUSTOMER;

                PrintService.printReceipt(response, receipt, cardReceiptArr, skipCashDrawer, printType);
            };

            var generateReceiptCounter = function () {
                var transaction = {};
                SmbPosService.populateReceiptCounter(transaction);
                return transaction.receiptCounter;
            };

            const setItemAndChildrenAsPrinted = (item) => {
                item.quantityPrintedToKitchen = item.quantity;
                item.updatedSinceLastKitchenPrint = false;
                item.createdSinceLastKitchenPrint = false;

                // We need to recursively update all modifiers attached to this
                // item as children

                if (!item.children) {
                    return;
                }

                item.children.forEach((item) => {
                    setItemAndChildrenAsPrinted(item);
                });
            };

            $scope.unselectTenderType = function () {
                // these changes are related to the Nown UI and being used to determine the data/page on the
                // tender popup that should be shown
                $scope.currentTender = undefined;
                $scope.tenderType = null;
                $scope.setPage($scope.PAGE.TENDER_OPTIONS);
                HelpService.setQueryText('transaction');

                // these changes are related to the underlying balance calculation
                $scope.adjustments.useCash = false;
                $scope.$parent.removeCurrentTender();

                /** Commented by Akash Mehta
                **  We are adding this piece of code to reset and make the meal card
                **  payment available if no other taxable tender has been used.
                **/
                var anyTenderUsed = false;
                if ($scope.tenderData) {
                    _.each($scope.tenderData.allTenders, function (tender) {
                        if (GatewayFiit.isEnabled()) {
                            anyTenderUsed = true;
                        }
                    });
                }

                $scope.canUseFiitGateway = !anyTenderUsed;
            };

            $scope.otherTenderLabels = [];
            var refreshOtherTenderLabels = function () {
                $scope.otherTenderLabels.length = 0;

                getCustomOtherTenderLabels();
                getIntegratedOtherTenderLabels();
            };
            var getCustomOtherTenderLabels = function () {
                let labelString = CurrentSession.getCompany().attributes.other__other_tender_labels;
                let labels = (labelString) ? labelString.split(',') : [];

                for (let label of labels) {
                    let option = {
                        name: label,
                        displayName: label,
                        selected: false
                    };

                    $scope.otherTenderLabels.push(option);
                }
            };
            var getIntegratedOtherTenderLabels = function () {
                if (GatewayAlphaPay.isEnabled()) {
                    let option = {
                        name: 'alphapay',
                        displayName: $translate.instant('smb.pos.tender.alphapay.label'),
                        selected: false
                    };

                    $scope.otherTenderLabels.push(option);
                }
            };

            $scope.showOtherTenderLabelOptions = function () {
                if (canShowOtherTenderLabelOptions($scope.currentTender)) {
                    refreshOtherTenderLabels();

                    if ($scope.otherTenderLabels && $scope.otherTenderLabels.length) {
                        var defaultOtherTypeLabel = $translate.instant('smb.pos.tenderType.other');

                        if (!$scope.currentTender.transactionTenderDetail || !$scope.currentTender.transactionTenderDetail.otherType) {
                            $scope.currentTender.transactionTenderDetail = {
                                otherType: defaultOtherTypeLabel,
                                otherDisplayName: defaultOtherTypeLabel
                            };
                        }

                        for (var label of $scope.otherTenderLabels) {
                            if ($scope.currentTender.transactionTenderDetail
                                && $scope.currentTender.transactionTenderDetail.otherType === label.name) {
                                label.selected = true;
                            }
                        }

                        if ($scope.otherTenderLabels.length === 1) {
                            $scope.currentTender.transactionTenderDetail = {
                                otherType: $scope.otherTenderLabels[0].name,
                                otherDisplayName: $scope.otherTenderLabels[0].displayName
                            };
                        } else if ($scope.otherTenderLabels.length > 1) {
                            var modalInstance = $modal.open({
                                templateUrl: 'common/modals/modalGenericList.html',
                                controller: 'GenericListController',
                                resolve: {
                                    title: function () {
                                        return 'smb.pos.tenderType.other.selectLabel.description';
                                    },
                                    items: function () {
                                        return $scope.otherTenderLabels;
                                    },
                                    itemDisplayField: function () {
                                        return 'displayName';
                                    }
                                },
                                windowClass: 'modal-autowidth',
                                backdrop: 'static'
                            });

                            modalInstance.result.then(function (selectedOption) {
                                $scope.currentTender.transactionTenderDetail = {
                                    otherType: selectedOption.name,
                                    otherDisplayName: selectedOption.displayName
                                };
                            });
                        }
                    }
                }
            };
            var canShowOtherTenderLabelOptions = function (tender) {
                if (tender && tender.type === 'other') {
                    return true;
                } else {
                    return false;
                }
            };

            var processSingleTender = function () {
                let totalAmountToCharge = (KioskService.isKiosk()) ? KioskService.totalAmountToCharge() : $scope.totalAmountFromPosData;
                let toShowTenderInputs = true;

                if ($scope.tenderType.value === 'card') {
                    $scope.adjustmentOptions = [];

                    $scope.setAdjustment(totalAmountToCharge);
                    $scope.adjustments.useCash = false;
                    $scope.balanceChange($scope.adjustments);

                    toShowTenderInputs = false;
                } else if ($scope.tenderType.value === 'cash') {
                    // This is to tell the balance algorithm that this transaction
                    // will be cash only (0.01 for testing) and to calculate
                    // cash rounding amount, which will be needed to calculate
                    // the correct default tender amount options in the UI
                    triggerCashTender();

                    var outstandingBalance = $scope.totalAmountFromPosData - $scope.tenderAmounts.cashRounding;
                    $scope.adjustmentOptions = calculateAdjustmentOptions(outstandingBalance);
                    $scope.setAdjustment($scope.adjustmentOptions[0]);

                    if (outstandingBalance <= 0) {
                        $scope.tender();
                    }
                } else if ($scope.tenderType.value === 'other' || $scope.tenderType.value === 'gateway') {
                    // $scope.adjustmentOptions = calculateAdjustmentOptions(outstandingBalance);
                    $scope.adjustmentOptions = [$scope.totalAmountFromPosData];
                    $scope.setAdjustment($scope.totalAmountFromPosData);

                    $scope.adjustments.useCash = false;
                    $scope.balanceChange($scope.adjustments);
                } else if ($scope.tenderType.value === 'giftcard') {
                    $scope.adjustmentOptions = [];
                    $scope.setAdjustment($scope.totalAmountFromPosData);

                    $scope.adjustments.useCash = false;
                    $scope.balanceChange($scope.adjustments);
                }

                return toShowTenderInputs;
            };

            /**
            ** Comment By Akash Mehta
            ** The original workflow of this function for a FIIT Meal tender is that whenever this function
            ** is called, we call the setAdjustment function and pass it the outstanding balance which the setAdjustment function sets
            ** against the current tender. However, there is a change in the FIIT workflow. Instead of passing the
            ** outstanding balance, we need to pass the balance approved from the FIIT backend. Since all FIIT meal plan transactions
            ** are executed automatically after being approved, there is no screen the change the amount set against the current tender.
            ** Hence the new parameter, 'requestedAmount'. We pass the amount approved by the FIIT backend to the setAdjustment function
            ** and the other components of the Split Tender workflow remain the same.
            **/
            var processSplitTender = function (initialAdjustment = 0, requestedAmount) {
                let toShowTenderInputs = true;
                const amountToCharge = requestedAmount || initialAdjustment || $scope.tenderAmounts.remainingBalance;

                if ($scope.tenderType.value === 'card') {
                    $scope.adjustmentOptions = [];
                    $scope.setAdjustment(amountToCharge);

                    $scope.adjustments.useCash = false;
                    $scope.balanceChange($scope.adjustments);

                    if (KioskService.isKiosk()) {
                        toShowTenderInputs = false;
                    }
                } else if ($scope.tenderType.value === 'cash') {
                    triggerCashTender();

                    var outstandingBalance = Math.max($scope.tenderAmounts.remainingBalance, 0);
                    $scope.adjustmentOptions = calculateAdjustmentOptions(outstandingBalance);
                    $scope.setAdjustment($scope.adjustmentOptions[0]);
                } else if (['other', 'gateway', 'giftcard'].includes($scope.tenderType.value)) {
                    $scope.adjustmentOptions = [$scope.tenderAmounts.remainingBalance];
                    $scope.setAdjustment(amountToCharge);

                    $scope.adjustments.useCash = false;
                    $scope.balanceChange($scope.adjustments);
                }

                return toShowTenderInputs;
            };

            var setSplitTender = function (value) {
                $scope.tenderData.isSplit = !!value;
            };

            $scope.canEditPatron = false;
            var modalInstance;
            var lucovaTenderAdjustmentResult = {
                usePoints: false,
                mobilePay: false,
                useGiftCard: false
            };
            var availableBackup;
            var patronBackup;

            var canRedeemPoints = function () {
                var patron = ($scope.patron && !$scope.patron.guest) ? $scope.patron : undefined;
                var outstandingBalance = LoyaltyRedemption.calculateRequiredLoyaltyValue($scope.receipt, $scope.adjustments);

                return (!GatewayFiit.isEnabled() && patron && patron.loyalty
                    && LoyaltyRedemption.getPointsRedeemable(outstandingBalance, patron.loyalty.points, loyaltyRedemptionRules, loyaltyRedemptionPerDollar) > 0
                    && (lucovaTenderAdjustmentResult.usePoints || (!lucovaTenderAdjustmentResult.usePoints && !$scope.adjustments.dollarDiscount && !$scope.adjustments.percentDiscount)));
            };
            $scope.canRedeemPoints = canRedeemPoints;

            // paymentMethod should be renamed in the future
            $scope.showLucovaManualTenderFlow = async function (paymentMethod, scannedQR = '', tenderType) {
                if (modalInstance || !$scope.onPage($scope.PAGE.TENDER_OPTIONS)) {
                    return;
                }
                var activeTemplateUrl;
                var activeWindowClass;
                if (KioskService.isKiosk() && tenderType && tenderType.value != 'gateway') {
                    activeTemplateUrl = 'pos/smb/templates/kiosk.pos.tender.lucova.tpl.html';
                    activeWindowClass = 'kiosk__lucova-tender-modal';
                } else if (tenderType && tenderType.value === 'gateway') {
                    activeTemplateUrl = 'pos/smb/templates/pos.patron.tender.modal.tpl.html';
                    activeWindowClass = 'smb-pos smb-pos__lucova-tender-modal-new';
                } else {
                    activeTemplateUrl = 'pos/smb/templates/pos.tender.lucova.tpl.html';
                    activeWindowClass = 'smb-pos smb-pos__lucova-tender-modal';
                }
                modalInstance = $modal.open({
                    templateUrl: activeTemplateUrl,
                    controller: 'SmbPosTenderLucovaCtrl',
                    windowClass: activeWindowClass,
                    animation: false,
                    backdrop: 'static',
                    resolve: {
                        parentAdjustments: function () {
                            return $scope.adjustments;
                        },
                        adjustments: function () {
                            return lucovaTenderAdjustmentResult;
                        },
                        qrCode: function () {
                            return scannedQR;
                        },
                        patron: function () {
                            return $scope.patron;
                        },
                        giftCard: function () {
                            return $scope.giftCard;
                        },
                        outstandingBalance: function () {
                            return LoyaltyRedemption.calculateRequiredLoyaltyValue($scope.receipt, $scope.adjustments);
                        },
                        selectedPaymentMethod: function () {
                            return paymentMethod;
                        },
                        splitTender: function () {
                            return $scope.tenderData.isSplit;
                        },
                        fiitMpsUtil: function () {
                            return {
                                process: processLucovaManualTenderFlow,
                                unprocess: $scope.unselectTenderType,
                                fetchTenderAmounts: function () {
                                    return $scope.tenderAmounts;
                                },
                                fetchCurrentTender: function () {
                                    return $scope.currentTender;
                                },
                                TenderUtil: $scope.TenderUtil,
                                setSplitTender: setSplitTender
                            };
                        },
                        tenderType: function () {
                            return tenderType;
                        }
                    }
                });
                modalInstance.result.then(function (result) {
                    modalInstance = undefined;
                    if (result) {
                        var toResetCurrentTender = true;
                        if (result.fiitMps && result.fiitMps.resetCurrentTender != undefined) {
                            toResetCurrentTender = result.fiitMps.resetCurrentTender;
                        }
                        processLucovaManualTenderFlow(result, toResetCurrentTender);
                    }
                }, function (payload) {
                    modalInstance = undefined;

                    $scope.unselectTenderType();
                    if (payload && payload.savedMealCard) {
                        $scope.tryExit(true);
                    }
                });
            };

            /**
            ** Comment By Akash Mehta
            ** The original workflow of this function is that whenever this function
            ** is called, we reset the currentTender and set it amount to 0.
            ** I have added a new parameter called - resetCurrentTender
            ** We call the below function on clostng the FIIT Patron modal.
            ** What happens is that we reset the selected tender in the modal to 0 (which includes
            ** the approved amount from FIIT backend). We do not want to loose the information already
            ** set in the modal. Hence this new flag helps us. If the modal sends back toResetTender attribute
            ** we use that value or else always reset the current tender, thus minimizing the flow change
            **
            **/
            var processLucovaManualTenderFlow = function (result, resetCurrentTender = true) {
                var adjustments = result.adjustments;
                $scope.patronAdjustments = result.adjustments;
                var patron = result.patron;
                var giftCard = result.giftCard;
                lucovaTenderAdjustmentResult = adjustments;

                $scope.$parent.available.length = 0;

                if (!patronBackup) {
                    patronBackup = $scope.patron; // Take snapshot of patron we started with
                }
                if (patron) {
                    $scope.canEditPatron = true;
                    // $scope.patron = patron;
                    $scope.setPatron(patron);
                    $scope.posData.isLucovaUser = true;
                    // Patron with loyalty card
                    if (patron.fiitPatron) {
                        if (!availableBackup) {
                            availableBackup = angular.copy($scope.available); // Take snapshot of available
                        }
                        if (adjustments.usePoints) {
                            Array.isArray($scope.available) ? $scope.available.push(...patron.fiitPatron.mealPlans) : ($scope.available = patron.fiitPatron.mealPlans);
                        } else {
                            $scope.available = availableBackup;
                        }
                        $scope.useLoyalty = adjustments.usePoints;
                        $scope.adjustmentOptions = calculateAdjustmentOptions($scope.tenderAmounts.remainingBalance);
                        updateLoyaltyConfiguraiton();
                        runTransactionAutomatically();
                    }
                    // Pay with app. This will also take care of deducting from any giftcard on app
                    if (adjustments.mobilePay && patron.lucovaUser) {
                        $scope.currentOrder.patron = patron;
                        generateTenderTypes(true).then(() => {
                            $scope.updateLucovaPatron(patron.lucovaUser);
                            runTransactionAutomatically();
                        });
                        return;
                    } else {
                        $scope.currentOrder.patron = patronBackup;
                        generateTenderTypes().then(() => {
                            if (adjustments.useFiitMps) {
                                // mark as gateway payment
                                // set tender type to the appropriate type ("other")
                                // set tender label to the approrpaite value ("fiitmps")

                                // Commented By Akash Mehta on 19 th May 2020
                                // The objects fiitMpsTenderType && $scope.currentTender are duplicated
                                // on the calculation server. The tender workflow is tightly coupled with these objects.
                                // If any changes are made to any of these objects (For FIIT MPS), please make the necessary
                                // changes on the calcultion server as well.
                                var fiitMpsTenderType = {
                                    value: 'gateway',
                                    name: 'Meal Card',
                                    adjustment: 'otherAdjustment',
                                    description: 'smb.pos.tenderType.gateway'
                                };

                                $scope.lastActiveTender = undefined;

                                if (resetCurrentTender) {
                                    $scope.currentTender = {
                                        type: fiitMpsTenderType.value,
                                        field: fiitMpsTenderType.adjustment,
                                        amount: 0,
                                        uuid: Pure.generateUuid(),
                                        transactionTenderDetail: {
                                            otherType: 'fiitmps',
                                            otherDisplayName: 'FIIT Meal Card'
                                        }
                                    };
                                }

                                $scope.tenderType = fiitMpsTenderType;
                                currentAdjustmentString = '';
                                var fiitMpsOutstandingBalance = $scope.tenderAmounts.remainingBalance;
                                var initialAdjustment = 0;

                                if ($scope.patron.fiitMpsAccount) {
                                    $scope.tenderData.isSplit = true;

                                    var mealPlanSummary = $scope.patron.fiitMpsAccount.mealPlanSummary || {};
                                    var availableDcbCents = mealPlanSummary.amountCents || 0;
                                    var availableDollars = new Decimal(availableDcbCents)
                                        .dividedBy(100)
                                        .toNearest(SharedDataService.baseDollar)
                                        .toNumber();

                                    initialAdjustment = Math.min(fiitMpsOutstandingBalance, availableDollars);
                                }

                                // TODO: auto split?

                                if ($scope.tenderData.isSplit) {
                                    /** Commented By Akash Mehta
                                    ** We want to pass the tender amount approved by FIIT only
                                    ** when the modal returns a success. Hence we check for the same
                                    ** parameter (resetCurrentTender) that the FIIT Patron modal returns to not override &
                                    ** not reset the selected current Tender.
                                    **/
                                    if (!resetCurrentTender) {
                                        processSplitTender(initialAdjustment, $scope.currentTender.amount);
                                    } else {
                                        processSplitTender(initialAdjustment);
                                    }
                                } else {
                                    processSingleTender();
                                }

                                if ($scope.patron.fiitMpsAccount) {
                                    var fiitMpsResult = result.fiitMps || {};
                                    $scope.currentTender.skipTenderInput = fiitMpsResult.skipTenderInput;
                                    $scope.currentTender.useMealEquivalency = fiitMpsResult.useMealEquivalency;
                                }

                                if ($scope.patron.fiitMpsAccount && $scope.currentTender.skipTenderInput) {
                                    $scope.tender();
                                }
                            }
                        });
                    }
                } else {
                    $scope.canEditPatron = false;
                    // $scope.patron = patronBackup;
                    $scope.setPatron(patronBackup);
                    $scope.posData.isLucovaUser = false;
                }
                $scope.giftCard = giftCard;
                if (giftCard) { // apply gift card manually
                    if (adjustments.useGiftCard) {
                        var tenderType = {
                            value: 'giftcard',
                            name: 'smb.pos.tenderType.giftCard',
                            adjustment: 'giftCardAdjustment',
                            description: 'smb.pos.tenderType.giftCard'
                        };

                        $scope.lastActiveTender = undefined;
                        $scope.currentTender = {
                            type: tenderType.value,
                            field: tenderType.adjustment,
                            amount: 0,
                            uuid: Pure.generateUuid()
                        };

                        $scope.tenderType = tenderType;
                        currentAdjustmentString = '';
                        var outstandingBalance = $scope.totalAmountFromPosData - $scope.tenderAmounts.cashRounding;
                        var autoSplit = outstandingBalance > (giftCard.currentBalanceCents / 100);
                        if (autoSplit) {
                            $scope.tenderData.isSplit = true;
                        }
                        applyGiftCard(giftCard);
                        if ($scope.tenderData.isSplit || autoSplit) {
                            processSplitTender();
                        } else {
                            processSingleTender();
                        }

                        // Automatically tender if kiosk. We can't allow customers to do manual payment inputs.
                        if (KioskService.isKiosk()) {
                            $scope.tender();
                        } else {
                            $scope.setPage($scope.PAGE.TENDER_INPUT);
                        }
                    }
                } else {
                    $scope.giftCard = undefined;
                }
            };

            var applyGiftCard = function (giftCard) {
                $scope.currentTender.giftCardId = giftCard.id;
                $scope.currentTender.giftCard = giftCard;

                var giftCardAdjustment = Math.min(
                    $scope.tenderAmounts.remainingBalance,
                    $scope.currentTender.giftCard.currentBalanceCents / 100.0
                );
                $scope.setAdjustment(giftCardAdjustment);
            };

            $scope.useLoyalty = false;
            $scope.toggleUseLoyalty = function () {
                $scope.useLoyalty = !$scope.useLoyalty;
            };

            $scope.applyTransactionDollarDiscount = function () {
                $scope.balanceChange($scope.adjustments);
            };

            $scope.setAdjustment = function (adjustment, clearCurrentAdjustment) {
                if ($scope.tenderType) {
                    if (clearCurrentAdjustment) {
                        currentAdjustmentString = '';
                    }

                    var amount;

                    if (typeof adjustment == 'string') {
                        let adjustmentNumber = parseFloat(adjustment); // either a valid number, or NaN, which will be taken care of below this if block
                        amount = new Decimal(adjustmentNumber)
                            .times(new Decimal(SharedDataService.baseDollar))
                            .toNumber();
                    } else {
                        amount = adjustment;
                    }

                    if (isNaN(amount)) {
                        amount = 0; // convert NaN to 0
                    }

                    var roundedAmount = new Decimal(amount).toNearest(SharedDataService.baseDollar).toNumber();
                    $scope.currentTender.amount = roundedAmount;
                }
            };

            $scope.addAdjustment = function (adjustmentString) {
                if ($scope.tenderType) {
                    var newAdjustmentString = currentAdjustmentString + adjustmentString;
                    currentAdjustmentString = newAdjustmentString;

                    $scope.setAdjustment(newAdjustmentString);
                }
            };
            $scope.backspaceAdjustment = function () {
                if ($scope.tenderType) {

                    currentAdjustmentString = currentAdjustmentString.substring(0,
                        currentAdjustmentString.length - 1);

                    $scope.setAdjustment(currentAdjustmentString);
                }
            };

            $scope.clearAdjustment = function () {
                if ($scope.tenderType) {
                    currentAdjustmentString = '';

                    $scope.setAdjustment();
                }
            };

            var triggerCashTender = function () {
                $scope.adjustments.useCash = true; // cashAdjustment = 0.01;
                $scope.balanceChange($scope.adjustments);
            };

            $scope.getPaidWithAppLabel = function () {
                return CurrentSession.getOrganizationAppName();
            };

            var calculateAdjustmentOptions = function (tenderValue) {
                var tenderCents = Math.round(tenderValue * 100);

                var adjustmentRounding = TransactionService.getAdjustmentRounding();
                var options = _.map(adjustmentRounding, function (rounding) {
                    rounding = rounding * SharedDataService.baseCent;
                    var roundedValue = Math.ceil(tenderCents / rounding) * rounding;
                    return roundedValue / 100.0;
                });
                // Remove duplicates when the tender value is an exact nickel/dollar
                options = _.chain(options).uniq().sortBy().value();

                return options.slice(0, 6);
            };

            $scope.tender = function () {
                if ($scope.$parent.tendering) {
                    return;
                }

                if ($scope.currentTender && $scope.currentTender.field) {
                    $scope.currentTender.tipAmount = $scope.tenderAmounts.tipAmount;
                    $scope.addToCurrentTender($scope.currentTender);
                }

                if ($scope.currentTender && $scope.currentTender.type === 'giftcard') {
                    $scope.tenderData.isSplit = true;
                }

                if ($scope.tenderData.isSplit) {
                    initializeSplitTenderTransaction();
                } else {
                    tenderWholeTransaction();
                }
            };

            const suspendForPayAtCounter = () => {
                const extraDataObj = {
                    adjustments: $scope.$parent.adjustments
                };

                const transactionObject = {
                    locationId: SmbPosService.shift.locationId,
                    receiptJson: JSON.stringify($scope.$parent.fullReceipt),
                    receiptNumItems: _.filter($scope.receipt, {level: 0}).length,
                    receiptTotalCostCents: $scope.$parent.transactionAmount * 100,
                    orderEmail: '',
                    orderPhoneNumber: '',
                    messages: [],
                    extraData: JSON.stringify(extraDataObj),
                    isPayAtCounter: true,
                };

                const receiptCounter = generateReceiptCounter();
                transactionObject.receiptCounter = receiptCounter;

                if ($scope.isGuestTransaction) {
                    // Guest Transaction
                    transactionObject.orderName = receiptCounter;
                    transactionObject.guestTransaction = true;
                } else {
                    // Lucova User Transaction, stored in Lucova Fiit backend
                    transactionObject.guestTransaction = false;

                    if ($scope.currentOrder.patron && $scope.currentOrder.patron.lucovaUser) {
                        transactionObject.patronPhotoUrl = $scope.currentOrder.patron.lucovaUser.photo_url;
                    }

                    // Sometimes patronKey and patronId do not exist
                    // This may occur if the Lucova user has not been registered on the Fiit backend yet
                    if ($scope.currentOrder.patron.fiitPatron
                        && $scope.currentOrder.patron.fiitPatron.patronKey) {
                        transactionObject.patronKey = $scope.currentOrder.patron.fiitPatron.patronKey;
                    }

                    if ($scope.currentOrder.patron.fiitPatron
                        && $scope.currentOrder.patron.fiitPatron.patronId) {
                        transactionObject.patronId = $scope.currentOrder.patron.fiitPatron.patronId;
                    }

                    transactionObject.orderName = $scope.currentOrder.patron.fullName;
                }

                return Locations.addSuspend(transactionObject).$promise
                .then(function (response) {
                    transactionObject.suspendId = response.suspendId;
                    transactionObject.serviceMode = AppConstants.serviceModes.KIOSK;

                    if ($scope.kdsEnabled && CompanyAttributesService.hasTicketPrePrint()) {
                        $scope.$parent.sendToKdsAndBackend(transactionObject, receiptCounter, true);
                    }

                    $scope.kioskReceiptCounter = receiptCounter;
                    printSuspendedBill(transactionObject);

                    if (!CompanyAttributesService.hasTicketPrePrint()) {
                        SmbPosService.printKitchenReceipts($scope.receipt, transactionObject,
                            {successCallback: function () {
                                $scope.$parent.fullReceipt.forEach((item) => {
                                setItemAndChildrenAsPrinted(item);
                            });
                            transactionObject.receiptJson = JSON.stringify($scope.$parent.fullReceipt);
                            Locations.updateSuspend(transactionObject);
                        },
                        orderName: transactionObject.orderName});
                    } else {
                        $scope.$parent.fullReceipt.forEach((item) => {
                            setItemAndChildrenAsPrinted(item);
                        });
                        transactionObject.receiptJson = JSON.stringify($scope.$parent.fullReceipt);
                        Locations.updateSuspend(transactionObject);
                    }

                    try {
                        const transactionUuid = $scope.$parent.adjustmentstransactionUuid;
                        const heldOrderStartTime = $scope.$parent.adjustments.transactionStartTime;
                        SolinkService.sendHeldOrder(transactionUuid, heldOrderStartTime, $scope.$parent.fullReceipt, $scope.$parent.solinkCancelledItems);
                    } catch (err) {
                        $log.error('Failed to send event to Solink', err);
                    }
                });
            };

            // This is to track the last split tender made so that the TENDER_OPTIONS
            // page can indicate the tender was just last processed. This is reset when
            // the screen moves away fron TENDER_OPTIONS (eg. when selecting a tender type)
            $scope.lastActiveTender = undefined;

            var initializeSplitTenderTransaction = function () {
                var lucovaUser = $scope.currentOrder.patron.lucovaUser || $scope.patron.lucovaUser || {};
                if (lucovaUser.user_name && lucovaUser.email) {
                    var lucovaUserObj = {
                        username: lucovaUser.user_name,
                        email: lucovaUser.email,
                        firstName: lucovaUser.first_name || lucovaUser.full_name || '',
                        lastName: lucovaUser.last_name || ''
                    };
                    $scope.tenderAmounts.lucovaUser = lucovaUserObj;
                }

                $scope.setPage($scope.PAGE.TENDER_PENDING);
                processTender().then(function (response) {
                    if (response
                        && response.initializeOnly
                        && response.promise) {

                        response.promise.then(function (response) {
                            var hasBalance = response.hasBalance;

                            if (hasBalance) {
                                $scope.setPage($scope.PAGE.TENDER_OPTIONS);

                                $scope.$parent.tendering = false;
                                $scope.$parent.tenderPage = TRANSACTION_STATUS.INITIAL;

                                $scope.lastActiveTender = $scope.currentTender;
                                $scope.currentTender = undefined;
                            }
                        });
                    }
                });
            };
            var processTender = function () {
                return $scope.getLocationPrinters().then(async function (printerResponse) {
                    return $scope.TenderUtil.beginTransactionWorkflow($scope.tenderAmounts, printerResponse);
                }, function (error) {
                    if (error) {
                        $log.error(error);
                    } else {
                        $log.error('processTender: Unknown error occurred');
                    }
                    $scope.setTendering(false);

                    if ($scope.tenderPage === TRANSACTION_STATUS.PENDING) {
                        $scope.setTenderPage(TRANSACTION_STATUS.CANCELLED);
                    }
                });
            };

            $scope.cancelTerminalTransaction = function () {
                $scope.tenderData.currentTender.isCancellableFromPos = false;

                var transactionUuid = $scope.transactionUuid;
                $scope.tenderData.currentTender.cancelFromPos({transactionUuid: transactionUuid}).then(function (success) {
                    $scope.setTenderPage(TRANSACTION_STATUS.CANCELLED);
                }, function (error) {
                    // failed to cancel because it's too late to cancel. Let the terminal process the transaction
                    // and eventually update to COMPLETED
                });
            };

            var tenderWholeTransaction = function () {
                if (!$scope.$parent.tendering) {
                    var currentOrderUser = $scope.currentOrder.patron.lucovaUser;
                    var patron = $scope.patron ? $scope.patron : {};
                    var lucovaUser = currentOrderUser || patron.lucovaUser || {};
                    if (lucovaUser.user_name && lucovaUser.email) {
                        var lucovaUserObj = {
                            username: lucovaUser.user_name,
                            email: lucovaUser.email,
                            firstName: lucovaUser.first_name || '',
                            lastName: lucovaUser.last_name || ''
                        };
                        $scope.tenderAmounts.lucovaUser = lucovaUserObj;
                    }

                    $scope.setPage($scope.PAGE.TENDER_PENDING);


                    // Flow when tendering the whole, non-Lucova transaction
                    // - tenderReceipt
                    // - TenderUtil.captureCreditDebitTransaction (capture any credit card tender through terminal or manual workflow)
                    // - shouldInitializeOrCreateTransaction (whether to send transaction as split or not)
                    //   - if split:
                    //     - TenderUtil.createSplitTenderTransaction (should return true/false indicating whether there is any balance)
                    //   - if split:
                    //     - TenderUtil.createTransaction (ensures locationId is populated in the transaction)
                    //     - TenderUtil.processTransaction (not really doing anything atm - just passing along the info)
                    //     - TenderUtil.sendTransaction (returns false to indicate no balance - not needed but just to keep it consistent with createSplitTenderTransaction)
                    var tenderReceiptPromise;
                    if ($scope.currentTender && $scope.currentTender.type == 'card') {
                        tenderReceiptPromise = $scope.$parent.tenderReceipt(true, PrintType.MERCHANT);
                    } else {
                        tenderReceiptPromise = $scope.$parent.tenderReceipt(); // from `PosCtrl`
                    }

                    // At the moment $scope.tenderReceipt returns a promise only when it is
                    if (tenderReceiptPromise && tenderReceiptPromise.then) {
                        tenderReceiptPromise.then(function (response) {
                            if (response
                                && response.initializeOnly
                                && response.promise) {

                                response.promise.then(function (hasBalance) {
                                    if (hasBalance) {
                                        $scope.setPage($scope.PAGE.TENDER_OPTIONS);

                                        $scope.$parent.tendering = false;
                                        $scope.$parent.tenderPage = TRANSACTION_STATUS.INITIAL;

                                        $scope.lastActiveTender = $scope.currentTender;
                                        $scope.currentTender = undefined;
                                    }
                                });
                            }
                        });
                    }
                }
            };

            $scope.retryTransaction = function () {
                $scope.setPage($scope.PAGE.TENDER_OPTIONS);
                KioskService.disableTimeoutModal(false);
                $scope.$parent.tendering = false;
                $scope.$parent.tenderPage = TRANSACTION_STATUS.INITIAL;

                $scope.showNonMobileFlow();
            };

            $scope.scanForCardTerminals = function () {
                var modalInstance = $modal.open({
                    templateUrl: 'common/modals/modalScanForCardTerminals.html',
                    controller: 'ScanForCardTerminalsController',
                    windowClass: 'smb-pos products2',
                    animation: false,
                    backdrop: 'static'
                });

                modalInstance.result.then(function (result) {
                    if (result && result.ip) {
                        var posStationCopy = angular.copy(SharedDataService.posStation);
                        posStationCopy.cardTerminalProperties.ip = result.ip;
                        posStationCopy.cardTerminalProperties.port = result.port;

                        Locations.updatePOSStation(
                            {},
                            posStationCopy,
                            function (response) {
                                SmbPosService.loadPOSStations(SharedDataService.locationId).then(function () {
                                    $scope.retryTransaction(true);
                                });
                            }
                        );
                    }
                });
            };

            $scope.manuallyInitializeOrCreateTransaction = async function () {
                var result = await $scope.$parent.manuallyInitializeOrCreateTransaction();

                if (result.initializeOnly) {
                    result.promise.then(function (response) {
                        if (response.hasBalance) {
                            $scope.setPage($scope.PAGE.TENDER_OPTIONS);

                            $scope.$parent.tendering = false;
                            $scope.$parent.tenderPage = TRANSACTION_STATUS.INITIAL;

                            $scope.currentTender = undefined;
                        }
                    });
                }
            };

            $scope.printReceipt = function () {
                if ($scope.tenderResponse) {
                    /* var creditCardTenders = _.where($scope.tenderData.allTenders, { type: 'card' });

                    var creditCardResponses = [];
                    _.each(creditCardTenders, function (tender) {
                        if (tender.receiptFields && tender.receiptFields.length) {
                            creditCardResponses = creditCardResponses.concat(tender.receiptFields);
                        }
                    })

                    PrintService.printReceipt(
                        $scope.tenderResponse,
                        $scope.receipt,
                        creditCardResponses,
                        false,
                        PrintType.CUSTOMER
                    ); */
                    var cardReceiptArr = ReceiptBuilderService.buildCardReceipt(
                        $scope.tenderResponse.terminalResponses);

                    PrintService.printReceipt(
                        $scope.tenderResponse,
                        $scope.receipt,
                        cardReceiptArr,
                        false,
                        PrintType.CUSTOMER);
                }
            };

            $scope.showPrintReceiptOptions = function () {
                $modal.open({
                    templateUrl: 'pos/smb/templates/pos.print.options.tpl.html',
                    controller: 'SmbPosPrintOptionsCtrl',
                    windowClass: 'smb-pos smb-pos-popup',
                    animation: false,
                    backdrop: true,
                    keyboard: false,
                    resolve: {
                        tenderData: function () {
                            return $scope.tenderData;
                        },
                        tenderResponse: function () {
                            return $scope.tenderResponse;
                        },
                        receipt: function () {
                            return $scope.receipt;
                        },
                        preorderDetails: function () {
                            return {
                                isPreorder: false,
                                preorder: {},
                            };
                        },
                    }
                });
            };

            $scope.emailReceipt = function (tenderAmounts) {
                tenderAmounts = tenderAmounts || $scope.tenderAmounts;

                return SmbPosService.emailReceipt(tenderAmounts);
            };

            $scope.startSignup = function () {
                $scope.showSignupForm = true;
                $scope.patronSignup = {};
            };
            $scope.endSignup = function (finishTransaction) {
                $scope.showSignupForm = false;
                $scope.showInputForm = false;
                $scope.showPatronSignupButton = false;
                $scope.emailingKioskReceipt = false;
                if (KioskService.isKiosk() && finishTransaction) {
                    $scope.finishLucovaTransaction();
                }
            };
            $scope.emailKioskReceipt = function () {
                clearTimeout($scope.kioskTimeoutId);
                document.addEventListener('click', kioskIdleDebounce);
                document.addEventListener('keydown', kioskIdleDebounce);
                const user = $scope.patron.lucovaUser;
                if (user && user.email) {
                    $scope.patronSignup = {firstName: user.first_name || '', lastName: user.last_name || '', emailAddress: user.email};
                    $scope.signup(true);
                } else {
                    $scope.emailingKioskReceipt = true;
                    $scope.startSignup();
                }
            };

            const kioskIdleDebounce = _.debounce(() => {
                document.removeEventListener('click', kioskIdleDebounce);
                document.removeEventListener('keydown', kioskIdleDebounce);
                $scope.endSignup(true);
            }, 30000);

            $scope.bypassScan = () => { };

            const searchPatronByEmail = _.debounce(async () => {
                $scope.searchingPatronEmail = true;
                const foundPatrons = await SmbPosService.searchPatronByEmail($scope.patronSignup.enteredPatronEmail)
                    .catch(() => {
                        // NOOP
                    });
                $scope.searchingPatronEmail = false;
                if (foundPatrons && foundPatrons.length) {
                    $scope.patrons = foundPatrons;
                    $scope.showPatronSignupButton = false;
                } else {
                    $scope.patrons = [];
                    $scope.showPatronSignupButton = true;
                }

                $scope.patronSignup.emailAddress = $scope.patronSignup.enteredPatronEmail;

            }, SEARCH_DEBOUNCE);

            $scope.searchPatronByEmail = () => {
                $scope.patrons = [];
                $scope.showInputForm = false;
                $scope.patronSelected = false;
                $scope.showPatronSignupButton = false;

                const emailToSearch = $scope.patronSignup.enteredPatronEmail || '';
                const isEmailValid = emailToSearch && emailToSearch.length && emailToSearch.length > 2;
                if (!isEmailValid) return;

                searchPatronByEmail();
            };

            $scope.fillPatronSignUp = function (selectedPatron) {
                $scope.patronSignup.enteredPatronEmail = selectedPatron.emailAddress;
                $scope.patronSignup.emailAddress = selectedPatron.emailAddress;
                $scope.patronSignup.firstName = selectedPatron.firstName;
                $scope.patronSignup.lastName = selectedPatron.lastName;
                $scope.showPatronSignupButton = false;
                $scope.patronSelected = true;
                $scope.patrons = [];
            };

            $scope.toggleInputForm = function () {
                $scope.patronSelected = false;
                $scope.showPatronSignupButton = false;
                $scope.showInputForm = true;
                $scope.patronSignup.firstName = '';
                $scope.patronSignup.lastName = '';
            };

            $scope.hidePatronDropdown = function () {
                $timeout(() => {
                    $scope.displayPatronDropdown = false;
                }, 200);
            };

            $scope.showPatronDropdown = function () {
                $scope.displayPatronDropdown = true;
            };

            $scope.signup = function (sendReceipt) {
                var signupObj = {
                    firstName: $scope.patronSignup.firstName,
                    lastName: $scope.patronSignup.lastName,
                    emailAddress: $scope.patronSignup.emailAddress
                };
                $scope.patronSignup.stage = 'signup';
                SmbPosService.signupNewPatron(signupObj).then(function (response) {
                    $scope.patronSignup.stage = 'collectPoints';
                    return SmbPosService.linkGuestToNewPatron(response, $scope.tenderAmounts);
                }, function (error) {
                    $scope.patronSignup.errorMsg = error.data.error;
                    OfflineEmailSignupService.pushSignup(signupObj, $scope.tenderAmounts, function () {
                        $scope.patronSignup.stage = 'collectPoints';
                    });
                }).then(function () {
                    if (PosStatusService.isOffline()) {
                        $scope.patronSignup.stage = 'success';
                        return;
                    }
                    if (sendReceipt) {
                        $scope.patronSignup.stage = 'emailReceipt';
                        $scope.emailReceipt($scope.tenderAmounts).then(function () {
                            $scope.patronSignup.stage = 'success';
                        }).catch(function (error) {
                            $scope.patronSignup.stage = 'error';
                        }).then(() => $scope.emailingKioskReceipt = true);
                    } else {
                        $scope.patronSignup.stage = 'success';
                    }
                });
            };

            var onTenderStatusChanged = function (event, tenderStatus, params) {
                if (tenderStatus === TRANSACTION_STATUS.COMPLETED
                    || tenderStatus === TRANSACTION_STATUS.SUMMARY) {
                    $scope.setPage($scope.PAGE.RECEIPT);

                    if (params && params.tenderResponse) {
                        $scope.tenderResponse = params.tenderResponse;
                    }

                    if ($scope.tenderResponse
                        && $scope.tenderResponse.giftCardPurchases
                        && $scope.tenderResponse.giftCardPurchases.length) {
                        mapGiftCardPurchases($scope.tenderResponse.giftCardPurchases);
                        $scope.showActivatedGiftCards($scope.tenderResponse.giftCardPurchases);
                    }

                    if (KioskService.isKiosk()) {
                        $scope.kioskTimeoutId = setTimeout(() => {
                            $scope.finishLucovaTransaction();
                        }, 10000);
                    }
                }
            };
            const onTenderStatusChangedListener = $rootScope.$on('pos::tenderStatusChanged', onTenderStatusChanged);

            var mapGiftCardPurchases = function (giftCardPurchases) {
                $scope.giftCardPurchaseMap = {};
                _.each(giftCardPurchases, function (giftCardPurchase) {
                    $scope.giftCardPurchaseMap[giftCardPurchase.code] = giftCardPurchase;
                });
            };

            $scope.showActivatedGiftCards = function (activatedGiftCards) {
                $modal.open({
                    templateUrl: 'pos/smb/templates/pos.giftcard.activation.tpl.html',
                    controller: 'SmbPosGiftCardActivationCtrl',
                    windowClass: 'smb-pos smb-pos__gift-card-modal modal-90',
                    animation: false,
                    backdrop: true,
                    resolve: {
                        activatedGiftCards: function () {
                            return activatedGiftCards;
                        },
                        transaction: function () {
                            return $scope.tenderAmounts;
                        }
                    }
                });
            };

            $scope.isPayNowDisabled = function () {
                // prevents multiple transaction submissions when the transaction is buy
                var isTendering = $scope.$parent.tendering;

                var currentTender = $scope.currentTender || {amount: 0};

                // prevents $0 tender
                var hasNoTender = currentTender.amount == 0 && $scope.tenderAmounts.remainingBalance > 0;
                // ensures transation is fully tendered when it's a non-split transaction
                var hasInsufficientTender = !$scope.tenderData.isSplit && $scope.tenderAmounts.remainingBalance > currentTender.amount;
                // ensures that customer cannot pay more than needed when it is not cash (since "change" is a "cash" concept and should not be allowed for other forms of tender)
                var hasExcessNonCashTender = (currentTender.type != 'cash' && currentTender.amount > $scope.tenderAmounts.remainingBalance);
                // ensures that when offline, gateway payment is at most $25
                var isOverOfflineGatewayDollarLimit = (currentTender.type == 'gateway' && ($scope.patron.fiitMpsAccount && $scope.patron.fiitMpsAccount.status === -1) && currentTender.amount > 25);

                var giftCardBalanceDollar = 0;
                if ($scope.giftCard) {
                    giftCardBalanceDollar = new Decimal($scope.giftCard.currentBalanceCents)
                        .dividedBy(new Decimal(100))
                        .toNearest(SharedDataService.baseDollar)
                        .toNumber();
                }
                var hasInsufficientGiftCardBalance = (currentTender.type === 'giftcard' && $scope.giftCard && (currentTender.amount > giftCardBalanceDollar));

                var fiitMpsBalanceDollar = 0;
                if ($scope.patron.fiitMpsAccount && $scope.patron.fiitMpsAccount.mealPlanSummary) {
                    fiitMpsBalanceDollar = new Decimal($scope.patron.fiitMpsAccount.mealPlanSummary.amountCents)
                        .dividedBy(new Decimal(100))
                        .toNearest(SharedDataService.baseDollar)
                        .toNumber();
                }
                var hasInsufficientFiitMpsBalance = (currentTender.type === 'gateway' && $scope.patron.fiitMpsAccount && (currentTender.amount > fiitMpsBalanceDollar));

                var isNotValidDenomination = !$scope.isValidDenomination(currentTender.type, currentTender.amount);

                return isTendering
                    || hasNoTender
                    || hasInsufficientTender
                    || hasExcessNonCashTender
                    || isOverOfflineGatewayDollarLimit
                    || hasInsufficientGiftCardBalance
                    || hasInsufficientFiitMpsBalance
                    || isNotValidDenomination;
            };

            var updateLoyaltyConfiguraiton = function () {
                // Because there isn't a way to pass whether loyalty is used into the tender screen without
                // introducing more parameters, use `SmbPosService.order` to carry the value forward to
                // perform a calculation right when the tender screen is initialized.
                $scope.adjustments.mealEquivalencyAdjustment = $scope.useLoyalty;

                if ($scope.adjustments.mealEquivalencyAdjustment) {
                    var loyaltyDiscountPercentage = LoyaltyRedemption.calculateLoyaltyDiscountPercentage($scope.receipt,
                        $scope.adjustments, $scope.available, organizationSettings);
                    var percentageValue = new Decimal(loyaltyDiscountPercentage).dividedBy(100).toDecimalPlaces(2).toNumber();
                    $scope.applyTransactionPercentageDiscount(percentageValue, true);
                }

                var calculated = SharedFunctionService.calculateBalances($scope.receipt, $scope.available, $scope.adjustments, $scope.posData, $scope.patron);
                $scope.updateTenderAmounts(calculated);

                // assuming no tender has been accepted yet
                $scope.totalAmountFromPosData = $scope.tenderAmounts.totalSales;
            };

            var runTransactionAutomatically = function () {
                var isRunningAutomatically = false;

                if ($scope.currentOrder.patron.lucovaUser
                    && $scope.tenderTypes
                    && $scope.tenderTypes.length === 1
                    && $scope.tenderTypes[0].value === 'mobile') {
                    $scope.selectTenderType($scope.tenderTypes[0].value, true);
                } else {
                    // Below means that the cart build so far is of $0 items or full discount
                    // CAUTION: While working with this since kiosk also use same functionality
                    // don't destroy $scope.tenderAmounts else where, this could break kiosk as this while interpreted
                    // as $0 item and transaction should be approved in this case
                    if ($scope.tenderAmounts.remainingBalance <= 0) {
                        $scope.tender();
                        isRunningAutomatically = true;
                    }
                }
                SecondaryDisplay.updateTransaction($scope.receipt, $scope.currentOrder.balance, $scope.tenderAmounts, $scope.currentOrder.patron, $scope.page);

                return isRunningAutomatically;
            };

            $scope.isValidDenomination = function (tenderType, amount) {
                if (tenderType !== 'cash') {
                    return true;
                }

                var minimumDenomination = CurrentSession.getCompany().minimumDenomination || 1;
                var amountCents = new Decimal(amount || 0).times(new Decimal(100)).round().toNumber();

                return (amountCents % minimumDenomination) === 0;
            };

            $scope.modifyExistingItem = function (receiptItem) {
                $scope.$parent.modifyExistingItem(receiptItem);
            };

            $scope.removeReceiptItem = function (receiptItem) {
                $scope.$parent.removeReceiptItem(receiptItem);
            };

            $scope.modifyItemQuantity = function (receiptItem, option) {
                $scope.$parent.modifyItemQuantity(receiptItem, option);
            };

            $scope.init = function () {
                $scope.adjustmentOptions = calculateAdjustmentOptions($scope.totalAmountFromPosData);

                $scope.useLoyalty = $scope.currentOrder.balance.useLoyalty;
                updateLoyaltyConfiguraiton();

                var isRunningAutomatically = runTransactionAutomatically();
                if (isRunningAutomatically) {
                    return;
                }

                /**
                 * Try to automatically show sb giftcard workflow
                 */
                if ($scope.patron && !$scope.patron.guest && !GatewayFiit.isEnabled()) {
                    if (canRedeemPoints() && !CompanyAttributesService.loyaltyStepsEnabled()) {
                        $scope.showLucovaManualTenderFlow(PAYMENT_METHODS.APP);
                    }
                }

                if ($scope.patron && !$scope.patron.guest && GatewayFiit.isEnabled()) {
                    if ($scope.patron.lucovaUser && $scope.patron.lucovaUser.fiit && $scope.patron.lucovaUser.fiit.patron_id) {
                        const patronId = $scope.patron.lucovaUser.fiit.patron_id;

                        if (patronId) {
                            SmbPosService.fetchPatronFromFiitBackend(patronId)
                            .then(function (response) {
                                $scope.patron.fiitMpsAccount = response.fiitMpsAccount;
                                $scope.patron.photoUrl = $scope.patron.fiitMpsAccount.photoUrl || $scope.patron.lucovaUser.asset_url;
                                $scope.patron.photoUrl = $scope.patron.lucovaUser.asset_url;
                                $scope.selectTenderType(UI_TENDER_TYPE.GATEWAY);
                            })
                            .catch(function (error) {
                                $scope.patron.photoUrl = $scope.patron.lucovaUser.asset_url;
                            });
                        }
                    } else if ($scope.patron.fiitMpsAccount) {
                        $scope.selectTenderType(UI_TENDER_TYPE.GATEWAY);
                    }
                }
            };

            generateTenderTypes().then($scope.init);


            $scope.tryExit = function (goToHome) {
                var canExit = checkCanExit();

                if (canExit) {
                    // This code executes when the user simply wants go back to the order screen (ie. to add more items to the transaction).
                    // Calling $dismiss will go bak to the order screen
                    if ($scope.patronAdjustments && $scope.patronAdjustments.usePoints) {
                        $scope.adjustments.percentDiscount = 0;
                        $scope.applyTransactionPercentageDiscount(0, true);
                    }
                    $scope.$dismiss({goToHome});
                } else {
                    var modalInstance = $modal.open({
                        templateUrl: 'pos/smb/templates/pos.tender.incomplete.tpl.html',
                        controller: 'SmbPosTenderIncompleteCtrl',
                        windowClass: 'smb-pos smb-pos-popup',
                        animation: false,
                        backdrop: true,
                        keyboard: false,
                        resolve: {
                            tenderData: function () {
                                return $scope.tenderData;
                            },
                            posObj: function () {
                                return $scope.tenderAmounts;
                            }
                        }
                    });

                    modalInstance.result.then(function () {
                        PrintService.printReceipt(
                            $scope.tenderData.lastTenderResponse,
                            $scope.receipt,
                            $scope.tenderAmounts.cardReceiptFields || [],
                            true);

                        // This code executes when the user wants to end the current order/transaction, so it makes more sense to
                        // call $close, which will return the screen all the way back to the home/initial POS screen.
                        $scope.$close();
                    });

                }
            };

            var checkCanExit = function () {
                var isTransationInitialized = !!$scope.tenderData.transactionId;
                var isTransactionComplete = $scope.completedTransaction;
                var hasUserAcknowledged = $scope.tenderData.canExit;

                return !isTransationInitialized || isTransactionComplete || hasUserAcknowledged;
            };

            $scope.$on('$destroy', function () {
                KioskService.disableTimeoutModal(false);
                document.removeEventListener('click', kioskIdleDebounce);
                document.removeEventListener('keydown', kioskIdleDebounce);
                onTenderStatusChangedListener();
            });
        }
    ]);
};
